import React, { useEffect, useState } from "react";
import { CallGETAPI } from "../../../../common/services";
import DynamicTblSkeleton from "../../../accounts/components/skeleton/table/DynamicTblSkeleton";
import { Skeleton } from "@mui/material";

const siteHoursobj = {
  sundayopen: "",
  mondayopen: "",
  tuesdayopen: "",
  wednesdayopen: "",
  thrusdayopen: "",
  fridayopen: "",
  saturdayopen: "",
  sundayclose: "",
  mondayclose: "",
  tuesdayclose: "",
  wednesdayclose: "",
  thrusdayclose: "",
  fridayclose: "",
  saturdayclose: "",
};

const SiteHours = ({ siteId, setEditSiteAdmin }) => {
  function compareSiteHours(siteHoursObj, receivedSiteHoursObj) {
    for (const key in siteHoursObj) {
      if (siteHoursObj?.hasOwnProperty(key)) {
        if (
          receivedSiteHoursObj?.hasOwnProperty(key) &&
          siteHoursObj[key] !== receivedSiteHoursObj[key]
        ) {
          return false;
        }
      }
    }
    return true;
  }

  function convertTo12Hour(timeString) {
    if (!timeString || timeString.toLowerCase() === "closed") {
      return "Closed";
    }

    const [hours, minutes] = timeString.split(":");
    const hour = parseInt(hours, 10);
    const minute = parseInt(minutes, 10);

    if (hour === 0) {
      return `12:${minutes} AM`;
    } else if (hour < 12) {
      return `${hour}:${minutes} AM`;
    } else if (hour === 12) {
      return `12:${minutes} PM`;
    } else {
      return `${hour - 12}:${minutes} PM`;
    }
  }

  function getDaySchedule(openTime, closedTime) {
    if (openTime && openTime.toLowerCase() === "closed") {
      return "Closed";
    } else if (openTime && closedTime) {
      return `${convertTo12Hour(openTime)} - ${convertTo12Hour(closedTime)}`;
    } else if (openTime) {
      return `${convertTo12Hour(openTime)} - `;
    } else if (closedTime) {
      return ` - ${convertTo12Hour(closedTime)}`;
    } else {
      return "";
    }
  }

  const [loading, setLoading] = useState(false);
  const [siteHoursData, setHoursData] = useState("");

  const fetchData = async () => {
    setLoading(true);
    const result = await CallGETAPI(
      "/site/site-sitehour-data/" + siteId + "?site-hours"
    );
    if (result?.data?.status) {
      setHoursData(result?.data?.data?.siteHours || {});
      const siteHours = result?.data?.data?.siteHours;
      setEditSiteAdmin((prev) => ({
        ...prev,
        siteHoursData: siteHours,
      }));
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      {/* {compareSiteHours(siteHoursobj, siteHoursData) == false && ( */}
        <>
          <table className="no-border">
            <tr className="bg-blue no-border">
              <th colSpan={5} className="tbl-title text-left no-border">
                Site Hours
              </th>
            </tr>

            <tr>
              <th>Sunday</th>
              <th>Monday</th>
              <th>Tuesday</th>
              <th>Wednesday</th>
              <th>Thursday</th>
              <th>Friday</th>
              <th>Saturday</th>
            </tr>
            <tr>
              <td>
                {loading ? (
                  <>
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  </>
                ) : (
                  getDaySchedule(
                    siteHoursData?.sundayopen,
                    siteHoursData?.sundayclosed
                  )
                )}
              </td>
              <td>
                {loading ? (
                  <>
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  </>
                ) : (
                  getDaySchedule(
                    siteHoursData?.mondayopen,
                    siteHoursData?.mondayclosed
                  )
                )}
              </td>
              <td>
                {loading ? (
                  <>
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  </>
                ) : (
                  getDaySchedule(
                    siteHoursData?.tuesdayopen,
                    siteHoursData?.tuesdayclosed
                  )
                )}
              </td>
              <td>
                {loading ? (
                  <>
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  </>
                ) : (
                  getDaySchedule(
                    siteHoursData?.wednesdayopen,
                    siteHoursData?.wednesdayclosed
                  )
                )}
              </td>
              <td>
                {loading ? (
                  <>
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  </>
                ) : (
                  getDaySchedule(
                    siteHoursData?.thrusdayopen,
                    siteHoursData?.thrusdayclosed
                  )
                )}
              </td>
              <td>
                {loading ? (
                  <>
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  </>
                ) : (
                  getDaySchedule(
                    siteHoursData?.fridayopen,
                    siteHoursData?.fridayclosed
                  )
                )}
              </td>
              <td>
                {loading ? (
                  <>
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  </>
                ) : (
                  getDaySchedule(
                    siteHoursData?.saturdayopen,
                    siteHoursData?.saturdayclosed
                  )
                )}
              </td>
            </tr>
          </table>
        </>
      {/* )} */}
    </>
  );
};

export default SiteHours;
