import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Edit from "../../../../common/img/Edit.png";
import SubHeadingOther from "../../../../common/components/SubHeadingOther";
import { CallGETAPI } from "../../../../common/services";
import AssignedAedTbl from "../components/AssignAedTbl";
import { getAssignedAed } from "../services";
import { Skeleton } from "@mui/material";

export default function AssignAed() {
  const navigate = useNavigate();
  const { accountId, siteId } = useParams();
  const [assignData, setAssignData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const aedId = searchParams.get("aed_id");
  const location = useLocation();
  const propsFromAedDetails = location?.state ? location?.state?.aedData : "";

  function transformAEDData(inputData) {
    return {
      data: [
        {
          site_name: inputData.site_name,
          account_name: inputData.account_name,
          site_id: inputData.site_id,
          data: [
            {
              aed_brand: `${inputData.brandName}/${inputData.modelName}`,
              // aed_details: {
                aed_id: inputData.aed_id,
                account_id: inputData.account_id,
                site_id: inputData.site_id,
                aed_brand_id: inputData.aed_brand_id,
                aed_model_id: inputData.aed_model_id,
                assign_user: inputData.assign_user,
                placement: inputData.placement,
                serial_number: inputData.serial_number
              // }
            }
          ]
        }
      ]
    };
  }
  
  // Example usage:
  const transformedData = transformAEDData(propsFromAedDetails);

  const HandleArr = (data) => {
    const arr = [];
    for (let i1 = 0; i1 < data.length; i1++) {
      const el1 = data[i1];
      const obj = {
        udi1: i1,
        site_id: el1.site_id,
        site_name: el1.site_name,
        account_name: el1.account_name,
        data: [],
        aed_id: "",
      };
      for (let index = 0; index < el1.data.length; index++) {
        const el2 = el1.data[index];
        if (el2?.aed_brand) {
          el2.aed_id = el2?.aed_id;
          obj.data.push(el2);
        }
      }
      arr.push(obj);
    }
    return arr;
  };

  function transformResponseData(response) {
    return response.map((site, index) => {
      return {
        udi1: index,
        site_id: site?.site_id || "",
        site_name: site?.site_name || "",
        account_name: site?.account_name || "",
        data:
          site?.data?.map((item) => ({
            aed_brand: item?.aed_brand || "",
            aed_id: item?.aed_details?.aed_id || "",
            account_id: item?.aed_details?.account_id || "",
            site_id: item?.aed_details?.site_id || "",
            serial_number: item?.aed_details?.serial_number || "unknown",
            assign_user: item?.aed_details?.assign_user || null,
            placement: item?.aed_details?.placement || "unknown",
          })) || [],
        aed_id: site?.aed_id || "",
      };
    });
  }

  const getDocumentsData = async () => {
    setLoading(true);
    let search_ar = "";
    if (accountId) {
      search_ar = "?account_id=" + accountId;
    }

    if (aedId) {
      if (search_ar != "") {
        search_ar += "&aed_id=" + aedId;
      } else {
        search_ar = "?aed_id=" + aedId;
      }
    }

    if (siteId) {
      //   const response = await CallGETAPI( "account/get-aed-list-by-site/" + siteId + search_ar);
      const response = await CallGETAPI(
        "aed/site-aed-for-assign/" + siteId + search_ar
      );

      if (response?.status) {
        const ar1 = HandleArr(response?.data.data);
        const arr = transformResponseData(ar1);
        setAssignData(arr);
      }
    } else {
      const result = await getAssignedAed(accountId);
      const ar1 = HandleArr(result);
      setAssignData(ar1);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (accountId && !propsFromAedDetails) {
      getDocumentsData();
    } else {
      setAssignData(transformAEDData(propsFromAedDetails).data)
    }
  }, [accountId]);

  const handleNavigate = (item) => {
    let url = "/account/assign-equipment/edit/" + Number(item.site_id);
    //   if(aedId){
    //     url  = url
    //     // +"?aed_id="+aedId
    //   }
    navigate(url, {
      state: {
        accountId: accountId,
        assignAedData: assignData,
        fromAed: !propsFromAedDetails ? 0 : 1,
      },
    });
  };

  return (
    <>
      {loading ? (
        <>
          <div className="mt-4" style={{ width: "100%", paddingInline: "0px" }}>
            <SubHeadingOther
              // title={""}
              hideNew={true}
              hideHierarchy={true}
              hideInstructor={true}
              subHeading={true}
              bottomLinks={false}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <h4 style={{ marginRight: "10px" }}>Account:</h4>
              <Skeleton
                width={"20%"}
                height={20}
                // style={{ marginLeft: "1%" }}
              />
            </div>

            <div className="mb-5">
              <div
                className="row"
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  marginLeft: "5px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {loading ? (
                  <Skeleton
                    width={"20%"}
                    height={20}
                    style={{ marginLeft: "40%" }}
                  />
                ) : (
                  ""
                )}

                <button
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    border: "none",
                    width: "70px",
                    backgroundColor: "transparent",
                    padding: "0%",
                  }}
                >
                  <img
                    style={{
                      width: "18px",
                      height: "18px",
                      marginRight: "3%",
                      marginBottom: "5px",
                      backgroundColor: "transparent",
                    }}
                    src={Edit}
                  />
                  <h1
                    style={{
                      color: "#0C71C3",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                      marginTop: "3%",
                    }}
                  >
                    {" "}
                    Edit
                  </h1>
                </button>
              </div>

              <AssignedAedTbl tableData={[]} showLoading={loading} />
            </div>
          </div>
        </>
      ) : (
        assignData && (
          <>
            <div
              className="mt-4"
              style={{ width: "100%", paddingInline: "0px" }}
            >
              <SubHeadingOther
                // title={assignData[0]?.data[0].account_id ? `Account: ${assignData[0]?.data[0].account_id}` : ""}
                title={`Account: ${assignData[0]?.account_name}`}
                hideNew={true}
                hideHierarchy={true}
                hideInstructor={true}
                subHeading={true}
                bottomLinks={false}
              />
              {assignData?.map((item, i) => {
                return (
                  <div key={`siteDetail${i}`} className="mb-5">
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        marginLeft: "5px",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <h1
                        style={{
                          color: "#000",
                          textAlign: "center",
                          fontSize: "20px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "normal",
                          width: "90%",
                          textTransform: "uppercase",
                        }}
                      >
                        {item?.site_name}
                      </h1>

                      <button
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          border: "none",
                          width: "70px",
                          backgroundColor: "transparent",
                          padding: "0%",
                        }}
                        onClick={() => handleNavigate(item)}
                      >
                        <img
                          style={{
                            width: "18px",
                            height: "18px",
                            marginRight: "3%",
                            marginBottom: "5px",
                            backgroundColor: "transparent",
                          }}
                          src={Edit}
                        />
                        <h1
                          style={{
                            color: "#0C71C3",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            marginTop: "3%",
                          }}
                        >
                          {" "}
                          Edit
                        </h1>
                      </button>
                    </div>

                    <AssignedAedTbl
                      tableData={item?.data || []}
                      showLoading={loading}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )
      )}
    </>
  );
}
