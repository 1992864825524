import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import "./tab.scss";

const CustomTabBar = ({
  tabs,
  onTabChange,
  activeTab: propActiveTab,
  navProps = {},
  navItemProps = {},
  navLinkProps = {},
}) => {
  const [internalActiveTab, setInternalActiveTab] = useState(tabs[0]?.id || '');
  
  const activeTab = propActiveTab !== undefined ? propActiveTab : internalActiveTab;

  const handleSelect = (selectedKey) => {
    if (propActiveTab === undefined) {
      setInternalActiveTab(selectedKey);
    }
    onTabChange(selectedKey);
  };

  return (

    <Nav variant="tabs" 
    className='bg-primary custom-tab' 
    // className="text-light tab-button"
     activeKey={activeTab} onSelect={handleSelect} {...navProps}>
      {tabs.map((tab) => (
            
        <Nav.Item key={tab.id} {...navItemProps} {...tab.itemProps}  className='tab-item' >
          <Nav.Link 
            eventKey={tab.id} 
            {...navLinkProps} 
            {...tab.linkProps}
            className='tab-item-a'
            // className={` ${tab.id===activeTab?'':'text-white'}`}
          >
            {tab.title}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  );
};

export default CustomTabBar;