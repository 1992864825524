import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Box, FormControlLabel, Switch } from "@mui/material";
import SubHeadingOther from "../../../common/components/SubHeadingOther";
import "../CSS/pop.css"
import SubHeading from "../../accounts/components/header/SubHeading"
import {
    Form,
    Button as BButton,
    Button as BsButton,
    InputGroup,
} from "react-bootstrap";
// import MessageHandler from "../../../common/components/MessageHandler";
// import Select from "react-select";
import DataGrid, {
    Scrolling,
    Paging,
    Column,
} from "devextreme-react/data-grid";
// import Stack from "@mui/material/Stack";
// import TextField from "@mui/material/TextField";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import dayjs from "dayjs";
import CustomToggleButton from "../../../common/components/toggleSwitch/CustomToggleButton";
import Contacts from "../../../common/img/Contacts.svg";
import Locations from "../../../common/img/Locations.svg";
import AEDCabinet from "../../../common/img/AEDCabinet.svg";
import Adult from "../../../common/img/Adult.svg";
import pediatricPad from "../../../common/img/pediatricPad.svg";
import Battery from "../../../common/img/Battery.svg";
import RMSBattery from "../../../common/img/RMSBattery.svg";
import Cancel from "../../../common/img/Cancel.svg";
import Check from "../../../common/img/Check.svg";
import Edit from "../../../common/img/Edit.png";
import Equipment from "../../../common/img/Equipment.svg";
import Accessories from "../../../common/img/Accessories.svg";
import MoneyBag from "../../../common/img/MoneyBag.svg";
import Courses from "../../../common/img/Courses.svg";
import { CallGETAPI, CallPOSTAPI } from "../../../common/services/index";
import PopEquipment from "../../PopManagement/Pages/PopEquipment";
import PopAccessories from "../../PopManagement/Pages/PopAcessories";
import PopCourses from "../../PopManagement/Pages/PopCourses";
// import AddSitespagemodal from "./AddSitesPage";
import AddSitesmodal from "../../PopManagement/Pages/AddSitesPage";
// import AddContactsmodal from "./AddContactspage";
// import InpersonContactModel from "../../../components/modals/InpersonContactModel/InpersonContactModel";
import {
    ContactList,
    FetchAccountDetails,
    GetCertAgencyList,
    PriceFormat,
} from "../../../common/helper/BasicFn";
import {
    Debounce,
    FormatDate,
    RemoveDollarSign,
    addDollarSign,
    prepareOptions,
} from "../../../common/helper/Common";
import { toast } from "react-toastify";
import AddContactsmodalNew from "../Pages/AddContactsmodalNew";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../src/global.css";
import CommonDatePicker from "../../../common/components/DatePickers/CommonDatePicker"
import { resetAllPops } from "../../../store/slices/EquipmentSlice";

const NewPop = () => {

    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [validatedContract, setValidatedContract] = useState(false);
    const { accountId } = useParams();

    const dispatch = useDispatch();

    const EquipEquipmentList = useSelector(
        (state) => state.equipment.equipmentList
    );
    const ContractEquipmentList = useSelector(
        (state) => state.equipment.ContractEquipmentList
    );
    // ContractEquipmentList
    const EquipAccessoriesList = useSelector(
        (state) => state.equipment.AssesoriesList
    );
    const ContractAccessoriesList = useSelector(
        (state) => state.equipment.ContractAssesoriesList
    );
    const PopCourseListData = useSelector(
        (state) => state.equipment.POPCourseList
    );

    const [planTypes, setPlanTypes] = useState([]);
    const [selectedPlanType, setSelectedPlanType] = useState("");
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [equipmentModal, setEquipmentModal] = useState(false);
    const [accessoriesModal, setAccessoriesModal] = useState(false);
    const [type, setType] = useState(null);
    const [edit, setEdit] = useState("");
    const [courseModal, setCourseModal] = useState(false);
    const [addSitesModal, setAddSitesModal] = useState(false);
    const [addContactsModal, setAddContactsModal] = useState(false);
    const [isEquipmentSelected, setIsEquipmentSelected] = useState(false);
    const [getPlanType, setGetPlanType] = useState([]);
    const [cerifyAgencyList, setCertifyAgency] = useState([]);
    const [ContractPricingData, setContractPricingData] = useState([]);
    const [purchaseProEquip, setPurchaseProEquip] = useState([]);
    const [purchaseProAssesory, setPurchaseProAssesory] = useState([]);
    const [ContractPricEquip, setContractPricEquip] = useState([]);
    const [ContractPricAssesory, setContractPricAssesory] = useState([]);
    const [showSitesError, setShowSitesError] = useState(false);
    const [showContactsError, setShowContactsError] = useState(false);
    const [ShowGraceError, setShowGraceError] = useState(false);
    const [ShowPopError, setShowPopError] = useState(false);
    const [formData, setFormData] = useState({
        contractNumber: "",
        reqNumber: "",
        orderNumber: "",
        modificationNumber: "",
        contractStart: null,
        contractYear: "",
        OfYear: "",
        gracePeriod: "",
        popType: "",
        qbInvoiceNumber: "",
        invoicePaid: 0,
        invoicingInstructions: "",
        contacts: "",
        sites: "",
        planType: "",
        rental: false,
        visits: "Annual",
        rms: true,
        accessoriesIncluded: [
            "MoneyBag",
            "Battery",
            "Battery",
            "Adult",
            "Adult",
            "AEDCabinet",
            "RMSBattery",
        ],
        contractCLINS: "",
        RMSMonthlyPrice: "",
        Price: 0,
    });
    const [loading, setLoading] = useState(false);
    const textAreaRef = useRef(null);
    const saveForm = async () => {
        if (isEquipmentSelected) {
            if (!selectedPlanType) {
                setValidated(true);
                toast.error("Please Fill All The Required Details");
                return;
            }
        }
        // !formData.contractType ||
        if (
            !formData.popType
            // !formData.contacts ||
            // !formData.sites ||
            // !(isEquipmentSelected && !selectedPlanType)
        ) {
            setValidated(true);
            toast.error("Please Fill All The Required Details");
            return;
        }
        const siteIds = selectedSites.map((it) => it.account_site_info_id);
        if (!siteIds) {
            toast.error("Please Select Site");
            return "";
        }
        const checkSelectedContact =
            selectedContact?.contract_officer?.length > 0 ||
            selectedContact?.contracting_officer_rep?.length > 0 ||
            selectedContact?.other_reps?.length > 0;
        if (!checkSelectedContact) {
            toast.error("Please Select Contact");
            return "";
        }
        setLoading(true);
        const EquipmentIds = EquipEquipmentList.map(
            (it) => it.equipment_id
        ).toString();
        const EquipmentAssesIds = EquipAccessoriesList.map(
            (it) => it.accessories_id
        ).toString();
        const COntractAssesIds = ContractAccessoriesList.map(
            (it) => it.accessories_id
        ).toString();
        const ContractEquipmentIds = ContractEquipmentList.map(
            (it) => it.equipment_id
        ).toString();
        const CourseIds = PopCourseListData.map((it) => it.id).toString();

        const payLoad = {
            account_id: accountId,
            contract: formData.contractNumber,
            req: formData.reqNumber,
            order: formData.orderNumber,
            modification: formData.modificationNumber,
            of_year: formData.numberOfYears,
            contract_start: formData.contractStart,
            contract_year: formData.contractYear,
            pop_type: formData.popType,
            grace_period: formData.gracePeriod,
            qb_invoice: formData.qbInvoiceNumber,
            invoice_paid: formData?.invoicePaid || 0,
            invoicing_instructions: formData.invoicingInstructions,
            contact: selectedContact,
            sites: siteIds.toString(),
            plan_id: selectedPlanType,
            active:0,

        };

        let result = await CallPOSTAPI("pop/add-pop", payLoad);
        if (result.data.status) {
            navigate('/account-details/' + accountId)
            toast.success("Pop Added Successfully");
        } else {
            toast.error(result.data.msg);
        }
        setFormData({ type: result?.data?.status, msg: result?.data?.data });
        setLoading(false);
    };
    // save form
    //   const saveForm = async() => {
    //   setFormData({ type: result?.data?.status, msg: result?.data?.data });
    // };



    // const debouncedPriceFunction = Debounce(calculateYearlyPrice, 1000);

    const handleSubmit = (event) => {
        event.preventDefault();

        saveForm(accountId);

        let formIsValid = true;

        // Check contractStart
        if (!formData.contractStart) {
            setValidatedContract(true);
            formIsValid = false;
        } else {
            setValidatedContract(false);
        }

        // Check gracePeriod
        if (formData.gracePeriod === "") {
            setShowGraceError(true);
            formIsValid = false;
        } else {
            setShowGraceError(false);
        }

        // Check popType
        if (formData.popType === "") {
            setShowPopError(true);
            formIsValid = false;
        } else {
            setShowPopError(false);
        }

        // Check selected contacts
        const checkSelectedContact =
            selectedContact?.contract_officer?.length > 0 ||
            selectedContact?.contracting_officer_rep?.length > 0 ||
            selectedContact?.other_reps?.length > 0;

        if (!checkSelectedContact) {
            setShowContactsError(true);
            formIsValid = false;
        } else {
            setShowContactsError(false);
        }

        // Check selected sites
        if (selectedSites.length === 0) {
            setShowSitesError(true);
            formIsValid = false;
        } else {
            setShowSitesError(false);
        }

        // Check other form fields


        // If form is not valid, stop submission
        if (!formIsValid) {
            return;
        }

        // If everything is valid, submit the form
        // saveForm();
    };

    const calendarIcon = () => {
        return <img src="/calendar.svg" alt="calendar" style={{ marginLeft: "40px;" }} />;
    };

    const addSites = () => {
        setAddSitesModal(true);
    };

    const addContacts = () => {
        setAddContactsModal(true);
    };

    const handlePopTypeChange = (event) => {
        const selectedPopType = event.target.value;
        setIsEquipmentSelected(selectedPopType === "Equipment");
        setFormData({
            ...formData,
            popType: selectedPopType,
        });

        dispatch(resetAllPops());

    };

    const handleGracePeriod = (event) => {
        const selectedGracePeriod = event.target.value;

        setFormData({
            ...formData,
            gracePeriod: selectedGracePeriod,  // Update gracePeriod in formData
        });
    };


    // Fetch plan types from the API and set them in the state
    const [accountDetails, setAccountDetails] = useState({});

    const fetchPlanTypes = async () => {
        try {
            const response = await CallGETAPI("pop/get-plans");
            const planTypesData = response?.data?.data || [];
            if (planTypesData?.length > 0) {
                setPlanTypes(planTypesData);
                // planTypes.push(planTypesData)
            }





            if (accountId) {
                const AccDetails = await FetchAccountDetails(accountId);
                setAccountDetails(AccDetails);
            }

            // let cart = await GetCertAgencyList();
            // if (cart.status) {
            //     let CertAgencyData = cart?.data?.agenciesList;
            //     let allCertAgencyData = prepareOptions(
            //         CertAgencyData,
            //         "certifying_agency_id",
            //         "certifying_agency_name"
            //     );
            //     setCertifyAgency(allCertAgencyData);
            // }
        } catch (error) {
            console.error("Error fetching plan types:", error);
        }
    };

    // Function to handle Plan Type selection
    const handlePlanTypeChange = async (selectedOption) => {
        setSelectedPlanType(selectedOption);
    };


    const fetchData = async () => {
        try {
            const response = await CallGETAPI(
                `pop/get-plan-by-id/${selectedPlanType}`
            );
            if (response) {
                setGetPlanType(response?.data.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        if (selectedPlanType) {
            fetchData();
        }
    }, [selectedPlanType]);

    // export const addDollarSign = (value) =>
    // {
    // 	value = value.replace('$', '');
    // 	const regex = /^\d*\.?\d*$/;

    // 	if (regex.test(value))
    // 	{
    // 		return '$' + value;
    // 	} else
    // 	{
    // 		return '$';
    // 	}
    // }
    const [ofYearList, setOfYearList] = useState([]);

    const handleChange = (e, fieldName) => {
        let value = e.target.value;
        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                value = 1;
            } else {
                value = 0;
            }
        }

        if (e.target.name === "numberOfYears") {
            // Parse the value from the input
            const numYears = parseInt(e.target.value);

            // Initialize the array
            let ar1 = [];

            // Check if the parsed number is valid
            if (!isNaN(numYears) && numYears >= 0) {
                // Populate the array based on the number of years
                for (let i = 0; i <= numYears; i++) {
                    if (i === 0) {
                        ar1[0] = "Base";
                    } else {
                        ar1.push(i);
                    }
                }
            } else {
                // Handle the case where the input is invalid or not a number
                ar1 = [1]; // Default value or handle as needed
            }

            // Update the state
            setOfYearList(ar1);
        }

        const oldData = { ...formData };

        // if(fieldName==='RMSMonthlyPrice'){
        //   oldData[fieldName]  = PriceFormat(value);
        // }else{
        //   oldData[fieldName]  = value;
        // }
        oldData[fieldName] = value;

        setFormData(oldData);

    };
    // contract year change value
    // handle calendar change
    const handleCalendarChange = (name, date) => {
        let dateValue = null;
        if (date) {
            setValidatedContract(false);
            // const formattedDate = dayjs(date).format("MM/DD/YYYY");
            const formattedDate = date ? FormatDate(date) : '';
            setFormData((prevData) => ({
                ...prevData,
                [name]: formattedDate,
            }));
        }
    };

    const [accountContact, setAccountContact] = useState([]);
    const [selectedContact, setSelectedContacts] = useState({
        contract_officer: [],
        contracting_officer_rep: [],
        other_reps: [],
    });
    // const [contactRepsList,    ] = useState([]);
    const [selectedSites, setSelectedSites] = useState([]);

    const fetchContactByAccount = async () => {
        let AccountContactList = await ContactList(accountId);

        if (AccountContactList) {
            setAccountContact(AccountContactList);
        }
    };
    useEffect(() => {
        fetchPlanTypes();
        fetchContactByAccount();
    }, []);
    useEffect(() => {
        // Reset the value only if the current contractYear is no longer in the updated list
        if (!ofYearList.includes(formData.contractYear)) {
            setFormData((prev) => ({ ...prev, contractYear: "" }));
        }
    }, [ofYearList]);

    const adjustTextareaHeight = () => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = "10px"; // Reset height
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`; // Set to scroll height
        }
    };

    // Adjust textarea height when content changes
    useEffect(() => {
        adjustTextareaHeight();
    }, [formData.invoicingInstructions]);

    useEffect(() => {
        const checkSelectedContact =
            selectedContact?.contract_officer?.length > 0 ||
            selectedContact?.contracting_officer_rep?.length > 0 ||
            selectedContact?.other_reps?.length > 0;
        if (!checkSelectedContact) {
            // setShowContactsError(true);
        } else {
            setShowContactsError(false);
        }
    }, [selectedContact]);

    return (
        <>

            <div className="mt-4" style={{ position: "relative", width: "100%", paddingInline: "45px" }}>
                <SubHeading
                    hideNew="tab"
                    title={"Account : " + (accountDetails?.account_name ? accountDetails?.account_name : "")}
                    newUrl="/new-account"
                    subHeading={true}
                    hideHierarchy={
                        true
                    }

                />

                <Form
                    className=""
                    onSubmit={handleSubmit}
                    noValidate
                    validated={validated}
                    id="create-new-equipment-form"
                >
                    <div
                        className="container-fluid mt-4 border-blue pt-2"
                        style={{
                            borderBottom: "solid rgb(13, 110, 253)",
                            background: "#eee",
                            paddingBottom: "20px",
                        }}
                    >
                        <h2 className="heading">Period of Performance Information</h2>

                        {/* <div className="row my-3"> */}
                        <div style={{ background: "#eee", }}>

                            <div className="row">
                                {/* Container with Flexbox */}
                                <div className="d-flex flex-wrap">
                                    {/* Contract # */}
                                    <Form.Group className="col mr-3 mb-3" style={{ maxWidth: "200px" }}>
                                        <Form.Label>Contract #</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="contractNumber"
                                            value={formData.contractNumber}
                                            onChange={(e) => handleChange(e, "contractNumber")}
                                            style={{
                                                borderRadius: "4px", // Add curve to the edges
                                                borderColor: "#bbb",
                                                padding: "4px",
                                                height: "40px",
                                                width: "100%",
                                                fontSize: "14px"
                                            }}
                                        />
                                    </Form.Group>

                                    {/* Req # */}
                                    <Form.Group className="col mr-3 mb-3" style={{ maxWidth: "200px" }}>
                                        <Form.Label>Req #</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="reqNumber"
                                            value={formData.reqNumber}
                                            onChange={(e) => handleChange(e, "reqNumber")}
                                            style={{
                                                borderRadius: "4px", // Add curve to the edges
                                                borderColor: "#bbb",
                                                padding: "4px",
                                                height: "40px",
                                                width: "100%",
                                                fontSize: "14px"
                                            }}
                                        />
                                    </Form.Group>

                                    {/* Order # */}
                                    <Form.Group className="col mr-3 mb-3" style={{ maxWidth: "200px" }}>
                                        <Form.Label>Order #</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="orderNumber"
                                            value={formData.orderNumber}
                                            onChange={(e) => handleChange(e, "orderNumber")}
                                            style={{
                                                borderRadius: "4px", // Add curve to the edges
                                                borderColor: "#bbb",
                                                padding: "4px",
                                                height: "40px",
                                                width: "100%",
                                                fontSize: "14px"
                                            }}
                                        />
                                    </Form.Group>

                                    {/* Modification # */}
                                    <Form.Group className="col mr-3 mb-3" style={{ maxWidth: "220px" }}>
                                        <Form.Label>Modification #</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="modificationNumber"
                                            value={formData.modificationNumber}
                                            onChange={(e) => handleChange(e, "modificationNumber")}
                                            style={{
                                                borderRadius: "4px", // Add curve to the edges
                                                borderColor: "#bbb",
                                                padding: "4px",
                                                height: "40px",
                                                width: "100%",
                                                fontSize: "14px"
                                            }}
                                        />
                                    </Form.Group>

                                    {/* Contract Start */}
                                    <Form.Group className="col mr-3 mb-3" style={{ maxWidth: "200px" }}>
                                        <Form.Label>Contract Start*</Form.Label>
                                        <div
                                            className={
                                                validatedContract
                                                    ? "d-flex align-items-center calendar-input-btn invalid-datepicker-div"
                                                    : "d-flex align-items-center calendar-input-btn"
                                            }
                                            style={{
                                                borderRadius: "4px", // Add curve to the edges
                                                borderColor: "#bbb",
                                                padding: "4px",
                                                height: "40px",
                                                width: "100%",
                                                fontSize: "14px",
                                            }}
                                        >
                                            <CommonDatePicker
                                                calName={"contractStart"}
                                                CalVal={formData.contractStart ? FormatDate(formData.contractStart) : null}
                                                HandleChange={(name, val) => handleCalendarChange(name, val)}
                                                disabled={false}
                                            />
                                        </div>
                                        {validatedContract && (
                                            <div className="invalid mt-2">This field is required.</div>
                                        )}
                                    </Form.Group>

                                    {/* # of Year */}
                                    <Form.Group className="col mr-3 mb-3" style={{ maxWidth: "200px" }}>
                                        <Form.Label># of Year</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="numberOfYears"
                                            value={formData.numberOfYears}
                                            onChange={(e) => handleChange(e, "numberOfYears")}
                                            style={{
                                                borderRadius: "4px", // Add curve to the edges
                                                borderColor: "#bbb",
                                                padding: "4px",
                                                height: "40px",
                                                width: "100%",
                                                fontSize: "14px"
                                            }}
                                        />
                                    </Form.Group>

                                    {/* Contract Year */}
                                    <Form.Group className="col mr-3 mb-3" style={{ maxWidth: "220px" }}>
                                        <Form.Label>Contract Year</Form.Label>
                                        <div className="custom-select-wrapper">
                                            <select
                                                className="form-control custom-select"
                                                value={formData.contractYear}
                                                name="contractYear"
                                                onChange={(e) => handleChange(e, "contractYear")}
                                                style={{
                                                    borderRadius: "4px", // Add curve to the edges
                                                    borderColor: "#bbb",
                                                    height: "40px",
                                                    width: "100%",
                                                    fontSize: "14px"
                                                }}
                                            >
                                                <option value="">--Select One--</option>
                                                {ofYearList.length > 0
                                                    ? ofYearList.map((it) => (
                                                        <option key={it} value={it}>
                                                            {it}
                                                        </option>
                                                    ))
                                                    : ""}
                                            </select>

                                            <div className="custom-arrow"></div>
                                        </div>
                                    </Form.Group>
                                </div>

                            </div>

                            {/* </div> */}

                            <div className="row my-4">
                                <div className="col-12 d-flex flex-wrap">
                                    {/* POP Type */}
                                    <Form.Group className="mr-3 mb-3 flex-grow-1" style={{ maxWidth: "200px" }}>
                                        <Form.Label>POP Type*</Form.Label>
                                        <div className="custom-select-wrapper">
                                            <select
                                                className="form-control"
                                                name="popType"
                                                value={formData.popType}
                                                onChange={(e) => {
                                                    handlePopTypeChange(e); // Update form data
                                                    if (e.target.value !== "") {
                                                        setShowPopError(false); // Clear error if value is filled
                                                    }
                                                }}
                                                required
                                                style={{
                                                    borderRadius: "4px", // Add curve to the edges
                                                    borderColor: "#bbb",
                                                    height: "40px",
                                                    width: "100%",
                                                    fontSize: "14px"
                                                }}
                                            >
                                                <option value="">--Select One--</option>
                                                <option value="Equipment">Equipment</option>
                                                <option value="Training">Training</option>
                                            </select>
                                        </div>
                                        {ShowPopError && (
                                            <div className="invalid mt-2">This field is required.</div>
                                        )}
                                    </Form.Group>

                                    {/* Conditionally Rendered Plan Type */}
                                    {formData?.popType === "Equipment" && planTypes.length > 0 && (
                                        <Form.Group className="mr-3 mb-3 flex-grow-1" style={{ maxWidth: "200px" }}>
                                            <Form.Label>Plan Type*</Form.Label>
                                            <select
                                                className="form-control"
                                                name="planType"
                                                value={selectedPlanType}
                                                onChange={(e) => handlePlanTypeChange(e.target.value)}
                                                required
                                                style={{
                                                    borderRadius: "4px", // Add curve to the edges
                                                    borderColor: "#bbb",
                                                    height: "40px",
                                                    width: "100%",
                                                    fontSize: "14px"
                                                }}
                                            >
                                                <option value="">- Select Plan Type -</option>
                                                {planTypes.map((planType) => (
                                                    <option key={planType.id} value={planType.id}>
                                                        {planType.plan_name}
                                                    </option>
                                                ))}
                                            </select>
                                            <Form.Control.Feedback type="invalid">
                                                This field is required
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )}

                                    {/* Grace Period */}
                                    <Form.Group className="mr-3 mb-3 flex-grow-1" style={{ maxWidth: "200px" }}>
                                        <Form.Label>Grace Period*</Form.Label>
                                        <div className="custom-select-wrapper">
                                            <select
                                                className="custom-select form-control"
                                                name="gracePeriod"
                                                value={formData.gracePeriod}
                                                onChange={(e) => {
                                                    handleGracePeriod(e); // Update form data
                                                    if (e.target.value !== "") {
                                                        setShowGraceError(false); // Clear error if value is filled
                                                    }
                                                }}
                                                required
                                                style={{
                                                    borderRadius: "4px", // Add curve to the edges
                                                    borderColor: "#bbb",
                                                    height: "40px",
                                                    width: "100%",
                                                    fontSize: "14px"
                                                }}
                                            >
                                                <option value="">--Select One--</option>
                                                <option value="15">15 Days</option>
                                                <option value="30">30 Days</option>
                                                <option value="45">45 Days</option>
                                                <option value="60">60 Days</option>
                                            </select>
                                        </div>
                                        {ShowGraceError && (
                                            <div className="invalid mt-2">This field is required.</div>
                                        )}
                                    </Form.Group>

                                    {/* QB Invoice # */}
                                    <Form.Group className="mr-3 mb-3 flex-grow-1" style={{ maxWidth: "200px" }}>
                                        <Form.Label>QB Invoice #</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="qbInvoiceNumber"
                                            value={formData.qbInvoiceNumber}
                                            onChange={(e) => handleChange(e, "qbInvoiceNumber")}
                                            style={{
                                                borderRadius: "4px", // Add curve to the edges
                                                borderColor: "#bbb",
                                                height: "40px",
                                                width: "100%",
                                                fontSize: "14px"
                                            }}
                                        />
                                    </Form.Group>

                                    {/* Invoice Paid */}
                                    <Form.Group className="mr-3 mb-3" style={{ maxWidth: "150px" }}>
                                        <Form.Label>Invoice Paid</Form.Label>
                                        <CustomToggleButton
                                            ToggleName="invoicePaid"
                                            ToggleValue={formData?.invoicePaid}
                                            changeHandler={(e) => handleChange(e, "invoicePaid")}
                                            style={{ height: "7px" }}
                                        />
                                    </Form.Group>
                                </div>
                            </div>



                            <div className="row">
                                <Form.Group className={"NewPoptypeField"} style={{ maxWidth: "auto" }}>
                                    <Form.Label>Invoicing Instructions</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        ref={textAreaRef}
                                        name="invoicingInstructions"
                                        value={formData.invoicingInstructions}
                                        onChange={(e) => handleChange(e, "invoicingInstructions")}
                                        style={{
                                            wordWrap: "break-word", // Word wrap property
                                            overflowWrap: "break-word", // Overflow wrap property
                                            overflow: "hidden", // Hide content that overflows
                                            height: "10px", // Minimum height for the textarea
                                            resize: "none", // Disable resizing (optional, allows full control)
                                            borderRadius: "4px", // Add curve to the edges
                                            marginBottom: "25px"
                                        }}
                                    />
                                </Form.Group>
                            </div>


                            <div className="row ">
                                <div className={"col NewPopContactField"}>
                                    <Form.Group className="col">
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Form.Label>Contacts*</Form.Label>
                                            <button
                                                style={{ border: "none", backgroundColor: "transparent", height: "17px" }}
                                                onClick={() => addContacts(true)}
                                                type="button"
                                            >
                                                <img src={Contacts} alt="" />
                                                <Form.Label
                                                    style={{
                                                        color: "rgb(12, 113, 195)",
                                                        fontFamily: "Calibri",
                                                        fontStyle: "normal",
                                                        marginLeft: "3px",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    Contacts
                                                </Form.Label>
                                            </button>
                                        </div>

                                        {addContactsModal && (
                                            <AddContactsmodalNew
                                                addContactsModal={addContactsModal}
                                                setAddContactsModal={setAddContactsModal}
                                                accountId={accountId}
                                                accountContact={accountContact}
                                                selectedContact={selectedContact}
                                                setSelectedContacts={setSelectedContacts}
                                            />
                                        )}

                                        <div
                                            className="data-table col-md-12 bg-white"
                                            style={{ height: "150px", overflowY: "auto" }} // Added overflow style here
                                        >
                                            <table
                                                className="w-100 border-gray"
                                                style={{ minWidth: "100%" }} // Ensure the table fills the container width
                                            >
                                                <thead style={{ backgroundColor: "#999999" }}>
                                                    <tr style={{ backgroundColor: "#999999" }}>
                                                        <th
                                                            scope="col"
                                                            width="33%"
                                                            className="py-2 px-2 bg-tbl-border border-t-blue border-l-blue border-r-blue"
                                                        >
                                                            Contract Officer
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            width="33%"
                                                            className="py-2 px-2 bg-tbl-border border-t-blue border-r-blue"
                                                        >
                                                            Contract Officer Rep
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            width="33%"
                                                            className="py-2 px-2 bg-tbl-border border-t-blue border-r-blue"
                                                        >
                                                            Other Rep
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="odd-even-row">
                                                    {Math.max(
                                                        selectedContact?.contract_officer.length || 0,
                                                        selectedContact?.contracting_officer_rep.length || 0,
                                                        selectedContact?.other_reps.length || 0
                                                    ) > 0 &&
                                                        Array.from(
                                                            {
                                                                length: Math.max(
                                                                    selectedContact?.contract_officer.length || 0,
                                                                    selectedContact?.contracting_officer_rep.length || 0,
                                                                    selectedContact?.other_reps.length || 0
                                                                )
                                                            },
                                                            (_, rowIndex) => (
                                                                <tr key={rowIndex}>
                                                                    <td className="px-2 bg-tbl-border border-l-blue border-r-blue border-b-blue">
                                                                        {selectedContact?.contract_officer[rowIndex]?.contact_name || ''}
                                                                    </td>
                                                                    <td className="py-2 px-2 bg-tbl-border border-r-blue border-b-blue">
                                                                        {selectedContact?.contracting_officer_rep[rowIndex]?.contact_name || ''}
                                                                    </td>
                                                                    <td className="py-2 px-2 bg-tbl-border border-r-blue border-b-blue">
                                                                        {selectedContact?.other_reps[rowIndex]?.contact_name || ''}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                </tbody>
                                            </table>
                                        </div>

                                        {showContactsError && (
                                            <div className="invalid mt-2">This field is required.</div>
                                        )}
                                        <Form.Control.Feedback type="invalid">
                                            This field is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>


                                <div className={"col NewPopContactField"}>
                                    <Form.Group className={"col"}>
                                        <div
                                            style={{ display: "flex", justifyContent: "space-between" }}
                                        >
                                            <Form.Label>Sites*</Form.Label>
                                            <button
                                                style={{ border: "none", cursor: "pointer", backgroundColor: "transparent" }}
                                                onClick={() => addSites()}
                                                type="button"
                                            >
                                                <img src={Locations} alt="" />
                                                <Form.Label
                                                    style={{
                                                        color: "rgb(12, 113, 195)",
                                                        fontFamily: "Calibri",
                                                        fontStyle: "normal",
                                                        marginLeft: "3px",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    Sites
                                                </Form.Label>
                                            </button>
                                        </div>
                                        {/* <Form.Control
                                      type="text"
                                      name="sites"
                                      value={formData.sites}
                                      onChange={(e)=>handleChange(e,'sites')}
                                      required
                                      style={{ height: "150px" }}
                                    /> */}
                                        <div className="bg-white border-t-blue border-b-blue border-r-blue border-l-blue">
                                            <ul
                                                className=""
                                                style={{
                                                    height: "145px",
                                                    overflow: "auto",
                                                    listStyle: "none",
                                                    paddingLeft: "10px",
                                                }}
                                            >
                                                {selectedSites?.map((site) => (
                                                    <li key={site?.id} className="list-item mt-2 pl-1">
                                                        <span>{site?.account_site_name}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                        {selectedSites.length <= 0 && showSitesError && (
                                            <div className="invalid mt-2">This field is required.</div>
                                        )}

                                        {/* <Form.Control.Feedback type="invalid">
                                        This field is required
                                      </Form.Control.Feedback> */}
                                    </Form.Group>
                                </div>
                            </div>

                            {addSitesModal && (
                                <AddSitesmodal
                                    accountId={accountId}
                                    addSitesModal={addSitesModal}
                                    setAddSitesModal={setAddSitesModal}
                                    selectedSites={selectedSites}
                                    setSelectedSites={setSelectedSites}

                                />

                            )}
                        </div> </div >







                    <div className="row pb-3 py-5">
                        <div className="col-12 content-flex-right">
                            <button
                                className="btn btn-danger text-uppercase"
                                type="button"
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-success text-uppercase ms-2"
                                type="submit"

                                disabled={loading}

                            >
                                {loading ? "Loading..." : "Submit"}
                            </button>
                        </div>
                    </div>
                </Form >



            </div >
        </>
    );
};

export default NewPop;
