import React, { useEffect, useState } from 'react'
import { CallGETAPI } from '../../../../common/services';
import { Box } from '@mui/material';
import DataTableSkeleton from './ProgramDetailsSkeleton';

const ProgramDetails = ({accountId, setProgramData})=> {
    const [programDetails,setProgramDetails] = useState({});
    const [loading,setLoading] = useState(false);

    const fetchData = async()=>{
        setLoading(true);
        const result  = await CallGETAPI('account/acc-details-programs/'+accountId);
        if(result?.data?.status){
            setProgramDetails(result?.data?.data)
            setProgramData(result?.data?.data)
        }
        setLoading(false);
    }

    useEffect(()=>{
        fetchData();
    },[accountId])
  return (
    <>
              {/* Program Details */}
      <Box className="text-left pt-3 ">
        <h4 className="heading">Program Details</h4>
      </Box>

      <table className="w-100 border-b-blue">
        <tbody>
          <tr className="">
            <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">
              Active Sites
            </th>
            <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">
              AEDS
            </th>
            <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">
              Equipment
            </th>
            <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">
              CPR Trained
            </th>
            <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">
              AED Check length
            </th>
            <th className=" py-1 px-2 bg-tbl-border border-t-blue">
              Extra Fields
            </th>
          </tr>
          {loading ? <DataTableSkeleton/> : 
          <tr className="">
            <td className=" py-1 px-2 border-r-blue">{programDetails?.activeSites}</td>
            <td className=" py-1 px-2 border-r-blue">{programDetails?.aeds}</td>
            <td className=" py-1 px-2 border-r-blue">{programDetails?.equipment}</td>
            <td className=" py-1 px-2 border-r-blue">{programDetails?.crp_trained}</td>
            <td className=" py-1 px-2 border-r-blue">{programDetails?.aed_check_length}</td>
            <td> {programDetails?.extra_field1} {programDetails?.extra_field2 ? ", "+programDetails?.extra_field2:''}</td>
          </tr>}
        </tbody>
      </table>


    </>
  )
}

export default ProgramDetails