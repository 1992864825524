import React from "react";
import SubHeading from "../components/header/SubHeading";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const navigate = useNavigate();

  return (
    <>
      <div className="mt-4">
        <SubHeading
          hideNew={true}
          hideHierarchy={true}
          title={"Dashboard"}
          newUrl="/new-account"
          subHeading={true}
        />

        <div
          className="text-center py-5"
          style={{
            minHeight: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            className="btn btn-primary"
            onClick={() => navigate("/accounts-listing")}
          >
            Go to Account Listings
          </button>
        </div>
      </div>
    </>
  );
}
