// // src/components/ProtectedRoute.js
// import React from 'react';
// import {  Route, Routes } from 'react-router-dom';
// import AccountCreate from '../domains/accountManagement/pages/AccountCreate';
// import { withPermissionAndBreadcrumbs } from '../common/hooks/withPermissionAndBreadcrumbs';

import Layout from "../common/components/layout";
import Dashboard from "../domains/dashboard/pages/Dashboard";
import AccountsListing from "../domains/accounts/pages/accounts-listing";
import AdminAccount from "../domains/accounts/pages/AdminAccount";
import AdminSiteEdit from "../domains/sitesManagments/pages/AdminSiteEdit";
import AdminSiteNew from "../domains/sitesManagments/pages/AdminSiteNew";
import SiteMain from "../domains/sitesManagments/pages/SiteMain";
import SitePage from "../domains/sitesManagments/pages";
import EquipmentListing from "../domains/EquipmentManagement/pages/EquipmentListing";
import SiteTrainingNew from "../domains/sitesManagments/pages/SiteTrainingNew";
import PopDetails from "../domains/PopManagement/Pages/PopDetails";
import PopEdit from "../domains/PopManagement/Pages/PopEdit"
import AccountDetails from "../domains/accounts/pages/account-details";
import AccountEdit from "../domains/accounts/pages/account-edit";
import ContactDetails from "../domains/contactManagement/pages/ContactMain";
import EditContact from "../domains/contactManagement/pages/EditContact";
import AddNewContacts from "../domains/contactManagement/pages/AddNewContacts";
import NewStandloneAcce from "../domains/Accessories/pages/NewStandloneAcce";
import SiteTrainingEdit from "../domains/sitesManagments/pages/SiteTrainingEdit";
import SitesListing from "../domains/sitesManagments/pages/SitesListing";
import ContactListing from "../domains/contactManagement/pages/ContactListing";
import AccessoriesListing from "../domains/Accessories/pages/AccessoriesListing";
import AedMain from "../domains/Aed/AedDetails/pages/AedMain";
import MoveAed from "../domains/Aed/MoveAed/pages/MoveAed";
import EditAed from "../domains/Aed/EditAed/pages/EditAed";
import NewAed from "../domains/Aed/NewAed/pages";
import NewPop from "../domains/PopManagement/Pages/NewPop";
import MoveAccessory from "../domains/Aed/MoveAccessory/pages/MoveAccessory";
import AedCheckSelect from "../domains/Aed/AedDetails/components/AedCheckSelect";
import AEDChecksDetails from "../domains/Aed/AedDetails/components/AEDChecksDetails";
import PopList from "../domains/accounts/components/tabs/PopList";
import Pops from "../domains/accounts/components/tabs/Pops";
import AssignAed from "../domains/Aed/AssignAed/pages/AssignAed";
import EditAssignAed from "../domains/Aed/AssignAed/pages/EditAssignedAed";
import AedServiceCheck from "../domains/Aed/ServiceCheck/pages/AedServiceCheck";
import AedServiceCheckService1 from "../domains/Aed/ServiceCheck/pages/AedServiceCheckService1";
import AedServiceCheckService2 from "../domains/Aed/ServiceCheck/pages/AedServiceCheckService2";
import AEDServiceDetails from "../domains/Aed/AedDetails/components/aedService/AEDServiceDetails";
import UserDashboard from "../domains/dashboard/pages/UserDashboard";
import UserProfile from "../domains/profile/pages/UserProfile";

// const ProtectedRoute = () => {


//   return (
//     <Routes>
//       <Route path="/dashboard" element={withPermissionAndBreadcrumbs(AccountCreate,"dashboard")()} />
//       <Route path="/account/create" element={withPermissionAndBreadcrumbs(AccountCreate,"dashboard")()} />
//       <Route path="/account/edit/:accont_id" element={withPermissionAndBreadcrumbs(AccountCreate,"dashboard")()} />
//       <Route path="/account/details/:accont_id" element={withPermissionAndBreadcrumbs(AccountCreate,"dashboard")()} />
//       <Route path="/account/listing/" element={withPermissionAndBreadcrumbs(AccountCreate,"dashboard")()} />
//     </Routes>
//   )
// };

// export default ProtectedRoute;

const ProtectedRoute = [
  { title: "Dashboard", path: "/dashboard", component: <Layout><Dashboard /></Layout> },
  { title: "Dashboard", path: "/user-dashboard", component: <Layout><UserDashboard /></Layout> },
  { title: "UserProfile", path: "/user-profile/:contactId", component: <Layout><UserProfile /></Layout> },
  { title: "Accounts", path: "/accounts-listing", component: <Layout><AccountsListing /></Layout> },
  { title: "New Standlone Acce", path: "/admin-account", component: <Layout><AdminAccount /></Layout> },
  { title: "Account Details", path: "/account-details/:accountId", component: <Layout><AccountDetails /></Layout> },
  { title: "Account Edit", path: "/account/accounts-edit/:accountId", component: <Layout><AccountEdit /></Layout> },
  { title: "Account-Create", path: "/account/create", component: <Layout><Dashboard /></Layout> },
  { title: "Account-Update", path: "/account/update/:account_id", component: <Layout><Dashboard /></Layout> },
  { title: "Account-Details", path: "/account/details/:account_id", component: <Layout><Dashboard /></Layout> },

  // Sites path
  { title: "Site-Create", path: "/admin-siteNew/:accountId", component: <Layout><AdminSiteNew /></Layout> },
  { title: "Site-Details", path: "/account/site-details/:siteId", component: <Layout><SiteMain /></Layout> },
  { title: "Site-Edit", path: "/admin-siteEdit/:accountId/:siteId", component: <Layout><AdminSiteEdit /></Layout> },
  { title: "Site-Training-New", path: "/account/:accountId/site/:siteId/training/new", component: <Layout> <SiteTrainingNew /> </Layout> },
  { title: "Site-Training-Edit", path: "/account/:accountId/site/:siteId/:DetailID/training/edit", component: <Layout> <SiteTrainingEdit /> </Layout> },
  { title: "Sites Management", path: "/sites/", component: <Layout><SitePage /></Layout> },
  { title: "Sites Listing", path: "/Admin/Sites-listing", component: <Layout><SitesListing /></Layout> },

  // Contacts Path
  { title: "Contact-Create", path: "/account/contacts/new/:accountId", component: <Layout><AddNewContacts /></Layout> },
  { title: "Contact-Details", path: "/account/:accountId/contact-details/:contactId", component: <Layout><ContactDetails /></Layout> },
  { title: "Contact-Edit", path: "/account/contact-details-edit/:contactId", component: <Layout><EditContact /></Layout> },
  { title: "Contact Listing", path: "/Admin/Contact-listing", component: <Layout><ContactListing /></Layout> },



  // Aed Equipments
  { title: "AED-Listing", path: "/aed/:accountId",component: <Layout><EquipmentListing /></Layout>},
  { title: "AED-New", path: "/account/new/aed/:accountId/:site_id", component: <Layout> <NewAed /> </Layout>},
  { title: "AedDetails", path: "/move-aed/:accountId/:siteId", component: <Layout> <MoveAed /> </Layout>},
  { title: "Move-Accessory", path: "/move-accessory/:accountId/:siteId", component: <Layout> <MoveAccessory /> </Layout>},
  { title: "AED-Edit", path: "/account/aed/edit/:aedId/:siteId", component: <Layout> <EditAed /> </Layout>},
  { title: "AED-Assigned", path: "/assign-quipment/:accountId", component: <Layout> <AssignAed /> </Layout>},
  { title: "AED-Assigned", path: "/assign-quipment/:accountId/:siteId", component: <Layout> <AssignAed /> </Layout>},
  { title: "AED-Assigned-Edit", path: "/account/assign-equipment/edit/:siteId", component: <Layout> <EditAssignAed /> </Layout>},
  {title: "Aed Service Check", path: "/account/aed/service-check/:accountId/:siteId", component: <Layout>  <AedServiceCheck /> </Layout>},
  {title: "Aed Service Check Service1", path: "/account/aed/service-check/service1/:accountId/:siteId/:aedId", component: <Layout><AedServiceCheckService1 /></Layout>},
  {title: "Aed Service Check Service2", path: "/account/aed/service-check/service2/:serviceQuestionID", component: <Layout><AedServiceCheckService2 /></Layout>},
  
  // Aed Details
  {title: "AedDetails", path: "/account/aed-details/:aedId", component: <Layout><AedMain /></Layout> },
  {title: "AedCheckSelect", path: "/account/aed-details/check-select", component: <Layout><AedCheckSelect /> </Layout>},
  {title: "AEDChecksDetails", path: "/account/aed-checks-details/:aedId/:checkId", component: <Layout><AEDChecksDetails /> </Layout>},
  {title: "AED Service Details", path: "/account-details/AEDServiceDetails/:aedId/:serviceQuestionId", component: <Layout><AEDServiceDetails />,</Layout>},

  // account/aed/NewStandloneAcce
  { title: "New Standalone", path: "/account/aed/NewStandloneAcce/:accountId/:siteId", component: <Layout><NewStandloneAcce /></Layout> },
  { title: "Accessory Listing", path: "/Admin/accessory-listing", component: <Layout><AccessoriesListing /></Layout> },

  // pop
  // { title: "Pop List", path: "/account/new-pop", component: <Layout><Pops /></Layout> },
  { title: "New Pop", path: "/account/new-pop/:accountId", component: <Layout><NewPop /></Layout> },
  { title: "pop-Details", path: "pop/get-pop-performance/:popId/:accountId", component: <Layout><PopDetails /></Layout> },
  { title: "pop-Edit", path: "/account/pop-details-edit/:popId/:accountId", component: <Layout><PopEdit /></Layout> },
  { title: "pop-List", path: "/pop/pop-list-by-account/:accountId", component: <Layout><Pops /></Layout> },


];

export default ProtectedRoute;