import React from 'react';
import CustomToggleButton2 from '../../../../common/components/toggleSwitch/CustomToggleButton2';

const AedSerialComponent = ({ AedItem, serviceQuestionData, handleToggleChange, handleAlarmReady, index }) => {
  return (
    <table className="theme-table w-full">
      <thead>
        <tr>
          <td className="border border-r-blue" colSpan={2}>Type</td>
          <td className="border border-r-blue" colSpan={2}>Brand / Model</td>
          <td className="border border-r-blue" colSpan={3}>Serial #</td>
          <td className="border border-r-blue" colSpan={3}>Placement</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={2}>AED</td>
          <td colSpan={2}>{AedItem?.brandModel}</td>
          <td colSpan={3}>{AedItem?.serial_number}</td>
          <td colSpan={3}>{AedItem?.placement}</td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <td className="border border-r-blue no-capitalization" colSpan={2}>Present / Ready</td>
          <td className="border border-r-blue no-capitalization" colSpan={2}>Replaced rescue kit</td>
          <td className="border border-r-blue no-capitalization" colSpan={2}>Replaced alarm battery</td>
          <td className="border border-r-blue no-capitalization" colSpan={2}>Replaced Accessories</td>
          <td className="border border-r-blue no-capitalization" colSpan={2}>Support Ticket</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={2}>
            <CustomToggleButton2
              ToggleName="AedReadyStatusToggle"
              ToggleValue={AedItem?.AedReadyStatusToggle}
              changeHandler={(e) => {
                handleToggleChange("AedReadyStatusToggle", e.target.checked, index);
                if (AedItem) {
                  AedItem.ReplacingAeds = 0;
                  AedItem.loaner_toggle = 0;
                }
              }}
              is_read_only={serviceQuestionData?.aeds_ready_status === true ? true : ""}
            />
          </td>
          <td colSpan={2}>
            <CustomToggleButton2
              ToggleName="RecueKitToggle"
              ToggleValue={AedItem?.RecueKitToggle}
              changeHandler={(e) => handleToggleChange("RecueKitToggle", e.target.checked, index)}
              is_read_only={serviceQuestionData?.rescue_kits_status === false ? true : ""}
            />
          </td>
          <td colSpan={2}>
            <CustomToggleButton2
              ToggleName="AlarmBatteryToggle"
              ToggleValue={AedItem?.AlarmBatteryToggle}
              changeHandler={(e) => handleToggleChange("AlarmBatteryToggle", e.target.checked, index)}
              is_read_only={() => handleAlarmReady(AedItem, serviceQuestionData)}
            />
          </td>
          <td colSpan={2}>
            <CustomToggleButton2
              ToggleName="ReplaceAccessoriesToggle"
              ToggleValue={AedItem?.ReplaceAccessoriesToggle}
              changeHandler={(e) => handleToggleChange("ReplaceAccessoriesToggle", e.target.checked, index)}
              is_read_only={serviceQuestionData?.accessories_status === false ? true : ""}
            />
          </td>
          <td colSpan={2}>
            <CustomToggleButton2
              ToggleName="SupportTicketToggle"
              ToggleValue={AedItem?.SupportTicketToggle}
              changeHandler={(e) => handleToggleChange("SupportTicketToggle", e.target.checked, index)}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default AedSerialComponent;