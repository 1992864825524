import React, { useState, useEffect } from "react";
import { Form, Button as BButton, Button as BsButton } from "react-bootstrap";
import Button from "@mui/material/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { CallPOSTAPI } from "../../../common/services";
import { DecryptToken, prepareOptions, sortData } from "../../../common/helper";
import {
  ContactDropDowns,
  FetchDropDowns,
  GetAccountEditContactList,
  GetCountries,
  ProductsDropDown,
} from "../../../common/helper/BasicFn";
import Loading from "../../../common/components/Loader/Loading";
import EditContactMultiEmailForm from "../components/common/EditContactMultiEmailForm";
import SubHeading from "../../../common/components/SubHeading";
import AddContactPhoneFrom from "../../../common/components/AddContactPhoneFrom";
import ContactModalPhone from "../../../common/components/ContactModalPhone";
import ContactModalEmail from "../../../common/components/ContactModalEmail";
import MessageHandler from "../../../common/components/MessageHandler";
import StateField from "../../../common/components/StateField";
import ToogleSwitch from "../../../common/components/toggleSwitch/ToogleSwitch";
import GlobalDropdown from "../../../common/components/custom-ui/GlobalDropdown";

const EditContact = ({ setShowSidebar }) => {
  const location = useLocation();
  const [loading2, setLoading2] = useState(false);
  const [mainError, setMainError] = useState(false);
  const [ProductModalData, setProductModalData] = useState([]);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    deletePhone: [],
    deleteEmail: [],
  });
  const navigate = useNavigate();
  const [listType, setListType] = React.useState("");
  const [openContactModal, setContactModal] = React.useState(false);
  const [phoneValidation, setPhoneValidation] = useState({});
  const user = DecryptToken();
  const [countryList, setCountryList] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState({});
  const { contactId } = useParams();

  const resetForm = () => {
    document.getElementById("create-new-account-form").reset();
  };

  const [multiEmailFormCount, setMultiEmailFormCount] = useState([
    {
      account_main_contact_email_id: "",
      account_main_contact_email: "",
      email_type_id: "",
      account_main_contact_email_main: 0,
      account_main_contact_id: contactId
    },
  ]);

  const MultiEmailFormIncrease = () => {
    let arr = [...multiEmailFormCount];
    let obj = {
      account_main_contact_email: "",
      email_type_id: "",
      account_main_contact_email_main: 0,
      account_main_contact_id: contactId
    };
    arr.push(obj);
    setMultiEmailFormCount(arr);
  };

  const MultiEmailFormDecrease = (index1) => {
   let arr = [...multiEmailFormCount];
    if(multiEmailFormCount[index1]?.account_main_contact_email_main == "0") {
    let deleteObject = multiEmailFormCount[index1];

    if (!formData.deleteEmail) {
      formData.deleteEmail = [];
  }
     formData.deleteEmail.push(deleteObject);

    if (index1 >= 0 && multiEmailFormCount.length > 1) {
      if (arr[index1].account_main_contact_email_main !== 1) {
        const updateArr = arr.filter((item, index) => index !== index1);
        setMultiEmailFormCount(updateArr);
      } else {
        toast.error("Main contact cannot be removed");
      }
    }
  }
  };

  const handleInputChange = (e) => {
    console.log("EditContact1", e.target.name, e.target.value)
    if (e.target.type === "checkbox") {
      setFormData((old) => ({ ...old, [e.target.name]: e.target.checked }));
    } else {
      setFormData((old) => ({ ...old, [e.target.name]: e.target.value }));
    }
  };

  const [altTrainerForm1, setAltTrainerForm1] = useState([
    {
      account_main_contact_phone_id: "",
      account_main_contact_phone: "",
      account_main_contact_phone_ext: "",
      phone_type_id: "",
      account_main_contact_phone_main: 0,
      account_main_contact_id:contactId
    },
  ]);

  const increaseAlternative1 = () => {
    let arr = [...altTrainerForm1];
    let obj = {
      account_main_contact_phone_id: "",
      account_main_contact_phone: "",
      account_main_contact_phone_ext: "",
      phone_type_id: "",
      account_main_contact_phone_main: 0,
      account_main_contact_id:contactId
    };
    arr.push(obj);
    setAltTrainerForm1(arr);
  };

  const decreaseAlternative1 = (index1) => {
    let arr = [...altTrainerForm1];
    if(altTrainerForm1[index1]?.account_main_contact_phone_main == "0") {
    
     let deleteObject = altTrainerForm1[index1];

     if (!formData.deletePhone) {
      formData.deletePhone = [];
  }
     formData.deletePhone.push(deleteObject);

    if (index1 >= 0 && altTrainerForm1.length > 1) {
      const updateArr = arr.filter((_, index) => index !== index1);
      setAltTrainerForm1(updateArr);
    }
  }
  };

  const updatedPhone = (data) => {
    setAltTrainerForm1(data);
  };
  const [allDropDowns, setAllDropDowns] = React.useState([]);
  const [switchValue, setSwitchValue] = useState({});

  // handle select change
  const handleSelectChange = (data, key) => {
    setSelectedCountry((old) => ({
      ...old,
      [key]: {
        label: data.label,
        value: data.value,
      },
    }));

    setFormData((old) => ({ ...old, [key]: data.value }));
  };

  const [FormMsg, setFormMsg] = React.useState({ type: true, msg: "" });
  const [loading, setLoading] = React.useState(false);


  const checkisMainContact = (arr, key) =>
    {
      let newarr = arr.find((a) => (a[ key ]) ? true : false);
  
      return newarr ? true : false;
    }
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // check for phone validations
    for (const [key, value] of Object.entries(phoneValidation)) {
      if (value) {
        setLoading(false);
        setValidated(true);
        return;
      }
    }

    // setValidated();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setLoading(false);
      setValidated(true);
      return;
    }

    SaveForm();
  };

  const SaveForm = async () => {
    formData.contact_id = contactId;
    let arr = formData;
    arr.training_optout = formData.training_optout ? 1 : 0;
    console.log({formData})
    multiEmailFormCount.map((email, index) => {
      if (
        email.account_main_contact_email_id == "" ||
        email.account_main_contact_email_id == null ||
        email.account_main_contact_email_id == undefined
      ) {
        email.account_main_contact_email_id = "";
        email.email_type_id = email?.email_type_id == "0" ? "" : email.email_type_id; 
      }
      email.account_main_contact_id = contactId;
    });

    altTrainerForm1.map((phone, index) => {
      if (
        phone.account_main_contact_phone_id == "" ||
        phone.account_main_contact_phone_id == null ||
        phone.account_main_contact_phone_id == undefined
      ) {
        phone.account_main_contact_phone_id = "";
        phone.phone_type_id = phone?.phone_type_id == "0" ? "" : phone.phone_type_id;
      }
      phone.account_main_contact_id = contactId;
    });

    arr.email = multiEmailFormCount;
    arr.phone = altTrainerForm1.filter((it)=>it.account_main_contact_phone!="") || [] //altTrainerForm1;
    // console.log({DeletedPhone: arr});return "";
    const DeletedArr =  altTrainerForm1.filter((it)=>it.account_main_contact_phone==""  && it.account_main_contact_phone_id!="")
    arr.deletePhone  =  arr?.deletePhone ? [...DeletedArr,...arr.deletePhone] : DeletedArr;// .push(deleteObject);
    // console.log({})
    // console.log({PhoneNumberTextin: arr.phone}); return "";
    // console.log({email:arr.email, phne: arr.phone});return "";
    if (arr.phone.length > 0) {
      let mainPhone  = arr.phone.find((data) => {
        return data.account_main_contact_phone_main == 1;
      });
    }
    if (arr.email.length > 0) {
      let mainEmail  = arr.email.find((data) => {
        return data.account_main_contact_email_main == 1;
      });
    }
    // account_main_contact_phone_main

    arr.deletePhone = !formData?.deletePhone ? [] : formData?.deletePhone;
    arr.deleteEmail = !formData?.deleteEmail ? [] : formData?.deleteEmail;

    console.log({CheckPhoneHere:arr.phone});
    let checkMainPhone = checkisMainContact(arr.phone, 'account_main_contact_phone_main');
    let checkMainEmail = checkisMainContact(arr.email, 'account_main_contact_email_main');


    if (!checkMainPhone  && arr.phone.some(i => i?.account_main_contact_phone) && arr.phone?.length > 1)
      {
        setListType('phone');
        setContactModal(true);
        return false;
      } else if(!checkMainPhone  && arr.phone?.length === 1 && arr.phone[0]?.account_main_contact_phone?.length === 10){
        arr.phone[0].account_main_contact_phone_main = 1;
      }
  
      if (!checkMainEmail && multiEmailFormCount?.length > 1)
      {
        setListType('email');
        setContactModal(true);
        setLoading(false);
        return false;
      } else if(!checkMainEmail && multiEmailFormCount?.length === 1) {
        arr.email[0].account_main_contact_email_main = 1;  
      }
      // console.log({finalPayload: arr});return "";
    const result = await CallPOSTAPI("contact/update-contact-details", arr);
   
    if(result?.data?.status){
    toast.success(result?.data?.msg);
    // setLoading(false);
    let url = "";
    if (
      user?.user_type === 0 ||
      (user?.user_type === 2 && user?.sub_admin != "")
    ) {
      // url = "/account-details/" + formData?.account_id;
      url = `/account/${formData?.account_id}/contact-details/${contactId}`;
    } else {
      url = "/user/Details/" + formData?.account_id;
    }
    navigate(url, {
      state: {
        tab: "Contacts",
        type: result?.data?.status,
        msg: result?.data?.msg,
      },
    });
  }else{
    toast.error('Something went wrong please try again');
  }
  };

  // switchChangeHandle
  const switchChangeHandle = (switchValue, e) => {
    setFormData((old) => ({ ...old, [switchValue?.key]: switchValue?.value }));
  };

  // check for switch value and update values
  useEffect(() => {
    switchChangeHandle(switchValue);
  }, [switchValue]);

  useEffect(() => {
    setFormData(location?.state?.contactInfoDetails?.contact_details);

    setMultiEmailFormCount(
      location?.state?.contactInfoDetails?.emails?.length > 0
        ? location?.state?.contactInfoDetails?.emails
        : [
            ...multiEmailFormCount,
            ...location?.state?.contactInfoDetails?.emails,
          ]
    );
    setAltTrainerForm1(
      location?.state?.contactInfoDetails?.phone_numbers.length > 0
        ? location?.state?.contactInfoDetails?.phone_numbers
        : [
            ...altTrainerForm1,
            ...location?.state?.contactInfoDetails?.phone_numbers,
          ]
    );

    setFormData((prev) => ({
      ...prev,
      "contact_state": location?.state?.contactInfoDetails?.contact_details?.contact_state,
    }))

    setSelectedCountry((old) => ({
      ...old,
      ["contact_country"]: {
        label: location?.state?.contactInfoDetails?.contact_details?.contact_country_name,
        value: location?.state?.contactInfoDetails?.contact_details?.contact_country,
      },
    }));
   
  }, [location]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      contact_state: location?.state?.contactInfoDetails?.contact_details?.contact_state || "", 
      contact_state_name: location?.state?.contactInfoDetails?.contact_details?.contact_state_name || "",
    }));
    console.log({formData})
  },[])

  console.log("location", location?.state?.contactInfoDetails)

  return (
    <>
          <div className="mt-4">
            <SubHeading
              hideNew
              hideHierarchy
              subHeading={true}
              title="Edit Contact"
            />
          </div>

          <Form
            className=""
            onSubmit={handleSubmit}
            noValidate
            validated={validated}
            id="create-new-account-form"
          >
            <div className="contailer-fluid px-2">
              <div
                className="container-fluid bottom-border-blue pb-4 pt-2"
                style={{ background: "#eee" }}
                id="account_main_contact_section"
              >
                <h2 className="text-left heading">Account Main Contact</h2>
                <div className="row mb-4 mt-3">
                  <Form.Group className={"col"}>
                    <Form.Label>Salutation</Form.Label>
                    <Form.Control
                      type="text"
                      name="account_main_contact_salutation"
                      // onChange={(e) => handleInputChange(e)}
                      onInput={handleInputChange}
                      defaultValue={formData.account_main_contact_salutation}
                    />
                  </Form.Group>

                  <Form.Group className={"col"}>
                    <Form.Label>First Name* </Form.Label>
                    <Form.Control
                      type="text"
                      name="account_main_contact_firstname"
                      // onChange={handleInputChange}
                      onInput={handleInputChange}
                      defaultValue={formData.account_main_contact_firstname}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter First Name.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className={"col"}>
                    <Form.Label>Middle Name </Form.Label>
                    <Form.Control
                      type="text"
                      name="account_main_contact_middlename"
                      // onChange={handleInputChange}
                      onInput={handleInputChange}
                      defaultValue={formData.account_main_contact_middlename}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Middle Name.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className={"col"}>
                    <Form.Label>Last Name*</Form.Label>
                    <Form.Control
                      type="text"
                      name="account_main_contact_lastname"
                      // onChange={handleInputChange}
                      onInput={handleInputChange}
                      defaultValue={formData.account_main_contact_lastname}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Last Name.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className={"col"}>
                    <Form.Label>Suffix</Form.Label>
                    <Form.Control
                      type="text"
                      name="account_main_contact_suffix"
                      // onChange={handleInputChange}
                      onInput={handleInputChange}
                      defaultValue={formData.account_main_contact_suffix}
                    />
                  </Form.Group>

                  <Form.Group className={"col"}>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="account_main_contact_title"
                      // onChange={handleInputChange}
                      onInput={handleInputChange}
                      defaultValue={formData.account_main_contact_title}
                    />
                  </Form.Group>
                  <Form.Group className={"col"}>
                    <Form.Label>Department</Form.Label>
                    <Form.Control
                      type="text"
                      name="account_main_contact_department"
                      // onChange={handleInputChange}
                      onInput={handleInputChange}
                      defaultValue={formData.account_main_contact_department}
                    />
                  </Form.Group>

                  <Form.Group className={"col"}>
                    <Form.Label>Contact Status</Form.Label>

                    {/* <Form.Select
                      className={""}
                      name="contact_status_id"
                      onChange={handleInputChange}
                      value={formData.contact_status_id}
                    >
                      <option value="0" selected>
                        --Select One--
                      </option>
                      {allDropDowns?.contactStatus &&
                        allDropDowns?.contactStatus.map((CS, index) => (
                          <option
                            key={index}
                            value={CS.dropdown_contact_status_id}
                          >
                            {CS.contact_status_type}
                          </option>
                        ))}
                    </Form.Select> */}

                    <GlobalDropdown
                      dropDownName={"contact_status_id"}
                      apiEndpoint={"contact/contact-dropdowns"}
                      idKey={"dropdown_contact_status_id"}
                      valueKey={"contact_status_type"}
                      parentKey={"contactStatus"}
                      onSelect={(e) => {
                        handleInputChange(e);
                      }}
                      shouldFetchOnOpen={true}
                      isCache={true}
                      initialSelectedValue={
                        location?.state?.contactInfoDetails?.contact_details
                          ?.contact_status_id
                      }
                      defaultValue={{
                        id: location?.state?.contactInfoDetails?.contact_details
                          ?.contact_status_id,
                        value:
                          location?.state?.contactInfoDetails?.contact_details
                            ?.contact_status_name,
                      }}
                    />
                  </Form.Group>
                </div>

                <AddContactPhoneFrom
                  altTrainerForm={altTrainerForm1}
                  setSubFormData={setAltTrainerForm1}
                  increaseAlternative={increaseAlternative1}
                  decreaseAlternative={decreaseAlternative1}
                  handleInputChange={handleInputChange}
                  allDropDowns={allDropDowns}
                  formData={formData.main_contact_phone}
                  formName={"main_contact_phone"}
                  setFormData={setFormData}
                  noBtns={true}
                  setPhoneValidation={setPhoneValidation}
                  phoneValidation={phoneValidation}
                  setLoading={setLoading}
                  sectionId={"edit_contact_phone"}
                />

                <EditContactMultiEmailForm
                  altTrainerForm={multiEmailFormCount}
                  setSubFormData={setMultiEmailFormCount}
                  increaseAlternative={MultiEmailFormIncrease}
                  decreaseAlternative={MultiEmailFormDecrease}
                  handleInputChange={handleInputChange}
                  allDropDowns={allDropDowns}
                  formData={formData.main_contact_email}
                  formName={"main_contact_email"}
                  setFormData={setFormData}
                  sectionId={"edit_contact_email"}
                />

                <div className="row my-4" id="edit_contact_address" >
                  <Form.Group className={"col"}>
                    <Form.Label>Country</Form.Label>
                    {/* <Select
                      value={selectedCountry?.contact_country}
                      options={countryList}
                      onChange={(data) => {
                        handleSelectChange(data, "contact_country");
                      }}
                    /> */}

                    <GlobalDropdown
                      dropDownName={"contact_country"}
                      apiEndpoint={"account/get-country"}
                      idKey={"id"}
                      valueKey={"country_name"}
                      parentKey={"country"}
                      // onSelect={(e) => {
                      //   handleInputChange(e);
                      // }}
                      onSelect={(data, value) => {
                        const selectedText =
                          data.target.options[data.target.selectedIndex].text;
                        const data2 = {
                          label: selectedText,
                          value: value,
                        };
                        handleSelectChange(
                          data2,
                          "contact_country"
                        );
                      }}
                      shouldFetchOnOpen={true}
                      isCache={true}
                      initialSelectedValue={
                        location?.state?.contactInfoDetails?.contact_details
                          ?.contact_country
                      }
                      defaultValue={{
                        id: location?.state?.contactInfoDetails?.contact_details
                          ?.contact_country,
                        value:
                          location?.state?.contactInfoDetails?.contact_details
                            ?.contact_country_name,
                      }}
                    />
                  </Form.Group>
                  <Form.Group className={"col"}>
                    <Form.Label>Address </Form.Label>
                    <Form.Control
                      type="text"
                      name="contact_address1"
                      // onChange={handleInputChange}
                      onInput={handleInputChange}
                      // required
                      value={formData.contact_address1}
                    />

                    <Form.Control.Feedback type="invalid">
                      Please Enter Address.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className={"col"}>
                    <Form.Label>Address 2</Form.Label>
                    <Form.Control
                      type="text"
                      name="contact_address2"
                      // onChange={handleInputChange}
                      onInput={handleInputChange}
                      value={formData.contact_address2}
                    />
                  </Form.Group>

                  <Form.Group className={"col"}>
                    <Form.Label>City </Form.Label>
                    <Form.Control
                      type="text"
                      name="contact_city"
                      // onChange={handleInputChange}
                      onInput={handleInputChange}
                      value={formData.contact_city}
                      // required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter City.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className={"col"}>
                    <Form.Label>State </Form.Label>
                    <StateField
                      setFormData={setFormData}
                      valueKey="contact_state"
                      selectedCountry={selectedCountry?.contact_country?.value}
                      validated={false}
                      required={true}
                      stateSelectedValue={formData?.contact_state}
                      setStateAbreaviation={setFormData}
                    />
                  </Form.Group>

                  <Form.Group className={"col"}>
                    <Form.Label>Zip code </Form.Label>
                    <Form.Control
                      type="text"
                      name="contact_zipcode"
                      // onChange={handleInputChange}
                      onInput={handleInputChange}
                      value={formData.contact_zipcode}
                      // required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Zip Code.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <Form.Group className={"col"}>
                  <b className={"d-block"}>Training OptOut</b>
                  <span className="d-inline-block mt-10-px">
                    <ToogleSwitch
                      switchKeyValue={formData?.training_optout}
                      setSwitchValue={setSwitchValue}
                      switchValue={switchValue}
                      switchKey={"training_optout"}
                    />
                  </span>
                  {/* <div className="" >
                            <FormControlLabel
                                className={ '' }
                                label=""
                                control={
                                <Switch
                                    color="primary"
                                    size="medium"
                                    value={true}
                                    name="training_optout"
                                    onChange={ handleInputChange }
                                    checked={ formData.training_optout == 1 || formData.training_optout ? true : false }
                                /> }
                            />
                            </div> */}
                </Form.Group>
              </div>

              {/* message */}
              <div className="my-4">
                <MessageHandler
                  status={FormMsg.type}
                  msg={FormMsg.msg}
                  HandleMessage={setFormMsg}
                />
              </div>

              {/* <div
                        className="container-fluid bottom-border-blue"
                        style={ { marginBottom: "50px" } }
                    >
                        <div
                            className="col-md-12 d-flex"
                            style={ { marginTop: "25px", justifyContent: "right" } }
                        >
                            <Button
                                className={ "btn btn-danger mx-4" }
                                variant="danger"
                                style={ { fontSize: "16px" } }
                                onClick={()=>{navigate(-1)}}
                            >
                                Cancel
                            </Button>

                            <Button
                                className={ "btn btn-success" }
                                variant="success"
                                style={ { marginRight: "5px", fontSize: "16px" } }
                                type="submit"
                                // disabled={ loading }
                            >
                                Submit
                            </Button>
                        </div>
                    </div> */}

              <div className="row pb-3 py-5">
                <div className="col-12 content-flex-right">
                  <button
                    className="btn btn-danger text-uppercase"
                    type="button"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-success text-uppercase ms-2"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Submit"}
                    {/* submit */}
                  </button>
                </div>
              </div>
            </div>
          </Form>

          <ContactModalPhone
            // open={openContactModal}
            open={listType === "phone" ? true : false}
            hanldeModal={setContactModal}
            emailDataList={altTrainerForm1}
            phoneDataList={ altTrainerForm1.filter((it)=>it.account_main_contact_phone!="") }
            dataType={listType}
            setSubFormData={setAltTrainerForm1}
            SaveForm={SaveForm}
            setDataType={setListType}
            setLoading={setLoading}
          />

          <ContactModalEmail
            open={listType === "email" ? true : false}
            hanldeModal={setContactModal}
            emailDataList={multiEmailFormCount}
            phoneDataList={multiEmailFormCount}
            dataType={listType}
            setSubFormData={setMultiEmailFormCount}
            SaveForm={SaveForm}
            setDataType={setListType}
            setLoading={setLoading}
          />
      
    </>
  );
};

export default EditContact;
