import React from 'react'
import { isSubAdminPermission } from '../../../../../common/helper/permission';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import servicecheck from "../../../../../common/img/ServiceCheck.svg";

const TopAedNewMove = ({accountId, accountName, siteId,siteName,newAed=1,moveAed=0,isserviceCheck=0,
    hideAccMove=0,
    hideAedMove=0,

})=> {
    const navigate = useNavigate();

  return (
    <>
            <Dropdown>
                {newAed ?  
                <Dropdown.Toggle
                className="btn btn-transparent text-primary ms-2 bg-white DropDownBtn"
                id="new-tab-btn"
                style={{
                    backgroundColor: "transparent !important",
                    border: "none",
                }}
                >
                <img
                    className=""
                    src="/assets/images/add.svg"
                    alt="New"
                />
                <span className="ms-1 textSize">New</span>
                </Dropdown.Toggle>
                :""}
             
                <Dropdown.Menu
                className=""
                style={{ minWidth: "20px" }}
                >
                    <Dropdown.Item
                    className="DropDownListtextSize"
                    onClick={() => {
                        navigate(
                        `/account/aed/NewStandloneAcce/${accountId}/${siteId}`,
                        {
                            state: {
                            selectedAccount: {
                                accountId: accountId,
                                accountName: accountName,
                            },
                            selectedSite: {
                                siteId: siteId,
                                siteName: siteName,
                            },
                            },
                        }
                        );
                    }}
                    >
                    Accessories
                    </Dropdown.Item>
                

                    <Dropdown.Item
                    className="DropDownListtextSize"
                    onClick={() => {
                        navigate(
                        `/account/new/aed/${accountId}/${siteId}`, {
                            state: {
                            selectedAccount: {
                                accountId: accountId,
                                accountName: accountName,
                            },
                            selectedSite: {
                                siteId:   siteId,
                                siteName:   siteName,
                            },
                            }                            
                        }
                        );
                    }}
                    >
                    AED
                    </Dropdown.Item>

                </Dropdown.Menu>
                
            </Dropdown>
            {moveAed && (hideAccMove===0 || hideAedMove===0)  ?
                <Dropdown>
                    <Dropdown.Toggle
                    className="btn btn-transparent text-primary ms-2 bg-white DropDownBtn"
                    id="new-tab-btn"
                    style={{
                        backgroundColor: "transparent !important",
                        border: "none",
                    }}
                    >
                    <img src="/assets/images/add.svg" alt="Move" />
                    <span className="ms-1 textSize">Move</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                    className="bg-primary"
                    style={{ minWidth: "20px" }}
                    >

                        {hideAccMove ? "":
                            <Dropdown.Item
                            className="DropDownListtextSize"
                            onClick={() => {
                                navigate(
                                `/move-accessory/${accountId}/${siteId}`,
                                {
                                    state: {
                                    selectedAccount: {
                                        accountId: accountId,
                                        accountName: accountName,
                                    },
                                    selectedSite: {
                                        siteId:   siteId,
                                        siteName:   siteName,
                                    },
                                    }                            
                                }
                                );
                            }}
                            >
                            Accessories
                            </Dropdown.Item>
                        }
                        {hideAedMove ?'':
                            <Dropdown.Item
                            className="DropDownListtextSize"
                            onClick={() => {
                                navigate(
                                `/move-aed/` +
                                    accountId +
                                    "/" +
                                    siteId,
                                    {
                                        state: {
                                        selectedAccount: {
                                            accountId: accountId,
                                            accountName: accountName,
                                        },
                                        selectedSite: {
                                            siteId:   siteId,
                                            siteName:   siteName,
                                        },
                                        }                            
                                    }
                                );
                            }}
                            >
                            AED
                            </Dropdown.Item>
                        }
                    </Dropdown.Menu>
                </Dropdown>
            :""}
            {isserviceCheck ? 
                <button
                    className="btn text-primary serviceCheckbtn"
                    type="button"
                    onClick={(e) => {
                    navigate( "/account/aed/service-check/" + accountId + "/" + siteId,
                        { state: { siteName: siteName }, }
                    );
                    }}
                >
                    <img
                    className="serviceCheckImage"
                    src={servicecheck}
                    alt="svg"
                    style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                    }}
                    />
                    <span className="ms-1 textSize">Service Check</span>
                </button>
            :""}
    </>
  )
}

export default TopAedNewMove