import React, { useEffect, useState } from 'react';
import { Container, Row, Col, } from 'react-bootstrap';
import Header from './Header';
import Sidebar from './Sidebar';
import "./layout.scss";
import Footer from './Footer';
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import { DecryptToken } from '../../helper';
import UserSidebar from './UserSidebar';
// import Breadcrumbs from '../breadcrumbsComp/Breadcrumbs';

const Layout = ({ children }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const user = DecryptToken();
  const navigate = useNavigate();
  useEffect(() => {
    setShow(false);
  }, [navigate])
  return (
    <div className="dashboard" id="main-layout">
      <Header handleClose={handleClose} handleShow={handleShow} />
     
     {Number(user?.user_type) === 0 ? ( 
      <Sidebar show={show} handleClose={handleClose} />
    ) : ""}

{Number(user?.user_type) === 3 ? ( 
      <UserSidebar show={show} handleClose={handleClose} />
) : ""}
      {/* <Breadcrumbs /> */}
      <Container className='my-container' fluid>
        <Row>
          <Col style={{ minHeight: "85.1vh" }}>
            {children}
          </Col>
        </Row>
      </Container>

      <ToastContainer />
      <Footer />
    </div>
  );
};

export default Layout;