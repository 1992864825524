import React from "react";
import { useEffect, useState } from "react";
import { AedTabs } from "../../../../common/hooks/fieldsConfig";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { AED_IMG_BASE, CallGETAPI } from "../../../../common/services/index";
import {
  AEDGroupBYCoordinatorInfo,
  BatteryTypebyModel,
  DecryptToken,
  GetAedBrands,
  GetAedModelsByBrandId,
  GetAedSumModelsById,
  GetAedGeneralInformation,
  GetAedStorageInformation,
  GetAedBatteryInformation,
  GetAedPadInformation,
  GetAedCoordinatorInformation,
} from "../../../../common/helper/BasicFn";
import Box from "@mui/material/Box";
import Details from "../components/tabs/Details";
// import Notes from "../components/tabs/Notes";
import SubHeadingOther from "../../AedDetails/components/header/SubHeadingOther";
import Loading from "../../../../common/components/Loader/Loading";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getPermission } from "../../../../common/helper/Common";
import AEDChecksTbl from "../components/tabs/AEDChecksTbl";
import { EquipmentIcon } from "../../../../common/helper/icons";
// import AedSupport from "./tabs/AEDSupport";
// import AedDocuments from "./tabs/AEDDocuments";
// import AedServicing from "./tabs/AEDServicing";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
// import OutOfServiceSecondModal from "../../../components/modals/outOfServiceModal/outOfServiceSecondModal";
import { addItem } from "../../AedDetails/components/header/BreadCrumbsSlice";
import Report from "../../../../common/img/Xls.png";
import {
  isSubAdminPermission,
  isUserPermission,
} from "../../../../common/helper/permission";
import AedServicing from "../components/aedService/AEDServicing";
import OutOfServiceModal from "../OutofService/model/OutOfServiceModal";
import OutOfServiceSecondModal from "../OutofService/model/OutOfServiceSecondModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 386,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AedMain = ({ setShowSidebar }) => {
  const [currentTab, setCurrentTab] = useState(AedTabs.Details);
  const [showLoading, setShowLoading] = React.useState(true);
  const [filteredTabs, setFilteredTabs] = useState({});

  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const handleClose = () => setOpen(false);
  const [aedDetails, setAedDetails] = useState({});
  const [aedData, setAEDData] = useState({});
  const [cordinatorInfo, setCordinatorInfo] = useState([]);
  const { aedId } = useParams();
  const [batteryTypeList, setBatteryTypeList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [aedList, setAedList] = useState([]);
  const [loanerList, setLoanerList] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [storageInfoList, setStorageInfoList] = useState([]);
  const [rmsBrandList, setrmsBrandList] = useState([]);
  const [aedBattryTypeList, setaedBattryTypeList] = useState([]);
  const [aedPadTypeList, setaedPadTypeList] = useState([]);
  const [OtherFiled, setOtherFileds] = useState([]);
  const [subModelList, setSubModelList] = useState([]);
  const dispatch = useDispatch();

  const [showOutOfServiceModal, setShowOutOfServiceModal] = useState(false);
  const [outofServiceSecond, setOutofServiceSecond] = useState(false);
  const [outofServiceFormData, setOutofServiceFormData] = useState({});
  const [outOfServiceLoding, setoutOfServiceLoding] = useState(false);
  const [allPads, stAllPads] = useState([]);
  const exportReport = true;
  const user = DecryptToken();
  const privilege = getPermission();

  let is_user = location?.state?.is_user;
  // let privileges = location?.state?.privileges;

  // filter data of array with key and value
  const filterData = (arr, key, value) => {
    const data = arr.find((a) => a[key] == value);

    return data;
  };
  const [loading, setLoading] = useState(false);
  const [aedGeneralInfo, setAedGeneralInfo] = useState();
  const [aedStorageInfo, setAedStorageInfo] = useState();
  const [aedBatteryInfo, setAedBatteryInfo] = useState();
  const [aedCoordinatorInfo, setAedCoordinatorInfo] = useState();
  const [aedPadInfo, setAedPadInfo] = useState();
  const [chargePakInfo, setChargePakInfo] = useState();
  const [showAllLoading, setShowAllLoading] = useState({
    showAedGeneralInfo: true,
    showAedPadInfo: true,
    showAedStorageInfo: true,
    showAedBatteryInfo: true,
    showAedCoordinatorInfo: true,
  });

  const getAedGeneralInformation = async () => {
    setLoading(true);
    const result = await GetAedGeneralInformation(aedId);
    if (result?.status) {
        const modelCondition = result?.data?.aedModels;
      let aedDetails = {
        account_name: result?.data?.account_name,
        site_name: result?.data?.site_name,
        ...result?.data?.aed,
      };
      const aedGeneralInformationSectionData = result?.data?.aed || {};
      aedDetails.modelCondition = modelCondition;
      aedDetails.pediatric_key_child = modelCondition?.has_ped_key || modelCondition?.has_ped_pad || modelCondition?.has_pedpak;
      aedDetails.pediatric_key_icon = aedGeneralInformationSectionData?.pediatric_key // modelCondition?.has_ped_key // || modelCondition?.has_ped_pad || modelCondition?.has_pedpak;
      // aedDetails.pediatric_key = modelCondition?.has_ped_key || modelCondition?.has_ped_pad || modelCondition?.has_pedpak;
      
      aedDetails.builtin_RMS_info = JSON.parse(
        aedDetails?.builtin_RMS_info || "[]"
      );
      aedDetails.builtin_RMS_info =
        typeof aedDetails.builtin_RMS_info === "string"
          ? JSON.parse(aedDetails.builtin_RMS_info)
          : aedDetails.builtin_RMS_info;

      aedDetails.charge_pak_info = JSON.parse(
        aedDetails?.charge_pak_info || "[]"
      );

      aedDetails.rms_info = JSON.parse(aedDetails?.rms_info || "[]");
      aedDetails.rms_info =
        typeof aedDetails.rms_info === "string"
          ? JSON.parse(aedDetails.rms_info)
          : aedDetails.rms_info;

      aedDetails.out_of_service_info = JSON.parse(
        aedDetails?.out_of_service_info || "[]"
      );

      aedDetails.other = JSON.parse(aedDetails?.other || "[]");

      aedDetails.gateway_info = JSON.parse(aedDetails?.gateway_info || "[]");
      aedDetails.gateway_info =
        typeof aedDetails.gateway_info === "string"
          ? JSON.parse(aedDetails.gateway_info)
          : aedDetails.gateway_info;

      setAedGeneralInfo(aedDetails);
    }
    setShowAllLoading((prev) => ({
      ...prev,
      showAedGeneralInfo: false,
    }));
    setLoading(false);
    setoutOfServiceLoding(false);
  };

  const getAedStorageInformation = async () => {
    const result = await GetAedStorageInformation(aedId);

    if (result?.status) {
      setAedStorageInfo(result?.data?.storageInfo || []);
    }
    setShowAllLoading((prev) => ({
      ...prev,
      showAedStorageInfo: false,
    }));
  };

  const getAedBatteryInformation = async () => {
    const result = await GetAedBatteryInformation(aedId);

    if (result?.status) {
      const allBatteryData = result?.data?.batteryInfo || [];

      setAedBatteryInfo(allBatteryData);

      const ar1 = [];
      const SPar1 = [];
      for (let ci = 0; ci < allBatteryData?.length; ci++) {
        const ell = allBatteryData[ci];
        if (ell.section_name === "charge_pack") {
          const ChargePakInfoObj = {
            battery_expiration: ell?.battery_expiration,
            battery_lot: ell?.battery_lot,
            charge_pak_part: ell?.battery_type_id,
            charge_pak_uiid: ell?.charge_pak_uid,
            charge_pak_uid: ell?.charge_pak_uid,
            pad_1_expiration: "",
            pad_1_lot: "",
            pad_1_part: "",
            pad_2_expiration: "",
            pad_2_lot: "",
            pad_2_part: "",
          };
          if (ell.is_spare) {
            SPar1.push(ChargePakInfoObj);
          } else {
            ar1.push(ChargePakInfoObj);
          }
        }
      }
      setChargePakInfo({ charge_pak_info: ar1, spare_charge_pak_info: SPar1 });
    }
    setShowAllLoading((prev) => ({
      ...prev,
      showAedBatteryInfo: false,
    }));
  };

  const getAedCoordinatorInformation = async () => {
    const result = await GetAedCoordinatorInformation(aedId);

    if (result?.status) {
      let CoordiData = AEDGroupBYCoordinatorInfo(
        result?.data?.cordinatorInformation
      );
      setAedCoordinatorInfo(CoordiData);
    }
    setShowAllLoading((prev) => ({
      ...prev,
      showAedCoordinatorInfo: false,
    }));
  };

  const getAedPadInformation = async () => {
    const result = await GetAedPadInformation(aedId);

    if (result?.status) {
      setAedPadInfo(result?.data?.allPads);
    }
    setShowAllLoading((prev) => ({
      ...prev,
      showAedPadInfo: false,
    }));
  };

  // get aeds by account
  // const getAedDetails = async () => {
  //   const result1 = await CallGETAPI(
  //     "aed/get-aed-general-information/" + aedId
  //   );
  //   const result2 = await CallGETAPI("aed/get-aed-battery-info/" + aedId);
  //   const result3 = await CallGETAPI("aed/get-aed-pad-info/" + aedId);
  //   const result4 = await CallGETAPI("aed/get-aed-storage-info/" + aedId);
  //   const result5 = await CallGETAPI("aed/get-aed-coordinator-info/" + aedId);

  //   const result = {
  //     data: {
  //       ...result1.data.data,
  //       ...result2.data.data,
  //       ...result3.data.data,
  //       ...result4.data.data,
  //       ...result5.data.data,
  //     },
  //   };

  //   stAllPads(result?.data?.allPads || []);
  //   const allPadsData = result?.data?.allPads || [];
  //   const allBatteryData = result?.data?.batteryInfo;
  //   const modelCondition = result?.data?.aedModels;
  //   // const rmsBrands = result?.data?.rmsBrand;

  //   const aedDetails = result?.data;
  //   console.warn("Aer", aedDetails);
  //   // const batteryTypeList = [];
  //   if (result?.status) {
  //     // set list data ;

  //     setAEDData(aedDetails);

  //     setStorageInfoList(result?.data?.storageInfo);
  //     // setSubModelList(result?.data?.storageInfo)
  //     setrmsBrandList(result?.data?.rmsBrand);
  //     setaedBattryTypeList(result?.data?.aedBattryType);
  //     setaedPadTypeList(result?.data?.allPads);

  //     // aed_model_id
  //     const BatteryRes = await BatteryTypebyModel(aedDetails?.aed_model_id);
  //     setBatteryTypeList(BatteryRes);
  //     if (aedDetails?.length === 0) {
  //       toast.error("Something went wrong please try again");
  //       return "";
  //     }
  //     // allPadsData.forEach((item, index) =>
  //     //   if (item.section_name === "adult_pad_info") {
  //     //     aedDetails.adult_pad_info = allPadsData[index];
  //     //   }
  //     // });
  //     const APDArr = allPadsData?.filter(
  //       (it) => it.section_name != "charge_pack"
  //     );

  //     aedDetails.adult_pad_info = APDArr;
  //     aedDetails.adult_pad_pak_info = APDArr;
  //     aedDetails.modelCondition = modelCondition;
  //     aedDetails.pediatric_key_child =
  //       modelCondition?.has_ped_key ||
  //       modelCondition?.has_ped_pad ||
  //       modelCondition?.has_pedpak;
  //     aedDetails.battery_info = allBatteryData;
  //     aedDetails.builtin_RMS_info = JSON.parse(aedDetails?.builtin_RMS_info);
  //     aedDetails.charge_pak_info = JSON.parse(aedDetails?.charge_pak_info);

  //     // aedDetails.adult_pad_info = JSON.parse(aedDetails?.adult_pad_info);

  //     // aedDetails.adult_pad_pak_info = JSON.parse(
  //     //   aedDetails?.adult_pad_pak_info
  //     // );
  //     // aedDetails.pediatric_key = modelCondition?.has_ped_key || modelCondition?.has_ped_pad || modelCondition?.has_pedpak;
  //     // modelCondition
  //     // aedDetails.battery_info = JSON.parse(aedDetails?.battery_info);

  //     const ar1 = [];
  //     const SPar1 = [];
  //     const PartnumberPads = [];
  //     for (let ci = 0; ci < allBatteryData?.length; ci++) {
  //       const ell = allBatteryData[ci];
  //       if (ell.section_name === "charge_pack") {
  //         const ChargePakInfoObj = {
  //           battery_expiration: ell?.battery_expiration,
  //           battery_lot: ell?.battery_lot,
  //           charge_pak_part: ell?.battery_type_id,
  //           charge_pak_uiid: ell?.charge_pak_uid,
  //           charge_pak_uid: ell?.charge_pak_uid,
  //           pad_1_expiration: "",
  //           pad_1_lot: "",
  //           pad_1_part: "",
  //           pad_2_expiration: "",
  //           pad_2_lot: "",
  //           pad_2_part: "",
  //         };

  //         for (let i3 = 0; i3 < allPadsData.length; i3++) {
  //           const apd = allPadsData[i3];
  //           if (apd?.section_name === "charge_pack") {
  //             if (apd?.pid === Number(ell?.charge_pak_pad_1_id)) {
  //               ChargePakInfoObj.pad_1_expiration = apd?.pad_expiration;
  //               ChargePakInfoObj.pad_1_lot = apd?.pad_lot;
  //               ChargePakInfoObj.pad_1_part = apd?.pad_type_id;
  //             } else if (apd?.pid === Number(ell?.charge_pak_pad_2_id)) {
  //               ChargePakInfoObj.pad_2_expiration = apd?.pad_expiration;
  //               ChargePakInfoObj.pad_2_lot = apd?.pad_lot;
  //               ChargePakInfoObj.pad_2_part = apd?.pad_type_id;
  //             }
  //           }
  //         }
  //         if (ell.is_spare) {
  //           SPar1.push(ChargePakInfoObj);
  //         } else {
  //           ar1.push(ChargePakInfoObj);
  //         }
  //       }
  //     }
  //     aedDetails.charge_pak_info = ar1?.[0];
  //     aedDetails.spare_charge_pak_info = SPar1;
  //     // aedDetails.charge_pak_info = aedDetails?.charge_pak_info?.[0];

  //     aedDetails.gateway_info = JSON.parse(aedDetails?.gateway_info);
  //     aedDetails.gateway_info = aedDetails?.gateway_info[0];
  //     aedDetails.other = JSON.parse(aedDetails?.other);
  //     aedDetails.out_of_service_info = JSON.parse(
  //       aedDetails?.out_of_service_info
  //     );
  //     // aedDetails.pediatric_pad_info = JSON.parse(
  //     //   aedDetails?.pediatric_pad_info
  //     // );
  //     aedDetails.pediatric_pad_info = APDArr;

  //     // aedDetails.pediatric_pak_pad_info = JSON.parse(
  //     //   aedDetails?.pediatric_pak_pad_info
  //     // );
  //     aedDetails.pediatric_pak_pad_info = APDArr;

  //     aedDetails.rms_info = JSON.parse(aedDetails?.rms_info);
  //     // aedDetails.spare_adult_pad_info = JSON.parse(
  //     //   aedDetails?.spare_adult_pad_info
  //     // );
  //     aedDetails.spare_adult_pad_info = APDArr;

  //     // aedDetails.spare_adult_pad_pak_info = JSON.parse(
  //     //   aedDetails?.spare_adult_pad_pak_info
  //     // );
  //     aedDetails.spare_adult_pad_pak_info = APDArr;

  //     aedDetails.spare_battery_info = JSON.parse(
  //       aedDetails?.spare_battery_info
  //     );
  //     // aedDetails.spare_charge_pak_info = JSON.parse(
  //     //   aedDetails?.spare_charge_pak_info
  //     // );
  //     // aedDetails.spare_padric_pad_info = JSON.parse(
  //     //   aedDetails?.spare_padric_pad_info
  //     // );
  //     aedDetails.spare_padric_pad_info = APDArr;

  //     // aedDetails.spare_padric_pak_pad = JSON.parse(
  //     //   aedDetails?.spare_padric_pak_pad
  //     // );
  //     aedDetails.spare_padric_pak_pad = APDArr;

  //     aedDetails.storage_info = JSON.parse(aedDetails?.storage_info);
  //     aedDetails.site_name = result?.data?.site_name;
  //     aedDetails.account_name = result?.data?.account_name;
  //     setOtherFileds(aedDetails?.other);
  //     let brandList = await GetAedBrands();
  //     setBrandList(brandList);

  //     const filteredBrand = filterData(
  //       brandList?.data,
  //       "id",
  //       aedDetails?.aed_brand_id
  //     );
  //     aedDetails.brand_name = filteredBrand?.AED_brands;

  //     let ModelRes = await GetAedModelsByBrandId(filteredBrand?.id);
  //     let sub_model_res = await GetAedSumModelsById(aedDetails.aed_model_id);
  //     let MODEL_NAME = ModelRes?.data.find(
  //       (item) => item.id === aedDetails.aed_model_id
  //     ); // aedDetails.aed_model_id
  //     setModelList(ModelRes?.data);

  //     const filteredModel = filterData(
  //       ModelRes?.data,
  //       "id",
  //       aedDetails?.aed_model_id
  //     );
  //     aedDetails.model_name = MODEL_NAME?.model_name; // filteredModel?.model_name

  //     const filteredSubModel = filterData(
  //       sub_model_res?.data,
  //       "id",
  //       aedDetails?.sub_model_id
  //     );
  //     aedDetails.sub_model_name = filteredSubModel
  //       ? filteredSubModel?.sub_model_name
  //       : "";
  //     aedDetails.assign_user = aedDetails.assign_user
  //       ? JSON.parse(aedDetails.assign_user)
  //       : [];
  //     // aedDetails.assign_user
  //     setAedDetails(aedDetails);

  //     let CoordiData = AEDGroupBYCoordinatorInfo(
  //       result?.data?.cordinatorInformation
  //     );
  //     setCordinatorInfo(CoordiData);
  //   }
  //   setShowLoading(false);
  // };

  // on load fetch data
  useEffect(() => {
    // getAedDetails();
    getAedGeneralInformation();
    getAedStorageInformation();
    getAedBatteryInformation();
    getAedCoordinatorInformation();
    getAedPadInformation();
  }, []);

  useEffect(() => {
    getAedGeneralInformation();
  }, [outOfServiceLoding]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleTab = (item) => {
    setCurrentTab(item);
  };

  useEffect(() => {
    if (location?.state?.tab) {
      setCurrentTab(location?.state?.tab);
    }
  }, [location]);

  const notesRedirect = () => {
    // aedId={aedId} account_id={aedData?.account_id}
    navigate(
      `/account/new-note?account_id=${aedData?.account_id}&aed_id=${aedId}`
    );
  };
  const handleAssignEqupment = (e) => {
    // let siteId = aedData?.site_id;
    let siteId = aedGeneralInfo?.site_id;
    const AEDID = aedId;
    // let equipmentUrl = "/assign-quipment/" + aedData?.account_id + "/" + siteId;
    // equipmentUrl = siteId ? equipmentUrl+'/'+siteId : equipmentUrl;
    // equipmentUrl = equipmentUrl + "?aed_id=" + AEDID;
    let equipmentUrl = "/assign-quipment/" + aedGeneralInfo?.account_id + "/" + siteId;
    navigate(equipmentUrl,{
      state:{
        aedData: aedGeneralInfo,
      }
    });
  };

  const getAllAeds = async () => {
    // const result = await CallGETAPI("account/get-aed/" + aedData?.account_id);
    const result = await CallGETAPI(
      "account/get-aed-with-standalon/" + aedData?.account_id
    );
    if (result?.data?.status) {
      let aeds = result?.data?.data || [];
      const pendingaeds = result?.data?.pendingData;
      let newArr = [];

      if (Array.isArray(aeds) && pendingaeds.length > 0) {
        newArr = [...pendingaeds, ...aeds];
      } else {
        newArr = aeds;
      }
      let currentList = [];

      for (let i = 0; i < newArr.length; i++) {
        for (let j = 0; j < newArr[i].data.length; j++) {
          currentList.push(newArr[i].data[j]);
        }
      }
      // aeds = newArr;
      setAedList(currentList);
    }
  };

  const getLoanerList = async () => {
    const result = await CallGETAPI("account/get-ross-aed");
    if (result?.data?.status) {
      setLoanerList(result?.data?.data);
    }
  };

  // useEffect(() => {
  //   if (aedGeneralInfo?.account_id) {
  //     getAllAeds();
  //     getLoanerList();
  //   }
  // }, [aedGeneralInfo]);

  const documentRedirect = () => {
    navigate("/account-document-upload", {
      state: {
        type: "Support",
        accountId: aedData?.account_id,
        siteId: "",
        aedId: aedId,
      },
    });
  };

  const supportRedirect = () => {
    const stateData = {
      type: "Support",
      site_id: 0,
      accountId: aedData?.account_id,
      contactId: 0,
      // accountName: accountDetails?.account_name || "",
      // support_name: accountDetails?.account_name,
    };

    navigate("/account/new-support/" + aedData.accountId, { state: stateData });
  };

  const handleServiceCheck = () => {
    navigate(
      `/account/aed/service-check/service1/${aedGeneralInfo?.account_id}/${
        aedGeneralInfo?.site_id
      }/aedId?aedId=${encodeURIComponent(aedId)}`, 
      {
      state: {
        navigateAedDetails: true
        }
      }
    );
  };

  useEffect(() => {
    dispatch(
      addItem({ title: "Equipment", path: location?.pathname, tab: currentTab })
    );
  }, [currentTab]);

  const breadcrumbs = useSelector((state) => state.BreadCrumbs.items); // Accessing breadcrumbs from Redux store state

  const handleHoverFloating = () => {
    setIsOpen(true);
  };

  const handleLeaveFloating = () => {
    setIsOpen(false);
  };
  //   const fetchSerial = () => {
  //   //   if(aedDetails?.out_of_service_info[0]?.replaced_serial || aedDetails?.out_of_service_info[0]?.loaner_rental_serial) {
  //   const replacedSerial = aedDetails?.out_of_service_info?.length > 0 ? (aedDetails?.out_of_service_info[0]?.replaced_serial)?.replace("") : "";
  //   const loanerRentalSerial = aedDetails?.out_of_service_info ? aedDetails?.out_of_service_info[0]?.loaner_rental_serial : "";

  //     // }
  //   }

  // useEffect(() => {
  //   fetchSerial();
  // },[aedDetails?.out_of_service_info && aedDetails?.out_of_service_info[0]?.replaced_serial]);

  return (
    <> 
     <div className="mt-4" style={{ width: "100%" }}>
        {/* top heading */}
        <SubHeadingOther
          title={
            <span>
              {"AED: " + (aedGeneralInfo?.serial_number || "") + " "}
              {aedGeneralInfo?.out_of_service_info ? (
                <>
                  {(aedGeneralInfo?.out_of_service_info != "" && JSON.parse(aedGeneralInfo?.out_of_service_info)[0]
                          ?.replaced_serial_name) && (
                    <span style={{ color: "#dc3545" }}>
                      {'"' +
                        JSON.parse(aedGeneralInfo?.out_of_service_info)[0]
                          ?.replaced_serial_name +
                        '"'}
                    </span>
                  )}
                  {(aedGeneralInfo?.out_of_service_info != "" && JSON.parse(aedGeneralInfo?.out_of_service_info)[0]
                          ?.loaner_rental_serial_name) && (
                    <span style={{ color: "#dc3545" }}>
                      {'"' +
                        JSON.parse(aedGeneralInfo?.out_of_service_info)[0]
                          ?.loaner_rental_serial_name +
                        '"'}
                    </span>
                  )}
                </>
              ) : (
                ""
              )}
            </span>
          }
          hideNew={true}
          hideHierarchy={true}
          hideInstructor={true}
          subHeading={true}
          bottomLinks={false}
          account={5}
          editUrl={false}
          assign_equipment={true}
          breadcrumbs={breadcrumbs}
        />

        {/* bottom buttons */}
        <div className="d-flex" style={{ justifyContent: "space-between" }}>
          <div className="d-flex">
            {(user?.user_type === 0 ||
              isSubAdminPermission("aed-edit") === 1) && (
              <button
                className="btn text-primary"
                type="button"
                onClick={() => navigate("/account/aed/edit/" + aedId + "/" + aedGeneralInfo?.site_id, {
                  state: {
                  selectedAccount: {
                      accountId: aedGeneralInfo?.account_id,
                      accountName: aedGeneralInfo?.account_name,
                  },
                  selectedSite: {
                      siteId:   aedGeneralInfo?.site_id,
                      siteName:   aedGeneralInfo?.site_name,
                  },
                  detailsData: {
                    aedGeneralInfo: aedGeneralInfo,
                    aedStorageInfo: aedStorageInfo,
                    aedBatteryInfo: aedBatteryInfo,
                    aedCoordinatorInfo: aedCoordinatorInfo,
                    aedPadInfo: aedPadInfo,
                    chargePakInfo: chargePakInfo,
                  }
                  }                            
              })}
              >
                <img
                  src="/assets/images/edit.svg"
                  alt="Edit"
                  style={{ marginRight: "5px" }}
                />
                <span className="ms-1">Edit </span>
              </button>
            )}

               {(user?.user_type === 0 ||
              isSubAdminPermission("out-of-service") === 1) && (
              <button
                className="btn text-primary"
                type="button"
                onClick={() => {
                  setShowOutOfServiceModal(true);
                }}
              >
                <img
                  src="/assets/images/outofservice.svg"
                  alt="outofservice"
                  style={{ marginRight: "5px" }}
                />
                <span className="ms-1">Out of Service</span>
              </button>
            )}

            {((user?.user_type === 0 || isSubAdminPermission("new-aed-checks") === 1) && aedGeneralInfo?.ready_status != "out of service") && (
              <button
                className="btn text-primary"
                type="button"
                onClick={() =>
                  navigate(
                    "/account/aed-details/check-select?aed_id=" +
                      aedId +
                      "&account_id=" +
                      aedGeneralInfo?.account_id
                  )
                }
              >
                <img
                  src="/assets/images/aedcheck.svg"
                  alt="aedcheck"
                  style={{ marginRight: "5px" }}
                />
                <span className="ms-1">AED Check</span>
              </button>
            )}

             {((user?.user_type === 0 || isSubAdminPermission("aed-service-check") === 1) && aedGeneralInfo?.ready_status != "out of service") && (
              <button
                className="btn text-primary"
                type="button"
                onClick={handleServiceCheck}
              >
                <img
                  src="/assets/images/servicecheck.svg"
                  alt="servicecheck"
                  style={{ marginRight: "5px" }}
                />
                <span className="ms-1">Service Check</span>
              </button>
            )}

            {/* <button className="btn text-primary" type="button">
                  <img
                    src="/servicecheck.svg"
                    alt="servicecheck"
                    style={{ marginRight: "5px" }}
                  />
                  <span className="ms-1">Service Check</span>
                </button> */}
            {(user?.user_type === 0 ||
              isSubAdminPermission("assign-aed") === 1) && (
              <button
                className="btn btn-transparent text-primary ms-2 bg-white"
                id="new-tab-btn-12111"
                type="button"
                style={{ backgroundColor: "transparent !important" }}
                onClick={(e) => handleAssignEqupment(e)}
              >
                <EquipmentIcon />
                <span className="ms-1">Assigned AED</span>
              </button>
            )}

          {/*  {exportReport &&
              (currentTab == "Sites" ||
                currentTab == "Contacts" ||
                currentTab == "Equipment" ||
                currentTab == "Notes" ||
                currentTab == "Support" ||
                currentTab == "AED Checks" ||
                currentTab == "AED Servicing") && (
                <button
                  className="btn text-primary ms-2 bg-white"
                  id="new-tab-btn-12111"
                  type="button"
                  style={{ backgroundColor: "transparent !important" }}
                  // onClick={() => navigate(equipmentUrl)}
                >
                  <img
                    src={Report}
                    alt="Report"
                    style={{ width: "25px", height: "24px" }}
                  />
                  <span className="ms-1"> Export Report</span>
                </button>
              )} */}
          </div>
        </div>

        {/* </div> */}

        {/* under bottom buttons */}
        <div className="row mt-3 align-items-center">
          <div
            className="d-flex gap-5 align-items-center"
            style={{ width: "80%", justifyContent: "space-between" }}
          >
            <div className="d-flex gap-5 align-items-center">
              {aedGeneralInfo?.ready_status == "ready" && (
                <span
                  className="px-5 py-3 text-white"
                  style={{
                    backgroundColor: "#5CB200",
                    borderRadius: "0px",
                  }}
                >
                  Ready
                </span>
              )}
              {aedGeneralInfo?.ready_status == "not ready" && (
                <span
                  className="px-5 py-3 text-white"
                  style={{
                    backgroundColor: "rgba(228, 0, 0, 1)",
                    borderRadius: "0px",
                  }}
                >
                  Not Ready
                </span>
              )}
              {aedGeneralInfo?.ready_status == "unknown" && (
                <span
                  className="px-5 py-3 text-white"
                  style={{
                    backgroundColor: "rgba(228, 0, 0, 1)",
                    borderRadius: "0px",
                  }}
                >
                  Unknown
                </span>
              )}
              {aedGeneralInfo?.ready_status == "out of service" && (
                <span
                  className="px-5 py-3 text-white"
                  style={{
                    backgroundColor: "rgba(191, 118, 9, 1)",
                    borderRadius: "0px",
                  }}
                >
                  Out of Service
                </span>
              )}
              <img
                src={AED_IMG_BASE + aedGeneralInfo?.aed_image}
                alt="aed_image"
                style={{ maxWidth: "100px" }}
              />

              {/* heading */}
              {/* <h2
                    className="text-center aed-title"
                    style={{
                      position: matches ? "" : "absolute",
                      right: matches ? "" : "35%",
                    }}
                  >
                    {aedGeneralInfo?.account_name} {aedGeneralInfo?.site_name}
                  </h2> */}
            </div>

            <div>
              <h2 className="text-center aed-title">
                {aedGeneralInfo?.account_name} {aedGeneralInfo?.site_name}
              </h2>
            </div>

            <div> </div>
          </div>
        </div>

        {/* tabs */}
        <Box className="bg-primary mt-3 mb-2">
          <div className="d-flex border-bottom border-secondary">
            {Object.values(AedTabs).map((tabItem, i) => {
              const hasAccess =
                user?.user_type === 0 ||
                (user?.user_type === 2 &&
                  user?.sub_admin != "" &&
                  ((tabItem === "Details" &&
                    privilege?.includes("aed-details")) ||
                    (tabItem === "Notes" && privilege?.includes("notes-tab")) ||
                    (tabItem === "AED Checks" &&
                      privilege?.includes("aed-check-details")) ||
                    (tabItem === "AED Servicing" &&
                      privilege?.includes("aed-service-check")) ||
                    (tabItem === "Support" &&
                      privilege?.includes("support-tab")) ||
                    (tabItem === "Documents" &&
                      privilege?.includes("documents-tab"))));

              return hasAccess ? (
                <div
                  role="button"
                  key={i}
                  className="text-light py-2 px-3"
                  style={{
                    backgroundColor:
                      tabItem === currentTab ? "#26AEE0" : "#0C71C3",
                  }}
                  onClick={() => handleTab(tabItem)}
                >
                  {tabItem}
                </div>
              ) : null;
            })}
          </div>
        </Box>

        {/* Details */}
        {currentTab === AedTabs.Details && (
          <Details
            aedDetails={aedGeneralInfo}
            loading={loading}
            storageInfo={aedStorageInfo}
            batteryInfo={aedBatteryInfo}
            cordinatorInfo={aedCoordinatorInfo}
            storageInfoList={aedStorageInfo}
            batteryTypeList={aedBatteryInfo}
            aedBattryTypeList={aedBatteryInfo}
            aedPadTypeList={aedPadInfo}
            gatewayInfo={aedGeneralInfo?.gateway_info[0]}
            OtherFiled={aedGeneralInfo?.other}
            rmsBrandList={aedGeneralInfo?.rms_info}
            outOfServiceInfo={
              typeof aedGeneralInfo?.out_of_service_info === "string"
                ? JSON.parse(aedGeneralInfo?.out_of_service_info)
                : aedGeneralInfo?.out_of_service_info
            }
            out_of_service_toggle={aedGeneralInfo?.out_of_service_toggle}
            sphereBatteryInfo={aedBatteryInfo}
            adultPadInfo={aedPadInfo}
            spareAdultPadInfo={aedPadInfo}
            adultPadPakInfo={aedPadInfo}
            spareAdultPadPakInfo={aedPadInfo}
            pediatricPadInfo={aedPadInfo}
            sparePadricPadInfo={aedPadInfo}
            pediatricPadPakInfo={aedPadInfo}
            sparePadricPadPakInfo={aedPadInfo}
            chargePakInfo={chargePakInfo?.charge_pak_info}
            spareChargePakInfo={chargePakInfo?.spare_charge_pak_info}
            allPads={aedPadInfo}
            showAedGeneralInfo={showAllLoading.showAedGeneralInfo}
            showAedStorageInfo={showAllLoading.showAedStorageInfo}
            showAedBatteryInfo={showAllLoading.showAedBatteryInfo}
            showAedPadInfo={showAllLoading.showAedPadInfo}
            showAedCoordinatorInfo={showAllLoading.showAedCoordinatorInfo}
          />
        )}

        {/* Notes */}
        {/*    {currentTab === AedTabs.Notes && (
           <Notes aedId={aedId} account_id={aedData?.account_id} /> 
            )} */}

              {currentTab === AedTabs.AEDChecks && <AEDChecksTbl  account_id={aedGeneralInfo?.account_id}/>}

           {currentTab === AedTabs.AEDServicing && (
              <AedServicing
                stateData={{
                  type: "AEDServicing",
                  accountId: aedGeneralInfo?.account_id,
                  aedId: aedId,
                  contactId: 0,
                  siteId: 0,
                  inpersonId: 0,
                }}
              />
            )}

         {/*    {currentTab === AedTabs.Support && (
              <AedSupport
                stateData={{
                  type: "Support",
                  accountId: aedData?.account_id,
                  aedId: aedId,
                  contactId: 0,
                  siteId: 0,
                  inpersonId: 0,
                }}
              />
            )} 

            {currentTab === AedTabs.Documents && (
              <AedDocuments aedId={aedId} accountID={aedData?.account_id} />
            )}
*/}
        {/* out of service modal */}
              {showOutOfServiceModal && (
              <OutOfServiceModal
                ShowModal={showOutOfServiceModal}
                SetShowModal={setShowOutOfServiceModal}
                outOfServiceInfo={aedGeneralInfo?.out_of_service_info}
                outOfServiceToggle={aedGeneralInfo?.out_of_service_toggle}
                // aedList={aedList}
                loanerList={loanerList}
                setLoanerList={setLoanerList}
                aedId={aedId}
                // getAedDetails={() => getAedDetails()}
                aedDetails={aedGeneralInfo}
                accountId={aedData?.account_id}
                outofServiceSecond={outofServiceSecond}
                setOutofServiceSecond={setOutofServiceSecond}
                setOutofServiceFormData={setOutofServiceFormData}
                cordinatorInfo={cordinatorInfo[5]}
                setoutOfServiceLoding={setoutOfServiceLoding}
                // setLoading={setLoading}
              />
            )}

            <OutOfServiceSecondModal
              accountId={aedGeneralInfo?.account_id}
              ShowSecondModal={outofServiceSecond}
              setShowSecondModal={setOutofServiceSecond}
              outOfServiceInfo={aedGeneralInfo?.out_of_service_info}
              outofServiceFormData={outofServiceFormData}
              setoutOfServiceLoding={setoutOfServiceLoding}
              // setLoading={setLoading}
            />
      </div>
      {/* <div
        className="floating-menu-btn d-flex flex-column gap-2"
        onMouseEnter={handleHoverFloating}
        onMouseLeave={handleLeaveFloating}
      >
        {isOpen && (
          <>
            {(isSubAdminPermission("new-document") === 1 ||
              isUserPermission("new-document") === 1) && (
              <img
                src="/assets/images/NewDocument.svg"
                width={60}
                height={60}
                style={{
                  padding: "2px",
                  borderRadius: "50%",
                  borderColor: "#0c71c3",
                  borderWidth: "3px",
                  borderStyle: "solid",
                }}
                className="pointer bg-white"
                onClick={documentRedirect}
              />
            )}

            {(isSubAdminPermission("new-support") === 1 ||
              isUserPermission("new-support") === 1) && (
              <img
                src="/assets/images/NewSupport.svg"
                width={60}
                height={60}
                style={{
                  padding: "2px",
                  borderRadius: "50%",
                  borderColor: "#0c71c3",
                  borderWidth: "3px",
                  borderStyle: "solid",
                }}
                className="pointer bg-white"
                onClick={supportRedirect}
              />
            )}

            {(isSubAdminPermission("new-note") === 1 ||
              isUserPermission("new-note") === 1) && (
              <img
                src="/assets/images/NewNote.svg"
                width={60}
                height={60}
                style={{
                  padding: "2px",
                  borderRadius: "50%",
                  borderColor: "#0c71c3",
                  borderWidth: "3px",
                  borderStyle: "solid",
                }}
                className="pointer bg-white"
                onClick={notesRedirect}
              />
            )}
          </>
        )}

        <img
          src="/assets/images/Plus.svg"
          width={60}
          height={60}
          style={{
            padding: "2px",
            borderRadius: "50%",
            borderColor: "#0c71c3",
            borderWidth: "3px",
            borderStyle: "solid",
          }}
          className="pointer bg-white"
          onClick={() => {
            setIsOpen((prev) => !prev);
          }}
        />
      </div> */}
    </>
  );
};

export default AedMain;
