import React from "react";
import { useEffect, useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Skeleton } from "@mui/material";
import CustomToggleButton2 from "../../../../../common/components/toggleSwitch/CustomToggle2";
import { Form } from "react-bootstrap";
import Moment from "react-moment";
import SubHeadingOther from "../header/SubHeadingOther";
import { CallGETAPI } from "../../../../../common/services";
import check from "../../../../../common/img/Check.svg";
import cancel from "../../../../../common/img/Cancel.svg";
import {
  BatteryInfocolumnList,
  RenderDate,
} from "../../../../../common/helper/BasicFn";
import moment from "moment";
import "moment-timezone";
import axios from "axios";
import { useTable, useSortBy } from "react-table";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import Loading from "../../../../../common/components/Loader/Loading";
import AedTableSkeleton from "../../../ServiceCheck/components/skeleton/AedTableSkeleton";
import AEDSupportTicketSkeleton from "../AedSkeletons/AEDSupportTicketSkeleton";
import InspectionTableSkeleton from "../AedSkeletons/InspectionTableSkeleton";
import ServicingNotesSkeleton from "../AedSkeletons/ServicingNotesSkeleton";
import AEDChecksTableSkeleton from "../AedSkeletons/AEDChecksTableSkeleton";

const AEDServiceDetails = () => {
  // const [apiData, setApiData] = useState();
  // const [batteryList, setBatteryList] = useState([]);
  // const [padList, setPadList] = useState([]);
  const { aedId, serviceQuestionId } = useParams();
  const navigate = useNavigate();
  const [aedModelId, setAedModelId] = useState();
  const [serviceQuestiondata, setServiceQuestiondata] = useState([]);
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState({
    aedDetails: true,
    pad: true,
    battery: true,
    serviceQuestion: true,
    serviceQuestiondata: true,
    issue: true,
    image: true,
  });

  const fetchServiceQuestionIssues = async () => {
    const response = await CallGETAPI(
      `aed/get_aed_modification_serviceQuestionIssues/${aedId}/${serviceQuestionId}`
    );

    if (response?.data?.status) {
      const resultData = response.data.data;
      setFormData((prev) => ({
        ...prev,
        issue: resultData,
      }));
    }
    setLoading((prev) => ({
      ...prev,
      issue: false,
    }));
  };

  const fetchServiceQuestionData = async () => {
    const response = await CallGETAPI(
      `aed/get_aed_modification_serviceQuestionData/${aedId}/${serviceQuestionId}`
    );

    if (response?.data?.status) {
      const resultData = response.data.data;
      setServiceQuestiondata(resultData || []);
      setFormData((prev) => ({
        ...prev,
        serviceQuestiondata: resultData,
      }));
    }
    setLoading((prev) => ({
      ...prev,
      serviceQuestiondata: false,
    }));
  };

  const fetchServiceQuestion = async () => {
    const response = await CallGETAPI(
      `aed/get_aed_modification_serviceQuestion/${aedId}/${serviceQuestionId}`
    );

    if (response?.data?.status) {
      const resultData = response.data.data;
      setFormData((prev) => ({
        ...prev,
        serviceQuestion: resultData,
      }));
    }
    setLoading((prev) => ({
      ...prev,
      serviceQuestion: false,
    }));
  };

  const fetchBatteryData = async () => {
    const response = await CallGETAPI(
      `aed/get_aed_modification_battery_data/${aedId}/${serviceQuestionId}`
    );

    if (response?.data?.status) {
      const resultData = response.data.data;
      const getVisibleColumns = FetchBatteryColumns(resultData);

      setFormData((prev) => ({
        ...prev,
        visiblae_columns: getVisibleColumns,
        battery: resultData || [],
      }));
    }
    setLoading((prev) => ({
      ...prev,
      battery: false,
    }));
  };

  const fetchPadData = async () => {
    const response = await CallGETAPI(
      `aed/get_aed_modification_pad_data/${aedId}/${serviceQuestionId}`
    );

    if (response?.data?.status) {
      const resultData = response.data.data;
      setFormData((prev) => ({
        ...prev,
        pad: resultData || [],
      }));
    }
    setLoading((prev) => ({
      ...prev,
      pad: false,
    }));
  };

  const fetchAeddata = async () => {
    const response = await CallGETAPI(
      `aed/get_aed_modification_data/${aedId}/${serviceQuestionId}`
    );

    if (response?.data?.status) {
      let resultData = response.data.data;
      resultData?.map((aedData, index) => {
        if (aedData.aed_model_id) {
          setAedModelId(aedData.aed_model_id);
        }
      });

      setFormData((prev) => ({
        ...prev,
        aedDetails: resultData || [],
      }));
    }
    setLoading((prev) => ({
      ...prev,
      aedDetails: false,
    }));
  };

  const fetchServiceQuestionImage = async () => {
    const response = await CallGETAPI(
      `aed/get_aed_modification_serviceQuestionImage/${aedId}/${serviceQuestionId}`
    );

    if (response?.data?.status) {
      const resultData = response.data.data;

      setFormData((prev) => ({
        ...prev,
        image: resultData || [],
      }));
    }
    setLoading((prev) => ({
      ...prev,
      image: false,
    }));
  };

  const fetchLoad = async () => {
    setLoading(true);
    const response = await CallGETAPI(
      `account/get_aed_modification_data_by_id_v2/${aedId}/${serviceQuestionId}`
    );

    if (response.data.data) {
      const resultData = response.data.data;
      const getVisibleColumns = FetchBatteryColumns(response.data.data);
      resultData.visiblae_columns = getVisibleColumns;

      resultData.aedDetails.map((aedData, index) => {
        if (aedData.aed_model_id) {
          setAedModelId(aedData.aed_model_id);
        }
      });

      setFormData({
        ...resultData,
      });

      setLoading(false);
    }
  };

  useEffect(() => {
    // fetchLoad();

    fetchServiceQuestionIssues();
    fetchServiceQuestionData();
    fetchServiceQuestion();
    fetchBatteryData();
    fetchPadData();
    fetchAeddata();
    fetchServiceQuestionImage();
  }, [aedId]);

  const FetchBatteryColumns = (fd) => {
    try {
      if (fd) {
        var BiArr = fd;

        const IsColumnVisible = (key, data) => {
          if (key === "battery_serial") {
            return false;
          }
          return data.some((row) => isValidValue(row[key]));
        };

        const isValidValue = (value) => {
          return (
            value !== null &&
            value !== undefined &&
            value !== "0000-00-00" &&
            value !== ""
          );
        };

        const visibleColumns = BatteryInfocolumnList.filter((column) => {
          return column.is_default === 1 || IsColumnVisible(column.key, BiArr);
        });

        return visibleColumns;
      }
    } catch (error) {
      console.error("An error occurred:", error);
      return [];
    }
  };

  const IsColumnVisible = (key) => {
    let is_found = 0;

    for (let index = 0; index < formData?.visiblae_columns?.length; index++) {
      const el = formData?.visiblae_columns[index];
      if (el.key === key) {
        is_found = 1;
        break;
      }
    }
    return is_found;
  };

  const sortedBatteryArray = formData?.battery
    ? formData.battery.slice().sort((a, b) => a.is_spare - b.is_spare)
    : [];

  const sortedPadArray = formData?.pad
    ? formData.pad.slice().sort((a, b) => {
        if (a.is_spare === 0 && a.is_pediatric === 0) return -1;
        if (b.is_spare === 0 && b.is_pediatric === 0) return 1;
        return 0;
      })
    : [];

  const downloadImage = async (imageUrl, fileName) => {
    try {
      const response = await axios.get(imageUrl, {
        responseType: "blob",
      });

      const contentType = response.headers["content-type"];

      // Explicitly set the file extension to "png"
      const anchor = document.createElement("a");
      anchor.href = window.URL.createObjectURL(response.data);
      anchor.download = `${fileName}.png`;
      document.body.appendChild(anchor);
      anchor.click();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "AED Serial #",
        accessor: "serialNumbers",
        Cell: ({ value, row }) => (
          <span
            style={{
              cursor: row.original.aed_id == aedId ? "default" : "pointer",
              color: row.original.aed_id == aedId ? "black" : "#0c71c3",
              textDecoration:
                row.original.aed_id == aedId ? "none" : "underline",
            }}
            onClick={() =>
              row.original.aed_id !== aedId &&
              navigate(
                `/account-details/AEDServiceDetails/${row.original.aed_id}/${row.original.service_question_id}`
              )
            }
          >
            {value}
          </span>
        ),
      },
      {
        Header: "Present / Ready",
        accessor: "aeds_ready_status",
        Cell: ({ value }) => (
          <img src={value ? check : cancel} alt="Status" height={12} />
        ),
      },
      {
        Header: "Replaced Rescue Kit",
        accessor: "rescue_kits_status",
        Cell: ({ value }) => (
          <img src={value ? check : cancel} alt="Status" height={12} />
        ),
      },
      {
        Header: "Alarm Battery Exp",
        accessor: "expiry_date",
        Cell: ({ value }) =>
          value && moment(value).isValid()
            ? moment(value).format("MM/DD/YYYY")
            : "",
      },
      {
        Header: "Replaced Accessories",
        accessor: "accessories_status",
        Cell: ({ value }) => (
          <img src={value ? check : cancel} alt="Status" height={12} />
        ),
      },
    ],
    [aedId, navigate, check, cancel]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: serviceQuestiondata || [],
      },
      useSortBy
    );

  return (
    <>
      <div
        className="mt-4 table-main-20002"
        style={{ width: "100%", paddingInline: "2%", marginBottom: "5%" }}
      >
        <SubHeadingOther
          hideNew={true}
          hideHierarchy={true}
          hideInstructor={true}
          subHeading={true}
          bottomLinks={false}
          account={5}
          editUrl={false}
          assign_equipment={true}
        />

        <Box className="text-left">
          <h4 className="heading" style={{ color: "black" }}>
            AED Service Date:{" "}
            <Moment
              date={formData?.serviceQuestion?.inspection_date}
              format="MM/DD/YYYY h:mm:ss"
            />
          </h4>
        </Box>
        {loading.aedDetails ? (
          <AedTableSkeleton applyPadding={false} />
        ) : (
          formData?.aedDetails &&
          formData?.aedDetails.map((aedData, index) => (
            <>
              <div key={""} className="col py-2">
                <h2 className="heading">Serial #:{aedData.serial_number}</h2>

                <table className="theme-table">
                  <thead>
                    <tr>
                      <td className="border border-r-blue" colSpan={2}>
                        Type
                      </td>
                      <td className="border border-r-blue" colSpan={2}>
                        Brand / Model
                      </td>
                      <td className="border border-r-blue" colSpan={2}>
                        Serial #
                      </td>
                      <td className="border border-r-blue" colSpan={2}>
                        Placement
                      </td>
                    </tr>
                  </thead>
                  <tbody className="">
                    <tr>
                      <td className="border border-r-blue" colSpan={2}>
                        AED
                      </td>
                      <td className="border border-r-blue" colSpan={2}>
                        {aedData.brandname + "/" + aedData.modelname}
                      </td>
                      <td className="border border-r-blue" colSpan={2}>
                        {aedData.serial_number}
                      </td>
                      <td className="border border-r-blue" colSpan={2}>
                        {aedData.placement}
                      </td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <td className="border border-r-blue" colSpan={2}>
                        Present / Ready
                      </td>
                      <td className="border border-r-blue" colSpan={2}>
                        Replace rescue kit
                      </td>
                      <td className="border border-r-blue" colSpan={2}>
                        Replace alarm battery
                      </td>
                      <td className="border border-r-blue" colSpan={1}>
                        Replace Accessories
                      </td>
                      <td className="border border-r-blue" colSpan={1}>
                        Support Ticket
                      </td>
                    </tr>
                  </thead>
                  <tbody className="">
                    <tr>
                      <td colSpan={2}>
                        <CustomToggleButton2
                          ToggleName="aeds_ready_status"
                          ToggleValue={aedData.aeds_ready_status}
                        />
                      </td>
                      <td colSpan={2}>
                        <CustomToggleButton2
                          ToggleName="rescue_kits_status"
                          ToggleValue={aedData.rescue_kits_status}
                        />
                      </td>
                      <td colSpan={2}>
                        <CustomToggleButton2
                          ToggleName="alarm_batteries_status"
                          ToggleValue={aedData.alarm_batteries_status}
                        />
                      </td>
                      <td colSpan={1}>
                        <CustomToggleButton2
                          ToggleName="accessories_status"
                          ToggleValue={aedData.accessories_status}
                        />
                      </td>
                      <td colSpan={1}>
                        <CustomToggleButton2
                          ToggleName="issue_toggle"
                          ToggleValue={aedData.issue_toggle}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {aedData.aeds_ready_status ? (
                " "
              ) : (
                <div className="col py-2">
                  <h2 className="heading">Present / Ready Information*</h2>

                  <table className="theme-table">
                    <thead>
                      <tr>
                        <td
                          className="border border-r-blue"
                          style={{ width: "50%" }}
                        >
                          Replacing AEDs
                        </td>
                        <td
                          className="border border-r-blue"
                          style={{ width: "50%" }}
                        >
                          Replacement Serial #
                        </td>
                      </tr>
                    </thead>
                    <tbody className="">
                      <tr>
                        <td>
                          <CustomToggleButton2
                            ToggleName="ReplacingAeds"
                            ToggleValue={aedData?.replacement_aed_toggle}
                          />
                        </td>
                        <td> {aedData?.replacement_aed_serial}</td>
                      </tr>
                    </tbody>
                  </table>

                  <table className="theme-table" style={{ marginBottom: "3%" }}>
                    <thead>
                      <tr>
                        <td className="border border-r-blue">Comment</td>
                      </tr>
                    </thead>
                    <tbody className="">
                      <tr>
                        <td>{aedData?.aed_present_info}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </>
          ))
        )}

        {formData?.battery?.length > 0 &&
          formData.battery[0]?.section_name !== "charge_pack" && (
            <div className="col py-2">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h2 className="heading">Battery Information</h2>
              </div>

              <table className="theme-table">
                <thead>
                  <tr>
                    <td
                      className="border border-r-blue"
                      style={{ minWidth: "150px" }}
                    >
                      Battery Type
                    </td>
                    {formData?.visiblae_columns?.map((it) => (
                      <td
                        className="border border-r-blue"
                        style={{ minWidth: "150px" }}
                      >
                        {it.title}
                      </td>
                    ))}
                  </tr>
                </thead>
                <tbody className="">
                  {sortedBatteryArray &&
                    sortedBatteryArray.map((battery, Bindex) => (
                      <React.Fragment key={Bindex}>
                        <tr>
                          <td>{battery?.is_spare === 0 ? "Main" : "Spare"}</td>

                          {IsColumnVisible("battery_type_id") ? (
                            <td>{battery?.battery_part}</td>
                          ) : (
                            ""
                          )}

                          {IsColumnVisible("battery_expiration") ? (
                            <td>
                              {battery?.battery_expiration &&
                              moment(battery.battery_expiration).isValid()
                                ? RenderDate(
                                    moment(battery.battery_expiration).format(
                                      "MM/DD/YYYY"
                                    )
                                  )
                                : "NA"}
                            </td>
                          ) : (
                            ""
                          )}

                          {IsColumnVisible("manufactured_date") ? (
                            <td>
                              {battery?.manufactured_date &&
                              moment(battery.manufactured_date).isValid()
                                ? moment(battery.manufactured_date).format(
                                    "MM/DD/YYYY"
                                  )
                                : "NA"}
                            </td>
                          ) : (
                            ""
                          )}

                          {IsColumnVisible("battery_lot") ? (
                            <td>{battery?.battery_lot}</td>
                          ) : (
                            ""
                          )}

                          {IsColumnVisible("battery_udi") ? (
                            <td>{battery?.battery_udi}</td>
                          ) : (
                            ""
                          )}

                          {IsColumnVisible("install_before_date") ? (
                            <>
                              <td>
                                {battery?.install_before_date &&
                                moment(battery.install_before_date).isValid()
                                  ? moment(battery.install_before_date).format(
                                      "MM/DD/YYYY"
                                    )
                                  : "NA"}
                              </td>
                            </>
                          ) : (
                            ""
                          )}

                          {IsColumnVisible("install_date") ? (
                            <>
                              <td>
                                {battery?.install_date &&
                                moment(battery.install_date).isValid()
                                  ? moment(battery.install_date).format(
                                      "MM/DD/YYYY"
                                    )
                                  : "NA"}
                              </td>
                            </>
                          ) : (
                            ""
                          )}

                          {IsColumnVisible("install_9v_date") ? (
                            <td>
                              {battery?.install_9v_date &&
                              moment(battery.install_9v_date).isValid()
                                ? moment(battery.install_9v_date).format(
                                    "MM/DD/YYYY"
                                  )
                                : "NA"}
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          )}

        {formData?.battery?.length > 0 &&
          formData?.battery[0]?.section_name === "charge_pack" && (
            <div className="col py-2">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h2 className="heading">Charge Pak Information</h2>
              </div>

              <table className="theme-table">
                <thead>
                  <tr>
                    <td
                      className="border border-r-blue"
                      style={{ minWidth: "150px" }}
                    >
                      Charge-Pak Type
                    </td>

                    <td className="border border-r-blue">Charge-Pak Part #</td>
                    <td className="border border-r-blue">Battery Expiration</td>
                    <td className="border border-r-blue">Battery Lot</td>
                    <td className="border border-r-blue">Charge-Pak UDI</td>
                  </tr>
                </thead>
                <tbody className="">
                  {sortedBatteryArray &&
                    sortedBatteryArray.map((battery, Bindex) => (
                      <>
                        <tr key={Bindex}>
                          <td>{battery?.is_spare === 0 ? "Main" : "Spare"}</td>
                          <td>{battery?.battery_part}</td>
                          <td>
                            {battery?.battery_expiration &&
                            moment(battery.battery_expiration).isValid()
                              ? RenderDate(
                                  moment(battery.battery_expiration).format(
                                    "MM/DD/YYYY"
                                  )
                                )
                              : "NA"}
                          </td>
                          <td>{battery?.battery_lot}</td>
                          <td>{battery?.charge_pak_uid}</td>
                        </tr>
                      </>
                    ))}
                </tbody>
              </table>
            </div>
          )}

        {formData?.pad && formData.pad.length > 0 ? (
          <>
            <div className="col py-2">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h2 className="heading">Pads Information</h2>
              </div>

              <table className="theme-table">
                <thead>
                  <tr>
                    <td className="border border-r-blue">Pad Type</td>
                    <td className="border border-r-blue">Part #</td>
                    <td className="border border-r-blue">Expiration Date</td>
                    <td className="border border-r-blue">Pad Lot</td>
                    <td className="border border-r-blue">Pad UID</td>
                  </tr>
                </thead>
                <tbody className="">
                  {sortedPadArray &&
                    sortedPadArray.map((pad, Pindex) => (
                      <>
                        <tr key={Pindex}>
                          <td>
                            {pad?.is_spare === 0 && pad?.is_pediatric === 0
                              ? "Main"
                              : pad?.is_spare === 1 && pad?.is_pediatric === 0
                              ? "Spare"
                              : pad?.is_spare === 0 && pad?.is_pediatric === 1
                              ? "Pediatric"
                              : "Spare Pediatric"}
                          </td>
                          <td>{pad?.pad_part}</td>
                          <td>
                            {pad?.pad_expiration &&
                            moment(pad.pad_expiration).isValid()
                              ? RenderDate(
                                  moment(pad.pad_expiration).format(
                                    "MM/DD/YYYY"
                                  )
                                )
                              : "NA"}{" "}
                          </td>
                          <td>{pad.pad_lot}</td>
                          <td>{pad?.pad_udi ? pad.pad_udi : "N/A"}</td>
                        </tr>
                      </>
                    ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          ""
        )}

        {loading.aedDetails ? (
          <AEDSupportTicketSkeleton />
        ) : (
          formData?.aedDetails?.map(
            (aedData, index) =>
              aedData?.issue_toggle === 1 && (
                <div className="col py-2" key={index}>
                  <h2 className="heading">Support Ticket Issue</h2>

<Form.Group
              className={`service-notes-container`}
              style={{
                height: "70px",
                width: "100%",
                border: `3px solid #337ab7`,
                padding: "5px"
              }}
            >
              {aedData?.issue}
            </Form.Group>

              {/*    <textarea
                    className="form-control"
                    name="support_description"
                    value={aedData?.issue}
                    style={{
                      height: "auto",
                      width: "100%",
                      border: "3px solid #337ab7",
                    }}
                  ></textarea> */}
                </div>
              )
          )
        )}

        <div className="col py-2 table-main-20002">
          <h2 className="heading">Servicing Questions*</h2>

          {loading.serviceQuestion ? (
            <InspectionTableSkeleton />
          ) : (
            <table className="table">
              <tbody className="">
                <tr>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        columnGap: "8%",
                      }}
                    >
                      <span>Inspection Date:</span>
                      <span>
                        <div
                          className={
                            "d-flex align-items-center calendar-input-btn"
                          }
                        >
                          <span>
                            <Moment
                              date={formData?.serviceQuestion?.inspection_date}
                              format="MM/DD/YYYY"
                            />
                          </span>
                        </div>
                      </span>
                    </div>
                  </td>
                  <td style={{ columnGap: "18%" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        columnGap: "7%",
                        alignItems: "center",
                      }}
                    >
                      <span>Inspection Completed by: </span>
                      <span>{formData?.serviceQuestion?.inspection_by}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td> Are all AEDS present and in a ready status?</td>
                  <td>
                    {" "}
                    <CustomToggleButton2
                      ToggleName="aeds_ready_status"
                      ToggleValue={formData?.serviceQuestion?.aeds_ready_status}
                      is_read_only={false}
                    />
                  </td>
                </tr>

                <tr>
                  <td> Did you replaced any rescue kits?</td>
                  <td>
                    {" "}
                    <CustomToggleButton2
                      ToggleName="rescue_kits_status"
                      ToggleValue={
                        formData?.serviceQuestion?.rescue_kits_status
                      }
                      is_read_only={false}
                    />
                  </td>
                </tr>

                <tr>
                  <td> Did you replace any alarm batteries?</td>
                  <td>
                    {" "}
                    <CustomToggleButton2
                      ToggleName="alarm_batteries_status"
                      ToggleValue={
                        formData?.serviceQuestion?.alarm_batteries_status
                      }
                      is_read_only={false}
                    />
                  </td>
                </tr>

                <tr>
                  <td> Did you replace any accessories?</td>
                  <td>
                    {" "}
                    <CustomToggleButton2
                      ToggleName="accessories_status"
                      ToggleValue={
                        formData?.serviceQuestion?.accessories_status
                      }
                      is_read_only={false}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>

        {loading.serviceQuestion ? (
          <ServicingNotesSkeleton />
        ) : (
          <div className="col py-2">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h2 className="heading">Servicing Notes*</h2>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "5%",
                  marginRight: "1%",
                }}
              >
                <h2 className="heading">Issue</h2>
                <CustomToggleButton2
                  ToggleName="issue_toggle"
                  ToggleValue={formData?.serviceQuestion?.issue_toggle}
                  is_read_only={false}
                />
              </div>
            </div>
            <Form.Group
              className={`service-notes-container`}
              style={{
                height: "200px",
                width: "100%",
                border: `3px solid #337ab7`,
                padding: "5px",
              }}
            >
              {formData?.serviceQuestion?.issue_toggle
                ? formData?.issue
                : formData?.serviceQuestion?.servicing_notes}
            </Form.Group>
          </div>
        )}

        {loading.image ? (
          <ServicingNotesSkeleton
            showIssueToggle={false}
            heading="Servicing Images"
          />
        ) : (
          <div className="col py-2">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h2 className="heading">Servicing Images</h2>
            </div>

            <div
              className="col relative service-notes-container"
              style={{
                height: "200px",
                width: "100%",
                maxWidth: "100%",
                border: "3px solid #337ab7",
                padding: "1%",
                overflowX: "auto",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "flex-start",
              }}
            >
              <ul
                style={{
                  listStyleType: "none",
                  margin: 0,
                  padding: 0,
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "15px",
                  paddingRight: "20px",
                }}
              >
                {formData?.image &&
                  formData?.image.map((img, index) => (
                    <li
                      key={index}
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={`https://www.upload.rossdev.xyz/public/${img.image_id}`}
                        alt={img.image_id}
                        style={{ height: "120px", width: "200px" }}
                      />
                      <button
                        onClick={() =>
                          downloadImage(
                            `https://www.upload.rossdev.xyz/api/download/${img.image_id}`,
                            `image_${index}`
                          )
                        }
                        style={{
                          position: "absolute",
                          padding: "0px",
                          right: "0px",
                          marginTop: "0px",
                          textDecoration: "none",
                          color: "#337ab7",
                          cursor: "pointer",
                          border: "none",
                          background: "none",
                        }}
                      >
                        <img
                          src="/assets/images/download_document.svg"
                          alt=""
                          width={25}
                          height={25}
                        />
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        )}

        <div className="col py-2">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2 className="heading">AEDs on Service Check</h2>
          </div>

          {loading.serviceQuestiondata ? (
            <AEDChecksTableSkeleton columns={columns} />
          ) : (
            <table
              {...getTableProps()}
              className="table table-bordered table-hover"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        style={{ backgroundColor: "#999" }}
                      >
                        <div
                          className="d-flex align-items-center th-d"
                          style={{ cursor: "pointer" }}
                        >
                          {column.render("Header")}
                          <span className="ml-1">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <SouthIcon size={2} />
                              ) : (
                                <NorthIcon size={2} />
                              )
                            ) : (
                              <SouthIcon size={2} />
                            )}
                          </span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {formData?.serviceQuestiondata?.length === 0 ? (
                  <tr>
                    <td
                      colSpan={columns.length}
                      style={{
                        textAlign: "center",
                        padding: "20px",
                        fontStyle: "italic",
                        color: "grey",
                      }}
                    >
                      No data found
                    </td>
                  </tr>
                ) : (
                  rows?.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        style={{
                          backgroundColor: i % 2 === 0 ? "white" : "#e4e4e4",
                        }}
                      >
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          )}
        </div>

        <div style={{ marginTop: "70px", marginBottom: "10px" }}>
          <Box
            className="d-flex justify-content-between align-items-center"
            style={{ gap: "50px" }}
          >
            <p className="d-flex">
              Created :{" "}
              {loading.serviceQuestion ? (
                <Skeleton variant="rectangular" width={170} height={24} />
              ) : formData?.serviceQuestion?.created_date ? (
                <>
                  {formData?.serviceQuestion?.created_by}{" "}
                  <Moment
                    date={formData?.serviceQuestion?.created_date}
                    format={"MM/DD/YYYY h:mm A"}
                  />
                </>
              ) : (
                ""
              )}
            </p>
            <p>
              Last Touch Date :{" "}
              {/* {aedDetails?.last_check
                    ? FormatDate(aedDetails?.last_check)
                    : ""} */}
            </p>
            {/* <Moment date={aedDetails?.last_check} format={'DD-MM-YYYY'} /> */}
          </Box>
        </div>
      </div>
    </>
  );
};

export default AEDServiceDetails;
