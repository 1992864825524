import React, { useCallback, useEffect, useState } from "react";
import { Alert, Box, Snackbar } from "@mui/material";
import { Button as FixedButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { CallPOSTAPI } from "../../../common/services/index";
import Drawer from "@mui/material/Drawer";
import SubHeading from "../components/header/SubHeading";
import Filter from "../components/filter/index";
import { useSelector, useDispatch } from "react-redux";
import {
  removeFilterData,
  removePayloadData,
} from "../../../store/slices/AccountListFilter";
import MyTable from "../components/MyTable";
import NewGlobalTable from "../../../common/components/custom-ui/NewGlobalTable";
import { RenderEqupment } from "../../../common/helper/TblFn";
import CloseIcon from "@mui/icons-material/Close";
import { Lock } from "@mui/icons-material";
import { DecryptToken, truncateText } from "../../../common/helper/BasicFn.js";
import { getPermission } from "../../../common/helper/Common.js";

const AccountsListing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [accounts, setAccounts] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [showSearchReset, setShowSearchReset] = useState(false);
  const [search, setSearch] = useState("");
  const [showLoading, setShowLoading] = React.useState(true);
  const [openModel, setOpenModel] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const filterData = useSelector((state) => state.accountlistfilter.filterData);
  const payloadData = useSelector(
    (state) => state.accountlistfilter.payloadData
  );
  console.log({payloadData})

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleCloseModel = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenModel(false);
  };

  const getAccountsList = async (
    page = 1,
    payloadData1 = payloadData, 
    searchFn = true
  ) => {
    return '';
    setShowLoading(true);

    if (Object.keys(filterData).length !== 0) {
      const allFieldsEmpty = Object.entries(payloadData1)?.map(
        ([key, value]) => {
          if (key === "allToggle") {
            return value !== false;
          } else if (key !== "aed" && key !== "training") {
            return value?.length !== 0;
          }
          return false;
        }
      );

      let isTrue = false;
      for (const item of allFieldsEmpty) {
        if (item === true) {
          isTrue = true;
          break;
        }
      }

      if (!isTrue) {
        const accountsData = await CallPOSTAPI("account/account-list", {
          page: page,
          search: searchFn ? search.trimEnd() : "",
        });
        if (accountsData?.status) {
          setAccounts(accountsData?.data?.data?.account || []);
          setTotalPages(accountsData?.data?.data?.totalPages || 0);
        }
      } else {
        console.log({payloadData1})
        let results = await CallPOSTAPI("account/filter-search-result", {
          ...payloadData1,
          page: page,
          search: searchFn ? search.trimEnd() : "",
        });
        if (results?.status) {
          setAccounts(results?.data?.data?.account_list || []);
          setTotalPages(results?.data?.data?.totalPages || 0);
        }
      }
    } else {
      const accountsData = await CallPOSTAPI("account/account-list", {
        page: page,
        search: searchFn ? search.trimEnd() : "",
      });

      if (accountsData?.status) {
        setAccounts(accountsData?.data?.data?.account || []);
        setTotalPages(accountsData?.data?.data?.totalPages || 0);
      }
    }

    setShowLoading(false);
  };

  useEffect(() => {
    if (location?.state?.accountListings) {
      setTimeout(() => {
        setAccounts(location?.state?.accountListings);
      }, 1000);
    } else {
      // getAccountsList(currentPage, payloadData);
    }

    return () => {
      location.state = "";
    };
  }, [location, currentPage, search]);

  const handleClearFilterData = async () => {
    dispatch(removeFilterData());
    dispatch(removePayloadData());
    setCurrentPage(1);
    // getAccountsList(1, {});

    // navigate("/accounts-listing", {
    //   state: {
    //     accountListings: false,
    //   },
    // });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleReset = useCallback(() => {
    setShowSearchReset(!showSearchReset);
    setSearch("");
    // getAccountsList(1, payloadData, false);
  }, [getAccountsList, search]);

  const handleSetSearch = async(value) => {
    setCurrentPage(1);
    setSearch(value);
  }


///Render functions:  
const user = DecryptToken();
const privilege = getPermission();

const RenderAccTitle = (account) => {
    const isClickable =
    user?.user_type == 0 ||
    (user?.user_type == 2 &&
      user?.sub_admin != "" &&
      (privilege?.includes("account-details") ||
        privilege?.includes("site-tab") ||
        privilege?.includes("contact-tab") ||
        privilege?.includes("equipment-tab") ||
        privilege?.includes("training-tab") ||
        privilege?.includes("inperson-tab") ||
        privilege?.includes("pops-tab") ||
        privilege?.includes("notes-tab") ||
        privilege?.includes("support-tab") ||
        privilege?.includes("email-tab") ||
        privilege?.includes("documents-tab") ||
        privilege?.includes("rfi-tab")));

  return (
    <span
      className={isClickable ? "link" : ""}
      onClick={() =>
        isClickable &&
        navigate(`/account-details/${account.account_id}`, {
          state: {
            siteTitle: "Account: " + account?.account_name,
            editUrl: `/account/accounts-edit/${account?.account_id}`,
            deleteUrl: `/account/accounts-delete/${account?.account_id}`,
          },
        })
      }
    >
      {truncateText(account.account_name, 25)}
    </span>
  );
};

const RenderAccParent = (account) => {
  return (
    <span
      className={
        user?.user_type == 0 ||
        (user?.user_type == 2 &&
          user?.sub_admin != "" &&
          (privilege?.includes("account-details") ||
            privilege?.includes("site-tab") ||
            privilege?.includes("contact-tab") ||
            privilege?.includes("equipment-tab") ||
            privilege?.includes("training-tab") ||
            privilege?.includes("inperson-tab") ||
            privilege?.includes("pops-tab") ||
            privilege?.includes("notes-tab") ||
            privilege?.includes("support-tab") ||
            privilege?.includes("email-tab") ||
            privilege?.includes("documents-tab") ||
            privilege?.includes("rfi-tab")))
          ? "link"
          : ""
      }
      onClick={() =>
        (user?.user_type == 0 ||
          (user?.user_type == 2 &&
            user?.sub_admin != "" &&
            (privilege?.includes("account-details") ||
              privilege?.includes("site-tab") ||
              privilege?.includes("contact-tab") ||
              privilege?.includes("equipment-tab") ||
              privilege?.includes("training-tab") ||
              privilege?.includes("inperson-tab") ||
              privilege?.includes("pops-tab") ||
              privilege?.includes("notes-tab") ||
              privilege?.includes("support-tab") ||
              privilege?.includes("email-tab") ||
              privilege?.includes("documents-tab") ||
              privilege?.includes("rfi-tab")))) &&
        navigate(`/account-details/${account.parent_account_id}`, {
          state: {
            siteTitle: "Account: " + account?.parent_name,
            editUrl: `/account/accounts-edit/${account?.parent_account_id}`,
            deleteUrl: `/account/accounts-delete/${account?.parent_account_id}`,
          },
        })
      }
      role="button"
    >
      {truncateText(account.parent_name, 20)}
    </span>
  );
};

const RenderDistributer = (account) => {
  return (
    <span
      className={
        user?.user_type == 0 ||
        (user?.user_type == 2 &&
          user?.sub_admin != "" &&
          (privilege?.includes("account-details") ||
            privilege?.includes("site-tab") ||
            privilege?.includes("contact-tab") ||
            privilege?.includes("equipment-tab") ||
            privilege?.includes("training-tab") ||
            privilege?.includes("inperson-tab") ||
            privilege?.includes("pops-tab") ||
            privilege?.includes("notes-tab") ||
            privilege?.includes("support-tab") ||
            privilege?.includes("email-tab") ||
            privilege?.includes("documents-tab") ||
            privilege?.includes("rfi-tab")))
          ? "link"
          : ""
      }
      onClick={() =>
        (user?.user_type == 0 ||
          (user?.user_type == 2 &&
            user?.sub_admin != "" &&
            (privilege?.includes("account-details") ||
              privilege?.includes("site-tab") ||
              privilege?.includes("contact-tab") ||
              privilege?.includes("equipment-tab") ||
              privilege?.includes("training-tab") ||
              privilege?.includes("inperson-tab") ||
              privilege?.includes("pops-tab") ||
              privilege?.includes("notes-tab") ||
              privilege?.includes("support-tab") ||
              privilege?.includes("email-tab") ||
              privilege?.includes("documents-tab") ||
              privilege?.includes("rfi-tab")))) &&
        navigate(`/account-details/${account.distributor_id}`, {
          state: {
            siteTitle: "Account: " + account?.distributor_name,
            editUrl: `/account/accounts-edit/${account?.distributor_id}`,
            deleteUrl: `/account/accounts-delete/${account?.distributor_id}`,
          },
        })
      }
      role="button"
    >
      {truncateText(account.distributor_name, 25)}
    </span>
  );
};

const headerColumns = [
  {
    Header: "Account Name",
    accessor: "account_name",
    Cell: ({ row }) => (
      <div style={{textDecoration:'none' }}>
        {RenderAccTitle(row.original)}
      </div>
    ),
    width: "18%",
  },
  {
    Header: "Equipment",
    accessor: "equipment",
    Cell: ({ row }) => RenderEqupment(row.original),
    disableSortBy: true,
  },
  {
    Header: "Training",
    accessor: "trainning",
    Cell: () => (
      <span className="text-danger">
        <CloseIcon color="error" />
      </span>
    ),
    disableSortBy: true,
  },
  {
    Header: "Customer Type",
    accessor: "customer_type_name",
  },
  {
    Header: "Parent Account",
    accessor: "parent_name",
    Cell: ({ row }) => (
      <div style={{textDecoration:'none' }}>
        {RenderAccParent(row.original)}
      </div>
    ),
  },
  {
    Header: "Distributor Account",
    accessor: "distributon_name",
    Cell: ({ row }) => 
     RenderDistributer(row.original)
    ,
  },
  {
    Header: "Owner",
    accessor: "owner",
    width: "10%",
    Cell: ({ row }) => truncateText(row.original.owner, 15)
  },
  {
    Header: "Restricted",
    accessor: "isSecure",
    width: "5%",
    Cell: ({ value }) => 
      value ? (
        <Lock size={16} className="text-dark" />
      ) : (
        <CloseIcon color="error" />
      ),
    disableSortBy: true,
  },
]


  return (
    <>
      <div>
        {filterData && Object.keys(filterData).length !== 0 ? (
          <>
            <FixedButton
              className="btn-style-listing-cancel-filter"
              onClick={handleClearFilterData}
            >
              Clear Filter
            </FixedButton>
          </>
        ) : null}
        <FixedButton className="btn-style-filter" onClick={handleDrawerOpen}>
          Advanced Filters
        </FixedButton>
        <Drawer
          sx={{
            width: "400px",
            flexShrink: 0,
            // background: '#000',
            "& .MuiDrawer-paper": {
              width: "400px",
              boxSizing: "border-box",
              background: '#000',
            },
          }}
          anchor="right"
          open={open}
          onClose={handleDrawerClose}
        >
          {/* filter component  */}
          <Filter
            setaccounts={setAccounts}
            accountListingPage={true}
            setOpen={setOpen}
            setShowLoading={setShowLoading}
            getAccountsList={getAccountsList}
            currentPage={currentPage}
            setTotalPages={setTotalPages}
            search={search}
            clearBtn={filterData && Object.keys(filterData).length !== 0}
          />
        </Drawer>
      </div>
      <Snackbar
        open={openModel}
        autoHideDuration={3000}
        onClose={handleCloseModel}
      >
        <Alert
          severity="success"
          sx={{ width: "100%" }}
          onClose={handleCloseModel}
        >
          Record Deleted SuccessFully!!!
        </Alert>
      </Snackbar>
      <div className="mt-4">
        <SubHeading
          hideNew={false}
          hideHierarchy={true}
          title={"Account Listing"}
          newUrl="/admin-account"
          subHeading={true}
        />

        <div style={{ minHeight: "84.6vh" }}>
          <Box className=" py-4">
            {/* <MyTable
              tableData={Array.isArray(accounts) ? accounts : []}
              totalPages={totalPages}
              currentPage={currentPage}
              setShowSearchReset={setShowSearchReset}
              showSearchReset={showSearchReset}
              handleReset={handleReset}
              showLoading={showLoading}
              handleSetSearch={handleSetSearch}
              onPageChange={handlePageChange}
            /> */}
            <NewGlobalTable 
             isSearchable={true}
             isCache={true}
             pageSize={50}
             payloadData={payloadData && Object.keys(payloadData).length > 0  ? payloadData : null}
             keyAPiName={payloadData && Object.keys(payloadData).length > 0  ? "account_list" :"account"}
             apiEndPoint={payloadData && Object.keys(payloadData).length > 0 ? "account/filter-search-result":"account/account-list"}
             headerColumns={headerColumns}
            />
          </Box>
        </div>
      </div>
    </>
  );
};

export default AccountsListing;
