import { Box, Skeleton } from "@mui/material";
import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import TableSkeleton from "../skeleton/table/TableSkeleton";
import Moment from "react-moment";
import { AED_IMG_BASE } from "../../../../common/services/index";
import { FormatDateWithTime } from "../../../../common/helper/Common";
import "../../../../global.css";
import ProgramDetails from "../DetailsComponents/ProgramDetails";
import CoordinatorInformation from "../DetailsComponents/CoordinatorInformation";
import DataTableSkeleton from "../DetailsComponents/ProgramDetailsSkeleton";
import AedUnits from "../DetailsComponents/AedUnits"

export default function Details({
  accountDetails,
  CoordiDataList,
  programDetails,
  httpsWeb,
  tabTbldata,
  setProgramData,
  setAedUnitData=()=>{},
  setCoordinatorData,
  showLoading,
}) {
  // const navigate = useNavigate();
  // const [showLoading, setShowLoading] = React.useState(true);
  const { accountId } = useParams();

  // if(accountDetails && CoordiDataList && programDetails){
  // tabTbldata.site = ""
  // }

  // useEffect(() => {
  //   setShowLoading(false);
  // }, [accountDetails, CoordiDataList, programDetails, httpsWeb]);

  return (
    <>
      <div
        className="relative"
        style={{
          width: "100%",
          paddingInline: "0px",
          marginLeft: "0px",
          paddingLeft: "0px",
          marginBottom: "5%",
        }}
      >
        {/* loading */}
        {/*     {showLoading && (
        <div className="showloading-table">
          <TableSkeleton />
        </div>
      )}
*/}

        {/* heading */}
        <Box className="text-left pt-3">
          <h4 className="heading">General Information</h4>
        </Box>
        <table className="w-100">
          <tbody>
            <tr className="">
              <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">
                Main Site
              </th>
              <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">
                Terms
              </th>
              <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">
                Customer Type
              </th>
              <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">
                Industry
              </th>
              <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">
                Lead Source
              </th>
              <th className=" py-1 px-2 bg-tbl-border border-t-blue">
                Account Status
              </th>
            </tr>
            {showLoading ? (
              <DataTableSkeleton />
            ) : (
              <tr className="">
                <td className=" py-1 px-2 border-r-blue">
                  {accountDetails?.main_site}
                </td>
                <td className=" py-1 px-2 border-r-blue">
                  {accountDetails?.terms}
                </td>
                <td className=" py-1 px-2 border-r-blue">
                  {accountDetails?.customer_type}
                </td>
                <td className=" py-1 px-2 border-r-blue">
                  {accountDetails?.industry}
                </td>
                <td className=" py-1 px-2 border-r-blue">
                  {accountDetails?.lead_source}
                </td>
                <td className=" py-1 px-2">{accountDetails?.account_status}</td>
              </tr>
            )}

            <tr className="">
              <th className=" py-1 px-2 border-t-blue bg-tbl-border border-r-blue">
                Parent Account
              </th>
              <th
                className=" py-1 px-2 border-t-blue bg-tbl-border border-r-blue"
                colSpan="2"
              >
                Distributor Account
              </th>
              <th
                className=" py-1 px-2 border-t-blue bg-tbl-border"
                colSpan="3"
              >
                Product Interest
              </th>
            </tr>

            {showLoading ? (
              <tr>
                <td className="py-1 px-2 border-r-blue border-b-blue">
                  <Skeleton width={100} />
                </td>
                <td className=" py-1 px-2 border-r-blue border-b-blue"
                  colSpan="2">
                  <Skeleton width={130} />
                </td>
                <td className=" py-1 px-2 border-b-blue" colSpan="3">
                  <Skeleton width={270} />
                </td>
              </tr>
            ) : (
              <tr className="">
                <td className=" py-1 px-2 border-r-blue border-b-blue">
                  {accountDetails?.parent_account_id ? (
                    <>
                      <Link
                        className="link"
                        style={{ textDecoration: "none" }}
                        to={
                          "/account-details/" +
                          accountDetails?.parent_account_id
                        }
                      >
                        {accountDetails?.parent_account}
                      </Link>
                    </>
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className=" py-1 px-2 border-r-blue border-b-blue"
                  colSpan="2"
                >
                  {accountDetails?.distributor_account_id ? (
                    <>
                      <Link
                        className="link"
                        style={{ textDecoration: "none" }}
                        to={
                          "/account-details/" +
                          accountDetails?.distributor_account_id
                        }
                      >
                        {accountDetails?.distributor_account}
                      </Link>
                    </>
                  ) : (
                    ""
                  )}
                </td>
                <td className=" py-1 px-2 border-b-blue" colSpan="3">
                  {accountDetails?.product_interest?.join(", ")}
                </td>
              </tr>
            )}

            <tr className="">
              <th
                className=" py-1 px-2 border-b-blue border-r-blue"
                colSpan="3"
              >
                Important Note
              </th>
              <th className=" py-1 px-2 border-b-blue" colSpan="3">
                Website
              </th>
            </tr>
            {showLoading ? (
              <tr>
                <td className=" py-1 px-2 border-b-blue border-r-blue"
                colSpan="3">
                  <Skeleton width={230} />
                </td>
                <td className=" py-1 px-2 border-b-blue" colSpan="3">
                  <Skeleton width={370} />
                </td>
              </tr>
            ) : (
              <tr className="">
                <td
                  className=" py-1 px-2 border-b-blue border-r-blue"
                  colSpan="3"
                >
                  {accountDetails?.inportant_notes}
                </td>
                <td className=" py-1 px-2 border-b-blue" colSpan="3">
                  {accountDetails?.website != "" &&
                  accountDetails?.website != null &&
                  accountDetails?.website != "-" ? (
                    <>
                      <a
                        className="link"
                        style={{ textDecoration: "none" }}
                        href={
                          !httpsWeb
                            ? "https://" + accountDetails?.website
                            : accountDetails?.website
                        }
                        target="_blank"
                      >
                        {accountDetails?.website}
                      </a>
                    </>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {/* Program Details */}
        <ProgramDetails accountId={accountId} setProgramData={setProgramData} />
        

        {/* AED Units Owned */}
        <AedUnits accountId={accountId} setAEDUnitData={setAedUnitData} />
        

        <CoordinatorInformation
          accountId={accountId}
          setCoordinatorData={setCoordinatorData}
        />

        <div className="pb-0" style={{ marginTop: "40px" }}>
          <div className="d-flex Created-Modified">
            <div className="CreatedDiv">
              <span>
                Created:{" "}
                {accountDetails?.created_by}{" "}
                {accountDetails?.created_date
                  ? FormatDateWithTime(accountDetails?.created_date)
                  : ""}
              </span>
              {/* <span>Created By: {accountDetails?.created_by}</span> */}
            </div>

            <div className="ModifiedDiv">
              <span>
                Modified :{" "}
                {accountDetails?.modifiedBy?.account_name
                  ? accountDetails?.modifiedBy?.account_name
                  : accountDetails?.modifiedBy} {" "}
                {accountDetails?.modified_date ? (
                  <Moment
                    date={accountDetails?.modified_date}
                    format={"MM/DD/YYYY h:mm A"}
                  />
                ) : (
                  ""
                )}{" "}
              </span>
              {/* <span>
                Modified By:{" "}
                {accountDetails?.modifiedBy?.account_name
                  ? accountDetails?.modifiedBy?.account_name
                  : accountDetails?.modifiedBy}
              </span> */}
            </div>
            {/* <span>Last Touch Date: 11-14-2020 12:00:00</span> */}
          </div>
        </div>
      </div>
    </>
  );
}
