import React, { useState, useEffect, useRef } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import { useNavigate } from "react-router-dom";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import CheckIcon from "@mui/icons-material/Check";
import { Skeleton } from "@mui/material";
import BottomPagination from "../../../../../common/components/custom-ui/BottomPagination.jsx";
import { ContactStatus, DecryptToken, FormatDate, formatPhoneNumber, getPermission, GetProfile } from "../../../../../common/helper/index.js";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

const EditInventoryListTbl = ({
  tableData,
  currentPage,
  setSearch,
  showLoading,
  totalPages,
  onPageChange,
  search,
  showSearchReset,
  setShowSearchReset,
  handleReset,
  fetchLoad,
  searchFetchLoad,
  setContactDataList,
  type,
  secName,
  AedFormData,
  aed_brand_id,
  aed_model_id,
  contact_id,
  batteryInventory,
  padInventory,
  selectedBatteryRows,
  setSelectedBatteryRows,
  setSelectedPadRows,
  setSelectedRowIndex,
  selectedPadRows,
  selectSite,
  setModalHeight,
}) => {
  const navigate = useNavigate();
  const user = DecryptToken();
  const privilege = getPermission();
  const profile = GetProfile();

  const tableRef = useRef(null);

  // Adjust modal height based on table height
  useEffect(() => {
    if (tableRef.current) {
      setModalHeight(`${tableRef.current}px`);
    }
  }, [tableData, showLoading]);

  const renderDateBatteryCell = (battery) => {
    if (secName) {
      const dateField = battery.manufactured_date
        ? "manufactured_date"
        : battery.battery_expiration
        ? "battery_expiration"
        : "install_date";

      const imageSourceMap = {
        manufactured_date: "/assets/images/BatteryMfgr.svg",
        battery_expiration: "/assets/images/Battery.png",
        install_date: "/assets/images/Battery.png",
      };

      return (
        <div>
          {battery[dateField] && (
            <img
              src={imageSourceMap[dateField]}
              alt={dateField}
              style={{
                width: dateField === "manufactured_date" ? "20px" : undefined,
                height: dateField === "manufactured_date" ? "20px" : undefined,
                marginRight: "5%",
              }}
            />
          )}
          {battery[dateField] ? (
            <span>{FormatDate(battery[dateField])}</span>
          ) : (
            <span>N/A</span>
          )}
        </div>
      );
    }
  };

  const renderDniCell = (data) => {
    const feild = data.dni;
    return (
      <div>
        {feild === 1 ? (
          <DoneIcon color="success" />
        ) : (
          <CloseIcon color="error" />
        )}
      </div>
    );
  };

  useEffect(() => {

  },[batteryInventory])
  const [selectedIndex,setSelectedIndex] = useState(null)
  const selectedIndexRef = useRef();
  const handleCellClick = (rowData) => {

    selectedIndexRef.current = rowData.index
  setSelectedIndex(rowData.index)

    let name =
      secName === "has_battery" ||
      secName === "has_battery_spare" ||
      secName === "has_9v" ||
      secName === "has_9v_spare" ||
      secName === "has_installby" ||
      secName === "has_installby_spare" ||
      secName === "has_man" ||
      secName === "has_man_spare" ||
      secName === "has_10pk" ||
      secName === "has_10pk_spare" ||
      secName === "ChargePakInformation" ||
      secName === "SpareChargePakInformation"
        ? "battery"
        : "pads";
    if (name === "battery") {
      const selectedBatteryRowData = batteryInventory.filter(
        (row) => Number(row?.sbid) === Number(rowData?.original?.sbid)
      );
      setSelectedBatteryRows(selectedBatteryRowData);
    } else {
      const selectedPadRowData = padInventory.filter(
        (row) => row?.spid === rowData?.original?.spid
      );
      setSelectedPadRows(selectedPadRowData);
    }
  };

  const renderCheckBox = (e) => {
    const rowData = e;

    return (
      <>
        <input
          type="checkbox"
          value={rowData.index}
          name={"select_row"}
          checked={rowData.index === selectedIndexRef.current}
          onChange={() => handleCellClick(rowData)} 
        />
      </>
    );
  };

  const renderDatePadCell = (pad) => {
    const dateField = pad.pad_expiration ? "pad_expiration" : "";

    return (
      <div>
        {pad[dateField] && (
          <img
            src={
              pad.is_pediatric === 1 ? "/assets/images/child-Vector.png" : "/assets/images/people-Group.svg"
            }
            alt={
              pad.is_pediatric === 1 ? "/assets/images/child-Vector.png" : "/assets/images/people-Group.svg"
            }
            style={{
              width: pad.is_pediatric === 1 ? "20px" : undefined,
              height: pad.is_pediatric === 1 ? "32px" : undefined,
              marginRight: "5%",
            }}
          />
        )}
        {pad[dateField] ? (
          <span>{FormatDate(pad[dateField])}</span>
        ) : (
          <span>N/A</span>
        )}
      </div>
    );
  };

 const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        Cell: ({ row }) => (
          <div>
            {renderCheckBox(row)}
          </div>
        ),
        width: "3%",
        disableSortBy: true,
      },
      {
        Header: "Part #",
        accessor: type === "battery" ? "battery_part" : "pad_part",
        width: "350px",
        disableSortBy: false, 
      },
      {
        Header: "Date",
        accessor: "",
     Cell: ({row}) => type == "battery" ? renderDateBatteryCell(row.original) : renderDatePadCell(row.original),
        width: "350px",
        disableSortBy: false, 
       },
      {
        Header: type === "battery" ? "Battery Lot" : "Pad Lot",
        accessor: type === "battery" ?  "battery_lot" : "pad_lot",
        width: "15%",
        disableSortBy: false, 
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        width: "3%",
        disableSortBy: false,
      },
      {
        Header: "DNI",
        accessor: "dni",
        width: "5%",
        Cell: ({row}) => renderDniCell(row.original),
        disableSortBy: true,
      },
    ],
    [batteryInventory, padInventory, type]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data: (tableData && tableData) || [],
      initialState: { pageIndex: 0, pageSize: 50 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const RenderContact = (data) => {
    return (
      <>
        <span
          onClick={
            () =>
              // .includes('contact-details') && (
              navigate(
                "/account/" +
                  user?.account_id +
                  "/contact-details/" +
                  data.contact_id
              )
            // )}
            // navigate(
            //   "/account/" + accountId + "/contact-details/" + data.contact_id,
            // {
            //   state: {
            //     // siteTitle: "Contact : " + data?.contact_name,
            //     // editUrl: "/account/contact-details-edit/" + data.contact_id,
            //     deleteUrl: "/account/contact-details-edit/" + data.contact_id,
            //   },
            // }
            // )
          }
          className={"link"}
        >
          {data?.contact_name}
        </span>
      </>
    );
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (search.length > 0) {
      setShowSearchReset(true);
      searchFetchLoad();
    }
  };

  return (
    <div className="bg-none base-table" style={{ width: "100%" }}>
      <form
        className=""
        style={{ display: "flex", flexDirection: "row", gap: "20px" }}
      >
      </form>
      <div className="table-responsive" 
      // style={{position: "absolute"}}
      ref={tableRef}
      style={{
        width: "100%",
        flexGrow: 1,
      }}
      >
        <table
          {...getTableProps()}
          className="table table-bordered table-hover"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <td
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{ width: column.width, backgroundColor: "#999" }}
                    className="tb-td"
                  >
                    <div className="d-flex align-items-center th-d">
                      {column.render("Header")}
                      <span className="ml-1">
                        {!column.disableSortBy && // Only show icon if sorting is not disabled
                          (column.isSorted ? (
                            column.isSortedDesc ? (
                              <SouthIcon size={2} />
                            ) : (
                              <NorthIcon size={2} />
                            )
                          ) : (
                            <SouthIcon size={2} /> // Default icon if not sorted
                          ))}
                      </span>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {showLoading ? (
              [...Array(3)].map((_, i) => (
                <tr key={i}>
                  {columns.map((col, j) => (
                    <td
                      key={j}
                      style={{
                        backgroundColor: i % 2 === 0 ? "white" : "#e4e4e4",
                      }}
                      className="tb-td"
                    >
                      <Skeleton
                        width={"90%"}
                        height={15}
                        style={{ margin: "3px" }}
                      />
                    </td>
                  ))}
                </tr>
              ))
            ) :  (!showLoading && page?.length < 1) ? (
              <tr>
                <td
                  colSpan={columns.length}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontStyle: "italic",
                    color: "grey",
                  }}
                >
                  No Inventory Found.
                </td>
              </tr>
            ) : (
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          backgroundColor: i % 2 === 0 ? "white" : "#e4e4e4",
                        }}
                        className="tb-td"
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      
      {totalPages > 1 && (
        <div className="mt-4 d-flex justify-content-end align-items-center">
          <BottomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </div>
  );
};

export default EditInventoryListTbl;
