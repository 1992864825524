import React from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap';


const Header = () => (
    <>
      <Navbar bg="white" variant="dark">
        <Container>
          <Navbar.Brand href="#home">
          <img className="brand-img" src="/assets/images/red-logo.svg" alt="logo" style={{width: '300px'}} />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Navbar style={{background:'rgb(12, 113, 195)'}} variant="dark">
        <Container>
          <Nav>
            <Nav.Link className='text-white' href="#training">Training</Nav.Link>
            <Nav.Link className='text-white' href="#calendar">Calendar</Nav.Link>
            <Nav.Link className='text-white' href="#login">Login</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  );

  
export default Header