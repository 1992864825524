import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  FormControlLabel,
  Switch,
  Skeleton
} from "@mui/material";
import SubHeadingOther from "../../../common/components/SubHeadingOther";
import {
  Form,
  Button as BButton,
  Button as BsButton,
  InputGroup,
} from "react-bootstrap";
import Contacts from "../../../common/img/Contacts.svg";
import Locations from "../../../common/img/Locations.svg";
import CustomToggleButton from '../../../common/components/toggleSwitch/CustomToggleButton';
// import AddContactsmodal from './AddContactspage';
import AddSitesmodal from './AddSitesPage';
import { CallGETAPI, CallPOSTAPI } from '../../../common/services/index';
import { ContactList } from '../../../common/helper/BasicFn';
import { toast } from 'react-toastify';
import AddContactsmodalNew from './AddContactsmodalNew';
import Loading from "../../../common/components/Loader/Loading";
import { useSelector, useDispatch } from 'react-redux';
import { getCombinedData, selectStatus, selectError, fetchApi1, fetchApi2, fetchApi3 } from "../slices/PopDetailSlice"


const PopEdit = () => {
  const { popId, accountId } = useParams();
  console.log("apapapapapap", popId)
  const [validated, setValidated] = useState(false);
  const [addSitesModal, setAddSitesModal] = useState(false);
  const [addContactsModal, setAddContactsModal] = useState(false);
  const [selectedSites, setSelectedSites] = useState([]);
  const [popDetails, setPopDetails] = useState("");
  const [sitesDetails, setSitesDetails] = useState([]);
  const [contractOfficer, setContractOfficer] = useState([]);
  const [contractOfficerRep, setContractOfficerRep] = useState([]);
  const [otherReps, setOtherReps] = useState([]);
  const [accountContact, setAccountContact] = useState([]);
  const [isActive, setIsActive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isInvoicePaid, setIsInvoicePaid] = useState(0);
  const navigate = useNavigate();
  const [accountName, setAccountName] = useState('')
  const [siteName, setSiteName] = useState('');
  const [loading2, setLoading2] = useState(true);
  const [siteDetails, setSiteDetails] = useState([]);
  const [status, setStatus] = useState(() => {
    if (popDetails.status === 0) {
      return 'inactive';
    } else if (popDetails.status === 1) {
      return 'active';
    } else {
      return 'cancelled';
    }
  });
  const [selectedContact, setSelectedContacts] = useState({
    contract_officer: [],
    contracting_officer_rep: [],
    other_reps: [],
  });

  const params = useParams();
  console.log("rrrrrrr", params)
  const [formData, setFormData] = useState({});

  const fetchOnLoad = async () => {
    const response = await CallGETAPI(`pop/get-pop-performance/${popId}`);
    const data = response?.data?.data || [];
    console.log("dfdfdfdff", data.contact)

    if (data) {
      const PopDetails = data;
      const obj = {
        qb_invoice: PopDetails?.qb_invoice,
        invoice_paid: PopDetails?.invoice_paid,
        active: PopDetails?.active,
        status: PopDetails?.status,
      }
      setIsActive(PopDetails?.active);
      setIsInvoicePaid(PopDetails?.invoice_paid);
      setFormData(obj);
      setPopDetails(PopDetails);
    }

    console.log("cvfghjuytr", data)
    if (data) {
      const contactData = JSON.parse(data?.contact);
      console.log("oplkjuio", contactData)
      setSelectedContacts(contactData);
      const contractOfficer = contactData.contract_officer;
      const contractOfficerRep = contactData.contracting_officer_rep;
      const otherReps = contactData.other_reps;
      console.log("aqwertyuiop", contractOfficer, contractOfficerRep, otherReps)
      setContractOfficer(contractOfficer);
      setContractOfficerRep(contractOfficerRep);
      setOtherReps(otherReps);
    }

  }

  useEffect(() => {
    fetchOnLoad();
    getAccountName()
    getSiteDetails()
  }, [])
  console.log("zxcvbnm", siteDetails)
  console.log("5555555", popDetails)

  console.log("yyyyyyyyy", siteName)
  const fetchContactByAccount = async () => {
    let AccountContactList = await ContactList(accountId)

    if (AccountContactList) {
      setAccountContact(AccountContactList)
    }
  }
  useEffect(() => {
    fetchContactByAccount();
  }, []);

  const addSites = () => {
    setAddSitesModal(true)
  }

  const addContacts = () => {
    setAddContactsModal(true)
  }

  const handlePaidToggle = () => {
    setIsInvoicePaid(isInvoicePaid === 0 ? 1 : 0);
  };

  const handleActiveToggle = () => {
    setIsActive(isActive === 0 ? 1 : 0);
  };

  const dispatch = useDispatch();
  const combinedData = useSelector(getCombinedData);
  const sstatus = useSelector(selectStatus);
  const error = useSelector(selectError);
  useEffect(() => {
    dispatch(fetchApi1(popId));
    dispatch(fetchApi2(popId));
    dispatch(fetchApi3(popId));
  }, [dispatch, popId]);

  console.log("qpoiuytrew", combinedData)

  // useEffect(() => {
  //   // Update siteDetails state when selectedSites changes
  //   setSitesDetails((prevSiteDetails) => [...prevSiteDetails, ...selectedSites]);
  // }, [selectedSites]);

  useEffect(() => {
    // Assuming selectedContact is an object
    const { contract_officer, contracting_officer_rep, other_reps } = selectedContact;

    // Merge the values of contractOfficer
    setContractOfficer((prevContractOfficer) => ({
      ...prevContractOfficer,
      ...contract_officer,
    }));

    setContractOfficerRep(contracting_officer_rep);
    setOtherReps(other_reps);
  }, [selectedContact]);


  // Handle form field changes
  const handleChange = async (e, fieldName) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (loading) {
      return;
    }

    const checContact = selectedContact?.contract_officer?.length > 0 || selectedContact?.contracting_officer_rep?.length > 0 || selectedContact?.other_reps?.length > 0;

    if (!checContact || !siteDetails?.length > 0) {
      toast.error('Fill all required fields');
      setValidated(true);
      return;
    }
    setLoading(true);
    saveForm();
  };

  const saveForm = async () => {
    const sites = sitesDetails.map(item => item.account_site_info_id);
    const contacts = selectedContact;
    console.log("ghghghgh", popDetails?.popId)
    const payload = {
      "pop_id": popDetails?.pop_id,
      "qb_invoice": formData?.qb_invoice,
      "invoice_paid": isInvoicePaid,
      "active": isActive,
      "status": formData?.status,
      "contact": contacts,
      "sites": siteDetails?.map((sites) => sites.account_site_info_id).join(',')

    }


    const res = await CallPOSTAPI('pop/update-pop', payload);
    if (res.data.status) {
      toast.success(res.data.msg);
      navigate(-1);
    } else {
      toast.error(res.data.msg);
    }
  }

  const getAccountName = async () => {
    const response = await CallGETAPI(`account/account_info_detail/${accountId}`)
    console.log("pppppppp", response)
    if (response?.status) {
      setAccountName(response?.data?.data?.AccountDetails?.account_name)
    }
    setLoading2(false);
  }
  const getSiteDetails = async () => {
    const response = await CallGETAPI(`pop/get-pop-site-details/${popId}`)
    const data = response?.data?.data || [];
    console.log("tttttttttt", data)
    if (data) {
      setSiteDetails(data);



    }
  }
  console.log("nmjkjl", popDetails)

  return (
    <>
      <div className="mt-4" style={{ width: "100%", paddingInline: "45px" }}>

        <SubHeadingOther title={`Account: ${accountName}`} hideNew={true} hideHierarchy={true} hideInstructor={true} subHeading={true} bottomLinks={false} />

        <Form
          className=""
          onSubmit={handleSubmit}
          noValidate
          validated={validated}
          id="create-new-equipment-form"
        >

          <div className="container-fluid mt-4 bottom-border-blue pt-2"
            style={{
              borderBottom: "4px solid rgb(13, 110, 253)",
              background: "#eee",
              paddingBottom: "20px"
            }}
          >
            <h2 className="heading">Period of Performance Information</h2>

            <div className='row' style={{ display: "flex", justifyContent: "flex-start" }}>

              <Form.Group className={"col"} style={{ maxWidth: "20%" }}>
                <Form.Label>QB Invoice #</Form.Label>
                {loading ? (
                  <Skeleton
                    count={1}
                    width={"100%"}
                    height={20}
                    style={{ marginBottom: "8px" }}
                  />
                ) : (
                  <Form.Control
                    type="text"
                    name="qb_invoice"
                    value={formData?.qb_invoice}
                    onChange={(e) => handleChange(e, 'qb_invoice')}
                  />
                )}
              </Form.Group>


              <div className={"col"} style={{ display: "flex", flexDirection: "column", maxWidth: "150px" }}>
                <Form.Label>Paid</Form.Label>
                {loading ? (
                  <Skeleton
                    count={1}
                    width={"100%"}
                    height={30} // Adjust the height according to the height of your toggle button
                    style={{ marginTop: "4px" }} // Optional: adjust the margin if needed
                  />
                ) : (
                  <CustomToggleButton
                    ToggleName="isInvoicePaid"
                    ToggleValue={isInvoicePaid}
                    changeHandler={handlePaidToggle}
                    style={{ height: "7px" }}
                  />
                )}
              </div>


              <div className={"col"} style={{ display: "flex", flexDirection: "column", maxWidth: "150px" }}>
                <Form.Label>Activate Plan</Form.Label>
                {loading ? (
                  <Skeleton
                    count={1}
                    width={"100%"}
                    height={30} // Adjust the height to fit your toggle button
                    style={{ marginTop: "4px" }} // Optional: adjust the margin if needed
                  />
                ) : (
                  <CustomToggleButton
                    ToggleName="active"
                    ToggleValue={isActive}
                    changeHandler={handleActiveToggle}
                    style={{ height: "7px" }}
                  />
                )}
              </div>


              <Form.Group className={"col"} style={{ maxWidth: "20%" }}>
                <Form.Label>Status</Form.Label>
                {loading ? (
                  <Skeleton
                    count={1}
                    width={"100%"}
                    height={38} // Adjust height to fit the dropdown
                    style={{ marginBottom: "4px" }} // Optional: adjust the margin if needed
                  />
                ) : (
                  <>
                    <select
                      id="brandDropdown"
                      className="form-control"
                      name="status"
                      value={formData?.status}
                      onChange={(e) => handleChange(e, 'status')}
                      required
                      style={{ height: "38px" }} // Ensure height matches the Skeleton loader
                    >
                      <option value='0'>inactive</option>
                      <option value='1'>Active</option>
                      <option value='2'>Cancelled</option>
                    </select>
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </>
                )}
              </Form.Group>


            </div>


            <div className="row py-3">
              <Form.Group className="col" >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Form.Label >Contacts*</Form.Label>
                  <button style={{ border: "none" }} onClick={() => addContacts(true)} type="button">
                    <img src={Contacts} alt="" />
                    <Form.Label style={{ color: "rgb(12, 113, 195)", fontFamily: "Calibri", fontStyle: "normal", marginLeft: "3px", cursor: "pointer" }}>Contacts</Form.Label>
                  </button>
                </div>

                {addContactsModal && (
                  <AddContactsmodalNew
                    addContactsModal={addContactsModal}
                    setAddContactsModal={setAddContactsModal}
                    accountId={accountId}
                    accountContact={accountContact}
                    selectedContact={selectedContact}
                    setSelectedContacts={setSelectedContacts}
                  />
                )}




                <div className="data-table  col-md-12 bg-white " style={{ height: '150px' }} >
                  <table className="w-100 border-gray" style={{ height: '150px' }}>
                    <thead>
                      <tr className="">
                        <th scope='col' width="33%" className=" py-2 px-2 bg-tbl-border border-r-blue">
                          Contract Officer
                        </th>
                        <th scope='col' width="33%" className=" py-2 px-2 bg-tbl-border border-r-blue">
                          Contract Officer Rep
                        </th>
                        <th scope='col' width="33%" className=" py-2 px-2 bg-tbl-border">Other Rep</th>
                      </tr>
                    </thead>
                    <tbody className="odd-even-row">

                      <tr>
                        {/* <td className='py-2 px-2 bg-tbl-border border-r-blue'>{selectedContact?.contract_officer?.contact_name}</td>
                  <td className='py-2 px-2 bg-tbl-border border-r-blue'>{selectedContact?.contracting_officer_rep?.contact_name}</td>
                  <td className='py-2 px-2 bg-tbl-border'>{selectedContact?.other_reps?.contact_name}</td> */}
                        <td className='py-2 px-2 bg-tbl-border border-r-blue'>
                          {loading ? (
                            <Skeleton
                              count={1}
                              width={"100%"}
                              height={20}
                              style={{ marginLeft: "2px", marginRight: "2px" }}
                            />
                          ) : (
                            selectedContact?.contract_officer.map((it, i) => (
                              <div key={i}>{it?.contact_name}</div>
                            ))
                          )}
                        </td>

                        <td className='py-2 px-2 bg-tbl-border border-r-blue'>
                          {loading ? (
                            <Skeleton
                              count={1}
                              width={"100%"}
                              height={20}
                              style={{ marginLeft: "2px", marginRight: "2px" }}
                            />
                          ) : (
                            selectedContact?.contracting_officer_rep.map((it, i) => (
                              <div key={i}>{it?.contact_name}</div>
                            ))
                          )}
                        </td>

                        <td className='py-2 px-2 bg-tbl-border'>
                          {loading ? (
                            <Skeleton
                              count={1}
                              width={"100%"}
                              height={20}
                              style={{ marginLeft: "2px", marginRight: "2px" }}
                            />
                          ) : (
                            selectedContact?.other_reps.map((it, i) => (
                              <div key={i}>{it?.contact_name}</div>
                            ))
                          )}
                        </td>


                      </tr>
                    </tbody>
                  </table>
                </div>


                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>


              <Form.Group className={"col"}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Form.Label style={{ marginRight: "560px" }}>Sites*</Form.Label>
                  <button
                    style={{ border: "none", cursor: "pointer", marginTop: "-45px" }}
                    onClick={() => addSites()}
                    type="button"
                  >
                    <img src={Locations} alt="" />
                    <Form.Label
                      style={{
                        color: "rgb(12, 113, 195)",
                        fontFamily: "Calibri",
                        fontStyle: "normal",
                        marginLeft: "3px",
                        cursor: "pointer",
                        marginBottom: "-20px"
                      }}
                    >
                      Sites
                    </Form.Label>
                  </button>
                </div>

                {loading ? (
                  <Skeleton
                    count={3} // Adjust count based on the number of items you expect
                    width={"100%"}
                    height={30} // Adjust height based on the typical height of each list item
                    style={{ marginTop: "10px" }}
                  />
                ) : (
                  <div className="bg-white">
                    <ul
                      className='mt-2'
                      style={{
                        height: '150px',
                        overflow: 'auto',
                        listStyle: 'none',
                        paddingLeft: '10px'
                      }}
                    >
                      {siteDetails.map((site, index) => (
                        <li key={index} className='list-item mt-2 pl-1'>
                          <span>{site.account_site_name}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {/* Uncomment and use this for error handling */}
                {/* {showSitesError && (
    <div className="invalid mt-2">
      This field is required.
    </div>
  )} */}
              </Form.Group>

            </div>

            {addSitesModal && (
              <AddSitesmodal
                accountId={accountId}
                addSitesModal={addSitesModal}
                setAddSitesModal={setAddSitesModal}
                selectedSites={selectedSites}
                setSelectedSites={setSelectedSites}
                is_edit={true}
                editSites={siteDetails}
                setEditSites={setSiteDetails}
              />
            )}
          </div>

          {/* bottom buttons */}
          <div className="row pb-3 py-5" >
            <div className="col-12 content-flex-right" >
              <button className="btn btn-danger text-uppercase" type="button" onClick={() => {
                navigate(-1);
              }}>Cancel</button>
              <button className="btn btn-success text-uppercase ms-2" type="submit"
                disabled={loading} >{loading ? "Loading..." : "Submit"}</button>
            </div>
          </div>


        </Form>
      </div>

    </>
  )
}

export default PopEdit;