import React, { useState } from 'react';
import CustomTabBar from '../../../common/components/tabs';
import SiteDetails from './tabComponents/SiteDetails';
import ContactListing from './tabComponents/ContactListing';
import PopsListing from './tabComponents/PopsListing';
import EquipmentListing from './tabComponents/EquipmentListing/EquipmentListing';
import { DecryptToken, getPermission } from '../../../common/helper';
import SiteAeds from '../pages/SiteAeds';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import NewGlobalTable from '../../../common/components/custom-ui/NewGlobalTable';
import { Skeleton } from "@mui/material";
import { ContactStatus, formatPhoneNumber } from "../../../common/helper/Common.js";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import New from "../../../common/img/New.png";


const SitesTab = ({ 
  accountId, 
  siteId,
  siteData,
  setSiteData,
  billingData,
  shippingData,
  trainingData,
  siteHoursData,
  aedUnits,
  coordinatorData,
  fetch,
  type,
  // tabTbldata,
  // setTabTbldata,
  setEditSiteAdmin,
 }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('SITE_DETAILS');
  const [showLoading, setShowLoading] = useState(true);

  const user = DecryptToken();
  const privilege = getPermission();
  const location  = useLocation();
    // console.log({locationState:location?.state?.accountName})


  const [tabTbldata, setTabTbldata] = useState({
    site: false,
    contact: false,
    equipment: {
      aed: false,
      accessory: false,
    },
    note: false,
    support: false,
  });

// Render Functions:
const RenderContactName = (data) => {
  return(<>
    <span
    className={
    user?.user_type == 0 ||
    (user?.user_type == 2 &&
    user?.sub_admin != "" &&
    privilege?.includes("contact-details") ===
      "contact-details")
      ? "link"
      : ""
    } 
    onClick={() =>
    (user?.user_type == 0 ||
    (user?.user_type == 2 &&
    user?.sub_admin != "" &&
    privilege?.includes("contact-details") ===
      "contact-details")) &&
    navigate(
    `/account/${accountId}/contact-details/${data.contact_id}`,
    {
    state: {
      editUrl: `/account/contact-details-edit/${data.contact_id}`,
      deleteUrl: `/account/contact-details-edit/${data.contact_id}`,
      type: "Contacts",
      },
    }
   )
   }
  >
   {data?.contact_name}
  </span>
  </>)
}

const RenderUser = (data) => {
  return (
    <>
      {data?.user ? (
        <CheckIcon color={"success"} />
      ) : (
        <CloseIcon color="error" />
      )}
    </>
  );
};

const RenderPhone = (data) => {
  return (
    <>
      {data?.phone?.[0].phone != "" && data?.phone?.[0].phone != "-" && (
        <a
          className="link"
          style={{ textDecoration: "none" }}
          href={"tel:" + data?.phone?.[0].ext + data?.phone?.[0]?.phone}
        >
          {data?.phone?.[0].phone
            ? formatPhoneNumber(data?.phone?.[0].phone)
            : ""}
          {data?.phone?.[0].ext != "" ? "x" + data?.phone?.[0].ext : ""}
        </a>
      )}
    </>
  );
};

const RenderEmail = (data) => {
  return (
    <>
      {data?.email.length > 0 && (
        <a
          className="link"
          style={{ textDecoration: "none" }}
          href={"mailto:" + data?.email?.[0].email}
        >
          {data?.email?.[0].email}
        </a>
      )}
    </>
  );
};  


  const list = [
    { title: "Details", id: "SITE_DETAILS" },
    { title: "Contacts", id: "CONTACT_LISTING" },
    { title: "Equipment", id: "EQUIPMENT_LISTING" },
    // { title: "Inperson", id: "INPERSON_LISTING" },
    // { title: "Notes", id: "NOTES_LISTING" },
    // { title: "Emails", id: "EMAILS_LISTING" },
    // { title: "Support", id: "SUPPORT_LISTING" },
    // { title: "Documents", id: "DOCUMENTS_LISTING" },
    // { title: "RFI", id: "RFI_LISTING" },
  ];

  const componentsList = {
    SITE_DETAILS: <SiteDetails
    siteData={siteData}
    setSiteData={setSiteData}
    billingData={billingData}
    shippingData={shippingData}
    trainingData={trainingData}
    siteHoursData={siteHoursData}
    aedUnits={aedUnits}
    coordinatorData={coordinatorData}
    fetch={fetch} 
    setEditSiteAdmin={setEditSiteAdmin}
     />,

    CONTACT_LISTING: 
    <>
    <Box className="d-flex justify-content-between align-items-center py-2">
    <h3 className="heading">Site Contacts</h3>
      {(user?.user_type == 0 ||
      (user?.user_type == 2 &&
      user?.sub_admin != "" &&
        privilege?.includes("contacts-new"))) && (
      <Link
      style={{ textDecoration: "none" }}
      to={"/account/contacts/new/" + accountId}
      >
      <img src={New} />
      <span style={{ color: "#0C71C3" }}> New</span>
      </Link>
      )}
    </Box>
    <NewGlobalTable
     method='GET'
     isCache={true}
     keyAPiName='contact_list'
     apiEndPoint={`site/site-contacts-list/${siteId}`}
     headerColumns={[
      {
        Header: "Contact",
        accessor: "contact_name",
        Cell: ({row}) => (
          <div> {RenderContactName(row.original)}</div>
        ),
        width: "20%",
      },
      {
        Header: "User",
        accessor: "user",
        Cell: ({ row }) => (
          <>
            {showLoading ? (
              <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
            ) : (
              <div>{RenderUser(row.original)}</div>
            )}
          </>
        ),
        disableSortBy: true,
      },
      {
        Header: "Phone",
        accessor: "phone",
        Cell: ({ row }) => (
          <>
            {showLoading ? (
              <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
            ) : (
              <div>{RenderPhone(row?.original)}</div>
            )}
          </>
        ),
        disableSortBy: true,
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ row }) => (
          <>
            {showLoading ? (
              <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
            ) : (
              <div>{RenderEmail(row.original)}</div>
            )}
          </>
        ),
      },
      {
        Header: "Positions",
        accessor: "account_roles",
        Cell: ({ row }) => (
          <>
            {showLoading ? (
              <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
            ) : (
              <div>{row.original?.account_roles.join(", ")} </div>
            )}
          </>
        ),
      },
      {
        Header: "Sites",
        accessor: "main_site",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => (
          <>
            {showLoading ? (
              <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
            ) : (
              <>
                <p className={row.original?.status == 1 ? "" : "text-danger"}>
                  {ContactStatus[row.original?.status]}
                </p>
              </>
            )}
          </>
        ),
      },
    ]}
    />
    </>
    // <ContactListing 
    // accountId={accountId} 
    // siteId={siteId}
    // tabTbldata={tabTbldata}
    // setTabTbldata={setTabTbldata} />
    ,

    // EQUIPMENT_LISTING: <EquipmentListing 
    // accountId={accountId}
    // siteId={siteId}
    // type={type}
    // accountName=""
    // tabTbldata={tabTbldata}
    // setTabTbldata={setTabTbldata}

    
    // />,


    EQUIPMENT_LISTING: <SiteAeds 
    accountId={accountId}
    siteId={siteId}
    type={type}
    accountName={location?.state?.accountName}
    tabTbldata={tabTbldata}
    setTabTbldata={setTabTbldata}
    />,

    // POPS_LISTING: <PopsListing />,
  };

  const handleTabChange = (val) => {
    setActiveTab(val);
  };
  

  return (
    <>
      {/* {user?.user_type === 0 ||
      (activeTab === "Details" && user?.user_type === 2 && privilege?.includes("account-details")) ||
      (activeTab === "Contacts" && user?.user_type === 2 && privilege?.includes("contact-tab")) ||
      (activeTab === "Equipment" && user?.user_type === 2 && privilege?.includes("equipment-tab")) ||
      (activeTab === "Inperson" && user?.user_type === 2 && privilege?.includes("inperson-tab")) ||
      (activeTab === "Notes" && user?.user_type === 2 && privilege?.includes("notes-tab")) ||
      (activeTab === "Emails" && user?.user_type === 2 && privilege?.includes("email-tab")) ||
      (activeTab === "Support" && user?.user_type === 2 && privilege?.includes("support-tab")) ||
      (activeTab === "Documents" && user?.user_type === 2 && privilege?.includes("documents-tab")) ||
      (activeTab === "RFI" && user?.user_type === 2 && privilege?.includes("rfi-tab")) ? (
        <div>
        </div>
      ) : null} */}
      <div className='w-full'>
      <CustomTabBar 
        tabs={list}
        onTabChange={handleTabChange}
      />
      {componentsList?.[activeTab]}
      </div>
    </>
  );
};

export default SitesTab;
