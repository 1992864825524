import { Box, Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CallGETAPI } from '../../../../common/services';
import { useNavigate } from 'react-router-dom';
import { GroupBYCoordinatorInfo } from '../../../../common/helper/BasicFn';
import CoorrInfo from '../../../../common/components/coordinator-information/CoorrInfo';

const CoordinatorInformation = ({accountId, setCoordinatorData}) => {

    const [CoordiDataList,setCoordiDataList]    = useState([]);
    const [loading,setLoading]                  = useState(false);
    
    const fetchData = async()=>{
        setLoading(true);
        const result  = await CallGETAPI('account/acc-details-coordinator/'+accountId);
        if(result?.data?.status){
            const cordinatorInfo = result?.data?.data;
            console.log({cordinatorInfo})
            let CoordiData = GroupBYCoordinatorInfo(cordinatorInfo);
            console.log({CoordiData})
            setCoordiDataList(CoordiData);
            setCoordinatorData(CoordiData);
        }
        setLoading(false);
    }

    useEffect(()=>{
        fetchData();
    },[accountId])
  return (
    <>
    <CoorrInfo 
      CoordiDataList={CoordiDataList} 
      loading={loading}
      accountId={accountId}
    />
    </>
  )
}

export default CoordinatorInformation


