import React from 'react';
import { Form } from 'react-bootstrap';
import CustomToggleButton2 from '../../../../common/components/toggleSwitch/CustomToggle2';

const ServicingNotesComponent = ({ 
  formData, 
  setFormData, 
  handleToggleChange, 
  servicingNotesError, 
  setServicingNotesError 
}) => {
  return (
    <div className="col py-2">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2 className="heading">Servicing Notes*</h2>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            columnGap: "5%",
            marginRight: "1%",
          }}
        >
          <h2 className="heading">Issue</h2>
          <CustomToggleButton2
            ToggleName="issue_toggle"
            ToggleValue={formData?.issue_toggle}
            changeHandler={handleToggleChange}
            is_read_only={false}
          />
        </div>
      </div>
      <Form.Group
        className={`service-notes-container ${
          servicingNotesError ? "is-invalid" : ""
        }`}
      >
        <textarea
          className={`form-control ${
            servicingNotesError ? "is-invalid" : ""
          }`}
          name="servicing_notes"
          placeholder="Enter Servicing Notes"
          value={formData?.servicing_notes}
          style={{
            height: "200px",
            width: "100%",
            border: `3px solid ${
              servicingNotesError ? "#be6464" : "#337ab7"
            }`,
          }}
          onChange={(e) => {
            setFormData((prev) => ({
              ...prev,
              servicing_notes: e.target.value,
            }));
            setServicingNotesError(false);
          }}
        ></textarea>
        {servicingNotesError && !formData?.servicing_notes && (
          <div className="invalid"> Please fill servicing notes </div>
        )}
      </Form.Group>
    </div>
  );
};

export default ServicingNotesComponent;