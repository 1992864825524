// import React from "react";
// import GlobalDropdown from "../../../../../common/components/custom-ui/GlobalDropdown";
// const PadPartSelect = ({
//   formData,
//   name,
//   title,
//   crrFormData,
//   padList,
//   handleInputChange,
//   crrIndex,
//   is_adult = 0,
//   toogleKeyName = false,
//   disabled = false,
// }) => {

//   // const renderOptions = (item, idKey, valueKey) => {
//   //   const pediatricNumber = Number(item.pediatric);
  
//   //   // Return an option element based on `is_adult` and `pediatricNumber`
//   //   return is_adult 
//   //     ? (pediatricNumber === 0 && <option key={item[idKey]} value={item[idKey]} disabled={item.disabled}>
//   //         {item[valueKey]}
//   //       </option>)
//   //     : (pediatricNumber === 1 && <option key={item[idKey]} value={item[idKey]} disabled={item.disabled}>
//   //         {item[valueKey]}
//   //       </option>);
//   // };

//   const renderOptions = (item, idKey, valueKey,selectedVal='') => {
//     const pediatricNumber = Number(item.pediatric);

//     // Return an option element based on `is_adult` and `pediatricNumber`
//     const result   = is_adult
//       ? pediatricNumber === 0 && (
//           <option
//             key={item[idKey]}
//             value={item[idKey]}
//             disabled={item.disabled}
//             // selected={Number(item?.[idKey]) === Number(selectedVal)}
//           >
//             {item[valueKey]}
//           </option>
//         )
//       : pediatricNumber === 1 && (
//           <option
//             key={item[idKey]}
//             value={item[idKey]}
//             disabled={item.disabled}
//             // selected={Number(item?.[idKey]) === Number(selectedVal)}
//           >
//             {item[valueKey]}
//           </option>
//         );
//         return result;
//   };
  
//   // padList

//   return (
//     <>
//     {JSON.stringify(crrFormData?.pad_part)}
//       {/* <select
//         name={name}
//         value={crrFormData?.[name]}
//         class="form-control"
//         onChange={(e) => handleInputChange(e, crrIndex)}
//         disabled={toogleKeyName ? true : disabled}
//       >
//         <option value="" key={0} selected>
//           --Select One--
//         </option>
//         {padList?.map((item, index) => {
//           if (is_adult) {
//             return (
//               item?.pediatric === 0 && (
//                 <option
//                   // value={item?.charge_pak_pad_1_id}
//                   value={item?.pad_type_id}
//                   key={index + 1}
//                   className={item?.main ? "option-change" : ""}
//                 >
//                   {item?.pad_part_number}
//                 </option>
//               )
//             );
//           } else {
//             return (
//               item?.pediatric === 1 && (
//                 <option
//                   // value={item?.charge_pak_pad_2_id}
//                   value={item?.pad_type_id}
//                   key={index + 1}
//                   className={item?.main ? "option-change" : ""}
//                 >
//                   {item?.pad_part_number}
//                 </option>
//               )
//             );
//           }
//         })}
//       </select> */}

//       <GlobalDropdown
//         dropDownName={name}
//         apiEndpoint={"equipment/aed-pad-type-by-model/" + formData?.model_name}
//         idKey={"pad_type_id"}
//         valueKey={"pad_part_number"}
//         parentKey={""}
//         // onSelect={(e) => {
//         //   handleInputChange(e, crrIndex);
//         // }}
//         onSelect={(e) => {
//         const selectedId = e.target.value; // Selected ID
//         const selectedText = e.target.options[e.target.selectedIndex].text;
//       handleInputChange(e, crrIndex, selectedText, "pad_part");
//     }}
//         // customRender={renderOptions}
//         customRender={(crrFormData, idKey, valueKey)=>renderOptions(crrFormData, idKey, valueKey,crrFormData?.[idKey])}
//         shouldFetchOnOpen={false}
//         isCache={true}
//         disabled={toogleKeyName ? true : disabled}
//         initialSelectedValue={crrFormData?.pad_part}
//         defaultValue={{
//           id: crrFormData?.pad_type_id || "",
//           value: crrFormData?.pad_part || "",
//         }}
//       />
//     </>
//   );
// };

// export default PadPartSelect;

// /*
//  */




// ------- //  Edit-Aed Component code use // ----- 


import React from "react";
import GlobalDropdown from "../../../../../common/components/custom-ui/GlobalDropdown";
const EditPadPartSelect = ({
  formData,
  name,
  title,
  crrFormData,
  padList,
  handleInputChange,
  crrIndex,
  is_adult = 0,
  toogleKeyName = false,
  disabled = false,
  defaultId,
  defaultValue,
  isPad = 0,
}) => {
  // padList
  const renderOptions = (item, idKey, valueKey,selectedVal='') => {
    const pediatricNumber = Number(item.pediatric);

    // Return an option element based on `is_adult` and `pediatricNumber`
    const result   = is_adult
      ? pediatricNumber === 0 && (
          <option
            key={item[idKey]}
            value={item[idKey]}
            disabled={item.disabled}
            // selected={Number(item?.[idKey]) === Number(selectedVal)}
          >
            {item[valueKey]}
          </option>
        )
      : pediatricNumber === 1 && (
          <option
            key={item[idKey]}
            value={item[idKey]}
            disabled={item.disabled}
            // selected={Number(item?.[idKey]) === Number(selectedVal)}
          >
            {item[valueKey]}
          </option>
        );
        return result;
  };

  console.log({crrFormData});

  return (
    <>
    {/* {JSON.stringify(crrFormData?.pad_part_number)} */}
      {/* <select
        name={name}
        value={crrFormData?.[name]}
        class="form-control"
        onChange={(e) => handleInputChange(e, crrIndex)}
        disabled={toogleKeyName ? true : disabled}
      >
        <option value="" key={0} selected>
          --Select One--
        </option>
        {padList?.map((item, index) => {
          if (is_adult) {
            return (
              item?.pediatric === 0 && (
                <option
                  // value={item?.charge_pak_pad_1_id}
                  value={item?.pad_type_id}
                  key={index + 1}
                  className={item?.main ? "option-change" : ""}
                >
                  {item?.pad_part_number}
                </option>
              )
            );
          } else {
            return (
              item?.pediatric === 1 && (
                <option
                  // value={item?.charge_pak_pad_2_id}
                  value={item?.pad_type_id}
                  key={index + 1}
                  className={item?.main ? "option-change" : ""}
                >
                  {item?.pad_part_number}
                </option>
              )
            );
          }
        })}
      </select> */}

{/* {JSON.stringify({
          id: crrFormData?.pad_type_id  || "",
          value: crrFormData?.pad_part_number || "",
        })} */}
{(formData?.model_name && isPad === 0) ?
      <GlobalDropdown
        dropDownName={name}
        apiEndpoint={"equipment/aed-pad-type-by-model/" + formData?.model_name}
        idKey={"pad_type_id"}
        valueKey={"pad_part_number"}
        parentKey={""}
        onSelect={(e) => {
          const selectedId = e.target.value; // Selected ID
            const selectedText = e.target.options[e.target.selectedIndex].text;
          handleInputChange(e, crrIndex, selectedText, "pad_part_number");
        }}

        // onSelect={(e) => {
        //   const selectedId = e.target.value; // Selected ID
        //   const selectedText = e.target.options[e.target.selectedIndex].text;  
        //   handleInputChange(e, crrIndex, selectedText, "pad_part_number");
        // }}
        // selectedVal
        customRender={(crrFormData, idKey, valueKey)=>renderOptions(crrFormData, idKey, valueKey,crrFormData?.[idKey])}
        shouldFetchOnOpen={false}
        isCache={true}
        disabled={toogleKeyName ? true : disabled}
        initialSelectedValue={crrFormData?.pad_type_id }
        defaultValue={{
          id: crrFormData?.pad_type_id || "" ,
          value: crrFormData?.pad_part_number || "",
        }}
      />
      : ""
      }

{(formData?.model_name && isPad == 1) ?
      <GlobalDropdown
        dropDownName={"charge_pak_pad_1_id"}
        apiEndpoint={"equipment/aed-pad-type-by-model/" + formData?.model_name}
        idKey={"pad_type_id"}
        valueKey={"pad_part_number"}
        parentKey={""}
        onSelect={(e) => {
          const selectedId = e.target.value; // Selected ID
            const selectedText = e.target.options[e.target.selectedIndex].text;
          handleInputChange(e, crrIndex, selectedText, "charge_pak_pad_1_id");
        }}
        // selectedVal
        customRender={(crrFormData, idKey, valueKey)=>renderOptions(crrFormData, idKey, valueKey, crrFormData?.[idKey])}
        shouldFetchOnOpen={false}
        isCache={true}
        disabled={toogleKeyName ? true : disabled}
        initialSelectedValue={crrFormData?.charge_pak_pad_1_id }
        defaultValue={{
          id: crrFormData?.charge_pak_pad_1_id || "" ,
          value: crrFormData?.pad_1_part_number || "",
        }}
      />
      : ""
      }

 {/* {JSON.stringify(crrFormData?.pad_2_type_id)}
 {JSON.stringify(crrFormData?.pad_2_part_number)} */}
{(formData?.model_name && isPad == 2) ?
      <GlobalDropdown
        dropDownName={"charge_pak_pad_2_id"}
        apiEndpoint={"equipment/aed-pad-type-by-model/" + formData?.model_name}
        idKey={"pad_type_id"}
        valueKey={"pad_part_number"}
        parentKey={""}
        onSelect={(e) => {
          const selectedId = e.target.value; // Selected ID
            const selectedText = e.target.options[e.target.selectedIndex].text;
          handleInputChange(e, crrIndex, selectedText, "charge_pak_pad_2_id");
        }}

        // onSelect={(e) => {
        //   const selectedId = e.target.value; // Selected ID
        //   const selectedText = e.target.options[e.target.selectedIndex].text;  
        //   handleInputChange(e, crrIndex, selectedText, "pad_part_number");
        // }}
        // selectedVal
        customRender={(crrFormData, idKey, valueKey)=>renderOptions(crrFormData, idKey, valueKey,crrFormData?.[idKey])}
        shouldFetchOnOpen={false}
        isCache={true}
        disabled={toogleKeyName ? true : disabled}
        // initialSelectedValue={crrFormData?.pad_2_type_id }
        // defaultValue={{
        //   id: crrFormData?.pad_2_type_id || "" ,
        //   value: crrFormData?.pad_2_part_number || "",
        // }}
        initialSelectedValue={crrFormData?.charge_pak_pad_2_id }
        defaultValue={{
          id: crrFormData?.charge_pak_pad_2_id || "" ,
          value: crrFormData?.pad_2_part_number || "",
        }}
      />


      :""
      }


      {/* {formData?.model_name ? 
      <GlobalDropdown
        dropDownName={defaultId}
        apiEndpoint={"equipment/aed-pad-type-by-model/" + formData?.model_name}
        idKey={"pad_type_id"}
        valueKey={"pad_part_number"}
        parentKey={""}
        onSelect={(e) => {
          handleInputChange(e, crrIndex);
        }}
        // selectedVal
        customRender={(crrFormData, defaultId, defaultValue)=>renderOptions(crrFormData, defaultId, defaultValue,crrFormData?.[defaultId])}
        shouldFetchOnOpen={false}
        isCache={true}
        disabled={toogleKeyName ? true : disabled}
        initialSelectedValue={crrFormData?.[defaultId]}
        defaultValue={{
          id: crrFormData?.[defaultId],
          value: crrFormData?.[defaultValue],
        }}
      />
      :""} */}
    </>
  );
};

export default EditPadPartSelect;

