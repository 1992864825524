import React, { useEffect, useState } from 'react'
import { CallGETAPI } from '../../../../common/services';
import CoorrInfo from '../../../../common/components/coordinator-information/CoorrInfo';
import { GroupBYAdminSiteCoordinatorInfo, GroupBYCoordinatorInfo } from '../../../../common/helper/BasicFn';

const SiteCoordinatorInformation = ({accountId,siteId, setEditSiteAdmin}) => {

    const [CoordiDataList,setCoordiDataList]    = useState([]);
    const [loading,setLoading]                  = useState(false);
    
    const fetchData = async()=>{
        setLoading(true);
        const result  = await CallGETAPI('/site/site-coordinator-information/'+siteId);
        if(result?.data?.status){
            const resultVal         = result?.data?.data?.cordinatorInformation;
            const cordinatorInfo    = resultVal?.filter((it)=>it.title != 'Billing Coordinator' || it.title != 'Shipping Contact');
            let CoordiData          = GroupBYAdminSiteCoordinatorInfo(cordinatorInfo);
            setCoordiDataList(CoordiData);
            setEditSiteAdmin((prev) => ({
              ...prev,
              coordinatorInfo: CoordiData,
            }));
        }
        setLoading(false);
    }

    useEffect(()=>{
        fetchData();
    },[])
  return (
    <>
    <CoorrInfo 
      CoordiDataList={CoordiDataList} 
      loading={loading}
      accountId={accountId}
    />
    </>
  )
}

export default SiteCoordinatorInformation


