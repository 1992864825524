import React from 'react';
import Plus from "../../../../common/img/Plus.svg";
import AedModificationChargePackRow from './AedModificationChargePackRow';

const ChargePakInfo = ({
  AedItem,
  index,
  handleChargePakAdd,
  handleResetBtn,
  chargePakResetButton,
  handleRemoveMainChargePakRow,
  handleChargeSpareCrown,
  showAdditionalBatteryRows,
  handleCalendarChange,
  handleChange,
  HandleDNDChargePack,
  handleChargePackInput,
  AedFormData,
  setNewFormData,
  contact_id,
  inspection_by,
  selectedOption,
  isInventory,
  setDeleteNewBattery
}) => {
  return (
    <div className="col py-2">
      <div className="flex flex-row justify-between items-center">
        <h2 className="heading">
          Charge Pak Information
          <img
          src={Plus}
          onClick={() =>
            handleChargePakAdd(
              index,
              AedItem.aed_id,
              AedItem?.battery_section_name
            )
          }
          style={{ cursor: "pointer" }}
        />
        </h2>
        {((AedItem?.dni_array_list.length > 0 &&
          AedItem.dni_array_list.some((item) => item.hasOwnProperty("bid"))) ||
          AedItem.charge_pack_list.slice(
            0,
            AedItem?.default_charge_pak_info.length
          ).length !== AedItem?.default_charge_pak_info.length ||
          (AedItem?.deletedChargePak.length > 0 &&
            AedItem.deletedChargePak.some(
              (item) => !item.hasOwnProperty("inventory_id")
            )) ||
          chargePakResetButton) && (
          <button
            type="button"
            className="rounded-lg bg-red-500 text-white h-8 w-14 border-none cursor-pointer text-sm"
            onClick={() => handleResetBtn(index, 3)}
          >
            Reset
          </button>
        )}
      </div>
      <table className="theme-table">
        <thead>
          <tr>
            <td className="border border-r-blue">Charge Pak Type</td>
            <td className="border border-r-blue">Part #</td>
            <td className="border border-r-blue">Expiration Date</td>
            <td className="border border-r-blue">Lot</td>
            <td className="border border-r-blue">UDI</td>
            <td className="border border-r-blue w-16 min-w-14">Actions</td>
          </tr>
        </thead>
        <tbody>
          {AedItem?.charge_pack_list.map((chargeItem, Bindex) => (
            <AedModificationChargePackRow
              key={Bindex}
              setDeleteNewBattery={setDeleteNewBattery}
              handleRemoveMainChargePakRow={handleRemoveMainChargePakRow}
              handleChargeSpareCrown={handleChargeSpareCrown}
              list_item={chargeItem}
              batteryInfo={chargeItem}
              charge_pack_obj={chargeItem}
              default_battery_info={AedItem.default_battery_info}
              is_readonly={chargeItem?.is_readonly || 0}
              Bindex={Bindex}
              print_battery_part={AedItem.print_battery_part}
              index={index}
              showAdditionalBatteryRows={showAdditionalBatteryRows}
              handleCalendarChange={handleCalendarChange}
              handleChange={handleChange}
              batteryList={AedItem?.battery_type_list || []}
              handleDNDButton={HandleDNDChargePack}
              handleResetBtn={handleResetBtn}
              onInputChange={handleChargePackInput}
              AedFormData={AedFormData}
              setNewFormData={setNewFormData}
              contact_id={contact_id}
              inspection_by={inspection_by}
              selectedOption={selectedOption}
              batteryvisibleColumns={AedItem?.batteryvisibleColumns || []}
              aedPadTypeList={AedItem?.aedPadTypeList || []}
              isInventory={isInventory}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ChargePakInfo;