import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CallGETAPI } from "../../../../../common/services/index";
import {
  FormatDate,
  FormatDateWithTime,
} from "../../../../../common/helper/Common";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { isSubAdminPermission } from "../../../../../common/helper/permission";
import { useTable, useSortBy, usePagination } from "react-table";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import AEDChecksTableSkeleton from "../AedSkeletons/AEDChecksTableSkeleton";
import New from "../../../../../common/img/New.png";

const AEDChecksTbl = ({ account_id }) => {
  const navigate = useNavigate();
  const { aedId } = useParams();
  const [loading, setLoading] = useState(true);
  const [aedData, setAEDData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = async () => {
    const res = await CallGETAPI(`aed/aed-check-list/${aedId}?page=${currentPage}&pageSize=10`);
    if (res?.data?.data) {
      setAEDData(res?.data?.data?.aedCheckList || []);
      setTotalPages(res?.data?.data?.totalPages);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const renderDate = (value) => {
    return (
      <span
        className={
          isSubAdminPermission("aed-check-details") === 1 ? "link" : ""
        }
        style={{ background: "transparent" }}
        onClick={() =>
          isSubAdminPermission("aed-check-details") === 1 &&
          navigate(
            "/account/aed-checks-details/" + aedId + "/" + value.row.original.id
          )
        }
      >
        {FormatDateWithTime(value.value)}
      </span>
    );
  };

  const renderCheck = (value) => {
    return value.value ? (
      <CheckIcon sx={{ color: "#00FF00" }} />
    ) : (
      <CloseIcon color={"error"} />
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "AED Check Date",
        accessor: "created_date",
        Cell: renderDate,
        width: "180px",
      },
      {
        Header: "AED Present",
        accessor: "is_aed_present",
        Cell: renderCheck,
      },
      {
        Header: "AED Ready",
        accessor: "status_indicator",
        Cell: renderCheck,
      },
      {
        Header: "Rescue Kit Present",
        accessor: "rescue_kit_present",
        Cell: renderCheck,
      },
      {
        Header: "Alarm Battery Exp",
        accessor: "alarm_expiration",
        Cell: ({ value }) => FormatDate(value) || "N/A",
      },
      {
        Header: "Support Ticket",
        accessor: "support_tickets_toggle",
        Cell: renderCheck,
      },
      {
        Header: "Checked By",
        accessor: "created_by",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, page } =
    useTable(
      {
        columns,
        data: aedData || [],
        initialState: { pageIndex: 0, pageSize: 50 },
      },
      useSortBy,
      usePagination
    );

    const handlePageChange = (newPage) => {
      setCurrentPage(newPage);
    };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2 className="heading" >
          AED Checks History
        </h2>
        <button
          className="btn text-primary"
          type="button"
          onClick={() =>
            navigate(
              "/account/aed-details/check-select?aed_id=" +
                aedId +
                "&account_id=" +
                account_id
            )
          }
        >
          <img src={New} alt="New" style={{ marginRight: "5px" }} />
          <span className="ms-1">New</span>
        </button>
      </div>
      <div className="table-responsive">
        {loading ? (
          <AEDChecksTableSkeleton columns={columns} />
        ) : (
          <table
            {...getTableProps()}
            className="table table-bordered table-hover"
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{ backgroundColor: "#999" }}
                    >
                      <div className="d-flex align-items-center th-d" style={{cursor: "pointer"}}>
                        {column.render("Header")}
                        <span className="ml-1">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <SouthIcon size={2} />
                            ) : (
                              <NorthIcon size={2} />
                            )
                          ) : (
                            <SouthIcon size={2} />
                          )}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      style={{
                        backgroundColor: i % 2 === 0 ? "white" : "#e4e4e4",
                      }}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={columns.length}  style={{
                    textAlign: "center",
                    padding: "20px",
                    fontStyle: "italic",
                    color: "grey",
                  }}>
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
      {totalPages > 1 && (
        <div className="mt-4 d-flex justify-content-end align-items-center">
          <nav aria-label="Page navigation">
            <ul className="pagination">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="btn btn-light mx-1"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  style={{background: "#dad7d763"}}
                >
                  &lt;
                </button>
              </li>
              {currentPage > 3 && (
                <>
                  <li className="page-item">
                    <button
                      className="btn btn-light mx-1"
                      onClick={() => handlePageChange(1)}
                    >
                      1
                    </button>
                  </li>
                  <li className="page-item">
                    <span className="mx-1">...</span>
                  </li>
                </>
              )}
              {[...Array(5)].map((_, idx) => {
                const pageNumber = currentPage - 2 + idx;
                return pageNumber > 0 && pageNumber <= totalPages ? (
                  <li key={pageNumber} className="page-item">
                    <button
                      className={`btn mx-1 ${
                        currentPage === pageNumber ? "btn-primary" : "btn-light"
                      }`}
                      onClick={() => handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </button>
                  </li>
                ) : null;
              })}
              {currentPage < totalPages - 2 && (
                <>
                  <li className="page-item">
                    <span className="mx-1">...</span>
                  </li>
                  <li className="page-item">
                    <button
                      className="btn btn-light mx-1"
                      onClick={() => handlePageChange(totalPages)}
                    >
                      {totalPages}
                    </button>
                  </li>
                </>
              )}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  className="btn btn-light mx-1"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  style={{background: "#dad7d763"}}
                >
                  &gt;
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </>
  );
};

export default AEDChecksTbl;
