import React from "react";
import { Box, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { AccountDetailsTab } from "../../../common/hooks/index";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  BASE_API,
  CallGETAPI,
  CallPOSTAPI,
} from "../../../common/services/index";
import { FormatDate, getPermission } from "../../../common/helper/Common";
import {
  AccountContactDetails,
  AccountSiteList,
  DecryptToken,
  fetchAedLength,
  GroupBYCoordinatorInfo,
  truncateText,
} from "../../../common/helper/BasicFn";
import Inperson from "../components/tabs/Inperson";
import Pops from "../components/tabs/Pops";
import Notes from "../components/tabs/Notes";
import Emails from "../components/tabs/Emails";
import Support from "../components/tabs/Support";
import Documents from "../components/tabs/Documents";
import RFI from "../components/tabs/RFI";
import { styled } from "@mui/material/styles";
import { createTheme } from "@mui/material";
import TraningDetailsTabel from "../components/adminTrainingTable/TraningDetailsTabel";
import SubHeading from "../components/header/SubHeading";
import TableSkeleton from "../components/skeleton/table/TableSkeleton";
import Details from "../components/tabs/Details";
import Sites from "../components/tabs/Sites";
import Contacts from "../components/tabs/Contacts";
import Aeds from "../components/tabs/Aeds";
import { useDispatch, useSelector } from "react-redux";
import { selecteTab, setEditAccountData } from "../../../store/slices/TabSlice";
import { addItem } from "../components/header/BreadCrumbsSlice";
import "../../../global.css";
import { toast } from "react-toastify";
import {
  isSubAdminPermission,
  isUserPermission,
  linkTabsPermission,
} from "../../../common/helper/permission";
import { ContactStatus, formatPhoneNumber } from "../../../common/helper";
import NewGlobalTable from "../../../common/components/custom-ui/NewGlobalTable";
import New  from "../../../common/img/New.png";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const drawerWidth = 200;
const theme = createTheme();
const AccountCache = new Map();

const AccountDetails = ({ setShowSidebar, handleSetToken, is_user, accountName }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const [showLoading, setShowLoading] = React.useState(false);
  const [outsideClick, setOutsideClick] = React.useState("");
  const [currentTab, setCurrentTab] = useState(!location?.state?.tab ? AccountDetailsTab.DETAILS : location?.state?.tab);
  const [accountDetails, setAccountDetails] = useState({});
  const [CoordiDataList, setCoordiDataList] = useState([]);
  const [programDetails, setProgramDetails] = useState({});
  const [accountInfoDetails, setAccountInfoDetails] = useState();
  const [NotesData, setNotesData] = useState([]);
  const [forward, setForward] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [httpsWeb, setHttpsWeb] = useState(false);
  const [FormMsg, setFormMsg] = React.useState({ type: true, msg: "" });
  const [programData, setProgramData] = useState({});
  const [aedUnitData, setAedUnitData] = useState([]);
  const [coordinatorData, setCoordinatorData] = useState([]);
  const [EditUrl, setEditUrl] = useState("/account/accounts-edit/" + accountId);
  // const [showAlert, setshowAlert] = useState("");
  const [aedLength, setAedLength] = useState();
  const user = DecryptToken();
  const privilege = getPermission();

  const fetchNotesData = async () => {
    // const response = await fetch('http://localhost:5000/api/notes');
    // const data = await response.json();
    // setNotesData(data);
    let response = await CallGETAPI("notes/account-notes/" + accountId);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));

  const handleTab = (item) => {
    // if (item === "Sites") {
    // fetchSiteData();
    // } else if (item === "Contacts") {
    // fetchContactData();
    // }

    dispatch(selecteTab(item));
    setCurrentTab(item);
  };

  const fetchOnload = async () => {
   
    //  Fetch From the Cache
    const fetchUrl = `account/account_info_detail/${accountId}`;
    const cacheKey = `${fetchUrl}`; //-${parentKey}-${pageNum}-${searchText}`;

  //   if (AccountCache.has(cacheKey)) {
  //     const cachedData = AccountCache.get(cacheKey);
  //     setAccountDetails(cachedData?.account_details || {});
  //     // setData(prevData => [...prevData, ...cachedData.data]);
  //     // setTotalCount(cachedData.totalCount);
  //     return;
  // }


    // AccountCache
    setShowLoading(true);
    const accountRes = await CallGETAPI( fetchUrl );
    AccountCache.set(cacheKey, {account_details:accountRes?.data?.data?.AccountDetails});

    const accountData = accountRes?.data?.data?.AccountDetails;
    setAccountDetails(accountData);

    var pattern = new RegExp("^(https?|http)://");
    if (pattern.test(accountDetails?.website)) {
      setHttpsWeb(true);
    }

    setShowLoading(false);
  };
  const prev_selected_tb = useSelector(
    (state) => state?.TAB_manager?.selectedTab
  );
  useEffect(() => {
    if (prev_selected_tb) {
      setCurrentTab(prev_selected_tb)
    }
  }, [prev_selected_tb])
  // console.log('---------------------- Here we are')
  // console.log({ prev_selected_tb });

  const handleSetData = () => {
    let accountInfoData = {};
    if (accountDetails?.parent_account_id !== "") {
      accountInfoData.parentAccount = {
        account_id: accountDetails?.parent_account_id,
        account_main_contact_firstname: accountDetails?.parent_account,
      };
    }
    if (accountDetails?.distributor_account_id !== "") {
      accountInfoData.distributor = {
        dropdown_distributor_id: accountDetails?.distributor_account_id,
        distributor_name: accountDetails?.distributor_account,
      };
    }
    if (accountDetails?.customer_id !== "") {
      accountInfoData.customer = {
        dropdown_customer_type_id: accountDetails?.customer_id,
        customer_type_name: accountDetails?.customer_type,
      };
    }
    if (accountDetails?.industry_id !== "") {
      accountInfoData.industry = {
        dropdown_industry_id: accountDetails?.industry_id,
        dropdown_industry_name: accountDetails?.industry,
      };
    }
    if (accountDetails?.terms_id !== "") {
      accountInfoData.terms = {
        dropdown_terms_id: accountDetails?.terms_id,
        dropdown_terms_name: accountDetails?.terms,
      };
    }
    if (accountDetails?.lead_source_id !== "") {
      accountInfoData.leadSource = {
        dropdown_lead_source_id: accountDetails?.lead_source_id,
        dropdown_lead_source_name: accountDetails?.lead_source,
      };
    }
    if (accountDetails?.account_status_id !== "") {
      accountInfoData.accountStatus = {
        drop_account_status_id: accountDetails?.account_status_id,
        account_status: accountDetails?.account_status,
      };
    }
    accountInfoData.restData = {
      account_name: accountDetails?.account_name,
      website: accountDetails?.website,
      inportant_notes: accountDetails?.inportant_notes,
      restricted: accountDetails?.restricted,
      productInterest: accountDetails?.product_interest_id,
    };
    // setAccountInfoDetails(accountInfoData);

    setAccountInfoDetails((prev) => ({
      ...prev,
      ...accountInfoData,
      coordinatorData: {
        projectManager: {
          primary: {
            id: coordinatorData[4]?.arr[0]?.id,
            account_main_contact_id:
              coordinatorData[4]?.arr[0]?.contact_id || "",
            account_main_contact_firstname:
              coordinatorData[4]?.arr[0]?.primary || "",
          },
          backup: {
            id: coordinatorData[4]?.arr[1]?.id,
            account_main_contact_id:
              coordinatorData[4]?.arr[1]?.contact_id || "",
            account_main_contact_firstname:
              coordinatorData[4]?.arr[1]?.backup || "",
          },
        },
        salesReps: {
          primary: {
            id: coordinatorData[5]?.arr[0]?.id,
            account_main_contact_id:
              coordinatorData[5]?.arr[0]?.contact_id || "",
            account_main_contact_firstname:
              coordinatorData[5]?.arr[0]?.primary || "",
          },
          backup: {
            id: coordinatorData[5]?.arr[1]?.id,
            account_main_contact_id:
              coordinatorData[5]?.arr[1]?.contact_id || "",
            account_main_contact_firstname:
              coordinatorData[5]?.arr[1]?.backup || "",
          },
        },
      },
    }));
  };

  useEffect(() => {
    if (coordinatorData?.length > 0 && accountDetails) {
      handleSetData();
    }
  }, [coordinatorData, accountDetails]);

  useEffect(() => {
    if (prev_selected_tb && !currentTab) {
      setCurrentTab(prev_selected_tb);
    } else if (location?.state?.tab) {
      setCurrentTab(location?.state?.tab);
    }
  }, [location]);

  useEffect(() => {
    // if(currentTab === "Details"){
    fetchOnload();
    // }
  },
    [currentTab, accountId]);

  const documentRedirect = () => {
    navigate("/account-document-upload", {
      state: {
        type: "account",
        accountId,
        siteId: "",
      },
    });
  };

  const [documentData, setDocumentData] = useState(null);

  const getDocumentsData = async () => {
    const response = await CallGETAPI("get-all-documents/", accountId);

    if (response?.status) {
      setDocumentData(response.data.data);
    }
  };
  const [aedList, setAedList] = useState([]);
  const fetchAEDData = async () => {
    // const result = await CallGETAPI('account/get-aed/' + accountId);
    const result = await CallGETAPI(
      "account/get-aed-with-standalon/" + accountId
    );
    // account/get-aed-with-standalon
    if (result?.data?.status) {
      const aeds = result?.data?.data;
      const resultArr = [];
      for (let a1 = 0; a1 < aeds.length; a1++) {
        const aed1 = aeds[a1];
        let obj = {
          site_name: aed1.site_name,
          site_id: "",
          data: [],
        };

        for (let a2 = 0; a2 < aed1.data.length; a2++) {
          const aeds2d = aed1.data[a2]?.aed_details;
          obj.site_id = aeds2d?.site_id;
          let obj2 = {
            aed_id: aeds2d?.aed_id,
            site_id: aeds2d?.site_id,
            serial_number: aeds2d?.serial_number,
            placement: aeds2d?.placement,
            brand_name: aed1.data[a2]?.aed_brand,
            battery_expiration: [],
            pads_expiration: [],
            last_check: FormatDate(aeds2d?.last_check),
            last_service: aeds2d?.last_service,
            rms_check: aeds2d?.rms_check,
            pediatric_key: aeds2d?.pediatric_key,
          };
          let bi1 = aeds2d?.battery_info
            ? JSON.parse(aeds2d?.battery_info)
            : [];
          let si1 = aeds2d?.storage_info
            ? JSON.parse(aeds2d?.storage_info)
            : [];
          let cpi1 = aeds2d?.charge_pak_info
            ? JSON.parse(aeds2d?.charge_pak_info)
            : []; // charge_pak_info
          let cpi1Arr = cpi1.length > 0 ? cpi1 : [];
          let cpi1BatteryDatesata = cpi1Arr.map(
            (cpi1Arritem) => cpi1Arritem.battery_expiration
          );

          let sbi1 = aeds2d?.spare_battery_info
            ? JSON.parse(aeds2d?.spare_battery_info)
            : []; //spare_battery_info
          let sbi1Arr = sbi1.length > 0 ? sbi1[0] : [];

          let sbi1_arr =
            sbi1Arr?.has_battery_spare && sbi1Arr?.has_battery_spare?.length > 0
              ? sbi1Arr?.has_battery_spare.map(
                (item) => item.battery_expiration
              )
              : [];
          let sbi1_arr2 =
            sbi1Arr?.has_9v_spare && sbi1Arr?.has_9v_spare?.length > 0
              ? sbi1Arr?.has_9v_spare.map((item) => item.battery_expiration)
              : [];
          let sbi1_arr3 =
            sbi1Arr?.has_10pk_spare && sbi1Arr?.has_10pk_spare?.length > 0
              ? sbi1Arr?.has_10pk_spare.map((item) => item.battery_expiration)
              : [];
          let sbi1_arr4 =
            sbi1Arr?.has_installby_spare &&
              sbi1Arr?.has_installby_spare?.length > 0
              ? sbi1Arr?.has_installby_spare.map(
                (item) => item.battery_expiration
              )
              : [];
          let sbi1_arr5 =
            sbi1Arr?.has_man_spare && sbi1Arr?.has_man_spare?.length > 0
              ? sbi1Arr?.has_man_spare.map((item) => item.battery_expiration)
              : [];

          let spare_obj = [
            {
              title: "spare_battery_info",
              data: sbi1_arr,
              img: "/Battery.png",
            },
            {
              title: "spare_battery_info",
              data: sbi1_arr2,
              img: "/spare-battery.png",
            },
            {
              title: "spare_battery_info",
              data: sbi1_arr3,
              img: "/Battery.png",
            },
            {
              title: "spare_battery_info",
              data: sbi1_arr4,
              img: "/Battery.png",
            },
            {
              title: "spare_battery_info",
              data: sbi1_arr5,
              img: "/Battery.png",
            },
            {
              title: "spare_battery_info",
              data: cpi1BatteryDatesata,
              img: "/Battery.png",
            },
          ];

          let ppi1 = aeds2d?.pediatric_pad_info
            ? JSON.parse(aeds2d?.pediatric_pad_info)
            : [];
          let sppi1 = aeds2d?.spare_padric_pad_info
            ? JSON.parse(aeds2d?.spare_padric_pad_info)
            : [];
          let api1 = aeds2d?.adult_pad_info
            ? JSON.parse(aeds2d?.adult_pad_info)
            : [];
          let sapi1 = aeds2d?.spare_adult_pad_info
            ? JSON.parse(aeds2d?.spare_adult_pad_info)
            : [];

          let ppd =
            ppi1 && ppi1?.length > 0
              ? ppi1.map(
                (item) =>
                  item?.pediatric_pad_expiration &&
                  item?.pediatric_pad_expiration != "" &&
                  item?.pediatric_pad_expiration
              )
              : [];
          let spsd =
            sppi1 && sppi1?.length > 0
              ? sppi1.map(
                (item) =>
                  item?.spare_pediatric_pad_expiration &&
                  item?.spare_pediatric_pad_expiration != "" &&
                  item?.spare_pediatric_pad_expiration
              )
              : [];
          let apid =
            api1 && api1?.length > 0
              ? api1.map(
                (item) =>
                  item?.adult_pad_expiration &&
                  item?.adult_pad_expiration != "" &&
                  item?.adult_pad_expiration
              )
              : [];
          let spd =
            sapi1 && sapi1?.length > 0
              ? sapi1.map(
                (item) =>
                  item?.spare_adult_pad_expiration &&
                  item?.spare_adult_pad_expiration != "" &&
                  item?.spare_adult_pad_expiration
              )
              : [];

          let batteryDateArr = [];
          for (let bi = 0; bi < bi1.length; bi++) {
            const hab_batery = bi1[bi]?.has_battery;
            const has_9v = bi1[bi]?.has_9v;

            const has_10pk = bi1[bi]?.has_10pk;
            const has_installby = bi1[bi]?.has_installby;
            const has_man = bi1[bi]?.has_man;

            let arr =
              hab_batery && hab_batery?.length > 0
                ? hab_batery.map((item) => item.battery_expiration)
                : [];
            let arr2 =
              has_9v && has_9v?.length > 0
                ? has_9v.map((item) => item.battery_expiration)
                : [];
            let arr3 =
              has_10pk && has_10pk?.length > 0
                ? has_10pk.map((item) => item.battery_expiration)
                : [];
            let arr4 =
              has_installby && has_installby?.length > 0
                ? has_installby.map((item) => item.battery_expiration)
                : [];
            let arr5 =
              has_man && has_man?.length > 0
                ? has_man.map((item) => item.battery_expiration)
                : [];
            let obj = [
              { title: "hab_batery", data: arr, img: "/Battery.png" },
              { title: "has_9v", data: arr2, img: "/spare-battery.png" },
              { title: "has_10pk", data: arr3, img: "/Battery.png" },
              { title: "has_installby", data: arr4, img: "/Battery.png" },
              { title: "has_man", data: arr5, img: "/Battery.png" },
            ];

            batteryDateArr = obj;
          }

          let si_obj = {
            title: "storage_info",
            data: [],
            img: "/Aed-Cabinet.png",
          };
          // if(obj2?.brand_name==='Defibtech Lifeline'){
          //      si_obj = { title: "storage_info", data: [], img: '/spare-battery.png' };
          // }

          for (let si = 0; si < si1.length; si++) {
            const sie = si1[si];
            si_obj.data.push(FormatDate(sie?.expiry_date));
          }
          batteryDateArr.push(si_obj);

          obj2.battery_expiration = [...batteryDateArr, ...spare_obj];
          let Chargepad1Arr = cpi1Arr.map(
            (cpi1Item) => cpi1Item.pad_1_expiration
          );
          let Chargepad2Arr = cpi1Arr.map(
            (cpi1Item) => cpi1Item.pad_2_expiration
          );

          let obj3 = [
            { title: "adult_pad_info", data: apid, img: "/people-Group.svg" },
            {
              title: "spare_adult_pad_info",
              data: spd,
              img: "/people-Group.svg",
            },
            {
              title: "pediatric_pad_info",
              data: ppd,
              img: "/child-Vector.png",
            },
            {
              title: "spare_padric_pad_info",
              data: spsd,
              img: "/child-Vector.png",
            },
            {
              title: "spare_padric_pad_info",
              data: Chargepad1Arr,
              img: "/people-Group.svg",
            },
            {
              title: "spare_padric_pad_info",
              data: Chargepad2Arr,
              img: "/people-Group.svg",
            },
          ];
          obj2.pads_expiration = obj3;
          obj.data.push(obj2);
        }
        resultArr.push(obj);
      }

      setAedList(resultArr);
    }
  };

  // useEffect(() => {
  //   handleSetToken();
  //   fetchOnload();
  //   getDocumentsData();
  //   fetchAEDData();
  // }, []);

  const supportRedirect = () => {
    const stateData = {
      type: "Account",
      site_id: 0,
      accountId: accountId ? accountId : 0,
      contactId: 0,
      accountName: accountDetails?.account_name || "",
      support_name: accountDetails?.account_name,
    };

    navigate("/account/new-support/" + accountId, { state: stateData });
  };

  const notesRedirect = () => {
    navigate(`/account/new-note?account_id=${accountId}`);
  };

  const handleHoverFloating = () => {
    setIsOpen(true);
  };

  const handleLeaveFloating = () => {
    setIsOpen(false);
  };
  const breadcrumbs1 = useSelector((state) => state.BreadCrumbs.items);

  const changeState = async () => {
    setForward(true);
  };

  useEffect(() => {
    dispatch(
      addItem({ title: "Accounts", path: location?.pathname, tab: currentTab })
    );
  }, [currentTab]);

  const handleExportReport = async () => {
    let payload = {
      account_id: accountId,
      tab: currentTab,
    };
    const res = await CallPOSTAPI("admin/report-account", payload);
    const path = res?.data?.filePath;
    handleDownload(path);
  };

  const equipmentPayloadData = useSelector(
    (state) => state.accountdetailsequipmentfilter.equipmentPayloadData
  );

  const handleExportAed = async () => {
    let payload = {};
    if (equipmentPayloadData != "") {
      payload = {
        accesory_brand_model: equipmentPayloadData?.accesory_brand_model,
        accessory_type: equipmentPayloadData?.accessory_type,
        aed_brand_model: equipmentPayloadData?.aed_brand_model,
        aed_checker: equipmentPayloadData?.aed_checker,
        expirationrange: equipmentPayloadData?.expirationrange,
        from: equipmentPayloadData?.from,
        site_name: equipmentPayloadData?.site_name,
        state: equipmentPayloadData?.state,
        to: equipmentPayloadData?.to,
        account_id: accountId,
        tab: "aed",
      };
    } else {
      payload = {
        accesory_brand_model: "",
        accessory_type: "",
        aed_brand_model: "",
        aed_checker: "",
        expirationrange: "",
        from: "",
        site_name: "",
        state: "",
        to: "",
        account_id: accountId,
        tab: "aed",
      };
    }
    const res = await CallPOSTAPI("admin/report-account", payload);
    const path = res?.data?.filePath;
    handleDownload(path);
  };

  const handleExportAccessory = async () => {
    let payload = {};
    if (equipmentPayloadData != "") {
      payload = {
        accesory_brand_model: equipmentPayloadData?.accesory_brand_model,
        accessory_type: equipmentPayloadData?.accessory_type,
        aed_brand_model: equipmentPayloadData?.aed_brand_model,
        aed_checker: equipmentPayloadData?.aed_checker,
        expirationrange: equipmentPayloadData?.expirationrange,
        from: equipmentPayloadData?.from,
        site_name: equipmentPayloadData?.site_name,
        state: equipmentPayloadData?.state,
        to: equipmentPayloadData?.to,
        account_id: accountId,
        tab: "accessory",
      };
    } else {
      payload = {
        accesory_brand_model: "",
        accessory_type: "",
        aed_brand_model: "",
        aed_checker: "",
        expirationrange: "",
        from: "",
        site_name: "",
        state: "",
        to: "",
        account_id: accountId,
        tab: "accessory",
      };
    }
    const res = await CallPOSTAPI("admin/report-account", payload);
    const path = res?.data?.filePath;
    handleDownload(path);
  };

  const handleDownload = (path) => {
    if (path) {
      const fullURL = BASE_API + `/${path}`;
      // Construct a temporary anchor element
      const link = document.createElement("a");
      link.href = fullURL;
      link.download = "report.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success("File download successfully");
    } else {
      toast.error("No file to download");
    }
  };

  const [tabTbldata, setTabTbldata] = useState({
    site: false,
    contact: false,
    equipment: {
      aed: false,
      accessory: false,
    },
    note: false,
    support: false,
  });

  const fetchAedLengthApi = async () => {
    const aedLength = await fetchAedLength(accountId);
    setAedLength(aedLength);
    }

  useEffect(() => {
    fetchAedLengthApi()
  }, []);

  useEffect(() => {
    dispatch(setEditAccountData(accountInfoDetails))
  }, [accountInfoDetails]);

// Render Functions:
const subAdminPermission = [
  "site-details",
  "contact-tab",
  "equipment-tab",
  "training-tab",
  "inperson-tab",
  "notes-tab",
  "documents-tab",
];

let site_url = is_user ? "/user/site-details/" : "/account/site-details/";

const RenderPhone = (data) => (
  <>
    {data?.account_site_phone && (
      <a
        className="link"
        style={{ textDecoration: "none" }}
        href={
          "tel:" + data?.account_site_phone_ext + data?.account_site_phone
        }
      >
        {data?.account_site_phone
          ? formatPhoneNumber(data?.account_site_phone)
          : ""}{" "}
        {data?.account_site_phone_ext
          ? " X " + data?.account_site_phone_ext
          : ""}
      </a>
    )}
  </>
);

const RenderSitePOC = (data) => (
  <span
    onClick={() => {
      navigate(
        "/account/" +
          accountId +
          "/contact-details/" +
          data.account_site_info_id,
        {
          state: {
            siteTitle: "Contact : " + data?.account_site_poc,
            editUrl:
              "/account/contact-details-edit/" + data.account_site_info_id,
            deleteUrl:
              "/account/contact-details-edit/" + data.account_site_info_id,
          },
        }
      );
    }}
    className="link"
  >
    {data?.account_site_poc}
  </span>
);

const RenderEquipment = (data) => (
  <span className="py-1 px-2 tbl-border">
    {data?.aedCount}/{data?.equipmentCount}
  </span>
);

const RenderSiteName = (data) => {
  return (
    <>
      <span
        className={linkTabsPermission(subAdminPermission) == 1 ? "link" : ""}
        onClick={() =>
          linkTabsPermission(subAdminPermission) == 1 &&
          navigate(site_url + data.account_site_info_id, {
            state: {
              siteTitle: "Site: " + data?.account_site_name,
              ...(is_user
                ? {
                  accountName:accountName
                }
                : {
                    accountName:accountName,
                    editUrl: "/account/site-details-edit/" + data.account_site_info_id,
                    deleteUrl: "/account/site-details-edit/" + data.account_site_info_id,
                  }),
            },
          })
        }
      >
        {data?.account_site_name}
      </span>
    </>
  );
};


// Render Functions for contact
const RenderContactName = (data) => {
  return (
    <>
      <span
        className={
          user?.user_type == 0 ||
          (user?.user_type == 2 &&
            user?.sub_admin != "" &&
            privilege?.includes("contact-details") === "contact-details")
            ? "link"
            : ""
        }
        onClick={() =>
          (user?.user_type == 0 ||
            (user?.user_type == 2 &&
              user?.sub_admin != "" &&
              privilege?.includes("contact-details") ===
                "contact-details")) &&
          navigate(
            `/account/${accountId}/contact-details/${data.contact_id}`,
            {
              state: {
                editUrl: `/account/contact-details-edit/${data.contact_id}`,
                deleteUrl: `/account/contact-details-edit/${data.contact_id}`,
                type: "Contacts",
              },
            }
          )
        }
      >
        {truncateText(data?.contact_name, 25)}
      </span>
    </>
  );
};

const RenderUser = (data) => {
  return (
    <>
      {data?.user ? (
        <CheckIcon color={"success"} />
      ) : (
        <CloseIcon color="error" />
      )}
    </>
  );
};

const RenderContactPhone = (data) => {
  return (
    <>
      {data?.phone?.[0].phone != "" && data?.phone?.[0].phone != "-" && (
        <a
          className="link"
          style={{ textDecoration: "none" }}
          href={"tel:" + data?.phone?.[0].ext + data?.phone?.[0]?.phone}
        >
          {data?.phone?.[0].phone
            ? formatPhoneNumber(data?.phone?.[0].phone)
            : ""}
          {data?.phone?.[0].ext != "" ? "x" + data?.phone?.[0].ext : ""}
        </a>
      )}
    </>
  );
};

const RenderEmail = (data) => {
  return (
    <>
      {data?.email.length > 0 && (
        <a
          className="link"
          style={{ textDecoration: "none" }}
          href={"mailto:" + data?.email?.[0].email}
        >
          {data?.email?.[0].email}
        </a>
      )}
    </>
  );
};


  return (
    <>
      <div
        className="mt-4 main-site"
        style={{ width: "100%" }}
        onClick={(e) => {
          setOutsideClick(e);
        }}
      >
        <SubHeading
          hideNew="tab"
          title={"Account : " + (accountDetails?.account_name ? accountDetails?.account_name : "")}
          newUrl="/new-account"
          subHeading={true}
          hideHierarchy={
            // accountDetails?.parent_account != "" &&
            // accountDetails?.parent_account != 0
            //   ? false
            //   : true
            true
          }
          assign_equipment={aedLength > 0 ? true : false}
          loading={showLoading}
          account_id={accountId}
          editUrl={"/account/accounts-edit/" + accountId}
          outsideClickEvent={outsideClick}
          accountName={"accountName"}
          support_type="Account"
          support_name={accountDetails?.account_name}
          site_id={0}
          exportReport={true}
          currentTab={currentTab}
          handleExportReport={handleExportReport}
          handleExportAed={handleExportAed}
          handleExportAccessory={handleExportAccessory}
          tabTbldata={tabTbldata}
          accountDetails={accountInfoDetails}
          programData={programData}
          aedUnitData={aedUnitData}
          coordinatorData={coordinatorData}
        />

        <Box>
          <div className="d-flex border-bottom border-secondary bg-primary">
            {Object.values(AccountDetailsTab).map((tabItem, i) => {
              if (
                user?.user_type === 0 ||
                (tabItem === "Details" &&
                  user?.user_type === 2 &&
                  privilege?.includes("account-details")) ||
                (tabItem === "Sites" &&
                  user?.user_type === 2 &&
                  privilege?.includes("site-tab")) ||
                (tabItem === "Contacts" &&
                  user?.user_type === 2 &&
                  privilege?.includes("contact-tab")) ||
                (tabItem === "Equipment" &&
                  user?.user_type === 2 &&
                  privilege?.includes("equipment-tab")) ||
                (tabItem === "Training" &&
                  user?.user_type === 2 &&
                  privilege?.includes("training-tab")) ||
                (tabItem === "Inperson" &&
                  user?.user_type === 2 &&
                  privilege?.includes("inperson-tab")) ||
                (tabItem === "POPS" &&
                  user?.user_type === 2 &&
                  privilege?.includes("pops-tab")) ||
                (tabItem === "Notes" &&
                  user?.user_type === 2 &&
                  privilege?.includes("notes-tab")) ||
                (tabItem === "Emails" &&
                  user?.user_type === 2 &&
                  privilege?.includes("email-tab")) ||
                (tabItem === "Support" &&
                  user?.user_type === 2 &&
                  privilege?.includes("support-tab")) ||
                (tabItem === "Documents" &&
                  user?.user_type === 2 &&
                  privilege?.includes("documents-tab")) ||
                (tabItem === "RFI" &&
                  user?.user_type === 2 &&
                  privilege?.includes("rfi-tab"))
              ) {
                return (
                  <div
                    role="button"
                    key={i}
                    className={"text-light tab-button"}
                    style={{
                      backgroundColor: `${tabItem === currentTab ? "#26AEE0" : "#0C71C3"
                        }`,
                    }}
                    onClick={() => handleTab(tabItem)}
                  >
                    {tabItem}
                  </div>
                );
              }
              return null;
            })}
          </div>
        </Box>

        {currentTab === AccountDetailsTab.DETAILS && (
          <>
            <Details
              accountDetails={accountDetails}
              CoordiDataList={CoordiDataList}
              programDetails={programDetails}
              httpsWeb={httpsWeb}
              tabTbldata={tabTbldata}
              setProgramData={setProgramData}
              setAedUnitData={setAedUnitData}
              setCoordinatorData={setCoordinatorData}
              showLoading={showLoading}
            />
          </>
        )}

        {currentTab === AccountDetailsTab.SITES && (
          <>
            {/* <Sites setTabTbldata={setTabTbldata} /> */}
            <Box
            className="d-flex justify-content-between align-items-center mt-2"
            style={{ marginBottom: "0px !important" }}
            >
            <h3 className="heading">Account Site Information</h3>
            <div style={{ display: "flex", flexDirection: "row", gap: "0px" }}>
            {(user?.user_type == 0 ||
             (user?.user_type == 2 &&
              user?.sub_admin != "" &&
              privilege?.includes("sites-new"))) && (
            <Link
              style={{ textDecoration: "none", marginRight: "10px" }}
              to={"/admin-siteNew/" + accountId}
            >
              <img src={New} />
              <span style={{ color: "#0C71C3" }}> New</span>
            </Link>
            )}
            </div>
            </Box>            
            <NewGlobalTable 
             isCache={true}
             pageSize={20}
             method="GET"
             apiEndPoint={`site/account-site-list/${accountId}`}
             keyAPiName={"site_details"}
             headerColumns={[
              {
                Header: "Main Site",
                accessor: "account_site_name",
                Cell: ({ row }) => RenderSiteName(row.original) ,
              },
              {
                Header: "Equipment",
                accessor: "equipmentCount",
                Cell: ({ row }) => (
                  <>
                    {showLoading ? (
                      <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
                    ) : (
                      <div>{RenderEquipment(row.original)}</div>
                    )}
                  </>
                ),
                disableSortBy: true,
              },
              {
                Header: "Training",
                accessor: "account_site_training",
                Cell: ({ row }) => (
                  <>
                    {showLoading ? (
                      <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
                    ) : (
                      row.original.account_site_training
                    )}
                  </>
                ),
                disableSortBy: true,
              },
              {
                Header: "Site POC",
                accessor: "account_site_poc",
                Cell: ({ row }) => (
                  <>
                    {showLoading ? (
                      <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
                    ) : (
                      <div style={{ color: "blue" }}>{RenderSitePOC(row.original)}</div>
                    )}
                  </>
                ),
              },
              {
                Header: "Phone",
                accessor: "account_site_phone",
                Cell: ({ row }) => (
                  <>
                    {showLoading ? (
                      <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
                    ) : (
                      <div style={{ color: "blue" }}>{RenderPhone(row.original)}</div>
                    )}
                  </>
                ),
              },
              {
                Header: "Email",
                accessor: "account_site_email",
                Cell: ({ row }) => (
                  <>
                    {showLoading ? (
                      <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
                    ) : (
                      <a
                        className="link"
                        href={"mailto:" + row.original.account_site_email}
                        style={{ textDecoration: "none" }}
                      >
                        {" "}
                        {row.original.account_site_email}
                      </a>
                    )}
                  </>
                ),
              },
            ]}
            />
          </>
        )}

        {currentTab === AccountDetailsTab.CONTACTS && (
          <>
            {/* <Contacts setTabTbldata={setTabTbldata} /> */}
            <Box className="d-flex justify-content-between align-items-center py-2">
             <h3 className="heading">Account Contacts</h3>
             {(user?.user_type == 0 ||
              (user?.user_type == 2 &&
              user?.sub_admin != "" &&
              privilege?.includes("contacts-new"))) && (
            <Link
              style={{ textDecoration: "none" }}
              to={"/account/contacts/new/" + accountId}
            >
              <img src={New} />
              <span style={{ color: "#0C71C3" }}> New</span>
            </Link>
            )}
            </Box>
            <NewGlobalTable 
            method={"GET"}
            isCache={true}
            keyAPiName="contact_list"
            apiEndPoint={`account/account-contacts-list/${accountId}`}
            headerColumns={[
              {
                Header: "Contact",
                accessor: "contact_name",
                Cell: ({ row }) => <div> {RenderContactName(row.original)}</div>,
              },
              {
                Header: "User",
                accessor: "user",
                Cell: ({ row }) => (
                  <>
                    {showLoading ? (
                      <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
                    ) : (
                      <div>{RenderUser(row.original)}</div>
                    )}
                  </>
                ),
                width: "10%",
                disableSortBy: true,
              },
              {
                Header: "Phone",
                accessor: "phone",
                width: "12%",
                Cell: ({ row }) => (
                  <>
                    {showLoading ? (
                      <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
                    ) : (
                      <div>{RenderContactPhone(row?.original)}</div>
                    )}
                  </>
                ),
                disableSortBy: true,
              },
              {
                Header: "Email",
                accessor: "email",
                Cell: ({ row }) => (
                  <>
                    {showLoading ? (
                      <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
                    ) : (
                      <div>{RenderEmail(row.original)}</div>
                    )}
                  </>
                ),
              },
              {
                Header: "Positions",
                accessor: "account_roles",
                Cell: ({ row }) => (
                  <>
                    {showLoading ? (
                      <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
                    ) : (
                      <div>{row.original?.account_roles.join(", ")} </div>
                    )}
                  </>
                ),
              },
              {
                Header: "Sites",
                accessor: "main_site",
              },
              {
                Header: "Status",
                accessor: "status",
                Cell: ({ row }) => (
                  <>
                    {showLoading ? (
                      <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
                    ) : (
                      <>
                        <p className={row.original?.status == 1 ? "" : "text-danger"}>
                          {ContactStatus[row.original?.status]}
                        </p>
                      </>
                    )}
                  </>
                ),
              },
            ]}
            />
          </>
        )}

        {/* DOCUMENTS */}
        {currentTab === AccountDetailsTab.DOCUMENTS && (
          <Documents documentData={documentData} type="Account" />
        )}

        {/* POPS/PLANS */}
        {currentTab === AccountDetailsTab.INPERSON && <Inperson />}

        {/* POPS/PLANS */}
        {currentTab === AccountDetailsTab.POPS && (
          <Pops accountId={accountId} type="ACCOUNT" />
        )}

        {/* TRAINING */}
        {currentTab === AccountDetailsTab.TRAINING && (
          <TraningDetailsTabel />
          // <TraningDetailsTabel showAlert={showAlert}/>
        )}

        {/* SUPPORT */}
        {currentTab === AccountDetailsTab.SUPPORT && (
          <Support
            setTabTbldata={setTabTbldata}
            stateData={{
              type: "Account",
              site_id: 0,
              accountId: accountId ? accountId : 0,
              contactId: 0,
              accountName: accountDetails?.account_name || "",
              support_name: accountDetails?.account_name,
            }}
          // navigate("/account/new-support/"+accountId, {state: {
          // 	type:	support_type,// contactId ? 'contact' : 'account',
          // 	site_id: site_id || 0,
          // 	accountId: accountId || 0,
          // 	contactId: contactId || 0,
          // 	accountName:accountName || "",
          // 	support_name: support_name,
          // }});
          />
        )}

        {/* Notes */}
        {currentTab === AccountDetailsTab.NOTES && (
          <Notes
            accountId={accountId}
            accountDetails={accountDetails}
            CoordiDataList={CoordiDataList}
            programDetails={programDetails}
            httpsWeb={httpsWeb}
            type="ACCOUNT"
            setTabTbldata={setTabTbldata}
          />
        )}


        {/* Emails */}
        {currentTab === AccountDetailsTab.EMAILS && (
          <Emails accountId={accountId} />
        )}

        {/* RFI */}
        {currentTab === AccountDetailsTab.RFI && <RFI />}

        {/* AEDS */}
        {currentTab === AccountDetailsTab.Equipment && (
          <Aeds tabTbldata={tabTbldata} setTabTbldata={setTabTbldata} accountName={accountDetails?.account_name} />
        )}
      </div>
      <div
        className="floating-menu-btn d-flex flex-column gap-2"
        onMouseEnter={handleHoverFloating}
        onMouseLeave={handleLeaveFloating}
      >
        {/*  {isOpen && (
              <>
                {(isSubAdminPermission("new-document") === 1 ||
                  isUserPermission("new-document") === 1) && (
                  <img
                    src="/NewDocument.svg"
                    width={60}
                    height={60}
                    style={{
                      padding: "2px",
                      borderRadius: "50%",
                      borderColor: "#0c71c3",
                      borderWidth: "3px",
                      borderStyle: "solid",
                    }}
                    className="pointer bg-white"
                    onClick={documentRedirect}
                    title="New Document"
                  />
                )}

                {(isSubAdminPermission("new-support") === 1 ||
                  isUserPermission("new-support") === 1) && (
                  <img
                    src="/NewSupport.svg"
                    width={60}
                    height={60}
                    style={{
                      padding: "2px",
                      borderRadius: "50%",
                      borderColor: "#0c71c3",
                      borderWidth: "3px",
                      borderStyle: "solid",
                    }}
                    className="pointer bg-white"
                    onClick={supportRedirect}
                    title="New Support"
                  />
                )}

                {(isSubAdminPermission("new-note") === 1 ||
                  isUserPermission("new-note") === 1) && (
                  <img
                    src="/NewNote.svg"
                    width={60}
                    height={60}
                    style={{
                      padding: "2px",
                      borderRadius: "50%",
                      borderColor: "#0c71c3",
                      borderWidth: "3px",
                      borderStyle: "solid",
                    }}
                    className="pointer bg-white"
                    onClick={notesRedirect}
                    title="New Note"
                  />
                )}
              </>
            )}  */}

        {/*<img
              src="/assets/images/Plus.svg"
              width={60}
              height={60}
              style={{
                padding: "2px",
                borderRadius: "50%",
                borderColor: "#0c71c3",
                borderWidth: "3px",
                borderStyle: "solid",
              }}
              className="pointer bg-white"
              // onMouseLeave={() => {
              //   setIsOpen((prev) => !prev);
              // }}
              onClick={() => {
                setIsOpen((prev) => !prev);
              }}
            />*/}
      </div>
    </>
  );
};

export default AccountDetails;
