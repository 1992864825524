import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import "../css/outOfServiceModal.scss";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import moment from "moment";
import { CallGETAPI, CallPOSTAPI } from "../../../../common/services";
import { FormatDate } from "../../../../common/helper";
import GlobalDropdown from "../../../../common/components/custom-ui/GlobalDropdown";
import InventoryListTbl from "../components/common/InventoryListTbl";

const SiteListModel = ({
  openSitelistModel,
  setOpenSitelistModel,
  secName,
  Pindex,
  index,
  setNewFormData,
  Bindex,
  inspection_by,
  AedFormData,
  contact_id,
  crrFormData,
}) => {
  const handleClose = () => {
    setOpenSitelistModel(false);
  };
  const [siteList, setSiteList] = useState([]);
  const [selectSite, setSelectedSite] = useState("");

  const aed_brand_id = AedFormData.brand;
  const aed_model_id = AedFormData.model_name;

  const [addAedInventoryModal, setAedInventoryModal] = useState(true);
  const [batteryInventory, setBatteryInventory] = useState([]);
  const [padInventory, setPadInventory] = useState([]);
  const [isDNI, setIsDNI] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedBatteryRows, setSelectedBatteryRows] = useState([]);
  const [selectedPadRows, setSelectedPadRows] = useState([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState("");
  const [modalHeight, setModalHeight] = useState("auto");

  const fetchBatteryInventaryData = async () => {
    setLoading(true);
    if (
      (secName === "has_battery" ||
        secName === "has_battery_spare" ||
        secName === "has_9v" ||
        secName === "has_9v_spare" ||
        secName === "has_installby" ||
        secName === "has_installby_spare" ||
        secName === "has_man" ||
        secName === "has_man_spare" ||
        secName === "has_10pk" ||
        secName === "has_10pk_spare" ||
        secName === "ChargePakInformation" ||
        secName === "SpareChargePakInformation") &&
      selectSite != ""
    ) {
      var body = {
        section_name: secName,
        contact_id: contact_id,
        model_id: aed_model_id,
        brand_id: aed_brand_id,
      };

      let payload = {
        section_name: secName.includes("_spare")
          ? secName.replace("_spare", "")
          : secName === "ChargePakInformation" ||
            secName === "SpareChargePakInformation"
          ? "charge_pack"
          : secName,
        site_id: selectSite,
        brand_id: AedFormData?.brand,
        model_id: AedFormData?.model_name,
      };
      // var response = await CallPOSTAPI(
      //   "account/get-site-battery-inventory",
      //   payload
      // );

      var response = await CallPOSTAPI(
        "acc/get-site-battery-inventory",
        payload
      );
      if (response.status === true) {
        var data = response?.data?.data;

        if (data?.batteryInfo) {
          for (
            let invent = data.batteryInfo.length - 1;
            invent >= 0;
            invent--
          ) {
            const element = data.batteryInfo[invent];
            let quantityUpdated = false; // Flag to check if quantity is updated
            for (
              let index2 = 0;
              index2 < AedFormData[`${secName.replace(/'/g, "")}`].length;
              index2++
            ) {
              if (
                secName === "has_9v" ||
                secName === "has_10pk" ||
                secName === "has_battery" ||
                secName === "has_installby" ||
                secName === "has_man" ||
                secName === "ChargePakInformation"
              ) {
                const element2 =
                  AedFormData[`${secName.replace(/'/g, "")}`][index2];
                const secSubName =
                  secName === "has_9v" || secName === "has_9v_spare"
                    ? "has_9v_spare"
                    : secName === "has_10pk" || secName === "has_10pk_spare"
                    ? "has_10pk_spare"
                    : secName === "has_battery" ||
                      secName === "has_battery_spare"
                    ? "has_battery_spare"
                    : secName === "has_installby" ||
                      secName === "has_installby_spare"
                    ? "has_installby_spare"
                    : secName === "has_man" || secName === "has_man_spare"
                    ? "has_man_spare"
                    : secName === "ChargePakInformation" ||
                      secName === "SpareChargePakInformation"
                    ? "SpareChargePakInformation"
                    : "";

                const element3 = AedFormData[secSubName][index2];

                if (element.sbid === element2?.inventory_id) {
                  element.quantity--;
                  quantityUpdated = true;
                  setBatteryInventory([...data.batteryInfo]);
                }
                if (element.sbid === element3?.inventory_id) {
                  //
                  element.quantity--;
                  quantityUpdated = true;
                  setBatteryInventory([...data.batteryInfo]);
                }
              } else {
                const element2 =
                  AedFormData[`${secName.replace(/'/g, "")}`][index2];
                
                const secSubName =
                  secName === "has_9v" || secName === "has_9v_spare"
                    ? "has_9v"
                    : secName === "has_10pk" || secName === "has_10pk_spare"
                    ? "has_10pk"
                    : secName === "has_battery" ||
                      secName === "has_battery_spare"
                    ? "has_battery"
                    : secName === "has_installby" ||
                      secName === "has_installby_spare"
                    ? "has_installby"
                    : secName === "has_man" || secName === "has_man_spare"
                    ? "has_man"
                    : secName === "ChargePakInformation" ||
                      secName === "SpareChargePakInformation"
                    ? "ChargePakInformation"
                    : "";
          
                const element3 = AedFormData[secSubName][index2];

                if (element.sbid === element2?.inventory_id) {
                  element.quantity--;
                  quantityUpdated = true;
                  setBatteryInventory([...data.batteryInfo]);
                }
                if (element.sbid === element3?.inventory_id) {
                  element.quantity--;
                  quantityUpdated = true;
                  setBatteryInventory([...data.batteryInfo]);
                }
              }
            }
            if (element.quantity === 0) {
              data.batteryInfo.splice(invent, 1);
              setBatteryInventory([...data.batteryInfo]);
              quantityUpdated = true;
            }
            if (!quantityUpdated) {
              setBatteryInventory([...data.batteryInfo]);
            }
          }
        }
        setLoading(false);
      }
    }
    // setLoading(false);
  };

  const fetchPadInventaryData = async () => {
    setLoading(true);
    if (
      (secName === "AdultPadInformation" ||
        secName === "SpareAdultPadInfo" ||
        secName === "AdultPadPakInfo" ||
        secName === "SpareAdultPadPakInfo" ||
        secName === "PediatricPadInformation" ||
        secName === "sparePadricPadInfo" ||
        secName === "PediatricPakPadInformation" ||
        secName === "sparePadricPakPad") &&
      selectSite != ""
    ) {
      var body = {
        contact_id: contact_id,
        model_id: aed_model_id,
        brand_id: aed_brand_id,
      };

      let payload = {
        section_name:
          secName === "AdultPadInformation" || secName === "SpareAdultPadInfo"
            ? "adult_pad_info"
            : secName === "AdultPadPakInfo" ||
              secName === "SpareAdultPadPakInfo"
            ? "adult_pad_pak_info"
            : secName === "PediatricPadInformation" ||
              secName === "sparePadricPadInfo"
            ? "pediatric_pad_info"
            : secName === "PediatricPakPadInformation" ||
              secName === "sparePadricPakPad"
            ? "pediatric_pak_pad_info"
            : "",
        site_id: selectSite,
        brand_id: AedFormData?.brand,
        model_id: AedFormData?.model_name,
      };
      // var response = await CallPOSTAPI(
      //   "account/get-site-pad-inventory/",
      //   payload
      // );

      var response = await CallPOSTAPI("acc/get-site-pad-inventory", payload);
      if (response.status === true) {
        var data = response?.data?.data;

        if (data?.padInfo) {
          for (let invent = data?.padInfo.length - 1; invent >= 0; invent--) {
            const element = data?.padInfo[invent];
            let quantityUpdated = false; // Flag to check if quantity is updated
            for (
              let index2 = 0;
              index2 < AedFormData[`${secName.replace(/'/g, "")}`].length;
              index2++
            ) {
              if (
                secName === "AdultPadInformation" ||
                secName === "AdultPadPakInfo" ||
                secName === "PediatricPadInformation" ||
                secName === "PediatricPakPadInformation"
              ) {
                const element2 =
                  AedFormData[`${secName.replace(/'/g, "")}`][index2];
                const secSubName =
                  secName === "AdultPadInformation" ||
                  secName === "SpareAdultPadInfo"
                    ? "SpareAdultPadInfo"
                    : secName === "AdultPadPakInfo" ||
                      secName === "SpareAdultPadPakInfo"
                    ? "SpareAdultPadPakInfo"
                    : secName === "PediatricPadInformation" ||
                      secName === "sparePadricPadInfo"
                    ? "sparePadricPadInfo"
                    : secName === "PediatricPakPadInformation" ||
                      secName === "sparePadricPakPad"
                    ? "sparePadricPakPad"
                    : "";

                const element3 = AedFormData[secSubName][index2];
             
                if (element.spid === element2?.inventory_id) {
                  element.quantity--;
                  quantityUpdated = true;
                  setPadInventory([...data.padInfo]);
                }
                if (element.spid === element3?.inventory_id) {
                  element.quantity--;
                  quantityUpdated = true;
                  setPadInventory([...data.padInfo]);
                }
              } else {
                const element2 =
                  AedFormData[`${secName.replace(/'/g, "")}`][index2];
                const secSubName =
                  secName === "AdultPadInformation" ||
                  secName === "SpareAdultPadInfo"
                    ? "AdultPadInformation"
                    : secName === "AdultPadPakInfo" ||
                      secName === "SpareAdultPadPakInfo"
                    ? "AdultPadPakInfo"
                    : secName === "PediatricPadInformation" ||
                      secName === "sparePadricPadInfo"
                    ? "PediatricPadInformation"
                    : secName === "PediatricPakPadInformation" ||
                      secName === "sparePadricPakPad"
                    ? "PediatricPakPadInformation"
                    : "";
                const element3 = AedFormData[secSubName][index2];
                
                if (element.spid === element2?.inventory_id) {
                  element.quantity--;
                  quantityUpdated = true;
                  setPadInventory([...data.padInfo]);
                }
                if (element.spid === element3?.inventory_id) {
                  element.quantity--;
                  quantityUpdated = true;
                  setPadInventory([...data.padInfo]);
                }
              }
            }
            if (element.quantity === 0) {
              data.padInfo.splice(invent, 1);
              setPadInventory([...data.padInfo]);
              quantityUpdated = true;
            }
            if (!quantityUpdated) {
              setPadInventory([...data.padInfo]);
            }
          }
        }

        setLoading(false);
      }
    }
    // setLoading(false);
  };

  useEffect(() => {
    if (selectSite) {
      setBatteryInventory("");
      setPadInventory("");
      fetchBatteryInventaryData();
      fetchPadInventaryData();
    }
  }, [selectSite]);

  const handleBatteryRowQnty = () => {
    const Bfd = [...batteryInventory];
    if (Bfd[selectedRowIndex]) {
      Bfd[selectedRowIndex].quantity = Bfd[selectedRowIndex].quantity - 1;
    }
    setBatteryInventory(Bfd);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      secName === "has_battery" ||
      secName === "has_battery_spare" ||
      secName === "has_9v" ||
      secName === "has_9v_spare" ||
      secName === "has_installby" ||
      secName === "has_installby_spare" ||
      secName === "has_man" ||
      secName === "has_man_spare" ||
      secName === "has_10pk" ||
      secName === "has_10pk_spare" ||
      secName === "ChargePakInformation" ||
      secName === "SpareChargePakInformation"
    ) {
      const currentDate = new Date().toISOString().split("T")[0];
      const formattedDate = moment(currentDate).format("YYYY-MM-DD");
      //   const formattedDate = `${
      //     currentDate.getMonth() + 1
      // }/${currentDate.getDate()}/${currentDate.getFullYear()}`;
    
      handleBatteryRowQnty();
      const fd = { ...AedFormData };
      const battery = secName.replace(/'/g, "");
      fd[`${battery}`][Bindex].battery_udi =
        selectedBatteryRows[0]?.battery_udi;
      fd[`${battery}`][Bindex].battery_lot =
        selectedBatteryRows[0]?.battery_lot;
      fd[`${battery}`][Bindex].battery_expiration =
        selectedBatteryRows[0]?.battery_expiration;
        {(battery == "has_man_spare" || battery == "has_man") && (fd[`${battery}`][Bindex].manufactured_date = selectedBatteryRows[0]?.manufactured_date)};
        {(battery == "has_10pk_spare" || battery == "has_10pk") && (fd[`${battery}`][Bindex].install_date = formattedDate)};
       
      // fd[`${battery}`][Bindex].manufactured_date =
      //   selectedBatteryRows[0]?.manufactured_date;
      fd[`${battery}`][Bindex].install_date = formattedDate;
      fd[`${battery}`][Bindex].battery_type_id =
        selectedBatteryRows[0]?.battery_type_id;
        fd[`${battery}`][Bindex].battery_part =
        selectedBatteryRows[0]?.battery_part;
      fd[`${battery}`][Bindex].battery_serial =
        selectedBatteryRows[0]?.battery_serial;

        {(battery == "has_9v_spare" || battery == "has_9v") && (fd[`${battery}`][Bindex].install_9v_date = secName === "has_9v" ? formattedDate : "N/A") }
        {(battery == "has_10pk_spare" || battery == "has_10pk" || battery == "has_installby_spare" || battery == "has_installby") && (fd[`${battery}`][Bindex].install_before_date = (secName === "has_10pk" || secName == "has_installby_spare") ? formattedDate : "N/A") }
        {(battery == "SpareChargePakInformation" || battery == "ChargePakInformation") && (fd[`${battery}`][Bindex].charge_pak_part = selectedBatteryRows[0]?.battery_type_id)};
      

      // fd[`${battery}`][Bindex].install_9v_date =
      //   secName === "has_9v" ? formattedDate : "N/A";
      // fd[`${battery}`][Bindex].charge_pak_part =
      //   selectedBatteryRows[0]?.battery_type_id;
      fd[`${battery}`][Bindex].inventory_id = selectedBatteryRows[0]?.sbid;
// console.log({selectedBatteryRows});
      {(battery == "SpareChargePakInformation" || battery == "ChargePakInformation") && (fd[`${battery}`][Bindex].battery_part_number = selectedBatteryRows[0]?.battery_part)};
      {(battery == "SpareChargePakInformation" || battery == "ChargePakInformation") && (fd[`${battery}`][Bindex].charge_pak_part = selectedBatteryRows[0]?.battery_type_id)};
      {(battery == "SpareChargePakInformation" || battery == "ChargePakInformation") && (fd[`${battery}`][Bindex].charge_pak_uid = selectedBatteryRows[0]?.charge_pak_uid)};
      {(battery == "SpareChargePakInformation" || battery == "ChargePakInformation") && (fd[`${battery}`][Bindex].pad_1_expiration = selectedBatteryRows[0]?.pad1?.pad_expiration)};
      {(battery == "SpareChargePakInformation" || battery == "ChargePakInformation") && (fd[`${battery}`][Bindex].pad_1_lot = selectedBatteryRows[0]?.pad1?.pad_lot)};
      {(battery == "SpareChargePakInformation" || battery == "ChargePakInformation") && (fd[`${battery}`][Bindex].pad_1_part = selectedBatteryRows[0]?.pad1?.pad_type_id)};
      {(battery == "SpareChargePakInformation" || battery == "ChargePakInformation") && (fd[`${battery}`][Bindex].charge_pak_pad_1_id = selectedBatteryRows[0]?.pad1?.pad_type_id)};
      {(battery == "SpareChargePakInformation" || battery == "ChargePakInformation") && (fd[`${battery}`][Bindex].pad_1_part_number = selectedBatteryRows[0]?.pad1?.pad_part_number)};

      {(battery == "SpareChargePakInformation" || battery == "ChargePakInformation") && (fd[`${battery}`][Bindex].pad_2_expiration = selectedBatteryRows[0]?.pad2?.pad_expiration)};
      {(battery == "SpareChargePakInformation" || battery == "ChargePakInformation") && (fd[`${battery}`][Bindex].pad_2_lot = selectedBatteryRows[0]?.pad2?.pad_lot)};
      {(battery == "SpareChargePakInformation" || battery == "ChargePakInformation") && (fd[`${battery}`][Bindex].pad_2_part = selectedBatteryRows[0]?.pad2?.pad_type_id)};
      {(battery == "SpareChargePakInformation" || battery == "ChargePakInformation") && (fd[`${battery}`][Bindex].charge_pak_pad_2_id = selectedBatteryRows[0]?.pad2?.pad_type_id)};
      {(battery == "SpareChargePakInformation" || battery == "ChargePakInformation") && (fd[`${battery}`][Bindex].pad_2_part_number = selectedBatteryRows[0]?.pad2?.pad_part_number)};
     

      // fd[`${battery}`][Bindex].charge_pak_uid =
      //   selectedBatteryRows[0]?.charge_pak_uid;
      // fd[`${battery}`][Bindex].pad_1_expiration =
      //   selectedBatteryRows[0]?.pad1?.pad_expiration;
      // fd[`${battery}`][Bindex].pad_1_lot =
      //   selectedBatteryRows[0]?.pad1?.pad_lot;
      // fd[`${battery}`][Bindex].pad_1_part =
      //   selectedBatteryRows[0]?.pad1?.pad_type_id;

      // fd[`${battery}`][Bindex].pad_2_expiration =
      //   selectedBatteryRows[0]?.pad2?.pad_expiration;
      // fd[`${battery}`][Bindex].pad_2_lot =
      //   selectedBatteryRows[0]?.pad2?.pad_lot;
      // fd[`${battery}`][Bindex].pad_2_part =
      //   selectedBatteryRows[0]?.pad2?.pad_type_id;
      fd[`${battery}`][Bindex].sbid = selectedBatteryRows[0]?.sbid;

      fd?.useSbidInventory.push(selectedBatteryRows[0]?.sbid);
      setNewFormData(fd);
    } else {
      const fd = { ...AedFormData };
      const pad = secName.replace(/'/g, "");
      fd[`${pad}`][Bindex].pad_udi = selectedPadRows[0]?.pad_udi;
      fd[`${pad}`][Bindex].pad_lot = selectedPadRows[0]?.pad_lot;
      fd[`${pad}`][Bindex].pad_expiration = selectedPadRows[0]?.pad_expiration;
      fd[`${pad}`][Bindex].pad_type_id = selectedPadRows[0]?.pad_type_id;
      fd[`${pad}`][Bindex].pad_part = selectedPadRows[0]?.pad_part;
      fd[`${pad}`][Bindex].pad_udi = selectedPadRows[0]?.pad_udi;
      fd[`${pad}`][Bindex].inventory_id = selectedPadRows[0]?.spid;
      fd[`${pad}`][Bindex].spid = selectedPadRows[0]?.spid;

      if (fd) {
        if (!Array.isArray(fd.useSpidInventory)) {
          fd.useSpidInventory = [];
        }

        if (selectedPadRows[0] && selectedPadRows[0].spid) {
          fd.useSpidInventory.push(selectedPadRows[0].spid);
        }
      }
      // fd?.useSpidInventory.push(selectedPadRows[0]?.spid);
      setNewFormData(fd);
    }
    setSelectedSite("");
    setSelectedBatteryRows([]);
    setSelectedPadRows([]);
    handleClose();
  };

  // console.log("AedFormData: ", AedFormData);

  const renderDatePadCell = (pad) => {
    const dateField = pad.pad_expiration ? "pad_expiration" : "";

    return (
      <div>
        {pad[dateField] && (
          <img
            src={
              pad.is_pediatric === 1 ? "/child-Vector.png" : "/people-Group.svg"
            }
            alt={
              pad.is_pediatric === 1 ? "child-Vector.png" : "people-Group.svg"
            }
            style={{
              width: pad.is_pediatric === 1 ? "20px" : undefined,
              height: pad.is_pediatric === 1 ? "32px" : undefined,
              marginRight: "5%",
            }}
          />
        )}
        {pad[dateField] ? (
          <span>{FormatDate(pad[dateField])}</span>
        ) : (
          <span>N/A</span>
        )}
      </div>
    );
  };

  if (padInventory) {
    padInventory?.sort(
      (a, b) => new Date(a.pad_expiration) - new Date(b.pad_expiration)
    );
  }
  if (batteryInventory) {
    batteryInventory.sort(
      (a, b) => new Date(a.render_date) - new Date(b.render_date)
    );
  }

  // console.log({ batteryInventory });
  // console.log({ padInventory });

  return (
    <>
      <Modal
        show={openSitelistModel}
        onHide={handleClose}
        // dialogClassName="modal-half"
        // aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        style={{ maxWidth: "900px", marginLeft: "22%", marginTop: "8%", 
          height: modalHeight,
          minHeight: "90vh", // Allow the modal to grow up to 90% of the viewport height
          overflowY: "auto", // Enable scrolling if content exceeds max height

        }}
        // id="outofservice-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center gap-3">
            <span>Standalone Inventory</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-container" id="outofservice-modal-content">
            <div className="my-modal-section" style={{ position: "relative" }}>
              <div className="row">
                <div className="col-md-12 mx-auto">
                  <Form
                    class=""
                    // onSubmit={(e)=> handleSubmit(e)}
                    // noValidate
                    // validated={validated}
                    // id="outofservice-form"
                  >
                    <div
                      className="bottom-border-blue py-4 px-2"
                      style={{
                        background: "#eee",
                      }}
                    >
                      <div className="row" style={{ maxWidth: "300px" }}>
                        <Form.Group className="col-md mb-3">
                          <Form.Label>Site </Form.Label>
                          {/* <select
                            className="form-control"
                            name="loaner_rental_serial"
                            value={selectSite}
                            onChange={(e) => setSelectedSite(e.target.value)}
                          >
                            <option value="" key={0}>
                              --Select One--
                            </option>
                            {siteList?.map((item, index) => (
                              <option key={index} value={item?.site_id}>
                                {item?.site_name}
                              </option>
                            ))}
                          </select> */}

                          <GlobalDropdown
                            dropDownName={"siteList"}
                            apiEndpoint={"acc/fetch-site-inventory"}
                            idKey={"site_id"}
                            valueKey={"site_name"}
                            parentKey={""}
                            onSelect={(e) => {
                              // handleInput(e);
                              setSelectedSite(e.target.value);
                            }}
                            shouldFetchOnOpen={true}
                            isCache={true}
                          />
                        </Form.Group>
                      </div>

                      <div className="upper-div">
                        {loading && selectSite == "" ? (
                          <>{/* <Loading /> */} </>
                        ) : (
                          <>
                            <div
                              style={{
                                position: "relative",
                                maxWidth: "750px",
                              }}
                            >
                              {selectSite != "" ?
                              // && (batteryInventory?.length > 0 || batteryInventory?.length == 0)) ? 
                              // {/* {(batteryInventory?.length > 0 || padInventory?.length > 0) && ( */}
                                <>
                                  <InventoryListTbl
                                    tableData={
                                      batteryInventory?.length > 0
                                        ? batteryInventory
                                        : padInventory?.length > 0 ? padInventory : []
                                    }
                                    type={batteryInventory?.length > 0 ? "battery" : "pad"}
                                    secName={secName}
                                    selectSite={selectSite}
                                    AedFormData={AedFormData}
                                    aed_brand_id={aed_brand_id}
                                    aed_model_id={aed_model_id}
                                    contact_id={contact_id}
                                    batteryInventory={batteryInventory}
                                    padInventory={padInventory}
                                    selectedBatteryRows={selectedBatteryRows}
                                    setSelectedBatteryRows={
                                      setSelectedBatteryRows
                                    }
                                    setSelectedPadRows={setSelectedPadRows}
                                    setSelectedRowIndex={setSelectedRowIndex}
                                    selectedPadRows={selectedPadRows}
                                    setModalHeight={setModalHeight}
                                    showLoading={loading}
                                  />
                                </>
                              // {/* )} */}
                              : ""}
                            </div>
                          </>
                        )}
                      </div>

                      <div className="row pb-2 py-2">
                        <div className="col-12 content-flex-right">
                          <button
                            className="btn btn-danger text-uppercase"
                            type="button"
                            onClick={() => handleClose()}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn-success text-uppercase ms-2"
                            type="submit"
                            onClick={(e) => handleSubmit(e)}
                            // disabled={loading}
                          >
                            {/* {loading ? "Loading..." : "Submit"} */}
                            submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer></Modal.Footer> */}
      </Modal>
    </>
  );
};

export default SiteListModel;
