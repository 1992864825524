import React from 'react';
import Plus from "../../../../common/img/Plus.svg";
import NewAedPadRow from './NewAedPadRow';

const PadsInformation = ({ 
  AedItem, 
  index, 
  HandleAddPadInfo, 
  handleResetBtn, 
  padList, 
  AedFormData, 
  setNewFormData, 
  handleRemoveSparePadiatricPadRow, 
  handleMoveSpareToMainPadRow, 
  handleCalendarChange, 
  handleChange, 
  handleRemovePadsRow, 
  handlePadSpareCrown, 
  onInputChangePads, 
  handleDNDButton, 
  inspection_by, 
  contact_id, 
  isInventory,
  padResetButton
}) => {
  let sectionPermissions = AedItem?.section_permissions;

  if (typeof sectionPermissions === "string") {
    try {
      sectionPermissions = JSON.parse(sectionPermissions);
    } catch (error) {
      console.error("Failed to parse section_permissions:", error);
    }
  }

  let hasPad = sectionPermissions.has_pad === 1;
  let hasPedpak = sectionPermissions.has_pedpak === 1;
  let hasPadpak = sectionPermissions.has_padpak === 1;
  let hasPedPad = sectionPermissions.has_ped_pad === 1;
  let hasPedKey = sectionPermissions.has_ped_key === 1;

  if (!(hasPad || hasPedpak || hasPadpak || hasPedPad || hasPedKey)) {
    return null;
  }

  return (
    <div className="col py-2">
      <div className="flex flex-row justify-between items-center">
        <h2 className="heading w-full">
          Pads Information
          {AedItem?.all_pads.some((item) => item.is_pediatric === 0) &&
          ((AedItem.aed_brand_id === 8 && AedItem.aed_model_id === 20) ||
            (AedItem.aed_brand_id === 6 && AedItem.aed_model_id === 18)) ? (
                <img
                src={"/assets/images/NOPED.svg"}
                alt="NoPed"
                width={20}
                style={{ margin: "0 0.5%" }}
              />
            ) : (
              ""
            )}
            <img
              src={Plus}
              onClick={() =>
                HandleAddPadInfo(index, AedItem.aed_id)
              }
              style={{ cursor: "pointer" }}
            />
        </h2>

        {((AedItem?.dni_array_list.length > 0 &&
          AedItem.dni_array_list.some((item) => item.hasOwnProperty("pid"))) ||
          AedItem.all_pads.slice(0, AedItem.default_all_pads.length).length !==
            AedItem.default_all_pads.length ||
          (AedItem?.deletedPads.length > 0 &&
            AedItem.deletedPads.some(
              (item) => !item.hasOwnProperty("inventory_id")
            )) ||
          padResetButton) && (
          <button
            className="rounded-[10%] bg-[#f24646] text-white h-[30px] w-[52px] border-none cursor-pointer text-[15px]"
            type="button"
            onClick={() => handleResetBtn(index, 2)}
          >
            Reset
          </button>
        )}
      </div>

      <table className="theme-table">
        <thead>
          <tr>
            <td className="border border-r-blue">Pad Type</td>
            <td className="border border-r-blue">Part #</td>
            <td className="border border-r-blue">Expiration Date</td>
            <td className="border border-r-blue">Pad Lot</td>
            <td className="border border-r-blue">Pad UDI</td>
            <td className="border border-r-blue" style={{ maxWidth: "70px", minWidth: "60px" }}>
              Actions
            </td>
          </tr>
        </thead>
        <tbody>
          {AedItem?.all_pads?.map((padInfo, Pindex) => (
            <NewAedPadRow
              key={Pindex}
              AedItem={AedItem}
              index={index}
              Pindex={Pindex}
              padInfo={padInfo}
              is_readonly={padInfo?.is_readonly || 0}
              padList={padList}
              AedFormData={AedFormData}
              setNewFormData={setNewFormData}
              handleRemoveSparePadiatricPadRow={handleRemoveSparePadiatricPadRow}
              handleMoveSpareToMainPadRow={handleMoveSpareToMainPadRow}
              handleCalendarChange={handleCalendarChange}
              handleChange={handleChange}
              handleRemovePadsRow={handleRemovePadsRow}
              handlePadSpareCrown={handlePadSpareCrown}
              onInputChangePads={onInputChangePads}
              aedPadTypeList={AedItem?.aedPadTypeList || []}
              handleDNDButton={handleDNDButton}
              handleResetBtn={handleResetBtn}
              inspection_by={inspection_by}
              contact_id={contact_id}
              isInventory={isInventory}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PadsInformation;