import React, { useEffect, useState } from 'react'
import { AED_IMG_BASE, CallGETAPI } from '../../../../common/services';
import AEDUnitsSkeleton from '../../../accounts/components/DetailsComponents/AedUnitsSkeleton';
import { Box } from '@mui/material';

const AedSiteUnits  = ({siteId}) => {
    const [aedUnits,setaedUnits] = useState([]);
    const [loading,setLoading] = useState(false);

    const fetchData = async()=>{
      setLoading(true)
      const result  = await CallGETAPI('/site/site-aed-unit/'+siteId);
      if(result?.data?.status){
        setaedUnits(result?.data?.data?.aed_units)
      }
      setLoading(false)
    }
    useEffect(()=>{
      fetchData()
    },[])
  return (
    <>
        {aedUnits && aedUnits?.length > 0 && (
          <>
          <Box className="text-left pt-3 ">
          <h4 className="heading">AED Units Owned</h4>
        </Box>

        <div>          
        {loading ? <AEDUnitsSkeleton/> :
          <div style={{ display: "flex", flexWrap: "wrap", gap: 0 }}>
            {aedUnits.length > 0 ? (
              [
                ...Array(Math.ceil(aedUnits.length / 6) * 6),
              ].map((_, i) => {
                const aedIndex = i < aedUnits.length ? i : null;
                const aed =
                  aedIndex !== null ? aedUnits[aedIndex] : null;
                const isLastRow =
                  Math.floor(i / 6) ===
                  Math.ceil(aedUnits.length / 6) - 1;
                return (
                  <div
                    key={i}
                    style={{
                      flex: "0 0 16.666%",
                      maxWidth: "17.666%",
                      marginBottom: 0,
                      padding: 0,
                    }}
                    className={`border-l-blue border-t-blue border-r-blue ${
                      isLastRow ? "border-b-blue" : ""
                    }`}
                  >
                    <div
                      style={{
                        background: "#D9D9D9",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: 55,
                      }}
                    >
                      <p style={{ margin: 0 }}>{aed?.brandName}</p>
                      <p style={{ margin: 0 }}>{aed?.modalName}</p>
                    </div>

                    {aed && (
                      <>
                        <div
                          style={{
                            padding: "8% 0",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={AED_IMG_BASE + aed?.modalImage}
                            alt={aed?.modalName}
                            style={{
                              width: 130,
                              height: 130,
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      </>
                    )}

                    {aed && (
                      <>
                        <div
                          style={{
                            padding: "1% 0",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <p style={{ fontSize: 18 }}>Own: {aed?.own}</p>
                        </div>
                      </>
                    )}
                  </div>
                );
              })
            ) : (
              <div
                style={{
                  width: "100%",
                  marginBottom: 0,
                  paddingTop: "12px",
                  textAlign: "center",
                }}
                className={`border-l-blue border-t-blue border-r-blue border-b-blue`}
              >
                <p>No AED units available</p>
              </div>
            )}
          </div>
          }
        </div>
            {/* <h4 className="heading"> AED Units Owned</h4>
            <table className="border-blue border-b-blue border-r-blue border-l-blue">
              <tbody>
                <>
                  {aedUnits
                    .reduce(
                      (rows, key, index) =>
                        (index % 6 == 0
                          ? rows.push([key])
                          : rows[rows.length - 1].push(key)) && rows,
                      []
                    )
                    .map((row, rowIndex) => (
                      <React.Fragment key={rowIndex}>
                        <tr className="no-border">
                          {row.map((aed, index) => (
                            <th
                              scope="col"
                              width="20%"
                              className="text-center py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                              key={index}
                            >
                              {aed?.brandName} {aed?.modalName}
                            </th>
                          ))}
                          {row.length < 6 &&
                            Array.from({ length: 6 - row.length }).map(
                              (_, index) => (
                                <th
                                  key={`empty_${index}`}
                                  className="text-center py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                                ></th>
                              )
                            )}
                        </tr>
                        <tr className="no-border">
                          {row.map((aed, index) => (
                            <td
                              key={index}
                              className=" py-1 px-2 border-r-blue"
                            >
                              <div className="text-center">
                                <img
                                  src={AED_IMG_BASE + aed?.modalImage}
                                  alt={aed?.modalName}
                                  style={{
                                    maxWidth: "100px",
                                    marginTop: "20px",
                                  }}
                                />
                                <p className="mt-3">Own: {aed?.own}</p>
                              </div>
                            </td>
                          ))}
                          {row.length < 6 &&
                            Array.from({ length: 6 - row.length }).map(
                              (_, index) => (
                                <td
                                  key={`empty_${index}`}
                                  className=" py-1 px-2 border-r-blue"
                                ></td>
                              )
                            )}
                        </tr>
                      </React.Fragment>
                    ))}
                </>
              </tbody>
            </table> */}
          </>
        )}
    </>
  )
}

export default AedSiteUnits