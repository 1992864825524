import React, { useState, useEffect } from "react";
import {
  Form,
  Button as BButton,
  Button as BsButton,
} from "react-bootstrap";
import Select from "react-select";
import Contacts from "../../../common/img/Contacts.svg";
import {
  AssignedRepList,
  AssignedSiteContactList,
  ContactList,
  DecryptToken,
  FetchDropDowns,
  GetCountries,
  ModalAccSiteReps,
  SiteContactList,
  SiteContactRepList,
  SiteDropDowns,
  SiteRepsDropDown,
} from "../../../common/helper/BasicFn";
import { CallDetails, CallGETAPI, CallPOSTAPI } from "../../../common/services";
import { toast } from "react-toastify";
import { FormControlLabel, Radio } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomToggleButton from "../../../common/components/CustomToggleButton";
import StateField from "../../../common/components/StateField";
import { prepareOptions, validatePhone } from "../../../common/helper";
import Loading from "../../../common/components/Loader/Loading";
import AdminContactModel from "../../../common/models/AdminContactModel";
import SubHeadingOther from "../components/common/SubHeadingOther";
import GlobalDropdown from "../../../common/components/custom-ui/GlobalDropdown";

const AdminSiteEdit = () => {
  const { accountId, siteId } = useParams();
  const location = useLocation();
  const { editSiteAdmin } = location.state || {}; 
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(true)
  const [techniciansLoading, setTechnicianLoading] = useState(false);
  const [backupLoading, setBackupTechnicianLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(true);
  const [adminContactModelData, setAdminContactModelData] = useState([]);
  const [isDefaultValueShown, setIsDefaultValueShown] = useState(true);
  const [primaryTechniciansField ,setTechniciansPrimaryField] = useState([]);
  const [backupTechniciansField,setTechniciansBackupField] = useState([]);
  const user = DecryptToken();
  const [resetState, setResetState] = useState(false);
  const [valueEmpty, setValueEmpty] = useState({
    site_state : "",
  });
  // console.log({editSiteAdmin})
  const [formData, setFormData] = useState({
    // Site Info
    account_site_state_abbreviation: "",
    account_site_country: "",
    account_site_address1: "",
    account_site_address2: "",
    building_name: "",
    account_site_city: "",
    account_site_state: "",
    account_site_zipcode: "",
    generate_name_toggle: "",
    account_site_name: "",
    account_site_phone: "",
    account_site_info_phone_ext: "",
    account_site_status_id: 1,
    invoice_asap: 0,
    call_ahead: 0,
    out_of_area: 0,
    security_clearance: 0,
    requires_escort: 0,
    site_poc: 0,
    site_hours_toggle: 0,
    same_billing_address: 1,
    billing_contact: 0,
    same_shipping_address: 1,
    shipping_contact: 0,

    // Site contact
    site_contact_salutation: "",
    site_contact_firstname: "",
    site_contact_middlename: "",
    site_contact_lastname: "",
    site_contact_suffix: "",
    site_contact_title: "",
    site_contact_department: "",
    site_contact_status: "",
    site_contact_phone: [],
    site_contact_email: [],

    // Billing contact
    billing_contact_salutation: "",
    billing_contact_firstname: "",
    billing_contact_middlename: "",
    billing_contact_lastname: "",
    billing_contact_suffix: "",
    billing_contact_title: "",          
    billing_contact_department: "",
    billing_contact_status: "",
    billing_contact_phone: [],
    billing_contact_email: [],

    // Billing Address
    account_billing_info_address1: "",
    account_billing_info_address2: "",
    account_billing_info_city: "",
    account_billing_info_state: "",
    account_billing_info_country: "",
    account_billing_info_zipcode: "",

    // Shipping contact
    shipping_contact_salutation: "",
    shipping_contact_firstname: "",
    shipping_contact_middlename: "",
    shipping_contact_lastname: "",
    shipping_contact_suffix: "",
    shipping_contact_title: "",
    shipping_contact_department: "",
    shipping_contact_status: "",
    shipping_contact_phone: [],
    shipping_contact_email: [],

    // Shipping Address
    account_shipping_info_address1: "",
    account_shipping_info_address2: "",
    account_shipping_info_city: "",
    account_shipping_info_state: "",
    account_shipping_info_country: "",
    account_shipping_info_zipcode: "",

    // Site Hours
    sundayopen: "",
    mondayopen: "",
    tuesdayopen: "",
    wednesdayopen: "",
    thursdayopen: "",
    fridayopen: "",
    saturdayopen: "",
    sundayclose: "",
    mondayclose: "",
    tuesdayclose: "",
    wednesdayclose: "",
    thursdayclose: "",
    fridayclose: "",
    saturdayclose: "",
    technicians: {
      primary: 0,
      backup: 0
    },
  });
  const [countryList, setCountryList] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState({});
  const [allDropDowns, setAllDropDowns] = useState([]);
  const [AccReps, setAccReps] = useState([]);
  const [AccRepsList, setAccRepsList] = useState([]);
  const [repsData, setRepsData] = useState([]);
  const [contactShowModel, setContactShowModel] = useState(false);
  const [SelectContact, setSelectContact] = useState([]);
  const [contactRepsList, setContactRepsList] = useState([]);
  const [contactReps, setContactReps] = useState([]);
  const [techniciansData, setTechniciansData] = useState([]);

  const [open, setOpen] = useState({
    sundayopen:    0,
    mondayopen:    0,
    tuesdayopen:   0,
    wednesdayopen: 0,
    thursdayopen:  0,
    fridayopen:    0,
    saturdayopen:  0,
  });

  const handleRadioChange = (openKey, closeopenKey) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [openKey]: prevOpen[openKey] === 0 ? 1 : 0,
    }));

    setFormData((prevOpen) => ({
      ...prevOpen,
      [openKey]: prevOpen[openKey] === 'Closed' ? '' : 'Closed',
      [closeopenKey]: prevOpen[closeopenKey] === 'Closed' ? '' : 'Closed',
    }));

  };

  const siteHoursobj = {
    sundayopen: "",
    mondayopen: "",
    tuesdayopen: "",
    wednesdayopen: "",
    thrusdayopen: "",
    fridayopen: "",
    saturdayopen: "",
    sundayclose: "",
    mondayclose: "",
    tuesdayclose: "",
    wednesdayclose: "",
    thrusdayclose: "",
    fridayclose: "",
    saturdayclose: "",
  }

  
  function compareSiteHours(siteHoursObj, receivedSiteHoursObj) {
    for (const key in siteHoursObj) {
        if (siteHoursObj?.hasOwnProperty(key)) {
            if (receivedSiteHoursObj?.hasOwnProperty(key) && siteHoursObj[key] !== receivedSiteHoursObj[key]) {
                return false;
            }
        }
    }
    return true;
}

const getData = async () => {
  let data = await AssignedRepList(siteId);
  if (data?.status) {
    if (!data.data.status) {
      let response = await CallDetails(accountId);
      setRepsData(response?.data?.data?.accountReps);
      return response?.data?.data?.accountReps;
    } else {
      setRepsData(data?.data?.siteRepstList);
      return data?.data?.siteRepstList;
    }
  }
  return "";
};


const fetchCountry = async () => {
    const countries = await GetCountries();
  if (countries?.status) {
    let countriesData = await prepareOptions(countries?.data?.country, "id", "country_name");
    setCountryList(countriesData);
}
}


const fetchTechnicianData = async () => {
  setTechnicianLoading(true);
  const result = await CallGETAPI("site/site-reps-dropdown");
  const AccreptList = result?.data?.data?.accountReps;
  if (AccreptList) {
    let RepList = [];
    for (let index = 0; index < AccreptList.length; index++) {
      const RepElement = AccreptList[index];
      let obj = { ...RepElement };
      obj.is_selected = false;
      obj.primary = { id: "", val: "" };
      obj.backup = { id: "", val: "" };
      RepList.push(obj);
    }
    setIsDefaultValueShown(false)
    setAccRepsList(RepList);   // AccRepsList set karna jaruri hai to yaha kar sakte hain
    setTechniciansData(RepList);  // Ye techniciansData ko set karega jo dropdown me show hoga
  }
  setTechnicianLoading(false);
};

useEffect(() => {
  fetchTechnicianData();
  fetchBackupTechnicianData();
},[])

const fetchBackupTechnicianData = async () => {
  setBackupTechnicianLoading(true);
  const result = await CallGETAPI("site/site-reps-dropdown");
  const AccreptList = result?.data?.data?.accountReps;
  if (AccreptList) {
    let RepList = [];
    for (let index = 0; index < AccreptList.length; index++) {
      const RepElement = AccreptList[index];
      let obj = { ...RepElement };
      obj.is_selected = false;
      obj.primary = { id: "", val: "" };
      obj.backup = { id: "", val: "" };
      RepList.push(obj);
    }
    setIsDefaultValueShown(false)
    setAccRepsList(RepList);   // AccRepsList set karna jaruri hai to yaha kar sakte hain
    setTechniciansData(RepList);  // Ye techniciansData ko set karega jo dropdown me show hoga
  }
  setBackupTechnicianLoading(false);
};


const fetchOnload = async() => {

  const AccResult = editSiteAdmin?.coordinatorInfo;
  // const AccreptList = await SiteRepsDropDown();

  const techniciansData = AccResult && AccResult.find(item => item.title === "Technicians");
console.group({techniciansData})
// Filter the array to get only those objects which have the "primary" key
const primaryTechnicians = techniciansData?.arr.filter(item => item.primary);
const backupTechnician = techniciansData?.arr.filter(item => item.backup);

  setTechniciansPrimaryField(primaryTechnicians);
  setTechniciansBackupField(backupTechnician);
    setFormData((prevFormData) => ({
      ...prevFormData,
      technicians: {
        primary: primaryTechnicians && primaryTechnicians[0]?.contact_id,
        backup: backupTechnician && backupTechnician[0]?.contact_id,
      },
    }));
  // const repsListData =   await getData();

//   if (AccreptList) {
//     let RepList = [];
//     for (let index = 0; index < AccreptList.length; index++) {
//       const RepElement = AccreptList[index];
//       let obj = { ...RepElement };
//       obj.is_selected = false;
//       obj.primary = { id: "", val: "" };
//       obj.backup = { id: "", val: "" };
//       RepList.push(obj);
//     }
//     setAccRepsList(RepList);
//     setTechniciansData(RepList);
//     const repsListData = RepList;
//     console.log({repsListData})

//     let CheckMarkList = [];
//     if (AccResult.length) {
//       let List = AccResult.map((AccEle) => {
//         let obj = { ...AccEle, backup: { id: "", val: "" }, primary: { id: "", val: "" }, contact_id: "" };
//         let findPPrimaryId = repsListData.find(e => e.position_id === AccEle.position_id && e.is_primary !== 0);
//         let findPBackupId = repsListData.find(e => e.position_id === AccEle.position_id && e.is_backup !== 0);

//         if (findPPrimaryId) {
//           let GetVal = AccreptList.find(e => e?.account_main_contact_id === findPPrimaryId.contact_id && findPPrimaryId.is_primary !== 0);
//           CheckMarkList.push(GetVal?.account_main_contact_id);
//           obj.primary = { e_id: findPPrimaryId.id, id: findPPrimaryId.contact_id, val: `${GetVal?.account_main_contact_firstname} ${GetVal?.account_main_contact_lastname}` };
//         }

//         if (findPBackupId) {
//           let GetVal = AccreptList.find(e => e?.account_main_contact_id === findPBackupId.contact_id && findPBackupId.is_backup !== 0);
//           CheckMarkList.push(GetVal?.account_main_contact_id);
//           obj.backup = { e_id: findPBackupId.id, id: findPBackupId.contact_id, val: `${GetVal?.account_main_contact_firstname} ${GetVal?.account_main_contact_lastname}` };
//         }

//         return obj;
//       });
//       console.log({List})
//       setAccReps(List);

  setSelectedCountry((old) => ({
          ...old,
          account_site_country: { label: editSiteAdmin?.siteInfo?.account_site_country, value: editSiteAdmin?.siteInfo?.account_site_country_id },
          // account_site_state: { label: editSiteAdmin?.siteInfo?.account_site_state, value: editSiteAdmin?.siteInfo?.account_site_state_id },
          account_billing_info_country: { label: editSiteAdmin?.billingInfo?.account_billing_info_country, value: editSiteAdmin?.billingInfo?.account_billing_info_country_id },
          account_shipping_info_country: { label: editSiteAdmin?.shippingInfo?.account_shipping_info_country, value: editSiteAdmin?.shippingInfo?.account_shipping_info_country_id }
        }));

        setFormData((old) => ({
                ...old,
                account_site_country: editSiteAdmin?.siteInfo?.account_site_country_id,
                account_billing_info_country: editSiteAdmin?.billingInfo?.account_billing_info_country_id,
                account_shipping_info_country: editSiteAdmin?.shippingInfo?.account_shipping_info_country_id
              }));

setLoading(false);
}



const fetchContactOnload = async () => {
  try {
    setApiLoading(true);

    const [
      // repsListDataResult,
      // AllDResultResult,
      // AccResultResult,
      // AccreptListResult,
      AccountContactListResult,
      AccountContectRepListResult,
      AssignContectRepListDataResult,
      // countriesResult
    ] = await Promise.allSettled([
      // getData(),
      // SiteDropDowns(),
      // ModalAccSiteReps(),
      // SiteRepsDropDown(),
      SiteContactList(accountId),
      SiteContactRepList(),
      AssignedSiteContactList(siteId),
      // GetCountries()
    ]);
   
    // const repsListData = repsListDataResult.status === 'fulfilled' ? repsListDataResult.value : [];
    // const AllDResult = AllDResultResult.status === 'fulfilled' ? AllDResultResult.value : null;
    // const AccResult = AccResultResult.status === 'fulfilled' ? AccResultResult.value : [];
    // const AccreptList = AccreptListResult.status === 'fulfilled' ? AccreptListResult.value : [];
    const AccountContactList = AccountContactListResult.status === 'fulfilled' ? AccountContactListResult.value : [];
    const AccountContectRepList = AccountContectRepListResult.status === 'fulfilled' ? AccountContectRepListResult.value : [];
    const AssignContectRepListData = AssignContectRepListDataResult.status === 'fulfilled' ? AssignContectRepListDataResult.value : [];
    // const countries = countriesResult.status === 'fulfilled' ? countriesResult?.value : null;
    
 
    console.log({AccountContactList})
    console.log({AccountContectRepList})
    console.log({AssignContectRepListData})
 
    // if (AllDResult) {
    //   setAllDropDowns(AllDResult);
    // }

    let CheckMarkList = [];
    // if (AccResult.length) {
    //   let List = AccResult.map((AccEle) => {
    //     let obj = { ...AccEle, backup: { id: "", val: "" }, primary: { id: "", val: "" }, contact_id: "" };
    //     let findPPrimaryId = repsListData.find(e => e.position_id === AccEle.position_id && e.is_primary !== 0);
    //     let findPBackupId = repsListData.find(e => e.position_id === AccEle.position_id && e.is_backup !== 0);

    //     if (findPPrimaryId) {
    //       let GetVal = AccreptList.find(e => e?.account_main_contact_id === findPPrimaryId.contact_id && findPPrimaryId.is_primary !== 0);
    //       CheckMarkList.push(GetVal?.account_main_contact_id);
    //       obj.primary = { e_id: findPPrimaryId.id, id: findPPrimaryId.contact_id, val: `${GetVal?.account_main_contact_firstname} ${GetVal?.account_main_contact_lastname}` };
    //     }

    //     if (findPBackupId) {
    //       let GetVal = AccreptList.find(e => e?.account_main_contact_id === findPBackupId.contact_id && findPBackupId.is_backup !== 0);
    //       CheckMarkList.push(GetVal?.account_main_contact_id);
    //       obj.backup = { e_id: findPBackupId.id, id: findPBackupId.contact_id, val: `${GetVal?.account_main_contact_firstname} ${GetVal?.account_main_contact_lastname}` };
    //     }

    //     return obj;
    //   });

    //   setAccReps(List);
    //   setFormData((prevFormData) => ({
    //     ...prevFormData,
    //     technicians: {
    //       primary: List[0]?.primary?.id,
    //       backup: List[0]?.backup?.id,
    //     },
    //   }));
    // }

    // if (AccreptList.length) {
    //   let RepList = AccreptList.map((RepElement) => ({
    //     ...RepElement,
    //     is_selected: false,
    //     primary: { id: "", val: "" },
    //     backup: { id: "", val: "" }
    //   }));

    //   setAccRepsList(RepList);
    //   setTechniciansData(RepList);
    // }

    let CheckContactList = [];
    if (AccountContectRepList.length) {
      let RepList = AccountContectRepList.map((RepElement) => {
        let obj = { ...RepElement, id: "" };
        let findPPrimaryId = AssignContectRepListData ? AssignContectRepListData.find(e => e.position_id === RepElement.position_id && e.is_primary !== 0) : "";
        let findPBackupId = AssignContectRepListData ? AssignContectRepListData.find(e => e.position_id === RepElement.position_id && e.is_backup !== 0) : "";

        obj.backup = { id: "", val: "", permissions: [] };
        obj.primary = { id: "", val: "", permissions: [] };
        obj.contact_id = obj.contact_id;

        const onlyUnique = (value, index, self) => self.indexOf(value) === index;
        
        if (findPPrimaryId) {
          obj.id = findPPrimaryId?.id;
          let GetVal = AccountContactList?.find(e => e.contact_id === findPPrimaryId.contact_id && findPPrimaryId.is_primary !== 0);
          if (GetVal) {
            CheckContactList.push(GetVal.contact_id);
          }
          let parr = findPPrimaryId?.permissions ? findPPrimaryId?.permissions.split(",") : [];
          obj.primary = { e_id: findPPrimaryId.id, id: findPPrimaryId.contact_id, val: GetVal?.contact_name, permissions: parr.filter(onlyUnique) };
        }

        if (findPBackupId) {
          obj.id = findPBackupId?.id;
          let GetVal = AccountContactList?.find(e => e.contact_id === findPBackupId.contact_id && findPBackupId.is_backup !== 0);
          CheckContactList.push(GetVal?.contact_id);
          let parr = findPBackupId.permissions ? findPBackupId.permissions.split(",") : [];
          obj.backup = { e_id: findPBackupId.id, id: findPBackupId.contact_id, val: GetVal?.contact_name, permissions: parr.filter(onlyUnique) };
        }

        return obj;
      });
      setContactRepsList(RepList);
    }

    // if (AccountContactList?.length) {
    //   let List = [];
    //       for (let index = 0; index < AccountContactList?.length; index++) {
    //         const AccEle = AccountContactList[index];
    //         let obj = { ...AccEle };
    //         let FindData = CheckContactList.find((e) => e === obj.contact_id);
    
    //         obj.backup = { id: "", val: "", permissions: [] };
    //         obj.primary = { id: "", val: "", permissions: [] };
    //         obj.contact_id = obj.contact_id;
    
    //         if (FindData) {
    //           obj.is_selected = false;
    //         } else {
    //           obj.is_selected = false;
    //         }
    //         List.push(obj);
    //       }
    //       const activeList = List.filter(item => item.status === 1)
    //       setContactReps(activeList);
    // }

    if (AccountContactList) {
      let List = [];
      for (let index = 0; index < AccountContactList.length; index++) {
        const AccEle = AccountContactList[index];
        let obj = { ...AccEle };
        let FindData = CheckContactList.find((e) => e === obj.contact_id);

        obj.backup = { id: "", val: "", permissions: [] };
        obj.primary = { id: "", val: "", permissions: [] };
        obj.contact_id = obj.contact_id;

        if (FindData) {
          obj.is_selected = false;
        } else {
          obj.is_selected = false;
        }
        List.push(obj);
      }
      // const activeList = List.filter((item) => item.status === 1);
      setContactReps(List);
    }

    // console.log({countries})
    // if (countries?.status) {
    //   let countriesData = prepareOptions(countries?.data?.country, "id", "country_name");
    //   setCountryList(countriesData);
    //   console.log({countriesData})
    //   const defaultCountry = countriesData[230];
    //   console.log({defaultCountry})
    //   setSelectedCountry((old) => ({
    //     ...old,
    //     account_site_country: { label: defaultCountry.label, value: defaultCountry.value },
    //     account_billing_info_country: { label: defaultCountry.label, value: defaultCountry.value },
    //     account_shipping_info_country: { label: defaultCountry.label, value: defaultCountry.value }
    //   }));
    //   setFormData((old) => ({
    //     ...old,
    //     account_site_country: defaultCountry.value,
    //     account_billing_info_country: defaultCountry.value,
    //     account_shipping_info_country: defaultCountry.value
    //   }));
    // }

  } catch (error) {
    console.error("An error occurred while fetching data", error);
  } finally {
    setApiLoading(false);
  }
};

  useEffect(() => {
    fetchOnload();
  },[editSiteAdmin])

  // handle select change
  const handleSelectChange = (data, key) => {
    setSelectedCountry((old) => ({
      ...old,
      [key]: {
        label: data.label,
        value: data.value,
      },
    }));
    setFormData((old) => ({ ...old, [key]: data.value }));

    setResetState(true);

    // if(key == "account_site_country") {
    //   setFormData((old) => ({ ...old,
    //      ["account_site_state"]: "" }))
    // }
    // console.log({formData})
  };

  const handleInputChange = (e) => {
    if (
      e.target.name == "account_site_phone" ||
      e.target.name == "account_billing_info_billing_phone" ||
      e.target.name == "account_shipping_info_shipping_phone"
    ) {
      e.target.value = e.target.value.replace(/[^0-9 ]/g, "").trim();
      e.target.value = e.target.value.slice(0, 10);

      const phoneValidate = validatePhone(e.target.value);
      setPhoneValidations((old) => ({
        ...old,
        [e.target.name]: phoneValidate ? false : true,
      }));
    }

    if (e.target.type == "checkbox") {
      setFormData((old) => ({ ...old, [e.target.name]: e.target.checked }));
    } else {
      setFormData((old) => ({ ...old, [e.target.name]: e.target.value }));
    }
  };

  const setUpSiteName = (formData) => {
    if (formData?.generate_name_toggle) {
      let siteName =
        formData?.account_site_state_abbreviation +
        " " +
        formData?.account_site_city +
        " - " +
        formData?.account_site_address1 +
        (formData?.building_name ? " / " + formData?.building_name : "");
      setFormData((old) => ({ ...old, ["temp_account_site_name"]: siteName }));
    } else {
      setFormData((old) => ({ ...old, ["temp_account_site_name"]: "" }));
    }
  };

  useEffect(() => {
    setUpSiteName(formData);
  }, [formData?.generate_name_toggle]);

  const timeIcon = () => {
    return <img src="/assets/images/icon-time.png" alt="time-icon" />;
  };

  const handleCheckBox = (e) => {
    if (e.target.type == "checkbox") {
      setFormData((old) => ({
        ...old,
        [e.target.name]: e.target.checked ? 1 : 0,
      }));
    } else if (e.target.type == "button") {
      setFormData((old) => ({
        ...old,
        [e.target.name]: e.target.value == 0 ? 1 : 0,
      }));
    } else {
      setFormData((old) => ({ ...old, [e.target.name]: e.target.value }));
    }
  };

  const handleTimeChanges = (event, fieldName) => {
    const newTimeValue = event.target.value;
    setFormData({
      ...formData,
      [fieldName]: newTimeValue,
    });
  };


  // Site Poc

  const [altTrainerForm1Site, setAltTrainerForm1Site] = useState([
    {
      phone_number: "",
      ext: "",
      phone_type_id: 0,
      main: 0,
    },
  ]);

  const [altTrainerForm1Billing, setAltTrainerForm1Billing] = useState([
    {
      phone_number: "",
      ext: "",
      phone_type_id: 0,
      main: 0,
    },
  ]);

  const [altTrainerForm1Shipping, setAltTrainerForm1Shipping] = useState([
    {
      phone_number: "",
      ext: "",
      phone_type_id: 0,
      main: 0,
    },
  ]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      site_contact_phone: altTrainerForm1Site,
    }));
  }, [altTrainerForm1Site]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      billing_contact_phone: altTrainerForm1Billing,
    }));
  }, [altTrainerForm1Billing]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      shipping_contact_phone: altTrainerForm1Shipping,
    }));
  }, [altTrainerForm1Shipping]);

  const increaseAlternative1 = (section) => {
    let arr;
    // let arr = [...altTrainerForm1];
    if (section === "site") {
      arr = [...altTrainerForm1Site];
    }
    if (section === "billing") {
      arr = [...altTrainerForm1Billing];
    }
    if (section === "shipping") {
      arr = [...altTrainerForm1Shipping];
    }
    let obj = {
      phone_number: "",
      ext: "",
      phone_type_id: "",
      main: "",
    };
    arr.push(obj);
    // setAltTrainerForm1(arr);
    if (section === "site") {
      setAltTrainerForm1Site(arr);
    }
    if (section === "billing") {
      setAltTrainerForm1Billing(arr);
    }
    if (section === "shipping") {
      setAltTrainerForm1Shipping(arr);
    }
  };

  const decreaseAlternative1 = (section) => {
    let arr;
    // let arr = [...altTrainerForm1];
    if (section === "site") {
      arr = [...altTrainerForm1Site];
    }
    if (section === "billing") {
      arr = [...altTrainerForm1Billing];
    }
    if (section === "shipping") {
      arr = [...altTrainerForm1Shipping];
    }

    if (section === "site" && altTrainerForm1Site.length > 1) {
      arr.pop();
      setAltTrainerForm1Site(arr);
    }
    if (section === "billing" && altTrainerForm1Billing.length > 1) {
      arr.pop();
      setAltTrainerForm1Billing(arr);
    }
    if (section === "shipping" && altTrainerForm1Shipping.length > 1) {
      arr.pop();
      setAltTrainerForm1Shipping(arr);
    }
  };

  const [multiEmailFormCountSite, setMultiEmailFormCountSite] = useState([
    {
      email: "",
      email_type: "0",
      main: 0,
    },
  ]);

  const [multiEmailFormCountBilling, setMultiEmailFormCountBilling] = useState([
    {
      email: "",
      email_type: "0",
      main: 0,
    },
  ]);

  const [multiEmailFormCountShipping, setMultiEmailFormCountShipping] =
    useState([
      {
        email: "",
        email_type: "0",
        main: 0,
      },
    ]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      site_contact_email: multiEmailFormCountSite,
    }));
  }, [multiEmailFormCountSite]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      billing_contact_email: multiEmailFormCountBilling,
    }));
  }, [multiEmailFormCountBilling]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      shipping_contact_email: multiEmailFormCountShipping,
    }));
  }, [multiEmailFormCountShipping]);

  // for phone validations
  const [phoneValidations, setPhoneValidations] = useState({
    account_site_phone: false,
    account_billing_info_billing_phone: false,
    account_shipping_info_shipping_phone: false,
  });

  const [subFormPhoneValidated, setSubFormPhoneValidated] =
    React.useState(false);

  const [traininglocation, setTraininglocation] = useState([
    {
      account_alternate_traning_location_company_name: "",
      alternative_phone: "",
      alternative_ext: "",
      account_alternate_traning_location_address1: "",
      account_alternate_traning_location_address2: "",
      account_alternate_traning_location_city: "",
      account_alternate_traning_location_state: "",
      account_alternate_traning_location_country: 231,
      account_alternate_traning_location_zipcode: "",
      account_main_contact_status: 0,
    },
  ]);

  const handleSubmit = async (e) => {
   e.preventDefault();
  //  setLoading(true);

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    let techRepsValueArray = [];
    const AccResult = editSiteAdmin?.coordinatorInfo;
    const techniciansData = AccResult?.find(item => item.title === "Technicians");
      console.group({techniciansData})

    if(formData?.technicians?.primary || !formData?.technicians?.primary){
      const primaryTechnicians = techniciansData?.arr.filter(item => item.primary);

      techRepsValueArray.push({
        id: primaryTechnicians && primaryTechnicians[0]?.id || "",
        position_id: 13, 
        contact_id: isNaN(Number(formData?.technicians?.primary)) ? "" : formData?.technicians?.primary,
        is_primary: 1, is_backup: 0, set_order: 1})
    }
    if(formData?.technicians?.backup || !formData?.technicians?.backup){
      const backupTechnician = techniciansData?.arr.filter(item => item.backup);
      
      // techRepsValueArray.push({id: AccReps[0]?.backup?.id || "", position_id: 13, contact_id: formData?.technicians?.backup === 0 ? "" : formData?.technicians?.backup, is_primary: 0, is_backup: 1, set_order: 2})
      techRepsValueArray.push({
         id: backupTechnician && backupTechnician[0]?.id || "",
         position_id: 13, 
         contact_id: isNaN(Number(formData?.technicians?.backup)) ? "" : formData?.technicians?.backup, 
         is_primary: 0, 
         is_backup: 1,
         set_order: 2})
    }
    console.log("SiteCountry", formData?.account_site_state)
    console.log({valueEmpty})
    const payload = {
      account_id: accountId,
      site_id: siteId,
      site_details: {
        account_site_info_id: editSiteAdmin?.siteInfo?.account_site_info_id,
        account_site_name: formData?.account_site_name,
        account_site_phone: formData?.account_site_phone,
        account_site_phone_ext: formData?.account_site_info_phone_ext,
        account_site_call_ahead: formData?.call_ahead,
        account_site_status_id: formData?.account_site_status_id,
        account_site_address1: formData?.account_site_address1,
        account_site_address2: formData?.account_site_address2,
        building_name: formData?.building_name,
        account_site_city: formData?.account_site_city,
        // account_site_state: valueEmpty === "" ? "" :formData?.account_site_state,
        account_site_state: formData?.account_site_state,
        account_site_country: formData?.account_site_country,
        account_site_zipcode: formData?.account_site_zipcode,
        generate_name_toggle: formData?.generate_name_toggle,
        invoice_asap: formData?.invoice_asap,
        call_ahead: formData?.call_ahead,
        out_of_area: formData?.out_of_area,
        security_clearance: formData?.security_clearance,
        requires_escort: formData?.requires_escort,
        site_poc: formData?.site_poc,
        site_hours_toggle: formData?.site_hours_toggle,
        same_billing_address: formData?.same_billing_address,
        same_shipping_address: formData?.same_shipping_address,
        billing_contact: formData?.billing_contact,
        shipping_contact: formData?.shipping_contact,
      },
    
      siteHoursData: {
        site_hour_id: editSiteAdmin?.siteHoursData?.site_hour_id,
        sundayopen: open?.sundayopen === 0 ? formData?.sundayopen : "Closed",
        mondayopen: open?.mondayopen === 0 ? formData?.mondayopen : "Closed",
        tuesdayopen: open?.tuesdayopen === 0 ? formData?.tuesdayopen : "Closed",
        wednesdayopen:
          open?.wednesdayopen === 0 ? formData?.wednesdayopen : "Closed",
        thrusdayopen:
          open?.thursdayopen === 0 ? formData?.thursdayopen : "Closed",
        fridayopen: open?.fridayopen === 0 ? formData?.fridayopen : "Closed",
        saturdayopen:
          open?.saturdayopen === 0 ? formData?.saturdayopen : "Closed",
        sundayclosed: open?.sundayopen === 0 ? formData?.sundayclose : "Closed",
        mondayclosed: open?.mondayopen === 0 ? formData?.mondayclose : "Closed",
        tuesdayclosed:
          open?.tuesdayopen === 0 ? formData?.tuesdayclose : "Closed",
        wednesdayclosed:
          open?.wednesdayopen === 0 ? formData?.wednesdayclose : "Closed",
        thrusdayclosed:
          open?.thursdayopen === 0 ? formData?.thursdayclose : "Closed",
        fridayclosed: open?.fridayopen === 0 ? formData?.fridayclose : "Closed",
        saturdayclosed:
          open?.saturdayopen === 0 ? formData?.saturdayclose : "Closed",
      },

      billing_details: {
        account_billing_info_id: editSiteAdmin?.billingInfo?.account_billing_info_id,
        account_billing_info_address1: formData?.account_billing_info_address1,
        account_billing_info_address2: formData?.account_billing_info_address2,
        account_billing_info_city: formData?.account_billing_info_city,
        account_billing_info_state: formData?.account_billing_info_state,
        account_billing_info_country: formData?.account_billing_info_country,
        account_billing_info_zipcode: formData?.account_billing_info_zipcode,
      },

      shipping_details: {
        account_shipping_info_id: editSiteAdmin?.shippingInfo?.account_shipping_info_id,
        account_shipping_info_address1:
          formData?.account_shipping_info_address1,
        account_shipping_info_address2:
          formData?.account_shipping_info_address2,
        account_shipping_info_city: formData?.account_shipping_info_city,
        account_shipping_info_state: formData?.account_shipping_info_state,
        account_shipping_info_country: parseInt(formData?.account_shipping_info_country),
        account_shipping_info_zipcode: formData?.account_shipping_info_zipcode,
      },

      site_reps: techRepsValueArray,
      contact_reps: adminContactModelData?.site_contact
        ? adminContactModelData?.site_contact
        : "",
    };

    const res = await CallPOSTAPI("site/update-site-details", payload);
    if (res?.data?.status) {
      toast.success(res?.data?.msg);
      if(user?.user_type === 0 || (user?.user_type === 2 && user?.sub_admin != "")) {
      navigate("/account/site-details/" + siteId)
      } else {
        navigate("/user/site-details/" + siteId)
      }
       setFormData("");
    } else {
      toast.error(res?.data?.msg);
    }
    // setLoading(false);
  };

  useEffect(() => {
    if (editSiteAdmin) {
      // const sitePocDetail = editSiteAdmin?.sitePocContact[0];

      setFormData((prevFormData) => ({
        ...prevFormData,
        account_site_address1:
          editSiteAdmin?.siteInfo?.account_site_address1 || "",
        account_site_address2:
          editSiteAdmin?.siteInfo?.account_site_address2 || "",
        building_name: editSiteAdmin?.siteInfo?.building_name || "",
        account_site_city: editSiteAdmin?.siteInfo?.account_site_city || "",
        account_site_state:
          editSiteAdmin?.siteInfo?.account_site_state_id || "",
        account_site_zipcode:
          editSiteAdmin?.siteInfo?.account_site_zipcode || "",
        generate_name_toggle: editSiteAdmin?.siteInfo?.account_site_name == "" ? 0 : 1,
        account_site_name: editSiteAdmin?.siteInfo?.account_site_name || "",
        account_site_phone: editSiteAdmin?.siteInfo?.account_site_phone || "",
        account_site_info_phone_ext: editSiteAdmin?.siteInfo?.account_site_phone_ext || "",
        // account_site_zipcode: editSiteAdmin?.siteInfo?.account_site_zipcode || "",
        account_site_status_id:
          editSiteAdmin?.siteInfo?.account_site_status_id || "",
        invoice_asap: editSiteAdmin?.siteInfo?.invoice_asap == 0 ? 0 : 1,  // invoice asap
        call_ahead: editSiteAdmin?.siteInfo?.account_site_call_ahead == 0 ? 0 : 1,
        out_of_area: editSiteAdmin?.siteInfo?.out_of_area == 0 ? 0 : 1,
        security_clearance: editSiteAdmin?.siteInfo?.security_clearence == 0 ? 0 : 1,  // Security clearance
        requires_escort: editSiteAdmin?.siteInfo?.requires_escort == 0 ? 0 : 1,
        // site_hours: compareSiteHours(siteHoursobj, editSiteAdmin?.siteHoursData) == true ? 0 : 1,
        site_hours_toggle: editSiteAdmin?.siteInfo?.site_hours_toggle == 0 ? 0 : 1,
        same_billing_address: editSiteAdmin?.siteInfo?.same_billing_address == 0 ? 0 : 1, // same Billing
        same_shipping_address: editSiteAdmin?.siteInfo?.same_shipping_address == 0 ? 0 : 1, // Same Shipping

        // Billing Address
        account_billing_info_address1:
          editSiteAdmin?.billingInfo?.account_billing_info_address1 || "",
        account_billing_info_address2:
          editSiteAdmin?.billingInfo?.account_billing_info_address2 || "",
        account_billing_info_city:
          editSiteAdmin?.billingInfo?.account_billing_info_city || "",
        account_billing_info_state:
          editSiteAdmin?.billingInfo?.account_billing_info_state_id || "",
        account_billing_info_country:
          editSiteAdmin?.billingInfo?.account_billing_info_country_id || "",
        account_billing_info_zipcode:
          editSiteAdmin?.billingInfo?.account_billing_info_zipcode || "",

        // Shipping Address
        account_shipping_info_address1:
          editSiteAdmin?.shippingInfo?.account_shipping_info_address1 || "",
        account_shipping_info_address2:
          editSiteAdmin?.shippingInfo?.account_shipping_info_address2 || "",
        account_shipping_info_city:
          editSiteAdmin?.shippingInfo?.account_shipping_info_city || "",
        account_shipping_info_state:
          editSiteAdmin?.shippingInfo?.account_shipping_info_state_id || "",
        account_shipping_info_country:
          editSiteAdmin?.shippingInfo?.account_shipping_info_country_id || "",
        account_shipping_info_zipcode:
          editSiteAdmin?.shippingInfo?.account_shipping_info_zipcode || "",

        // Site Hours
        sundayopen: editSiteAdmin?.siteHoursData?.sundayopen || "",
        mondayopen: editSiteAdmin?.siteHoursData?.mondayopen || "",
        tuesdayopen: editSiteAdmin?.siteHoursData?.tuesdayopen || "",
        wednesdayopen: editSiteAdmin?.siteHoursData?.wednesdayopen || "",
        thursdayopen: editSiteAdmin?.siteHoursData?.thrusdayopen || "",
        fridayopen: editSiteAdmin?.siteHoursData?.fridayopen || "",
        saturdayopen: editSiteAdmin?.siteHoursData?.saturdayopen || "",
        sundayclose: editSiteAdmin?.siteHoursData?.sundayclosed || "",
        mondayclose: editSiteAdmin?.siteHoursData?.mondayclosed || "",
        tuesdayclose: editSiteAdmin?.siteHoursData?.tuesdayclosed || "",
        wednesdayclose: editSiteAdmin?.siteHoursData?.wednesdayclosed || "",
        thursdayclose: editSiteAdmin?.siteHoursData?.thrusdayclosed || "",
        fridayclose: editSiteAdmin?.siteHoursData?.fridayclosed || "",
        saturdayclose: editSiteAdmin?.siteHoursData?.saturdayclosed || "",


      }));
    }
  }, [editSiteAdmin]);

  useEffect(() => {
    setOpen((prev) => ({
      ...prev,
      sundayopen: formData?.sundayopen?.toLowerCase() === "closed" ? 1 : 0,
      mondayopen: formData?.mondayopen?.toLowerCase() === "closed" ? 1 : 0,
      tuesdayopen: formData?.tuesdayopen?.toLowerCase() === "closed" ? 1 : 0,
      wednesdayopen: formData?.wednesdayopen?.toLowerCase() === "closed" ? 1 : 0,
      thursdayopen: formData?.thursdayopen?.toLowerCase() === "closed" ? 1 : 0,
      fridayopen: formData?.fridayopen?.toLowerCase() === "closed" ? 1 : 0,
      saturdayopen: formData?.saturdayopen?.toLowerCase() === "closed" ? 1 : 0,
    }));
  }, [formData]);

  let stateAbbr = formData?.account_site_state_abbreviation
  let generatedSiteName = (stateAbbr ? stateAbbr + ', ' : '') +
  (formData?.account_site_city ? formData.account_site_city + ' - ' : '') +
    formData?.account_site_address1 + 
    (formData?.building_name ? ' / ' + formData?.building_name : "");

  const handleGenerateSiteName = (e, type, name) => {
    if (!formData?.account_site_name || formData?.account_site_name) {
      setFormData({ ...formData, account_site_name: generatedSiteName })
    }
    else if (formData?.account_site_name && !formData?.account_site_name.includes(stateAbbr)) {
      setFormData({ ...formData, account_site_name: generatedSiteName })
    }
  }


  const handleTechniciansPrimarySelectChange = (e) => {
    const selectedId = parseInt(e.target.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      technicians: {
        ...prevFormData.technicians,
        primary: selectedId
      },
    }));
  };

  const handleTechniciansBackupSelectChange = (e) => {
    const selectedId = parseInt(e.target.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      technicians: {
        ...prevFormData.technicians,
        backup: selectedId
      },
    }));
  };

  console.log({formData})

  return (
    <>
    {loading ?
        <>
          <div className="showloading">
            <Loading />
          </div>
        </>
          :
      <>
      <div className="mt-4" style={{   }}>
        <SubHeadingOther
          hideNew="tab"
          title="Edit Site"
          subHeading={true}
          hideHierarchy={true}
          bottomLinks={false}
        />

        {/* account resps and products popup buttons */}
        <div className="d-flex">
          <button
            className="btn text-primary"
            type="button"
            onClick={() => {fetchContactOnload() ; setContactShowModel(true)}}
          >
            <img src={Contacts} alt="svg" style={{ marginRight: "1px" }} />
            <span className="ms-2">Contacts</span>
          </button>
        </div>

        {/* main form */}
        <Form
          className=""
          onSubmit={handleSubmit}
          noValidate
          validated={validated}
          id="create-new-account-form"
        >
          {/* Site Information */}
          <div
            className="container-fluid bottom-border-blue mt-4 pt-2 pb-2"
            style={{
              background: "#eee",
            }}
            id="edit_site_info_section"
          >
            <h2 className="text-left heading" style={{ marginBottom: "0" }}>
              Site Information
            </h2>
            <div className="row my-4" id="edit_side_info">
              <Form.Group className={"col"}>
                <Form.Label>Country</Form.Label>
                {/* <Select
                  value={selectedCountry?.account_site_country}
                  options={countryList}
                  onChange={(data) => {
                    handleSelectChange(data, "account_site_country");
                  }}
                  // onMenuOpen={fetchCountry}
                /> */}

            <GlobalDropdown
                  dropDownName={"account_site_country"}
                  apiEndpoint={"account/get-country"}
                  idKey={"id"}
                  valueKey={"country_name"}
                  parentKey={"country"}
                  // onSelect={(e) => {
                  //   handleInputChange(e);
                  // }}
                  onSelect={(data, value) => {
                    const selectedText =
                      data.target.options[data.target.selectedIndex].text;
                    const data2 = {
                      label: selectedText,
                      value: value,
                    };
                    handleSelectChange(
                      data2,
                      "account_site_country"
                    );
                  }}
                  shouldFetchOnOpen={true}
                  isCache={true}
                  initialSelectedValue={editSiteAdmin?.siteInfo?.account_site_country_id}
                  defaultValue={{
                    id: editSiteAdmin?.siteInfo?.account_site_country_id,
                    value:
                    editSiteAdmin?.siteInfo?.account_site_country,
                  }}
                />
              </Form.Group>

              <Form.Group className={"col"}>
                <Form.Label>Address *</Form.Label>
                <Form.Control
                  type="text"
                  name="account_site_address1"
                  value={formData?.account_site_address1}
                  onChange={handleInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Address.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className={"col"}>
                <Form.Label>Address 2</Form.Label>
                <Form.Control
                  type="text"
                  name="account_site_address2"
                  value={formData?.account_site_address2}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group className={"col"}>
                <Form.Label>Building Name</Form.Label>
                <Form.Control
                  type="text"
                  name="building_name"
                  value={formData?.building_name}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
              </Form.Group>

              <Form.Group className={"col"}>
                <Form.Label>City *</Form.Label>
                <Form.Control
                  type="text"
                  name="account_site_city"
                  value={formData?.account_site_city}
                  onChange={handleInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter City.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className={"col relative"}>
                <Form.Label>State * </Form.Label>
                {/* {JSON.stringify({selectedCountry})} */}
                <StateField
                  setFormData={setFormData}
                  valueKey="account_site_state"
                  selectedCountry={selectedCountry?.account_site_country?.value}
                  validated={validated}
                  required={true}
                  stateSelectedValue={formData?.account_site_state}
                  setStateAbreaviation={setFormData}
                  // resetState={resetState}
                  setValueEmpty={setValueEmpty}
                  setResetState={setResetState}
                 />
              </Form.Group>

              <Form.Group className={"col"}>
                <Form.Label>Zip code *</Form.Label>
                <Form.Control
                  type="text"
                  name="account_site_zipcode"
                  value={formData?.account_site_zipcode}
                  onChange={handleInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Zip Code.
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className="row my-4">
              <div className="col" style={{ maxWidth: "130px" }}>
                <div className="my-4">
                  <BButton
                    onClick={(e) => handleGenerateSiteName(e, 'site_details', 'generate_name_toggle')}
                    variant="primary" type="button">
                    Generate
                  </BButton>
                </div>
              </div>

              <Form.Group className={"col"}>
                <Form.Label>Site Name*</Form.Label>
                <Form.Control
                  type="text"
                  name="account_site_name"
                  value={formData?.account_site_name}
                  onChange={handleInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Site Name.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className={"col"}>
                <Form.Label>Site Phone</Form.Label>
                <Form.Control
                  type="number"
                  name="account_site_phone"
                  minLength="10"
                  maxLength="10"
                  value={formData?.account_site_phone}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  className={
                    phoneValidations.account_site_phone
                      ? "phone-invalid-input"
                      : ""
                  }
                />

                {phoneValidations.account_site_phone ? (
                  <>
                    <div className="phone-invalid">
                      Please Enter Exact 10 digits.
                    </div>
                  </>
                ) : (
                  <>
                    <Form.Control.Feedback type="invalid">
                      Please Enter Exact 10 digits.
                    </Form.Control.Feedback>
                  </>
                )}
              </Form.Group>

              <Form.Group className={"col"}>
                <Form.Label>Phone Ext</Form.Label>
                <Form.Control
                  type="number"
                  name="account_site_info_phone_ext"
                  value={formData?.account_site_info_phone_ext}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
              </Form.Group>

              <Form.Group className={"col"}>
                <Form.Label>Site Status</Form.Label>
                {/* <Form.Select
                  className={""}
                  name="account_site_status_id"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  value={formData?.account_site_status_id}
                >
                  <option value="" selected>
                    --Select One--
                  </option>
                  {allDropDowns?.siteStatus &&
                    allDropDowns?.siteStatus.map((SS, index) => (
                      <option value={SS.dropdown_site_status_id} key={index}>
                        {SS.dropdown_site_status_name}
                      </option>
                    ))}
                </Form.Select> */}

<GlobalDropdown
                  dropDownName={"account_site_status_id"}
                  apiEndpoint={"site/site-dropdowns"}
                  idKey={"dropdown_site_status_id"}
                  valueKey={"dropdown_site_status_name"}
                  parentKey={"siteStatus"}
                  onSelect={(e) => {
                    handleInputChange(e);
                  }}
                  shouldFetchOnOpen={true}
                  isCache={true}
                  initialSelectedValue={editSiteAdmin?.siteInfo?.account_site_status_id}
                  defaultValue={{
                    id: editSiteAdmin?.siteInfo?.account_site_status_id,
                    value:
                    editSiteAdmin?.siteInfo?.account_site_status_name,
                  }}
                />
              </Form.Group>
              {/* </div> */}
            </div>

            <div className="row my-2">
              <div className="col" style={{ maxWidth: "120px" }}>
                <Form.Group>
                  <b className={"d-block mb-3"}>Invoice ASAP</b>
                  <div className="my-2">
                    <CustomToggleButton
                      ToggleName="invoice_asap"
                      ToggleValue={formData?.invoice_asap}
                      changeHandler={handleCheckBox}
                     />
                  </div>
                </Form.Group>
              </div>

              <div
                className="col"
                style={{ maxWidth: "100px", marginRight: "2%" }}
              >
                <Form.Group>
                  <b className={"d-block mb-3"}>Call Ahead</b>
                  <div className="my-2">
                    <CustomToggleButton
                      ToggleName="call_ahead"
                      ToggleValue={formData?.call_ahead}
                      changeHandler={handleCheckBox}
                    />
                  </div>
                </Form.Group>
              </div>

              <div
                className="col"
                style={{ maxWidth: "100px", marginRight: "2%" }}
              >
                <Form.Group>
                  <b className={"d-block mb-3"}>Out of Area</b>
                  <div className="my-2">
                    <CustomToggleButton
                      ToggleName="out_of_area"
                      ToggleValue={formData?.out_of_area}
                      changeHandler={handleCheckBox}
                   />
                  </div>
                </Form.Group>
              </div>

              <div className="col" style={{ maxWidth: "140px" }}>
                <Form.Group>
                  <b className={"d-block mb-3"}>Security Clearence</b>
                  <div className="my-2">
                    <CustomToggleButton
                      ToggleName="security_clearance"
                      ToggleValue={formData?.security_clearance}
                      changeHandler={handleCheckBox}
                    />
                  </div>
                </Form.Group>
              </div>

              <div className="col" style={{ maxWidth: "120px" }}>
                <Form.Group>
                  <b className={"d-block mb-3"}>Requires Escort</b>
                  <div className="my-2">
                    <CustomToggleButton
                      ToggleName="requires_escort"
                      ToggleValue={formData?.requires_escort}
                      changeHandler={handleCheckBox}
                   />
                  </div>
                </Form.Group>
              </div>

              <div
                className="col"
                style={{ maxWidth: "100px", marginRight: "2%" }}
              >
                <Form.Group>
                  <b className={"d-block mb-3"}>Site Hours</b>
                  <div className="my-2">
                    <CustomToggleButton
                      ToggleName="site_hours_toggle"
                      ToggleValue={formData?.site_hours_toggle}
                      changeHandler={handleCheckBox}
                   />
                  </div>
                </Form.Group>
              </div>

              <div className="col" style={{ maxWidth: "110px" }}>
                <Form.Group>
                  <b className={"d-block mb-3"}>Same Billing</b>
                  <div className="my-2">
                    <CustomToggleButton
                      ToggleName="same_billing_address"
                      ToggleValue={formData?.same_billing_address}
                      changeHandler={handleCheckBox}
                    />
                  </div>
                </Form.Group>
              </div>

              <div className="col" style={{ maxWidth: "130px" }}>
                <Form.Group>
                  <b className={"d-block mb-3"}>Same Shipping</b>
                  <div className="my-2">
                    <CustomToggleButton
                      ToggleName="same_shipping_address"
                      ToggleValue={formData?.same_shipping_address}
                      changeHandler={handleCheckBox}
                  />
                  </div>
                </Form.Group>
              </div>
            </div>
          </div>

          {/* Site Hours */}
          {formData?.site_hours_toggle === 1 && (
            <>
              <div
                className="container-fluid bottom-border-blue pb-2 pt-2 mt-4"
                style={{ background: "#eee" }}
                id="edit_site_hour_section"
              >
                <h2 className="text-left heading" style={{ marginBottom: "0" }}>
                  Site Hours
                </h2>
                <div className="row my-4 ">
                  <Form.Group className={"col"} id="sun_input" >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Form.Label>Sun Open</Form.Label>
                      <FormControlLabel
                        className={""}
                        label=""
                        title={open?.sundayopen === 1 ? "Open" : "Closed"}
                        control={
                          <Radio
                            color="primary"
                            size="medium"
                            value={open?.sundayopen}
                            checked={open?.sundayopen === 1}
                            onClick={() => handleRadioChange("sundayopen", 'sundayclose')}
                          />
                        }
                      />
                    </div>
                    <div
                      className={"d-flex align-items-center calendar-input-btn"}
                    >
                      <input
                        type="time"
                        value={formData?.sundayopen}
                        onChange={(e) => handleTimeChanges(e, "sundayopen")}
                        dateFormat="HH:mm:ss"
                        placeholderText="HH:mm:ss"
                        disabled={open?.sundayopen === 1}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className={"col"} id="mon_input" >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Form.Label>Mon Open</Form.Label>
                      <FormControlLabel
                        className={""}
                        label=""
                        title={open?.mondayopen === 1 ? "Open" : "Closed"}
                        control={
                          <Radio
                            color="primary"
                            size="medium"
                            value={open?.mondayopen}
                            checked={open?.mondayopen === 1}
                            onClick={() => handleRadioChange("mondayopen", 'mondayclose')}
                          />
                        }
                      />
                    </div>
                    <div
                      className={"d-flex align-items-center calendar-input-btn"}
                    >
                      <input
                        type="time"
                        value={formData?.mondayopen}
                        onChange={(e) => handleTimeChanges(e, "mondayopen")}
                        dateFormat="HH:mm:ss"
                        placeholderText="HH:mm:ss"
                        disabled={open?.mondayopen === 1}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className={"col"} id="tues_input">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Form.Label>Tues Open</Form.Label>
                      <FormControlLabel
                        className={""}
                        label=""
                        title={open?.tuesdayopen === 1 ? "Open" : "Closed"}
                        control={
                          <Radio
                            color="primary"
                            size="medium"
                            value={open?.tuesdayopen}
                            checked={open?.tuesdayopen === 1}
                            onClick={() => handleRadioChange("tuesdayopen", 'tuesdayclose')}
                          />
                        }
                      />
                    </div>
                    <div
                      className={"d-flex align-items-center calendar-input-btn"}
                    >
                      <input
                        type="time"
                        value={formData?.tuesdayopen}
                        onChange={(e) => handleTimeChanges(e, "tuesdayopen")}
                        dateFormat="HH:mm:ss"
                        placeholderText="HH:mm:ss"
                        disabled={open?.tuesdayopen === 1}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className={"col"} id="wed_input" >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Form.Label>Wed Open</Form.Label>
                      <FormControlLabel
                        className={""}
                        label=""
                        title={open?.wednesdayopen === 1 ? "Open" : "Closed"}
                        control={
                          <Radio
                            color="primary"
                            size="medium"
                            value={open?.wednesdayopen}
                            checked={open?.wednesdayopen === 1}
                            onClick={() => handleRadioChange("wednesdayopen", 'wednesdayclose')}
                          />
                        }
                      />
                    </div>
                    <div
                      className={"d-flex align-items-center calendar-input-btn"}
                    >
                      <input
                        type="time"
                        value={formData?.wednesdayopen}
                        onChange={(e) => handleTimeChanges(e, "wednesdayopen")}
                        dateFormat="HH:mm:ss"
                        placeholderText="HH:mm:ss"
                        disabled={open?.wednesdayopen === 1}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className={"col"} id="thurs_input" >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Form.Label>Thurs Open</Form.Label>
                      <FormControlLabel
                        className={""}
                        label=""
                        title={open?.thursdayopen === 1 ? "Open" : "Closed"}
                        control={
                          <Radio
                            color="primary"
                            size="medium"
                            value={open?.thursdayopen}
                            checked={open?.thursdayopen === 1}
                            onClick={() => handleRadioChange("thursdayopen", 'thursdayclose')}
                          />
                        }
                      />
                    </div>
                    <div
                      className={"d-flex align-items-center calendar-input-btn"}
                    >
                      <input
                        type="time"
                        value={formData?.thursdayopen}
                        onChange={(e) => handleTimeChanges(e, "thursdayopen")}
                        dateFormat="HH:mm:ss"
                        placeholderText="HH:mm:ss"
                        disabled={open?.thursdayopen === 1}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className={"col"} id="fri_input" >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Form.Label>Fri Open</Form.Label>
                      <FormControlLabel
                        className={""}
                        label=""
                        title={open?.fridayopen === 1 ? "Open" : "Closed"}
                        control={
                          <Radio
                            color="primary"
                            size="medium"
                            value={open?.fridayopen}
                            checked={open?.fridayopen === 1}
                            onClick={() => handleRadioChange("fridayopen", 'fridayclose')}
                          />
                        }
                      />
                    </div>
                    <div
                      className={"d-flex align-items-center calendar-input-btn"}
                    >
                      <input
                        type="time"
                        value={formData?.fridayopen}
                        onChange={(e) => handleTimeChanges(e, "fridayopen")}
                        dateFormat="HH:mm:ss"
                        placeholderText="HH:mm:ss"
                        disabled={open?.fridayopen === 1}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className={"col"} id="sat_input" >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Form.Label>Sat Open</Form.Label>
                      <FormControlLabel
                        className={""}
                        label=""
                        title={open?.saturdayopen === 1 ? "Open" : "Closed"}
                        control={
                          <Radio
                            color="primary"
                            size="medium"
                            value={open?.saturdayopen}
                            checked={open?.saturdayopen === 1}
                            onClick={() => handleRadioChange("saturdayopen", 'saturdayclose')}
                          />
                        }
                      />
                    </div>
                    <div
                      className={"d-flex align-items-center calendar-input-btn"}
                    >
                      <input
                        type="time"
                        value={formData?.saturdayopen}
                        onChange={(e) => handleTimeChanges(e, "saturdayopen")}
                        dateFormat="HH:mm:ss"
                        placeholderText="HH:mm:ss"
                        disabled={open?.saturdayopen}
                      />
                    </div>
                  </Form.Group>
                </div>

                <div className="row my-4 ">
                  <Form.Group className={"col"} id="sun_closed_input" >
                    <Form.Label>Sun Closed</Form.Label>

                    <div
                      className={"d-flex align-items-center calendar-input-btn"}
                    >
                      <input
                        type="time"
                        value={formData?.sundayclose}
                        onChange={(e) => handleTimeChanges(e, "sundayclose")}
                        dateFormat="HH:mm:ss"
                        placeholderText="HH:mm:ss"
                        disabled={open?.sundayopen === 1}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className={"col"} id="mon_closed_input" >
                    <Form.Label>Mon Closed</Form.Label>

                    <div
                      className={"d-flex align-items-center calendar-input-btn"}
                    >
                      <input
                        type="time"
                        value={formData?.mondayclose}
                        onChange={(e) => handleTimeChanges(e, "mondayclose")}
                        dateFormat="HH:mm:ss"
                        placeholderText="HH:mm:ss"
                        disabled={open?.mondayopen === 1}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className={"col"} id="tues_closed_input" >
                    <Form.Label>Tues Closed</Form.Label>

                    <div
                      className={"d-flex align-items-center calendar-input-btn"}
                    >
                      <input
                        type="time"
                        value={formData?.tuesdayclose}
                        onChange={(e) => handleTimeChanges(e, "tuesdayclose")}
                        dateFormat="HH:mm:ss"
                        placeholderText="HH:mm:ss"
                        disabled={open?.tuesdayopen === 1}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className={"col"} id="wed_closed_input" >
                    <Form.Label>Wed Closed</Form.Label>

                    <div
                      className={"d-flex align-items-center calendar-input-btn"}
                    >
                      <input
                        type="time"
                        value={formData?.wednesdayclose}
                        onChange={(e) => handleTimeChanges(e, "wednesdayclose")}
                        dateFormat="HH:mm:ss"
                        placeholderText="HH:mm:ss"
                        disabled={open?.wednesdayopen === 1}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className={"col"} id="thrus_closed_input">
                    <Form.Label>Thurs Closed</Form.Label>

                    <div
                      className={"d-flex align-items-center calendar-input-btn"}
                    >
                      <input
                        type="time"
                        value={formData?.thursdayclose}
                        onChange={(e) => handleTimeChanges(e, "thursdayclose")}
                        dateFormat="HH:mm:ss"
                        placeholderText="HH:mm:ss"
                        disabled={open?.thursdayopen === 1}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className={"col"} id="fri_closed_input" >
                    <Form.Label>Fri Closed</Form.Label>

                    <div
                      className={"d-flex align-items-center calendar-input-btn"}
                    >
                      <input
                        type="time"
                        value={formData?.fridayclose}
                        onChange={(e) => handleTimeChanges(e, "fridayclose")}
                        dateFormat="HH:mm:ss"
                        placeholderText="HH:mm:ss"
                        disabled={open?.fridayopen === 1}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className={"col"} id="sat_closed_input" >
                    <Form.Label>Sat Closed</Form.Label>

                    <div
                      className={"d-flex align-items-center calendar-input-btn"}
                    >
                      <input
                        type="time"
                        value={formData?.saturdayclose}
                        onChange={(e) => handleTimeChanges(e, "saturdayclose")}
                        dateFormat="HH:mm:ss"
                        placeholderText="HH:mm:ss"
                        disabled={open?.saturdayopen === 1}
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>
            </>
          )}

          {/*Billing Address */}
          {formData?.same_billing_address != 1 && (
            <>
              <div
                className="container-fluid bottom-border-blue mt-4 pt-2 pb-2"
                style={{
                  background: "#eee",
                }}
                id="edit_site_billing_address_section"
              >
                <h2 className="text-left heading" style={{ marginBottom: "0" }}>
                  Billing Address
                </h2>
                <div className="row my-4" id="edit_site_billing_address" >
                  <Form.Group className={"col"}>
                    <Form.Label>Country</Form.Label>
                    {/* <Select
                      value={selectedCountry?.account_billing_info_country}
                      options={countryList}
                      onChange={(data) => {
                        handleSelectChange(
                          data,
                          "account_billing_info_country"
                        );
                      }}
                    /> */}

<GlobalDropdown
                  dropDownName={"account_billing_info_country"}
                  apiEndpoint={"account/get-country"}
                  idKey={"id"}
                  valueKey={"country_name"}
                  parentKey={"country"}
                  // onSelect={(e) => {
                  //   handleInputChange(e);
                  // }}
                  // onChange={(data) => {
                  //   // console.log({data})
                  //   // return;
                  //   handleSelectChange(
                  //     data,
                  //     "account_billing_info_country"
                  //   );
                  // }}

                  onSelect={(data, value) => {
                    const selectedText =
                      data.target.options[data.target.selectedIndex].text;
                    const data2 = {
                      label: selectedText,
                      value: value,
                    };
                    handleSelectChange(
                      data2,
                      "account_billing_info_country"
                    );
                  }}
                  shouldFetchOnOpen={true}
                  isCache={true}
                  initialSelectedValue={editSiteAdmin?.billingInfo?.account_billing_info_country}
                   defaultValue={{
                    id: editSiteAdmin?.billingInfo?.account_site_country_id,
                    value:
                    editSiteAdmin?.billingInfo?.account_billing_info_country,
                  }}
                />
                  </Form.Group>

                  <Form.Group className={"col"}>
                    <Form.Label>Address*</Form.Label>
                    <Form.Control
                      type="text"
                      name="account_billing_info_address1"
                      value={formData?.account_billing_info_address1}
                      onChange={handleInputChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Address.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className={"col"}>
                    <Form.Label>Address 2</Form.Label>
                    <Form.Control
                      type="text"
                      name="account_billing_info_address2"
                      value={formData?.account_billing_info_address2}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group className={"col"}>
                    <Form.Label>City*</Form.Label>
                    <Form.Control
                      type="text"
                      name="account_billing_info_city"
                      value={formData?.account_billing_info_city}
                      onChange={handleInputChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter City.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className={"col relative"}>
                    <Form.Label>State* </Form.Label>

                    <StateField
                      setFormData={setFormData}
                      valueKey="account_billing_info_state"
                      selectedCountry={
                        selectedCountry?.account_billing_info_country?.value
                      }
                      validated={validated}
                      required={true}
                      stateSelectedValue={formData?.account_billing_info_state}
                    />
                  </Form.Group>

                  <Form.Group className={"col"}>
                    <Form.Label>Zip code*</Form.Label>
                    <Form.Control
                      type="text"
                      name="account_billing_info_zipcode"
                      value={formData?.account_billing_info_zipcode}
                      onChange={handleInputChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Zip Code.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
            </>
          )}

          {/*Shipping Address */}
          {formData?.same_shipping_address != 1 && (
            <>
              <div
                className="container-fluid bottom-border-blue mt-4 pt-2 pb-2"
                style={{
                  background: "#eee",
                }}
                id="edit_site_shipping_address_section"
              >
                <h2 className="text-left heading" style={{ marginBottom: "0" }}>
                  Shipping Address
                </h2>
                <div className="row my-4" id="edit_site_shipping_address">
                  <Form.Group className={"col"}>
                    <Form.Label>Country</Form.Label>
                    {/* <Select
                      value={selectedCountry?.account_shipping_info_country}
                      options={countryList}
                      onChange={(data) => {
                        handleSelectChange(
                          data,
                          "account_shipping_info_country"
                        );
                      }}
                    /> */}

<GlobalDropdown
                  dropDownName={"account_shipping_info_country"}
                  apiEndpoint={"account/get-country"}
                  idKey={"id"}
                  valueKey={"country_name"}
                  parentKey={"country"}
                  // onSelect={(e) => {
                  //   handleInputChange(e);
                  // }}
                  onSelect={(data, value) => {
                    const selectedText =
                      data.target.options[data.target.selectedIndex].text;
                    const data2 = {
                      label: selectedText,
                      value: value,
                    };
                    handleSelectChange(
                      data2,
                      "account_shipping_info_country"
                    );
                  }}
                  shouldFetchOnOpen={true}
                  isCache={true}
                  initialSelectedValue={editSiteAdmin?.shippingInfo?.account_shipping_info_country}
                  // className={styles.ddLabel}
                  defaultValue={{
                    id: editSiteAdmin?.shippingInfo?.account_site_country_id,
                    value:
                    editSiteAdmin?.shippingInfo?.account_shipping_info_country,
                  }}
                />
                  </Form.Group>

                  <Form.Group className={"col"}>
                    <Form.Label>Address*</Form.Label>
                    <Form.Control
                      type="text"
                      name="account_shipping_info_address1"
                      value={formData?.account_shipping_info_address1}
                      onChange={handleInputChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Address.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className={"col"}>
                    <Form.Label>Address 2</Form.Label>
                    <Form.Control
                      type="text"
                      name="account_shipping_info_address2"
                      value={formData?.account_shipping_info_address2}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group className={"col"}>
                    <Form.Label>City*</Form.Label>
                    <Form.Control
                      type="text"
                      name="account_shipping_info_city"
                      value={formData?.account_shipping_info_city}
                      onChange={handleInputChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter City.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className={"col relative"}>
                    <Form.Label>State* </Form.Label>
                    <StateField
                      setFormData={setFormData}
                      valueKey="account_shipping_info_state"
                      selectedCountry={
                        selectedCountry?.account_shipping_info_country?.value
                      }
                      validated={validated}
                      required={true}
                      stateSelectedValue={formData?.account_shipping_info_state}
                    />
                  </Form.Group>

                  <Form.Group className={"col"}>
                    <Form.Label>Zip code*</Form.Label>
                    <Form.Control
                      type="text"
                      name="account_shipping_info_zipcode"
                      value={formData?.account_shipping_info_zipcode}
                      onChange={handleInputChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Zip Code.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
            </>
          )}

          {/* Technicians */}
          <div
          className="container-fluid bottom-border-blue pb-2 pt-2 mt-4"
          style={{ background: "#eee" }}
        >
            <h2 className="text-left heading" style={{ marginBottom: "0" }}>
              Technicians
            </h2> 
          
          <div className="row my-4" style={{width: "40%"}}>
          {/* <Form.Group className={"col"}>
          <Form.Label>Primary</Form.Label>
          <Form.Select
            className={""}
            name="technicians_primary"
            value={formData?.technicians?.primary}
        onChange={handleTechniciansPrimarySelectChange}
        onFocus={fetchTechnicianData} 
          >
            <option value="" selected>
              --Select One--
            </option>
            {techniciansData.map((item, index) => {
              if(item.account_main_contact_id !== formData?.technicians?.backup){
              return (
                <option
                value={item.account_main_contact_id}
                key={index}
              >
              {item.account_main_contact_firstname} {item.account_main_contact_lastname}
              </option>)
              }
            })}
          </Form.Select>
         </Form.Group> */}


<Form.Group className={"col"}>
      <Form.Label>Primary</Form.Label>
      {/* <Form.Select
        className={""}
        name="technicians_primary"
        value={isDefaultValueShown ? primaryTechniciansField && primaryTechniciansField[0]?.primary : formData?.technicians?.primary}
        onChange={handleTechniciansPrimarySelectChange}
        onFocus={fetchTechnicianData}  // Fetch data on focus
        // defaultValue={primaryTechniciansField[0]?.primary}
      >
            <option value="" selected>
              {isDefaultValueShown ? primaryTechniciansField && primaryTechniciansField[0]?.primary : "--Select One--"}
            </option>
            {techniciansData.map((item, index) => {
              if(item.account_main_contact_id !== formData?.technicians?.backup){
                return (
                  <option
  value={techniciansLoading ? "Loading..." : item.account_main_contact_id}
  key={index}
>
  {techniciansLoading ? "Loading..." : `${item.account_main_contact_firstname} ${item.account_main_contact_lastname}`}
</option>

                )
              }
            })}
      </Form.Select> */}


<Form.Select
  className={""}
  name="technicians_primary"
  value={
    formData?.technicians?.primary 
      ? formData.technicians.primary 
      : ""
  }
  onChange={handleTechniciansPrimarySelectChange}
  // onFocus={fetchTechnicianData}  // Fetch data on focus
  defaultValue={primaryTechniciansField && primaryTechniciansField[0]?.contact_id}
>
  {/* Always include the "Select One" option */}
  <option value="">
    --Select One--
  </option>
  
  {/* Render the other options */}
  {techniciansData.map((item, index) => {
    if (item.account_main_contact_id !== formData?.technicians?.backup) {
      return (
        <option
          value={item.account_main_contact_id}
          key={index}
        >
          {`${item.contact_name}`}
        </option>
      );
    }
  })}
</Form.Select>


    </Form.Group>

        <Form.Group className={"col"}>
        <Form.Label>Backup</Form.Label>
        <Form.Select
          className={""}
          name="technicians_backup"
          value={isDefaultValueShown ? backupTechniciansField && backupTechniciansField[0]?.backup : formData?.technicians?.backup}
          onChange={handleTechniciansBackupSelectChange}
          // onFocus={fetchBackupTechnicianData}
        >
         <option value=""  selected>
          {isDefaultValueShown ? backupTechniciansField && backupTechniciansField[0]?.backup : "--Select One--"}
        </option>
          {!isDefaultValueShown && techniciansData.map((item, index) => {
            if(item.account_main_contact_id !== formData?.technicians?.primary){
            return (
              <option
              value={backupLoading ? "Loading..." : item.account_main_contact_id}
              key={index}
            >
           {backupLoading ? "Loading..." : `${item.contact_name}`}
            </option>)
            }
          })}
        </Form.Select>
      </Form.Group>
      </div>
        </div>

          <div className="row pb-3 py-5">
            <div className="col-12 content-flex-right">
              <button
                className="btn btn-danger text-uppercase"
                type="button"
                onClick={() => navigate(-1)}
              >
                Cancel
              </button>
              <button
                className="btn btn-success text-uppercase ms-2"
                type="submit"
                disabled={loading}
              >
                 {loading ? 'Loading...' : 'Submit'}
                {/* Submit */}
              </button>
            </div>
          </div>
          <AdminContactModel
            ShowRepsModal={contactShowModel}
            SetShowRepsModal={setContactShowModel}
            setSelectAccReps={setSelectContact}
            setAccReps={setContactRepsList}
            AccReps={contactRepsList}
            setAccRepsList={setContactReps}
            AccRepsList={contactReps}
            resultData={repsData}
            setAdminContactModelData={setAdminContactModelData}
            type="siteContact"
            apiLoading={apiLoading}
          />
        </Form>
      </div>
      </>
    }
    </>
  );
};

export default AdminSiteEdit;
