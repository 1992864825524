import React, { useState, useEffect, useRef } from "react";
import { createTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Form } from "react-bootstrap";
import Select, { StylesConfig } from "react-select";
import { GetFilterData } from "../../../../common/helper/BasicFn";
import { CallPOSTAPI } from "../../../../common/services/index";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addFilterData,
  removeFilterData,
  updateFilterData,
  addPayloadData,
  removePayloadData,
} from "../../../../store/slices/AccountListFilter";
import CustomToggleButton3 from "../../../../common/components/toggleSwitch/CustomToggle3";
import GlobalMultiDropdown from "../../../../common/components/custom-ui/GlobalMultiDropdown";
import PaginateAutoComplete from "../../../../common/components/custom-ui/PaginateAutoComplete";
import { prepareAedBrandModelOptions } from "../../../../common/helper/Common";
import AEDBrandModalMultiDropdown from "../../../../common/components/custom-ui/AEDBrandModalMultiDropdown";
import CustomAEDBrandModalMultiDropdown from "../../../../common/components/custom-ui/CustomAEDBrandModalMultiDropdown";

export default function Index({
  setOpen,
  setaccounts,
  accountListingPage,
  setShowLoading,
  getAccountsList,
  setTotalPages,
  search,
  clearBtn,
}) {
  const theme = createTheme();
  const navigate = useNavigate();
  
  const [allToggle, setAllToggle] = useState(false);
  const [formData, setFormData] = useState({
    aed: false,
    equipment: false,
    training: false,
    account_name: [],
    customer_type: [],
    parent_account: [],
    distributors: [],
    sales_name: [],
    project_manager: [],
    product_interest: [],
    lead_source: [],
    account_status: [],
    industry_type: [],
    state: [],
    aed_brand_model: [],
    aed_technician_primary: [],
    aed_technician_backup: [],
    equipment_brand_model: [],
    equipment_type: [],
    project_managers_backup: [],
    sales_rep_backup: [],
    country: [],
  });
  const dispatch = useDispatch();
  const filterData = useSelector((state) => state.accountlistfilter.filterData);
  const payloadData = useSelector(
    (state) => state.accountlistfilter.payloadData
  );

  // drawer header
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "space-between",
  }));

  // close drawer function
  const handleDrawerClose = () => {
    setOpen(false);
  };

  // handle select change
  const handleSelectChange = (data, key) => {
    let valueArray = [];
    console.log({data})
    data.map((item, index) => {
      valueArray.push({
        label: item.label,
        value: item.value,
      });
    });
    setFormData((old) => ({ ...old, [key]: valueArray }));
  };

  // handle select change
  const handleSearchSelectChange = (data, key,event, idKey = "", valueKye = "") => {

    setFormData((old) => ({ ...old, [key]: data }));
  };

  // handle select change
  const handleSelectSubmitData = (data) => {
    let valueArray = [];
    data.map((item) => {
      valueArray.push(item.value);
    });

    return valueArray;
  };

  const handleSearchSelectSubmitData = (data,valueKey="value") => {
    let valueArray = [];
    data.map((item) => {
      valueArray.push(item?.[valueKey]);
    });
    return valueArray;
  };

  // handle radio buttons change
  const handleRadioChange = (value, key) => {
    setFormData((old) => ({ ...old, [key]: value }));
  };
  const handleAllRadioChange = (value) => {
    setAllToggle(value);
  };

  // prepare options
  const prepareOptions = (optionsData, key, value) => {
    if (optionsData) {
      let allData = [];
      for (let i = 0; i < optionsData.length; i++) {
        let singleData = {};
        singleData.value = optionsData[i][key];
        singleData.label = optionsData[i][value];
        allData.push(singleData);
      }
      allData.sort((a, b) => a.label.localeCompare(b.label));
      return allData;
    }
  };


  // clear filter
  const handleClearFilter = async (e) => {
    e.preventDefault();
    handleDrawerClose();

    const allFieldsEmpty = Object.entries(formData).map(([key, value]) => {
      if (key !== "aed" && key !== "training" && key !== "equipment") {
        return value?.length !== 0;
      }
      return false;
    });

    let isTrue = false;
    for (const item of allFieldsEmpty) {
      if (item === true) {
        isTrue = true;
        break;
      }
    }

    if ((isTrue || allToggle !== false) && clearBtn) {
      setFormData((old) => ({
        ...old,
        aed: false,
        equipment: false,
        training: false,
        account_name: [],
        customer_type: [],
        parent_account: [],
        distributors: [],
        sales_name: [],
        project_manager: [],
        product_interest: [],
        lead_source: [],
        account_status: [],
        industry_type: [],
        state: [],
        aed_brand_model: [],
        aed_technician_primary: [],
        aed_technician_backup: [],
        equipment_brand_model: [],
        equipment_type: [],
        project_managers_backup: [],
        sales_rep_backup: [],
        country: [],
      }));
      setAllToggle(false);
      dispatch(removeFilterData());
      dispatch(removePayloadData());

      navigate("/accounts-listing", {
        state: {
          accountListings: false,
        },
      });
    }
  };

  const stringifyArrays = (data) => {
    const stringifiedData = {};
    for (const key in data) {
      if (Array.isArray(data[key])) {
        stringifiedData[key] = data[key].join(",");
      } else {
        stringifiedData[key] = data[key];
      }
    }
    return stringifiedData;
  };

  const handleSubmit = async (e) => {
    handleDrawerClose();
    let payloadData = {
      allToggle: allToggle,
      aed: allToggle ? formData?.aed : false ,
      training: allToggle ? formData?.training : false,
      account_name:
        formData?.account_name?.length > 0
          ? handleSearchSelectSubmitData(formData?.account_name,'account_id')
          : [],
      customer_type:
        formData?.customer_type?.length > 0
          ? handleSelectSubmitData(formData?.customer_type)
          : [],
      parent_account:
        formData?.parent_account?.length > 0
          ? handleSearchSelectSubmitData(formData?.parent_account,'account_id')
          : [],
      distributors:
        formData?.distributors?.length > 0
          ? handleSearchSelectSubmitData(formData?.distributors,'dropdown_distributor_id')
          : [],
      sales_name:
        formData?.sales_name?.length > 0
          ? handleSelectSubmitData(formData?.sales_name)
          : [],
      project_manager:
        formData?.project_manager?.length > 0
          ? handleSelectSubmitData(formData?.project_manager)
          : [],
      product_interest:
        formData?.product_interest?.length > 0
          ? handleSelectSubmitData(formData?.product_interest)
          : [],
      lead_source:
        formData?.lead_source?.length > 0
          ? handleSelectSubmitData(formData?.lead_source)
          : [],
      account_status:
        formData?.account_status?.length > 0
          ? handleSelectSubmitData(formData?.account_status)
          : [],
      industry_type:
        formData?.industry_type?.length > 0
          ? handleSelectSubmitData(formData?.industry_type)
          : [],
      state:
        formData?.state?.length > 0
          ? handleSelectSubmitData(formData?.state)
          : [],
      aed_brand_model:
        formData?.aed_brand_model?.length > 0
          ? handleSelectSubmitData(formData?.aed_brand_model)
          : [],
      aed_technician_primary:
        formData?.aed_technician_primary?.length > 0
          ? handleSelectSubmitData(formData?.aed_technician_primary)
          : [],
      aed_technician_backup:
        formData?.aed_technician_backup?.length > 0
          ? handleSelectSubmitData(formData?.aed_technician_backup)
          : [],
      equipment_brand_model:
        formData?.equipment_brand_model?.length > 0
          ? handleSelectSubmitData(formData?.equipment_brand_model)
          : [],
      equipment_type:
        formData?.equipment_type?.length > 0
          ? handleSelectSubmitData(formData?.equipment_type)
          : [],
      project_managers_backup:
        formData?.project_managers_backup?.length > 0
          ? handleSelectSubmitData(formData?.project_managers_backup)
          : [],
      sales_rep_backup:
        formData?.sales_rep_backup?.length > 0
          ? handleSelectSubmitData(formData?.sales_rep_backup)
          : [],
      country:
        formData?.country?.length > 0
          ? handleSelectSubmitData(formData?.country)
          : [],
    };
    const payloadDataStringified = stringifyArrays(payloadData);

    setShowLoading(true);

    const allFieldsEmpty = Object.entries(payloadDataStringified).map(
      ([key, value]) => {
        if (key === "allToggle") {
          return value !== false;
        } else if (key !== "aed" && key !== "training") {
          return value?.length !== 0;
        }
        return false;
      }
    );

    let isTrue = false;
    for (const item of allFieldsEmpty) {
      if (item === true) {
        isTrue = true;
        break;
      }
    }

    if (isTrue) {
      let results = await CallPOSTAPI("account/filter-search-result", {
        ...payloadDataStringified,
        page: 1,
        search: search,
      });
      if (results?.status) {
        if (Object.keys(filterData).length !== 0) {
          dispatch(updateFilterData(formData));
        } else {
          dispatch(addFilterData(formData));
        }
        dispatch(addPayloadData(payloadDataStringified));
        if (accountListingPage) {
  
          setaccounts(results?.data?.data?.account_list || []);
          setTotalPages(results?.data?.data?.totalPages || 0);
        } else {
          navigate("/accounts-listing", {
            state: {
              accountListings: results?.data?.data?.account_list,
            },
          });
        }
      }
    } else {
      dispatch(removeFilterData());
      getAccountsList();
    }
    setShowLoading(false);
  };

  useEffect(() => {
    // fetchOnLoad();
    if (Object.keys(filterData).length !== 0) {
      setFormData({
        aed: filterData?.aed || false,
        equipment: filterData?.equipment || false,
        training: filterData?.training || false,
        account_name: filterData?.account_name || [],
        customer_type: filterData?.customer_type || [],
        parent_account: filterData?.parent_account || [],
        distributors: filterData?.distributors || [],
        sales_name: filterData?.sales_name || [],
        project_manager: filterData?.project_manager || [],
        product_interest: filterData?.product_interest || [],
        lead_source: filterData?.lead_source || [],
        account_status: filterData?.account_status || [],
        industry_type: filterData?.industry_type || [],
        state: filterData?.state || [],
        aed_brand_model: filterData?.aed_brand_model || [],
        aed_technician_primary: filterData?.aed_technician_primary || [],
        aed_technician_backup: filterData?.aed_technician_backup || [],
        equipment_brand_model: filterData?.equipment_brand_model || [],
        equipment_type: filterData?.equipment_type || [],
        project_managers_backup: filterData?.project_managers_backup || [],
        sales_rep_backup: filterData?.sales_rep_backup || [],
        country: filterData?.country || [],
      });
      if (Object.keys(payloadData).length !== 0) {
        setAllToggle(payloadData?.allToggle);
      }
    }
  }, []);


  const handleBrandModelRender = (item)=>{
    console.log({item});
    // return <option value={'0'} key={item} >Demo</option>
    const groupName = item?.brand_name;
    const brand_id = item?.brand_id;
    const groupItems = item?.data || [];
      const idKey = 'model_id';
      const valueKey = 'model_name';
    // brand_id
    return <optgroup key={brand_id} label={groupName}>
    {groupItems.map(it => (
      <option key={it[idKey]} value={it[idKey]} disabled={it.disabled}>
        {it[valueKey]}
      </option>
    ))}
  </optgroup>
  }

  return (
    <div style={{ background: "#000" }}>
      {/* drawer header */}
      <DrawerHeader>
        <div className="left-btns">
          <IconButton
            onClick={handleDrawerClose}
            className="my-account-list-btn"
          >
            <ChevronRightIcon />
          </IconButton>
        </div>
      </DrawerHeader>
      {/* main content of filter */}
      <div className="content px-4">
        <Form.Group className="mb-3" style={{maxWidth:"200px !important"}}>
          <Form.Label>Account Name</Form.Label>
          <PaginateAutoComplete
          showCountsOnly={1}
            dropDownName={"account_name"}
            apiEndpoint={"account/account-list-dropdown"}
            idKey={"account_id"}
            valueKey={"account_name"}
            parentKey={"allAccount"}
            onSelect={(e, value) => {
              handleSearchSelectChange(
                value,
                "account_name", 
                e,
                "account_id",
                "account_name"
              );
            }}
            defaultValue={
              formData?.account_name?.length > 0 ? formData.account_name : null
            }
            shouldFetchOnOpen={true}
            isCache={true}
            className={"react-select-container"}
            selectDisabled={false}
            closeMenuSelect={false}
            multiple={true}
            showCheckBox={true}
          />
        </Form.Group>

        {/* Account Status */}
        <Form.Group className="mb-3">
          <Form.Label>Account Status</Form.Label>
          {/*    <Select
            value={formData?.account_status}
            className="react-select-container"
            options={accountStatusList}
            onChange={(data) => {
              handleSelectChange(data, "account_status");
            }}
            isMulti
            menuPosition={"fixed"}
            closeMenuOnSelect={false}
          /> */}

          <GlobalMultiDropdown
            dropDownName={"account_status"}
            apiEndpoint={"account/account-status-dropdowns"}
            idKey={"drop_account_status_id"}
            valueKey={"account_status"}
            parentKey={"accountStatus"}
            onSelect={(e) => {
              handleSelectChange(e, "account_status");
            }}
            initialSelectedValue={
              formData?.account_status?.length > 0 ? formData?.account_status.map((it)=>({...it,drop_account_status_id: it.value,account_status:it.label})) : []
            }
            shouldFetchOnOpen={true}
            isCache={false}
            className={"react-select-container"}
            selectDisabled={false}
            isMultiSelect={true}
            closeMenuSelect={false}
          />
        </Form.Group>

        {/* AED Brand/Model */}
        <Form.Group className="mb-3">
          <Form.Label>AED Brand/Model</Form.Label>
          {/* /fetch-filter-brand-model */}
          <CustomAEDBrandModalMultiDropdown
            dropDownName={"aed_brand-model"}
            apiEndpoint={"account/fetch-filter-brand-model"}
            idKey={"model_id"}
            valueKey={"model_name"}
            parentKey={"BrandModel"}
            showSelectedCount={true} 
            onSelect={(e) => {
              const resultData  = e.map((it=>({value: it?.model_id, label: it?.model_name})))
              handleSelectChange(resultData, "aed_brand_model");
            }}
            shouldFetchOnOpen={true}
            isCache={false}
            className={"react-select-container custom-select"}
            selectDisabled={false}
            isMultiSelect={true}
            closeMenuSelect={false}
            prepareOptions={prepareAedBrandModelOptions}
            initialSelectedValue={formData?.aed_brand_model.map((it=>({model_id: it?.value, model_name: it?.label})))}
          />
          {/* <Select
            isMulti
            menuPosition="fixed"
            className="react-select-container custom-select"
            options={aedBrandModelList}
            value={formData?.aed_brand_model}
            onChange={(data) => {
              handleSelectChange(data, "aed_brand_model");
            }}
            closeMenuOnSelect={false}
          /> */}
        </Form.Group>

        {/* AED Technician Backup */}
        <Form.Group className="mb-3">
          <Form.Label>AED Technician Backup</Form.Label>

          <GlobalMultiDropdown
            dropDownName={"aed_technician_backup"}
            apiEndpoint={"account/fetch-filter-technician-backup"}
            idKey={"contact_id"}
            valueKey={"contact_name"}
            parentKey={"aedTechnicianBackup"}
            onSelect={(data) => {
              handleSelectChange(data, "aed_technician_backup");
            }}
            shouldFetchOnOpen={true}
            isCache={false}
            className={"react-select-container custom-select"}
            selectDisabled={false}
            isMultiSelect={true}
            closeMenuSelect={false}
            initialSelectedValue={
              formData?.aed_technician_backup?.length > 0 ? formData?.aed_technician_backup.map((it)=>({...it,contact_id: it.value,contact_name:it.label})) : []
            }
          />
          {/* <Select
            value={formData?.aed_technician_backup}
            className="react-select-container"
            options={aedTechnicianBackupList}
            onChange={(data) => {
              handleSelectChange(data, "aed_technician_backup");
            }}
            isMulti
            menuPosition={"fixed"}
            closeMenuOnSelect={false}
          /> */}

          {/* <GlobalMultiDropdown
          dropDownName={"aed_technician_backup"}
          apiEndpoint={"account/fetch-filter-technician-backup"}
          idKey={"account_id"}
          valueKey={"account_main_contact_firstname"}
          parentKey={"parentAccount"}
          onSelect={(e) => {
            handleSelectChange(e, "aed_technician_backup");
          }}
          shouldFetchOnOpen={true}
          isCache={false}
          className={"react-select-container"}
          selectDisabled={false}
          isMultiSelect={true}
          closeMenuSelect={false}
        /> */}
        </Form.Group>

        {/* AED Technician Primary */}
        <Form.Group className="mb-3">
          <Form.Label>AED Technician Primary</Form.Label>
          {/* <Select
            value={formData?.aed_technician_primary}
            className="react-select-container"
            options={aedTechnicianPrimaryList}
            onChange={(data) => {
              handleSelectChange(data, "aed_technician_primary");
            }}
            isMulti
            menuPosition={"fixed"}
            closeMenuOnSelect={false}
          /> */}
          <GlobalMultiDropdown
            dropDownName={"aed_technician_primary"}
            apiEndpoint={"account/fetch-filter-technician-primary"}
            idKey={"contact_id"}
            valueKey={"contact_name"}
            parentKey={"aedTechnicianPrimary"}
            onSelect={(data) => {
              handleSelectChange(data, "aed_technician_primary");
            }}
            shouldFetchOnOpen={true}
            isCache={false}
            className={"react-select-container custom-select"}
            selectDisabled={false}
            isMultiSelect={true}
            closeMenuSelect={false}
            initialSelectedValue={
              formData?.aed_technician_primary?.length > 0 ? formData?.aed_technician_primary.map((it)=>({...it,contact_id: it.value,contact_name:it.label})) : []
            }
          />
        </Form.Group>

        {/* Country */}
        <Form.Group className="mb-3">
          <Form.Label>Country</Form.Label>
          {/* <Select
            value={formData?.country}
            className="react-select-container"
            options={countryList}
            onChange={(data) => {
              handleSelectChange(data, "country");
            }}
            isMulti
            menuPosition={"fixed"}
            closeMenuOnSelect={false}
          /> */}

          <GlobalMultiDropdown
            dropDownName={"country"}
            apiEndpoint={"account/fetch-filter-country"}
            idKey={"id"}
            valueKey={"country_name"}
            parentKey={"country"}
            onSelect={(data) => {
              handleSelectChange(data, "country");
            }}
            shouldFetchOnOpen={true}
            isCache={false}
            className={"react-select-container custom-select"}
            selectDisabled={false}
            isMultiSelect={true}
            closeMenuSelect={false}
            initialSelectedValue={
              formData?.country?.length > 0 ? formData?.country.map((it)=>({...it,id: it.value,country_name:it.label})) : []
            }
          />
        </Form.Group>

        {/* Customer Type */}
        <Form.Group className="mb-3">
          <Form.Label>Customer Type</Form.Label>
          {/* <Select
            value={formData?.customer_type}
            className="react-select-container"
            options={customerTypeList}
            onChange={(data) => {
              handleSelectChange(data, "customer_type");
            }}
            isMulti
            menuPosition={"fixed"}
            closeMenuOnSelect={false}
          /> */}

          <GlobalMultiDropdown
            dropDownName={"customer_type"}
            apiEndpoint={"account/customer-account-dropdowns"}
            idKey={"dropdown_customer_type_id"}
            valueKey={"customer_type_name"}
            parentKey={"customerType"}
            onSelect={(data) => {
              handleSelectChange(data, "customer_type");
            }}
            shouldFetchOnOpen={true}
            isCache={false}
            className={"react-select-container custom-select"}
            selectDisabled={false}
            isMultiSelect={true}
            closeMenuSelect={false}
            initialSelectedValue={
              formData?.customer_type?.length > 0 ? formData?.customer_type.map((it)=>({...it,dropdown_customer_type_id: it.value,customer_type_name:it.label})) : []
            }
          />
        </Form.Group>

        {/* Distributor */}
        <Form.Group className="mb-3">
          <Form.Label>Distributor</Form.Label>
          {/* <Select
            value={formData?.distributors}
            className="react-select-container"
            options={distributerList}
            onChange={(data) => {
              handleSelectChange(data, "distributors");
            }}
            isMulti
            menuPosition={"fixed"}
            closeMenuOnSelect={false}
          /> */}
          <PaginateAutoComplete
          showCountsOnly={1}
            dropDownName={"distributors"}
            apiEndpoint={"account/distributors-account-dropdowns"}
            idKey={"dropdown_distributor_id"}
            valueKey={"distributor_name"}
            parentKey={"distributors"}
            defaultValue={
              formData?.distributors?.length > 0 ? formData.distributors : null
            }
            onSelect={(data, value) => {
              // handleSelectChange(data, "distributors");
              handleSearchSelectChange(
                value,
                "distributors",
                data,
                "dropdown_distributor_id",
                "distributor_name"
              );
            }}
            shouldFetchOnOpen={true}
            isCache={false}
            className={"react-select-container"}
            selectDisabled={false}
            closeMenuSelect={false}
            multiple={true}
            showCheckBox={true}
          />
        </Form.Group>

        {/* radio buttons */}
        <Form.Group className="mb-3">
          <div className="toggle-align">
            <Form.Label>Equipment</Form.Label>
            <div>
              <CustomToggleButton3
                ToggleName="all-toggle"
                ToggleValue={allToggle}
                changeHandler={(e) => {
                  handleAllRadioChange(!allToggle);
                }}
                is_read_only={false}
              />
            </div>
          </div>
          <div className="toggle-container">
            <div className="toggle-align">
              <div className="my-2">AED</div>
              <div>
                <CustomToggleButton3
                  ToggleName="aed"
                  ToggleValue={formData.aed}
                  changeHandler={(e) => {
                    handleRadioChange(!formData.aed, "aed");
                  }}
                  is_read_only={allToggle === false || false}
                />
              </div>
            </div>
            <div className="toggle-align">
              <div className="my-2">Training</div>
              <div>
                <CustomToggleButton3
                  ToggleName="training"
                  ToggleValue={formData.training}
                  changeHandler={(e) => {
                    handleRadioChange(!formData.training, "training");
                  }}
                  is_read_only={allToggle === false || false}
                />
              </div>
            </div>
          </div>
        </Form.Group>
        {/* Industry */}
        <Form.Group className="mb-3">
          <Form.Label>Industry</Form.Label>
          {/*     <Select
            value={formData?.industry_type}
            className="react-select-container"
            options={industryTypeList}
            onChange={(data) => {
              handleSelectChange(data, "industry_type");
            }}
            isMulti
            menuPosition={"fixed"}
            closeMenuOnSelect={false}
          />  */}

          <GlobalMultiDropdown
            dropDownName={"industry_type"}
            apiEndpoint={"account/industry-account-dropdowns"}
            idKey={"dropdown_industry_id"}
            valueKey={"dropdown_industry_name"}
            parentKey={"industryType"}
            onSelect={(e) => {
              handleSelectChange(e, "industry_type");
            }}
            shouldFetchOnOpen={true}
            isCache={false}
            className={"react-select-container"}
            selectDisabled={false}
            isMultiSelect={true}
            closeMenuSelect={false}
            initialSelectedValue={
              formData?.industry_type?.length > 0 ? formData?.industry_type.map((it)=>({...it,dropdown_industry_id: it.value,dropdown_industry_name:it.label})) : []
            }
          />
        </Form.Group>

        {/* Lead Source */}
        <Form.Group className="mb-3">
          <Form.Label>Lead Source</Form.Label>
          {/*        <Select
            value={formData?.lead_source}
            className="react-select-container"
            options={leadSourceList}
            onChange={(data) => {
              handleSelectChange(data, "lead_source");
            }}
            isMulti
            menuPosition={"fixed"}
            closeMenuOnSelect={false}
          /> */}

          <GlobalMultiDropdown
            dropDownName={"lead_source"}
            apiEndpoint={"account/leadsource-dropdowns"}
            idKey={"dropdown_lead_source_id"}
            valueKey={"dropdown_lead_source_name"}
            parentKey={"leadSources"}
            onSelect={(e) => {
              handleSelectChange(e, "lead_source");
            }}
            shouldFetchOnOpen={true}
            isCache={false}
            className={"react-select-container"}
            selectDisabled={false}
            isMultiSelect={true}
            closeMenuSelect={false}
            initialSelectedValue={
              formData?.lead_source?.length > 0 ? formData?.lead_source.map((it)=>({...it,dropdown_lead_source_id: it.value,dropdown_lead_source_name:it.label})) : []
            }
            showCheckBox={true}
          />
        </Form.Group>

        {/* Parent Account */}
        <Form.Group className="mb-3">
          <Form.Label>Parent Account</Form.Label>
          {/* <Select
            value={formData?.parent_account}
            className="react-select-container"
            options={parentAccountList}
            onChange={(data) => {
              handleSelectChange(data, "parent_account");
            }}
            isMulti
            menuPosition={"fixed"}
            closeMenuOnSelect={false}
          /> */}
          <PaginateAutoComplete
          showCountsOnly={1}
            dropDownName={"parent_account"}
            apiEndpoint={"account/parents-account-dropdowns"}
            idKey={"account_id"}
            valueKey={"account_main_contact_firstname"}
            parentKey={"parentAccount"}
            // defaultValue={
            //   formData?.parent_account?.length > 0
            //     ? formData.parent_account
            //     : null
            // }
            onSelect={(data, value) => {
              // handleSelectChange(data, "distributors");
              handleSearchSelectChange(
                value,
                "parent_account",
                data,
                "account_id",
                "account_main_contact_firstname"
              );
            }}
            shouldFetchOnOpen={true}
            isCache={false}
            className={"react-select-container"}
            selectDisabled={false}
            closeMenuSelect={false}
            multiple={true}
            defaultValue={
              formData?.parent_account?.length > 0 ? formData.parent_account : null
            }
            showCheckBox={true}
          />
        </Form.Group>

        {/* Product Interest */}
        <Form.Group className="mb-3">
          <Form.Label>Product Interest</Form.Label>
          {/*      <Select
            value={formData?.product_interest}
            className="react-select-container"
            options={productInterestList}
            onChange={(data) => {
              handleSelectChange(data, "product_interest");
            }}
            isMulti
            menuPosition={"fixed"}
            closeMenuOnSelect={false}
          /> */}

          <GlobalMultiDropdown
            dropDownName={"product_interest"}
            apiEndpoint={"account/product-dropdown"}
            idKey={"dropdown_product_interest_id"}
            valueKey={"dropdown_product_interest_name"}
            parentKey={"products"}
            onSelect={(e) => {
              handleSelectChange(e, "product_interest");
            }}
            shouldFetchOnOpen={true}
            isCache={false}
            className={"react-select-container"}
            selectDisabled={false}
            isMultiSelect={true}
            closeMenuSelect={false}
            initialSelectedValue={
              formData?.product_interest?.length > 0 ? formData?.product_interest.map((it)=>({...it,dropdown_product_interest_id: it.value,dropdown_product_interest_name:it.label})) : []
            }
          />
        </Form.Group>

        {/* Project Managers Backup */}
        <Form.Group className="mb-3">
          <Form.Label>Project Managers Backup</Form.Label>
          {/*    <Select
            value={formData?.project_managers_backup}
            className="react-select-container"
            options={projectManagersBackupList}
            onChange={(data) => {
              handleSelectChange(data, "project_managers_backup");
            }}
            isMulti
            menuPosition={"fixed"}
            closeMenuOnSelect={false}
          />*/}

          <GlobalMultiDropdown
            dropDownName={"project_managers_backup"}
            apiEndpoint={"account/fetch-filter-manager-backup"}
            idKey={"contact_id"}
            valueKey={"contact_name"}
            parentKey={"projectManagerbackup"}
            onSelect={(e) => {
              handleSelectChange(e, "project_managers_backup");
            }}
            shouldFetchOnOpen={true}
            isCache={false}
            className={"react-select-container"}
            selectDisabled={false}
            isMultiSelect={true}
            closeMenuSelect={false}

            initialSelectedValue={
              formData?.project_managers_backup?.length > 0 ? formData?.project_managers_backup.map((it)=>({...it,contact_id: it.value,contact_name:it.label})) : []
            }
          />
        </Form.Group>

        {/* Project Manager */}
        <Form.Group className="mb-3">
          <Form.Label>Project Managers Primary</Form.Label>
          {/*  <Select
            value={formData?.project_manager}
            className="react-select-container"
            options={projectManagerList}
            onChange={(data) => {
              handleSelectChange(data, "project_manager");
            }}
            isMulti
            menuPosition={"fixed"}
            closeMenuOnSelect={false}
          /> */}
          <GlobalMultiDropdown
            dropDownName={"project_manager"}
            apiEndpoint={"account/fetch-filter-manager-primary"}
            idKey={"contact_id"}
            valueKey={"contact_name"}
            parentKey={"projectManager"}
            onSelect={(e) => {
              handleSelectChange(e, "project_manager");
            }}
            shouldFetchOnOpen={true}
            isCache={false}
            className={"react-select-container"}
            selectDisabled={false}
            isMultiSelect={true}
            closeMenuSelect={false}
            
            initialSelectedValue={
              formData?.project_manager?.length > 0 ? formData?.project_manager.map((it)=>({...it,contact_id: it.value,contact_name:it.label})) : []
            }
          />
        </Form.Group>

        {/* Sales Rep Backup */}
        <Form.Group className="mb-3">
          <Form.Label>Sales Rep Backup</Form.Label>
          {/*  <Select
            value={formData?.sales_rep_backup}
            className="react-select-container"
            options={salesRepBackupList}
            onChange={(data) => {
              handleSelectChange(data, "sales_rep_backup");
            }}
            isMulti
            menuPosition={"fixed"}
            closeMenuOnSelect={false}
          /> */}

          <GlobalMultiDropdown
            dropDownName={"sales_rep_backup"}
            apiEndpoint={"account/fetch-filter-sales-backup"}
            idKey={"contact_id"}
            valueKey={"contact_name"}
            parentKey={"salesManagerbackup"}
            onSelect={(e) => {
              handleSelectChange(e, "sales_rep_backup");
            }}
            shouldFetchOnOpen={true}
            isCache={false}
            className={"react-select-container"}
            selectDisabled={false}
            isMultiSelect={true}
            closeMenuSelect={false}
            initialSelectedValue={
              formData?.sales_rep_backup?.length > 0 ? formData?.sales_rep_backup.map((it)=>({...it,contact_id: it.value,contact_name:it.label})) : []
            }
          />
        </Form.Group>

        {/* Primary Salesman */}
        <Form.Group className="mb-3">
          <Form.Label>Sales Rep Primary</Form.Label>
          {/*      <Select
            value={formData?.sales_name}
            className="react-select-container"
            options={salesManagerList}
            onChange={(data) => {
              handleSelectChange(data, "sales_name");
            }}
            isMulti
            menuPosition={"fixed"}
            closeMenuOnSelect={false}
          /> */}

          <GlobalMultiDropdown
            dropDownName={"sales_name"}
            apiEndpoint={"account/fetch-filter-sales-primary"}
            idKey={"contact_id"}
            valueKey={"contact_name"}
            parentKey={"salesManagerprimary"}
            onSelect={(e) => {
              handleSelectChange(e, "sales_name");
            }}
            shouldFetchOnOpen={true}
            isCache={false}
            className={"react-select-container"}
            selectDisabled={false}
            isMultiSelect={true}
            closeMenuSelect={false}
            initialSelectedValue={
              formData?.sales_name?.length > 0 ? formData?.sales_name.map((it)=>({...it,contact_id: it.value,contact_name:it.label})) : []
            }
          />
        </Form.Group>

        {/* State */}
        <Form.Group className="mb-3">
          <Form.Label>State</Form.Label>
          {/*     <Select
            value={formData?.state}
            className="react-select-container"
            options={stateList}
            onChange={(data) => {
              handleSelectChange(data, "state");
            }}
            isMulti
            menuPosition={"fixed"}
            closeMenuOnSelect={false}
          /> */}

          <GlobalMultiDropdown
            dropDownName={"state"}
            apiEndpoint={"account/fetch-filter-states"}
            idKey={"state_id"}
            valueKey={"state_name"}
            parentKey={"stateList"}
            onSelect={(e) => {
              handleSelectChange(e, "state");
            }}
            shouldFetchOnOpen={true}
            isCache={false}
            className={"react-select-container"}
            selectDisabled={false}
            isMultiSelect={true}
            closeMenuSelect={false}
            initialSelectedValue={
              formData?.state?.length > 0 ? formData?.state.map((it)=>({...it,state_id: it.value,state_name:it.label})) : []
            }
          />
        </Form.Group>

        {/* submit buttons */}
        <div className="mt-4 pb-2 btn-align">
          <button
            className="btn btn-light btn-clear-filter"
            onClick={(e) => {
              handleClearFilter(e);
            }}
          >
            Clear
          </button>
          <button
            className="btn btn-light ms-2 btn-submit-filter"
            style={{
              backgroundColor: "#4fe14fe6",
              color: "#FFF",
              border: "none",
            }}
            type="button"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
