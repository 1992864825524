import React, { useCallback, useEffect, useState } from "react";
import { Alert, Box, Snackbar } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { CallGETAPI, CallPOSTAPI } from "../../../common/services";
import SubHeading from "../../dashboard/components/header/SubHeading";
import TableSkeleton from "../../accounts/components/skeleton/table/TableSkeleton";
import SiteListTbl from "../components/SiteListTbl";
import NewGlobalTable from "../../../common/components/custom-ui/NewGlobalTable";
import { truncateText } from "../../../common/helper/BasicFn";

const SitesListing = ({ setShowSidebar, privileges }) => {
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = React.useState(true);
  const [accounts, setAccounts] = useState([]);
  const [filteredAccount, setFilterdAccount] = useState([]);
  const [openModel, setOpenModel] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [siteDataList, setsiteDataList] = useState([]);
  const [page, setPage] = useState("1");
  const [search, setSearch] = useState("");
  const [totalPage, setTotalPage] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [showSearchReset, setShowSearchReset] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const [searchInput, setSearchINput] = useState({
    name: "",
    equipment: "",
    training: "",
    type: "",
    parent: "",
    distributor: "",
    owner: "",
    secure: "",
  });
  const location = useLocation();
  const showDelete = location?.state?.showDelete;

  const handleCloseModel = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenModel(false);
  };

  const fetchLoad = async () => {
    setShowLoading(true);
    const payload = {
      page: currentPage,
      search: search,
    };
    let result = await CallPOSTAPI("site/all-site-list", payload);
    if (result?.status) {
      let siteData = result?.data?.data || [];
      setsiteDataList(siteData);
      setTotalPage(siteData?.totalPages);
    }
    setShowLoading(false);
  };

  const searchFetchLoad = async () => {
    setShowLoading(true);
    const payload = {
      page: 1,
      search: search,
    };
    let result = await CallPOSTAPI("site/all-site-list", payload);
    if (result?.status) {
      let siteData = result?.data?.data || [];
      setsiteDataList(siteData);
      setTotalPage(siteData?.totalPages);
    }
    setShowLoading(false);
  };

  const fetchResetLoad = async () => {
    setShowLoading(true);
    const payload = {
      page: 1,
      search: "",
    };
    let result = await CallPOSTAPI("site/all-site-list", payload);
    if (result?.status) {
      let siteData = result?.data?.data || [];
      setsiteDataList(siteData);
      setTotalPage(siteData?.totalPages);
      setCurrentPage(1);
    }
    setShowLoading(false);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchLoad();
  }, [currentPage]);

  useEffect(() => {
    let filteredData = accounts;
    if (searchInput.name !== "") {
      filteredData = filteredData.filter(({ account_name }) =>
        account_name
          .toLocaleLowerCase()
          .includes(searchInput.name.toLocaleLowerCase())
      );
    }
    if (searchInput.type !== "") {
      filteredData = filteredData.filter(({ customer_type_name }) =>
        customer_type_name
          .toLocaleLowerCase()
          .includes(searchInput.type.toLocaleLowerCase())
      );
    }
    if (searchInput.parent !== "") {
      filteredData = filteredData.filter(({ parent_name }) =>
        parent_name
          .toLocaleLowerCase()
          .includes(searchInput.parent.toLocaleLowerCase())
      );
    }
    if (searchInput.distributor !== "") {
      filteredData = filteredData.filter(({ distributon_name }) =>
        distributon_name
          .toLocaleLowerCase()
          .includes(searchInput.distributor.toLocaleLowerCase())
      );
    }
    if (searchInput.secure !== "") {
      filteredData = filteredData.filter(
        ({ isSecure }) => isSecure == Number(searchInput.secure)
      );
    }

    setFilterdAccount(filteredData);
  }, [searchInput]);

  const [isAsc, setIsAsc] = useState(false);

  const handleSorting = (key) => {
    let sortedData = [...filteredAccount];
    if (sortedData?.[0]?.[key] === undefined) {
      return;
    }
    // isSecure

    const data = sortedData.sort((a, b) => {
      if (Number.isInteger(a[key])) {
        if (isAsc) {
          return b[key] - a[key];
        } else {
          return a[key] - b[key];
        }
      } else {
        let fa = a[key].toLowerCase(),
          fb = b[key].toLowerCase();
        if (isAsc) {
          if (fa < fb) {
            return 1;
          }
          if (fa > fb) {
            return -1;
          }
          return 1;
        } else {
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        }
      }
    });

    setFilterdAccount(data);
    setIsAsc(!isAsc);
  };

  const handleReset = useCallback(() => {
    setShowSearchReset(false);
    setSearch("");
    fetchResetLoad();
  }, [search]);

// Table Column heading

  const headerColumns = [
    {
      Header: "Site Name",
      accessor: "account_site_name",
      Cell: ({ row }) => (
        <div 
         style={{ textDecoration: "none"}}
         className="link"
         onClick={() => navigate(`/account/site-details/${row.original.account_site_info_id}`)}
        >
          {truncateText(row.original.account_site_name, 20)}
        </div>
      ),
    },
    {
      Header: "Account Name",
      accessor: "account_name",
    },
    {
      Header: "Equipment",
      accessor: "aeds",
    },
    {
      Header: "Training",
      accessor: "trainingCount",
    },
    {
      Header: "Site Poc",
      accessor: "account_site_poc",
    },
  ]

  return (
    <>
      {/* loading */}
      {/* {showLoading && (
        <div className="showloading-table">
          <TableSkeleton />
        </div>
      )} */}

      {/*<div>
        <FixedButton className="btn-style-filter" onClick={handleDrawerOpen}>
          Advanced Filters
        </FixedButton>
        <Drawer
          sx={{
            width: "300px",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: "300px",
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          <Filter
            setaccounts={setAccounts}
            accountListingPage={true}
            setOpen={setOpen}
          />
        </Drawer>
        </div>*/}
      <Snackbar
        open={openModel}
        autoHideDuration={3000}
        onClose={handleCloseModel}
      >
        <Alert
          severity="success"
          sx={{ width: "100%" }}
          onClose={handleCloseModel}
        >
          Record Deleted SuccessFully!!!
        </Alert>
      </Snackbar>
      <div className="mt-4" style={{ paddingInline: "" }}>
        <SubHeading
          hideNew={true}
          hideHierarchy={true}
          title={"Site Listing"}
          // newUrl="/new-account"
          //   newUrl="/admin-account"
          subHeading={true}
        />

        <div style={{ minHeight: "84.6vh" }}>
          {/* <Box className="d-flex justify-content-center py-4">
            <SiteListTbl
              tableData={siteDataList?.siteList || []}
              privileges={privileges}
              showLoading={showLoading}
              setShowLoading={setShowLoading}
              setPage={setPage}
              setSearch={setSearch}
              search={search}
              fetchLoad={fetchLoad}
              searchFetchLoad={searchFetchLoad}
              totalPages={totalPage}
              onPageChange={handlePageChange}
              currentPage={currentPage}
              handleReset={handleReset}
              showSearchReset={showSearchReset}
              setShowSearchReset={setShowSearchReset}
            />
          </Box> */}
          <Box className="py-4">
            <NewGlobalTable
             isSearchable={true}
             isCache={true}
             pageSize={50}
             apiEndPoint={"site/all-site-list"}
             keyAPiName="siteList"
             headerColumns={headerColumns}
            />
          </Box>
        </div>
      </div>
    </>
  );
};

export default SitesListing;
