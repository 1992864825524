import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CallPOSTAPI } from "../../../../common/services";
import GlobalDropdown from "../../../../common/components/custom-ui/GlobalDropdown";

function EditAssignAedTbl({ data, fromAed }) {
  const [editedData, setEditedData] = useState([]);
  const [newArray, setNewArray] = useState({
    equipments: [],
  });
  const [loading, setLoading] = useState(false);

  // Function to find the index of aed_id in newArray
  function findAedIdIndex(newArray, editedData, rowIndex) {
    return newArray?.equipments?.findIndex((item) => Number(item?.aed_id) === Number(editedData[rowIndex]?.aed_id));
  }

  const handlerDropDownChange = (e, selectedText, rowIndex, key, idKey) => {
    const indexResult = findAedIdIndex(newArray, editedData, rowIndex);
    let updatedEquipments = [...newArray.equipments]; // Copy the existing equipments array
  
    const selectedEquipment = updatedEquipments[indexResult] || {
      aed_id: editedData[rowIndex]?.aed_id,
      users: [
        {
          type: "primary",
          primary: editedData[rowIndex]?.assign_user ? JSON.parse(editedData[rowIndex]?.assign_user)[0]?.primary : "", 
          contact_id: editedData[rowIndex]?.assign_user ? JSON.parse(editedData[rowIndex]?.assign_user)[0]?.contact_id : "",
        },
        {
          type: "backup1",
          backup1: editedData[rowIndex]?.assign_user ? JSON.parse(editedData[rowIndex]?.assign_user)[1]?.backup1 : "",
          contact_id: editedData[rowIndex]?.assign_user ? JSON.parse(editedData[rowIndex]?.assign_user)[1]?.contact_id : "",
        },
        {
          type: "backup2",
          backup2: editedData[rowIndex]?.assign_user ? JSON.parse(editedData[rowIndex]?.assign_user)[2]?.backup2 : "",
          contact_id: editedData[rowIndex]?.assign_user ? JSON.parse(editedData[rowIndex]?.assign_user)[2]?.contact_id : "",
        },
        {
          type: "backup3",
          backup3: editedData[rowIndex]?.assign_user ? JSON.parse(editedData[rowIndex]?.assign_user)[3]?.backup3 : "",
          contact_id: editedData[rowIndex]?.assign_user ? JSON.parse(editedData[rowIndex]?.assign_user)[3]?.contact_id : "",
        },
      ],
    };
  
    // Update the specific dropdown type based on key
    if (key === "primary") {
      selectedEquipment.users[0].primary = selectedText == "--Select One--" ? "" : selectedText;
      selectedEquipment.users[0].contact_id = idKey;
    } else if (key === "backup1") {
      selectedEquipment.users[1].backup1 = selectedText == "--Select One--" ? "" : selectedText;
      selectedEquipment.users[1].contact_id = idKey;
    } else if (key === "backup2") {
      selectedEquipment.users[2].backup2 = selectedText == "--Select One--" ? "" : selectedText;
      selectedEquipment.users[2].contact_id = idKey;
    } else if (key === "backup3") {
      selectedEquipment.users[3].backup3 = selectedText == "--Select One--" ? "" : selectedText;
      selectedEquipment.users[3].contact_id = idKey;
    }
  
    // Update equipment array
    if (indexResult !== -1) {
      updatedEquipments[indexResult] = selectedEquipment;
    } else {
      updatedEquipments.push(selectedEquipment);
    }
  
    setNewArray({ ...newArray, equipments: updatedEquipments });
  };
  

  useEffect(() => {
    if (data) {
      setEditedData(data);
    }
  }, [data]);

  const navigate = useNavigate();
  
  const handleSubmit = async () => {
    setLoading(true);
    const response = await CallPOSTAPI("aed/assign-aed-to-user", newArray);
    setLoading(false);
    if (response.data.status) {
      toast.success(response?.data?.msg);

      Number(fromAed) === 0 ? 
      navigate(-1) : 
      navigate(`/account/aed-details/${data[0]?.aed_id}`);    
    } else {
      toast.error(response?.data?.msg);
    }
  };

  return (
    <div className="mt-4 mb-5" style={{ width: "100%", paddingInline: "0px" }}>
      <div className="tableWrapper" id="table-main-20002">
        <table className="theme-table w-100 border-b-blue odd-even-row" style={{ width: "100vw", maxWidth: "100vw" }}>
          <thead className="heading">
            <tr className="border">
              <th className="border border-r-blue" style={{ whiteSpace: "nowrap", fontSize: "14px", maxWidth: "100px", paddingLeft: "10px" }}>AED Brand / Model</th>
              <th className="border border-r-blue" style={{ whiteSpace: "nowrap", fontSize: "14px", maxWidth: "100px", paddingLeft: "10px" }}>Serial #</th>
              <th className="border border-r-blue" style={{ whiteSpace: "nowrap", fontSize: "14px", maxWidth: "100px", paddingLeft: "10px" }}>AED Placement</th>
              <th className="border border-r-blue" style={{ whiteSpace: "nowrap", fontSize: "14px", maxWidth: "100px", paddingLeft: "10px" }}>Primary</th>
              <th className="border border-r-blue" style={{ whiteSpace: "nowrap", fontSize: "14px", maxWidth: "100px", paddingLeft: "10px" }}>Backup 1</th>
              <th className="border border-r-blue" style={{ whiteSpace: "nowrap", fontSize: "14px", maxWidth: "100px", paddingLeft: "10px" }}>Backup 2</th>
              <th className="border border-r-blue" style={{ whiteSpace: "nowrap", fontSize: "14px", maxWidth: "100px", paddingLeft: "10px" }}>Backup 3</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, rowIndex) => (
              <tr key={rowIndex}>
                <td style={{ maxWidth: "200px", paddingLeft: "10px" }}>{item?.aed_brand}</td>
                <td style={{ maxWidth: "200px", paddingLeft: "10px" }}>{item?.serial_number}</td>
                <td style={{ maxWidth: "200px", paddingLeft: "10px" }}>{item?.placement}</td>
                <td style={{ maxWidth: "200px" }}>
                 
                  <GlobalDropdown
                    dropDownName={"primary"}
                    apiEndpoint={"contact/acc-contact-dropdown/" + item?.account_id}
                    idKey={"contact_id"}
                    valueKey={"contact_name"}
                    parentKey={"contact_list"}
                    onSelect={(e) => {
                      const selectedId = e.target.value;
                      const selectedText = e.target.options[e.target.selectedIndex].text;
                      handlerDropDownChange(e, selectedText, rowIndex, "primary", selectedId);
                    }}
                    shouldFetchOnOpen={false}
                    isCache={true}
                    // initialSelectedValue={editedData[rowIndex]?.assign_user ? JSON.parse(editedData[rowIndex]?.assign_user)[0].contact_id : ""}
                    // defaultValue={{
                    //   id: editedData[rowIndex]?.assign_user ? JSON.parse(editedData[rowIndex]?.assign_user)[0].contact_id : "",
                    //   value: editedData[rowIndex]?.assign_user ? JSON.parse(editedData[rowIndex]?.assign_user)[0].primary : "",
                    // }}

                    initialSelectedValue={item.assign_user ? JSON.parse(item.assign_user)[0].contact_id : ""}
                    defaultValue={{
                      id: item.assign_user ? JSON.parse(item.assign_user)[0].contact_id : "",
                      value: item.assign_user ? JSON.parse(item.assign_user)[0].primary : "",
                    }}
                  />
                </td>
                <td style={{ maxWidth: "200px" }}>
                  <GlobalDropdown
                    dropDownName={"backup1"}
                    apiEndpoint={"contact/acc-contact-dropdown/" + item?.account_id}
                    idKey={"contact_id"}
                    valueKey={"contact_name"}
                    parentKey={"contact_list"}
                    onSelect={(e) => {
                      const selectedId = e.target.value;
                      const selectedText = e.target.options[e.target.selectedIndex].text;
                      handlerDropDownChange(e, selectedText, rowIndex, "backup1", selectedId);
                    }}
                    shouldFetchOnOpen={false}
                    isCache={true}
                    initialSelectedValue={item.assign_user ? JSON.parse(item.assign_user)[1].contact_id : ""}
                    defaultValue={{
                      id: item.assign_user ? JSON.parse(item.assign_user)[1].contact_id : "",
                      value: item.assign_user ? JSON.parse(item.assign_user)[1].primary : "",
                    }}
                  />
                </td>

                <td style={{ maxWidth: "200px" }}>
                  <GlobalDropdown
                    dropDownName={"backup2"}
                    apiEndpoint={"contact/acc-contact-dropdown/" + item?.account_id}
                    idKey={"contact_id"}
                    valueKey={"contact_name"}
                    parentKey={"contact_list"}
                    onSelect={(e) => {
                      const selectedId = e.target.value;
                      const selectedText = e.target.options[e.target.selectedIndex].text;
                      handlerDropDownChange(e, selectedText, rowIndex, "backup2", selectedId);
                    }}
                    shouldFetchOnOpen={false}
                    isCache={true}
                    initialSelectedValue={item.assign_user ? JSON.parse(item.assign_user)[2].contact_id : ""}
                    defaultValue={{
                      id: item.assign_user ? JSON.parse(item.assign_user)[2].contact_id : "",
                      value: item.assign_user ? JSON.parse(item.assign_user)[2].primary : "",
                    }}
                  />
                </td>

                <td style={{ maxWidth: "200px" }}>
                  <GlobalDropdown
                    dropDownName={"backup3"}
                    apiEndpoint={"contact/acc-contact-dropdown/" + item?.account_id}
                    idKey={"contact_id"}
                    valueKey={"contact_name"}
                    parentKey={"contact_list"}
                    onSelect={(e) => {
                      const selectedId = e.target.value;
                      const selectedText = e.target.options[e.target.selectedIndex].text;
                      handlerDropDownChange(e, selectedText, rowIndex, "backup3", selectedId);
                    }}
                    shouldFetchOnOpen={false}
                    isCache={true}
                    initialSelectedValue={item.assign_user ? JSON.parse(item.assign_user)[3].contact_id : ""}
                    defaultValue={{
                      id: item.assign_user ? JSON.parse(item.assign_user)[3].contact_id : "",
                      value: item.assign_user ? JSON.parse(item.assign_user)[3].primary : "",
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="row pb-3 py-5">
          <div className="col-12 content-flex-right">
            <button className="btn btn-danger text-uppercase" type="button" onClick={() => navigate(-1)}>
              Cancel
            </button>
            <button className="btn btn-success text-uppercase ms-2" type="button" onClick={handleSubmit} disabled={loading}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditAssignAedTbl;

