import React, { useCallback, useEffect, useState } from "react";
import { Alert, Box, Snackbar } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CallGETAPI, CallPOSTAPI } from "../../../common/services";
import SubHeading from "../../dashboard/components/header/SubHeading";
import TableSkeleton from "../../accounts/components/skeleton/table/TableSkeleton";
import { AccountContactDetails, fetchUserAccountContact, truncateText } from "../../../common/helper/BasicFn";
import { DecryptToken, GetProfile } from "../../../common/helper";
import ContactListTbl from "../components/ContactListTbl";
import NewGlobalTable from "../../../common/components/custom-ui/NewGlobalTable";
import { ContactStatus, formatPhoneNumber } from "../../../common/helper/index.js";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const ContactListing = ({ setShowSidebar }) => {
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = React.useState(true);
  const [accounts, setAccounts] = useState([]);
  const [filteredAccount, setFilterdAccount] = useState([]);
  const [openModel, setOpenModel] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [contactDataList, setContactDataList] = useState([]);
  const [siteContactList, setSiteContactList] = useState([]);
  const [page, setPage] = useState("1");
  const [search, setSearch] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [showSearchReset, setShowSearchReset] = useState(false);
 
  const user = DecryptToken();
  let userData = GetProfile(); //JSON.parse(localStorage.getItem("ross-profile"));
  let account_id = userData?.account_id;

  const [searchInput, setSearchINput] = useState({
    name: "",
    equipment: "",
    training: "",
    type: "",
    parent: "",
    distributor: "",
    owner: "",
    secure: "",
  });
  const location = useLocation();

  const handleCloseModel = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenModel(false);
  };

  const fetchLoad = async () => {
    try {
      setShowLoading(true);
      const payload = {
        page: currentPage,
        search: search,
      };
  
      let result = await CallPOSTAPI("contact/all-contact-list", payload);
  
      if (result?.status) {
        let contactData = result?.data?.data?.contact_list || [];
        if(contactData){
        setContactDataList(contactData);
        setTotalPage(result?.data?.data?.totalPages || 0)
        }
      }
    } catch (error) {
      console.error("Error in fetchLoad:", error);
    } finally {
      setShowLoading(false);
    }
  };
  

  const searchFetchLoad = async () => {
    setShowLoading(true);
    const payload = {
      page: 1,
      search: search,
    }
    setCurrentPage(1);
    let result = await CallPOSTAPI("contact/all-contact-list", payload);
   
    if (result?.status) {
      let contactData = result?.data?.data?.contact_list || [];
      if(contactData){
      setContactDataList(contactData);
      setTotalPage(result?.data?.data?.totalPages || 0)
      }
    }
    setShowLoading(false);
  };

  useEffect(() => {
    fetchLoad();
  }, [currentPage]); 

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    let filteredData = accounts;
    if (searchInput.name !== "") {
      filteredData = filteredData.filter(({ account_name }) =>
        account_name
          .toLocaleLowerCase()
          .includes(searchInput.name.toLocaleLowerCase())
      );
    }
    if (searchInput.type !== "") {
      filteredData = filteredData.filter(({ customer_type_name }) =>
        customer_type_name
          .toLocaleLowerCase()
          .includes(searchInput.type.toLocaleLowerCase())
      );
    }
    if (searchInput.parent !== "") {
      filteredData = filteredData.filter(({ parent_name }) =>
        parent_name
          .toLocaleLowerCase()
          .includes(searchInput.parent.toLocaleLowerCase())
      );
    }
    if (searchInput.distributor !== "") {
      filteredData = filteredData.filter(({ distributon_name }) =>
        distributon_name
          .toLocaleLowerCase()
          .includes(searchInput.distributor.toLocaleLowerCase())
      );
    }
    if (searchInput.secure !== "") {
      filteredData = filteredData.filter(
        ({ isSecure }) => isSecure == Number(searchInput.secure)
      );
    }

    setFilterdAccount(filteredData);
  }, [searchInput]);

  const [isAsc, setIsAsc] = useState(false);

  const handleSorting = (key) => {
    let sortedData = [...filteredAccount];
    if (sortedData?.[0]?.[key] === undefined) {
      return;
    }
    // isSecure

    const data = sortedData.sort((a, b) => {
      if (Number.isInteger(a[key])) {
        if (isAsc) {
          return b[key] - a[key];
        } else {
          return a[key] - b[key];
        }
      } else {
        let fa = a[key].toLowerCase(),
          fb = b[key].toLowerCase();
        if (isAsc) {
          if (fa < fb) {
            return 1;
          }
          if (fa > fb) {
            return -1;
          }
          return 1;
        } else {
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        }
      }
    });

    setFilterdAccount(data);
    setIsAsc(!isAsc);
  };

  const handleReset = useCallback(() => {
    setShowSearchReset(false);
    setSearch("");
    fetchResetLoad();
  }, [search]);

  const fetchResetLoad = async () => {
    setShowLoading(true);
    const payload = {
      page: 1,
      search: "",
    };
    setCurrentPage(1);
    let result = await CallPOSTAPI("contact/all-contact-list", payload);
    if (result?.status) {
      let contactData = result?.data?.data?.contact_list || [];
      setContactDataList(contactData);
      setTotalPage(result?.data?.data?.totalPages);
    }
    setShowLoading(false);
  };

// RENDER FUNCTIONS:

const RenderContact = (data) => {
  return (
    <>
      <span onClick={() => navigate("/account/" + user?.account_id + "/contact-details/" + data.contact_id)}
       className = "link"
      >
        {truncateText(data?.contact_name, 20)}
      </span>
    </>
  );
};

const RenderEmail = (email) => {
  return (
    <>
      {email?.email.length > 0 && (
        <a className="link" href={"mailto:" + email?.email?.[0].email}>
          {truncateText(email?.email?.[0].email, 20)}
        </a>
      )}
    </>
  );
};

const RenderPhone = (phone) => {
  return (
    <>
      {phone?.phone?.[0].phone != "" && phone?.phone?.[0].phone != "-" && (
        <a
          className="link"
          style={{ textDecration: "none !important" }}
          href={"tel:" + phone?.phone?.[0].ext + phone?.phone?.[0]?.phone}
        >
          {phone?.phone?.[0].phone
            ? formatPhoneNumber(phone?.phone?.[0].phone)
            : ""}
          {phone?.phone?.[0].ext != "" ? "x" + phone?.phone?.[0].ext : ""}
        </a>
      )}
    </>
  );
};

const RenderUser = (data) => {
  return (
    <>
      {data?.user ? (
        <CheckIcon color={"success"} />
      ) : (
        <CloseIcon color="error" />
      )}
    </>
  );
};

const RenderStatus = (data) => {
  return (
    <>
      <p className={data?.status == 1 ? "" : "text-danger"}>
        {ContactStatus[data?.status]}
      </p>
    </>
  );
};

const headerColumns = [
  {
    Header: "Contact",
    accessor: "contact_name",
    Cell: ({ row }) => (
      <div style={{textDecoration:'none' }}>
        {RenderContact(row.original)}
      </div>
    ),
    width: "18%",
  },
  {
    Header: "User",
    accessor: "user",
    Cell: ({ row }) => (
      <div style={{textDecoration:'none' }}>
        {RenderUser(row.original)}
      </div>
    ),
    disableSortBy: true,
  },
  {
    Header: "Phone",
    accessor: "phone",
    Cell: ({ row }) => RenderPhone(row.original),
    disableSortBy: true,
  },
  {
    Header: "Email",
    accessor: "email",
    disableSortBy: true,
    Cell: ({ row }) => RenderEmail(row.original),
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ row }) => RenderStatus(row.original),
  },
  {
    Header: "Account",
    accessor: "account_name",
    Cell: ({ row }) => truncateText(row.original.account_name, 20),
  },
];


  return (
    <>
      <Snackbar
        open={openModel}
        autoHideDuration={3000}
        onClose={handleCloseModel}
      >
        <Alert
          severity="success"
          sx={{ width: "100%" }}
          onClose={handleCloseModel}
        >
          Record Deleted SuccessFully!!!
        </Alert>
      </Snackbar>
      <div className="mt-4" >
        <SubHeading
          hideNew={true}
          hideHierarchy={true}
          title={"Contact Listing"}
          subHeading={true}
        />

        <div style={{ minHeight: "84.6vh" }}>
          {/* <Box className="d-flex justify-content-center py-4">
            <ContactListTbl
              tableData={contactDataList && contactDataList || []}
              showLoading={showLoading}
              setShowLoading={setShowLoading}
              setPage={setPage}
              search={search}
              setSearch={setSearch}
              fetchLoad={fetchLoad}
              searchFetchLoad={searchFetchLoad}
              totalPages={totalPage}
              onPageChange={handlePageChange}
              currentPage={currentPage}
              handleReset={handleReset}
              showSearchReset={showSearchReset}
              setShowSearchReset={setShowSearchReset}
              setContactDataList={setContactDataList}
              setCurrentPage={setCurrentPage}
            />                                                                                                                                                                                                                                                                  
          </Box> */}
          <Box className="py-4">
           <NewGlobalTable
           isSearchable={true}
           isCache={true}
           pageSize={50}
           apiEndPoint={"contact/all-contact-list"}
           keyAPiName="contact_list"
           headerColumns={headerColumns}
           />
          </Box>
        </div>
      </div>
    </>
  );
};

export default ContactListing;



