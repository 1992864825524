import React, { useState } from 'react';
import CustomTabBar from '../../../common/components/tabs';
import { DecryptToken, getPermission } from '../../../common/helper';
import { isContactPermission } from '../../../common/helper/permission';
import ContactDetails from './tabComponents/ContactDetails';

const ContactsTab = () => {
  const [activeTab, setActiveTab] = useState('CONTACT_DETAILS');

  const list = [
    { title: "Details", id: "CONTACT_DETAILS" },
    // { title: "Contacts", id: "CONTACT_LISTING" },
    // { title: "Equipment", id: "EQUIPMENT_LISTING" },
  ];

  const componentsList = {
    CONTACT_DETAILS: <ContactDetails />,
    // CONTACT_LISTING: <ContactListing accountId={accountId} siteId={siteId} />,
  };

  const handleTabChange = (val) => {
    setActiveTab(val);
  };
  

  return (
    <>
     {isContactPermission(activeTab) ? (
        <div className='w-full'>
          <CustomTabBar 
            tabs={list}
            onTabChange={handleTabChange}
          />
          {componentsList?.[activeTab]}
        </div>
      ) : null}
    </>
  );
};

export default ContactsTab;
