import { faCaretDown, faChartBar, faChartLine, faCog, faEnvelope, faHome, faListAlt, faSignOutAlt, faUserPlus, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {  useState } from 'react'
import { Nav, Offcanvas } from 'react-bootstrap'
import { HandleLogout } from '../../helper/BasicFn';
import { Link, useNavigate } from 'react-router-dom';



// Custom Dropdown component for sidebar
const SidebarDropdown = ({ title, icon, children, isOpen, onToggle }) => {


    return (
      <Nav.Item>
        <Nav.Link onClick={onToggle} className="sidebar-dropdown-toggle">
          <FontAwesomeIcon icon={icon} className="me-2" />
          {title} &nbsp; <FontAwesomeIcon icon={faCaretDown} className={`ms-auto ${isOpen ? 'rotate-icon' : ''}`} />
        </Nav.Link>
        <div className={`sidebar-dropdown   ${isOpen?'show':'none'}   `} style={{display:isOpen ? 'block':'none'}}>
          <Nav className="flex-column">
            {children}
          </Nav>
        </div>
      </Nav.Item>
    );
  };


const UserSidebar = ({show,handleClose})=> {
    const [openDropdown, setOpenDropdown] = useState(null);

    const toggleDropdown = (dropdownName) => {
        setOpenDropdown(prevState => prevState === dropdownName ? null : dropdownName);
      };
      const navigate  = useNavigate();
      const handleLogoutClick = async ()=>{
        await HandleLogout();
      }
  return (
    <>
  
  <Offcanvas show={show} onHide={handleClose} style={{width: '300px',background:'#000'}} id="sidebar-menus">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="text-white">Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
          <Link to="/user-dashboard" className="nav-link">
          {/* <FontAwesomeIcon icon={faListAlt} className="me-2" /> */}
          Dashboard</Link>
          {/* <Link to="/Admin/Sites-listing" className="nav-link"> */}
          {/* <FontAwesomeIcon icon={faListAlt} className="me-2" /> */}
          {/* Site Listing</Link> */}
          {/* <Link to="/Admin/Contact-listing" className="nav-link"> */}
          {/* <FontAwesomeIcon icon={faListAlt} className="me-2" /> */}
          {/* Contact Listing</Link> */}
          {/* <Link to="/Admin/accessory-listing" className="nav-link"> */}
          {/* <FontAwesomeIcon icon={faListAlt} className="me-2" /> */}
          {/* Accessory Listing</Link> */}

          {/* Admin/accessory-listing */}

            {/* <Nav.Link href="#dashboard"><FontAwesomeIcon icon={faHome} className="me-2" />Dashboard</Nav.Link> */}
            
{/* 
            <SidebarDropdown 
              title="Customers" 
              icon={faUsers} 
              isOpen={openDropdown === 'customers'}
              onToggle={() => toggleDropdown('customers')}
            >
                
              <Nav.Link href="#customers/list"><FontAwesomeIcon icon={faListAlt} className="me-2" />Customer List</Nav.Link>
              <Nav.Link href="#customers/add"><FontAwesomeIcon icon={faUserPlus} className="me-2" />Add Customer</Nav.Link>
            </SidebarDropdown>

            <SidebarDropdown 
              title="Analytics" 
              icon={faChartLine}
              isOpen={openDropdown === 'analytics'}
              onToggle={() => toggleDropdown('analytics')}
            >
              <Nav.Link href="#analytics/sales"><FontAwesomeIcon icon={faChartBar} className="me-2" />Sales Analytics</Nav.Link>
              <Nav.Link href="#analytics/customer"><FontAwesomeIcon icon={faUsers} className="me-2" />Customer Analytics</Nav.Link>
            </SidebarDropdown>

            <SidebarDropdown 
              title="Communication" 
              icon={faEnvelope}
              isOpen={openDropdown === 'communication'}
              onToggle={() => toggleDropdown('communication')}
            >
              <Nav.Link href="#communication/email"><FontAwesomeIcon icon={faEnvelope} className="me-2" />Email Campaigns</Nav.Link>
              <Nav.Link href="#communication/chat"><FontAwesomeIcon icon={faEnvelope} className="me-2" />Chat</Nav.Link>
            </SidebarDropdown>

            <Nav.Link href="#settings"><FontAwesomeIcon icon={faCog} className="me-2" />Settings</Nav.Link> */}
            {/* <Nav.Link href="#logout" onClick={handleLogoutClick}><FontAwesomeIcon icon={faSignOutAlt} className="me-2" />Logout</Nav.Link> */}
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>


    </>
  )
}

export default UserSidebar