import { Box, Skeleton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { truncateText } from '../../../common/helper/BasicFn';

const CoorrInfo = ({ CoordiDataList, loading, accountId = "" }) => {
  const navigate = useNavigate();
  return (
    <>
      <Box>
        <h4 className="heading mt-3">Coordinator Information</h4>
        <table className="w-100 last-table-border-hide">
          <tbody>
            <tr className="">
              {CoordiDataList?.map(
                (CoorD, index) =>
                  CoorD.title !== "Shipping Contact" &&
                  CoorD.title !== "Billing Coordinator" && (
                    <td
                      className="py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                      key={index}
                    >
                      {CoorD.title}
                    </td>
                  )
              )}
            </tr>

            {/* {loading ? <SkeletonTable/> : <> */}
            <tr>
              {CoordiDataList?.map(
                (CoorD, i1) =>
                  CoorD.title !== "Shipping Contact" &&
                  CoorD.title !== "Billing Coordinator" && (
                    <td className="py-1 px-2 border-r-blue" key={i1}>
                      {loading ? (
                        <>
                          <Skeleton
                            count={5}
                            width={"90%"}
                            height={20}
                            style={{ marginLeft: "2px", marginRight: "2px" }}
                          />
                        </>
                      ) : (
                        <>
                          P:
                          <span
                            onClick={() => {
                              navigate(
                                "/account/" +
                                  accountId +
                                  "/contact-details/" +
                                  (CoorD?.arr?.[0]?.contact_id),
                                  //  || CoorD?.arr?.[1]?.contact_id),
                                {
                                  state: {
                                    siteTitle:
                                      "Contact : " +
                                      (CoorD?.arr?.[0]?.primary), 
                                        // || CoorD?.arr?.[1]?.primary),
                                    editUrl:
                                      "/account/contact-details-edit/" +
                                      (CoorD?.arr?.[0]?.contact_id ||
                                        CoorD?.arr?.[1]?.contact_id),
                                    deleteUrl:
                                      "/account/contact-details-edit/" +
                                      (CoorD?.arr?.[0]?.contact_id ||
                                        CoorD?.arr?.[1]?.contact_id),
                                  },
                                }
                              );
                            }}
                            className="link"
                          >
                            {" "}
                            {truncateText(
                              CoorD?.arr?.[0]?.primary || CoorD?.arr?.[1]?.primary || "",
                              15 // Maximum length before truncation
                            )}
                            {/* {" "}
                            {CoorD?.arr?.[0]?.primary ||
                              CoorD?.arr?.[1]?.primary} */}
                          </span>
                        </>
                      )}
                    </td>
                  )
              )}
            </tr>

            <tr>
              {CoordiDataList?.map(
                (CoorD, i1) =>
                  CoorD.title !== "Shipping Contact" &&
                  CoorD.title !== "Billing Coordinator" && (
                    <td
                      className="py-1 px-2 border-b-blue border-r-blue"
                      key={i1}
                    >
                      {loading ? (
                        <>
                          <Skeleton
                            count={5}
                            width={"90%"}
                            height={20}
                            style={{ marginLeft: "2px", marginRight: "2px" }}
                          />
                        </>
                      ) : (
                        <>
                          {" "}
                          B:
                          <span
                            onClick={() => {
                              navigate(
                                "/account/" +
                                  accountId +
                                  "/contact-details/" +
                                  // CoorD?.arr?.[0]?.contact_id ||
                                  CoorD?.arr?.[1]?.contact_id,
                                {
                                  state: {
                                    siteTitle:
                                      "Contact : " + 
                                      // CoorD?.arr?.[0]?.backup ||
                                      CoorD?.arr?.[1]?.backup,
                                    editUrl:
                                      "/account/contact-details-edit/" +
                                        CoorD?.arr?.[0]?.contact_id ||
                                      CoorD?.arr?.[1]?.contact_id,
                                    deleteUrl:
                                      "/account/contact-details-edit/" +
                                        CoorD?.arr?.[0]?.contact_id ||
                                      CoorD?.arr?.[1]?.contact_id,
                                  },
                                }
                              );
                            }}
                            className="link"
                          >
                            {" "}
                            {truncateText(
                              CoorD?.arr?.[0]?.backup || CoorD?.arr?.[1]?.backup || "",
                              18 // Maximum length before truncation
                            )}
                            {/* {" "}
                            {CoorD?.arr?.[0]?.backup || CoorD?.arr?.[1]?.backup} */}
                          </span>
                        </>
                      )}
                    </td>
                  )
              )}
            </tr>
            {/* </>} */}
          </tbody>
        </table>
      </Box>
    </>
  );
};


export default CoorrInfo;
