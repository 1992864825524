// breadcrumbsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  breadcrumbs: []
};

const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    setBreadcrumbs: (state, action) => {
      state.breadcrumbs = action.payload;
    },
    addBreadcrumb: (state, action) => {
      // state.breadcrumbs.push(action.payload);
      state.breadcrumbs = [...state.breadcrumbs, action.payload];

    },
    // removeBreadcrumb: (state, action) => {
    //   state.breadcrumbs = state.breadcrumbs.filter(
    //     breadcrumb => breadcrumb.path !== action.payload
    //   );
    // },
    removeBreadcrumb: (state,action) => {
      // state.breadcrumbs = state.breadcrumbs.slice(0, -1);
      const arr = state.breadcrumbs;
      const newArr = [];
        for (let index = 0; index < arr.length; index++) {
          const element = arr[index];
          if(element.path === action.payload){
            break;
          }
          newArr.push(element)
        }
        state.breadcrumbs =  newArr;
    },
    clearBreadcrumbs: (state) => {
      state.breadcrumbs = [];
    }
  }
});

export const { setBreadcrumbs, addBreadcrumb, removeBreadcrumb, clearBreadcrumbs } = breadcrumbsSlice.actions;

export default breadcrumbsSlice.reducer;