import React from 'react';
import CustomToggleButton2 from '../../../../common/components/toggleSwitch/CustomToggleButton2';
import SupportTicketTableSkeleton from './AedSkeletons/SupportTicketTableSkeleton';

const SupportTicketTable = ({ formData, handleToggleChange, HIC, isLoading }) => {
  if (isLoading) {
    return <SupportTicketTableSkeleton />;
  }

  return (
    <table
      className="theme-table"
      style={{
        maxHeight: "400px",
        overflowY: "auto",
        padding: "0px",
      }}
    >
      <tbody>
        <tr>
          <td>
            <div style={{ display: "flex", alignItems: "center" }}>
              Do you need to submit a support ticket?
            </div>
          </td>
          <td>
            <div style={{ display: "flex", marginLeft: "23.8%" }}>
              <CustomToggleButton2
                ToggleName="support_tickets_toggle"
                ToggleValue={formData?.support_tickets_toggle}
                changeHandler={handleToggleChange}
                is_read_only={true}
              />
            </div>
          </td>
        </tr>

        {formData?.support_tickets_toggle === 1 && (
          <tr>
            <td>
              {formData?.issue_type}
            </td>
            <td>
              <textarea
                className="form-control"
                placeholder="Enter Text Here..."
                name="issue"
                value={formData?.issue}
                onChange={HIC}
                readOnly
              ></textarea>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default SupportTicketTable;