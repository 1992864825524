//Table for showing no data
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import TopAedNewMove from "../../../../Aed/NewAed/components/AedButtons/TopAedNewMove";

export default function EquipmentNoDataTbl({
  accountId,
  accountName,
  siteId,
  siteName,
  newAed = 1,
  hideAccMove=1,
  hideAedMove=1
}) {
  const navigate = useNavigate();

  return (
    <>
     
      <div className="d-flex align-items-center justify-content-end p-0" 
       style={{marginTop:"-55px", marginRight:"20px"}}
        >
          <TopAedNewMove
              accountId={accountId}
              accountName={accountName}
              siteId={siteId}
              siteName={siteName}
              moveAed={1}
              hideAccMove={hideAccMove}
              hideAedMove={hideAedMove}
              isserviceCheck={0}
            />
        </div>
      <table className="table data-table my-4 theme-table">
        <thead className="thread-style">
          <tr>
            <th className="border border-2 py-1 px-2 border-r-blue border-t-blue">
              AED Brand / Model
            </th>
            <th className="border border-2 py-1 px-2 border-r-blue border-t-blue">
              Serial Number
            </th>
            <th className="border border-2 py-1 px-2 border-r-blue border-t-blue">
              AED Placement
            </th>
            <th className="border border-2 py-1 px-2 border-r-blue border-t-blue">
              Battery Expiration
            </th>
            <th className="border border-2 py-1 px-2 border-r-blue border-t-blue">
              Pads Expiration
            </th>
            <th className="border border-2 py-1 px-2 border-r-blue border-t-blue">
              Last Check
            </th>
            <th className="border border-2 py-1 px-2 border-r-blue border-t-blue">
              Last Service
            </th>
            <th className="border border-2 py-1 px-2 border-r-blue border-t-blue">
              RMS Check
            </th>
          </tr>
        </thead>
        <tbody className="bordered-table">
          <tr>
            <td colSpan={8} className="text-center">
              No Data Found
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
